import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Badge,
  Button,
  Typography,
  IconButton,
  Grid,
  Tooltip,
  Avatar,
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  FormControl,
  FormLabel,
  Select
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "./AdminConsole.web.css";
import "./AdminConsoleFarmerUserScreen.web.css";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import CustomModal from "../../../components/src/customModal.web";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CloseIcon from "@material-ui/icons/Close";
import AdminUserForm from "../../../components/src/AdminUserForm.web";
import FieldExecutiveForm from "../../../components/src/FieldExecutiveForm.web";
import FarmerUserForm from "../../../components/src/FarmerForm.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import AssignVillageFilter from "../../../components/src/AssignVillageFilter";
import ImageCropEditor from "../../../components/src/ImageCropper";
import moment from "moment";
import { Link } from "react-router-dom"

import {
  linkSeparatorSVG,
  AddUserPlusIconSVG,
} from "./assets";

const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
      textTransform: "uppercase",
      color: "#1C2324",
    },
    fontFamily: "IBM Plex Sans",
  },
});

import AdminConsoleFarmerUserScreenController, {
  Props,
  configJSON,
} from "./AdminConsoleFarmerUserScreenController";
import UserFarmDiary from "../../AdminConsole2/src/UserFarmDiary.web";
import { SchemaAdminFormADD, SchemaFarmerForm, SchemaFieldExecutiveForm, getErrorMessage, getHelperText, getUserFullName, keyDown, keyDownInt } from "../../../components/src/helper";
import { getCustomErrorMessage, unVerifiedBadge} from "../../../components/src/AdminConsoleHelper";
import { UserBasedSurveys } from "../../../components/src/UserBasedSurvery.web";
import LinkShare from "../../LinkShare/src/LinkShare";

const { screenFarmerDefaultsTexts: screenDefaults } = configJSON;

export default class AdminConsoleFarmerUserScreen extends AdminConsoleFarmerUserScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }




  disaplyLeftBlock = () => {
    const feName = `${this.state.currentFarmerData.assigned_field_executive?.first_name || ''} 
    ${this.state.currentFarmerData.assigned_field_executive?.last_name || ''}`;
    return (
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        className="farmerViewRightSection"
      >
        <Grid item xs={12}>
          <Box
            className="leftBlock assignedFEBox"
            component="span"
            style={profileBlockStyles.labelSpan}
            mb={4}
          >
            <Box style={profileBlockStyles.profileLabelRight}>
              Assigned Field Executive&#58;
            </Box>
            <Tooltip title={feName}>
              <Typography noWrap style={profileBlockStyles.profileLabelValueRight}>
                {feName}
              </Typography>
            </Tooltip>
          </Box>
        </Grid>

        <Grid
          container
          item
          xs={12}
          className="farmerViewRightSectionBoxes"
        >
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <Box className="leftBlock">
              <Box className="cardLabel">Sowing Date</Box>
              <Typography noWrap className="cardLabelValue">
                {this.state.currentFarmerData.sowing_date ? moment(this.state.currentFarmerData.sowing_date).format("DD MMMM YYYY") : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <Box className="leftBlock">
              <Box className="cardLabel">Current Crop Phase</Box>
              <Typography noWrap className="cardLabelValue">
                {this.state.currentFarmerData.current_phase?.length ? this.state.currentFarmerData.current_phase.join('') : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <Box className="leftBlock">
              <Box className="cardLabel">Total Expense till now</Box>
              <Typography noWrap className="cardLabelValue">
                ₹ {this.showTotalExpense()}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="leftBlock">
              <Box className="cardLabel">Total sold cotton till now</Box>
              <Typography noWrap className="cardLabelValue">
                {this.showTotalCottonSold()} kg
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {!!this.shouldRenderUserFarmDiary() ? (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <UserFarmDiary data={this.state.farmDiaryData} {...this.props} landType={this.state.currentFarmerData.land_details?.[0]?.attributes?.land_type_id?.[0]?.label} />
          </Grid>
        ) : null}

        {!!this.shouldRenderUserBasedSurveys() ? (
          <Grid item xs={12}>
            <UserBasedSurveys data={this.state.userSurveyData} handleAccordionChange={this.handleSurveyAccordionChange} isExpanded={this.state.surveyExpanded} handleShareLink={this.handleShareLink} />
          </Grid>
        ) : null}
      </Grid>
    );
  };
  profileDetail = ({ label, value, formatter = (val: any) => val || '-' }: { label: string; value: string; formatter?: any }) => (
    <Box component="span" style={profileBlockStyles.labelSpan}>
      <Grid xs={6}>
        <Tooltip title={label}>
          <Typography style={profileBlockStyles.profileLabel} noWrap>
            {label}&#58;
          </Typography>
        </Tooltip>
      </Grid>
      <Grid xs={6}>
        <Tooltip title={formatter(value)}>
          <Typography noWrap style={profileBlockStyles.profileLabelValue}>
            {formatter(value)}
          </Typography>
        </Tooltip>
      </Grid>
    </Box>
  );


  assignFEPlaceholder = () => {
    return this.state.selectedFEformData ? "" : "Search by field executive name";
  };

  getAddUserModalTitleTxt = () => {
    return this.state.openEditUserModal === true ? "Edit User" : "Add New User";
  };

  getSubmitFormBtnTxt = () => {
    const updateBtnTxt = this.state.updateFarmerLoading ? "Updating..." : "Update User"
    const addBtnTxt = this.state.addFarmerLoading || this.state.addFELoading || this.state.addAdminLoading || this.state.addSALoading ? "Adding..." : "Add User"
    return (this.state.openEditUserModal === true ? updateBtnTxt : addBtnTxt)
  };

  getEyeIconConfirmPassword = () => {
    return this.state.showEyeIconConfirmPassword ? (
      <Visibility style={webStyle.customDateIcon} />
    ) : (
      <VisibilityOff style={webStyle.customDateIcon} />
    );
  };

  getshowEyeIcon = () => {
    return this.setState({
      showEyeIconPassword: !this.state.showEyeIconPassword,
    });
  };

  showEyeIconConfirm = () => {
    return this.setState({
      showEyeIconConfirmPassword: !this.state.showEyeIconConfirmPassword,
    });
  };

  villageNameAvatar = (index: number, option: any) => {
    return (
      <>
        <Avatar
          style={{
            backgroundColor: `${index % 2 === 0 ? "#108B88" : "#EB8F06"}`,
            color: "#fff",
            height: "28px",
            width: "28px",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "18px",
          }}
        >
          {option?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </>
    );
  };

  anchorCurrentTarget = (event: any) => {
    this.setState({
      anchorElVillageFilter: event.currentTarget,
    });
  };

  getEyeIconPassword = () => {
    return this.state.showEyeIconPassword ? (
      <Visibility style={webStyle.customDateIcon} />
    ) : (
      <VisibilityOff style={webStyle.customDateIcon} />
    );
  };

  handleSubmitAdmin = (values: any) => {
    if (this.state.seletedUserTypeAddUser === "SA") {
      this.addSuperAdmin(values);
    } else {
      this.addAdmin(values);
    }
  };

  handleSubmitFEUser = (values: any) => {
    this.addFieldExecutive(values);
  };

  showTotalCottonSold = () => {
    const { total_sold_cotton_till_now = 0 } = this.state.currentFarmerMeta;
    return total_sold_cotton_till_now;
  }

  showTotalExpense = () => {
    const { total_expense_till_now = 0 } = this.state.currentFarmerMeta;
    return total_expense_till_now;
  }

  shouldRenderUserBasedSurveys = () => {
    return this.state.userSurveyData && Array.isArray(this.state.userSurveyData) && this.state.userSurveyData.length;
  }

  shouldRenderUserFarmDiary = () => {
    return this.state.farmDiaryData && Array.isArray(this.state.farmDiaryData) && this.state.farmDiaryData.length;
  }
  adminAdharCard = () => {
    return this.state.currentFarmerData.aadhaar_number != null ? this.state.currentFarmerData.aadhaar_number : '-'
  }
  adminPhoneNumber = () => {
    return this.state.currentFarmerData.phone_number ? "+91-" + this.state.currentFarmerData.phone_number : ""
  }
  adminAvtar = () => {
    const name = getUserFullName(this.state.currentFarmerData.first_name, this.state.currentFarmerData.last_name)
    return (
      name || this.state.currentFarmerData.avatar?.url ?
        <Avatar style={profileBlockStyles.profileAvatar} src={this.state.currentFarmerData.avatar?.url}>
          {name[0]}
        </Avatar> : <Avatar style={profileBlockStyles.profileAvatar} />
    )
  }

  farmerProfileSection = () => this.state.farmerProfileImage?.icon && <ImageCropEditor open={this.state.cropImageModal} src={this.state.farmerProfileImage.icon} onClose={this.handleCloseCropModalFarmer} title="Farmer profile" buttonText="Submit" handleUploadImage={this.handleSubmitFarmerProgfileImage} />


  render() {
    // Customizable Area Start
    const { navigation, id } = this.props;
    const { currentFarmerData } = this.state;
    const ProfileDetail = this.profileDetail;    
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={navigation} id={id}>
        <ThemeProvider theme={theme}>
          <Container className="mainConatiner" maxWidth={"xl"}>
            {this.farmerProfileSection()}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Box display="flex" gridGap="10px" alignItems="center">
                  <Typography
                    style={{
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "48px",
                      color: "#1C2324",
                    }}
                  >
                    Users
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row-reverse"
                gridGap={5}
              >
                <Button
                  variant="contained"
                  style={webStyle.addUserModalBtnStyle}
                  data-testid="addUserModalBtn"
                  onClick={() => this.showUserModal()}
                >
                  <img src={AddUserPlusIconSVG} alt="+" style={{ marginRight: "8px" }} />Add User
                </Button>
              </Box>
            </Box>

            <Box className="breadcrumbMain" mb={4}>
              <Breadcrumbs
                separator={<img src={linkSeparatorSVG} alt={"separator"} />}
                aria-label="breadcrumb"
              >
                <Link style={{ textDecoration: "none"}} className="menuLinkUser" to="/users" >
                  Users
                </Link>
                <Typography className="menuTextUser">View Profile</Typography>
              </Breadcrumbs>
            </Box>

            <Grid
              container
              direction="row"
              xs={12}
            >
              <Grid item lg={6} md={6} sm={12} xs={12} style={adminViewStyle.maincard} className="farmerViewLeftSection">
                <Grid
                  container
                  xs={12}
                  style={profileBlockStyles.profileContainer}
                >
                  <Grid item xs={12}>
                    <Box
                      style={profileBlockStyles.profileAvatarContainer}
                    >
                      <input type="file" id="upload" data-test-id="uploadProfileButton" onChange={(event) => event.currentTarget?.files?.length && this.onProfileImageUpload(event)} accept="image/*" style={{ display: 'none' }} />
                      <Box>
                        <label htmlFor="upload" style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledBadge
                            overlap="rectangular"
                            badgeContent={
                              <PhotoCamera
                                fontSize="medium"
                                style={profileBlockStyles.profileIconButton}
                              />
                            }
                            data-testid="badge-content"
                          >
                            <IconButton
                              disableRipple={true}
                              aria-label="camera-edit_icon"
                              style={profileBlockStyles.avatarContainer}
                            >
                              {this.adminAvtar()}
                            </IconButton>
                          </StyledBadge>
                        </label>
                      </Box>
                      <Box className="profileName">
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Tooltip
                          title={getUserFullName(currentFarmerData.first_name,currentFarmerData.last_name)}
                        >
                          <Typography
                            noWrap
                            data-testid="profile-name"
                            style={
                              profileBlockStyles.avatarTypographyTitle
                            }
                          >{getUserFullName(currentFarmerData.first_name,currentFarmerData.last_name)}</Typography>
                        </Tooltip>
                        <span>{unVerifiedBadge(!currentFarmerData.verified)}</span>
                        </Box>                      
                        <Typography
                          noWrap
                          style={
                            profileBlockStyles.avatarTypographyContact
                          }
                        >
                          {this.adminPhoneNumber()}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      noWrap
                      style={profileBlockStyles.profileSubTitle}
                    >
                      Personal Details
                    </Typography>
                  </Grid>
                  
                  <Grid container xs={12} >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <ProfileDetail label={screenDefaults.profileAadhar} value={this.adminAdharCard()} />
                    </Grid>
                  </Grid>

                  <Grid container xs={12} style={{ paddingBottom: "15px" }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Box
                        style={profileBlockStyles.profileLabelContainer}
                      >
                        <ProfileDetail label={screenDefaults.profileGender} value={currentFarmerData.gender} />
                        <ProfileDetail label={screenDefaults.profileEducation} value={currentFarmerData.highest_education_id?.[0].name} />
                        <ProfileDetail label={screenDefaults.profileMobileType} value={currentFarmerData.mobile_type_id?.[0].name} />
                        <ProfileDetail label={screenDefaults.profileState} value={currentFarmerData.state_id?.[0].name} />
                        <ProfileDetail label={screenDefaults.profileDistrict} value={currentFarmerData.district_id?.[0].name} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Box
                        style={profileBlockStyles.profileLabelContainer}
                      >
                        <ProfileDetail label={screenDefaults.profileTextDOB} value={currentFarmerData.date_of_birth} formatter={(val: any) => val ? moment(val).format("DD MMMM YYYY") : ''} />
                        <ProfileDetail label={screenDefaults.profileFamily} value={currentFarmerData.total_family_members} />
                        <ProfileDetail label={screenDefaults.profileBelongsTo} value={currentFarmerData.number_belongs_to_id?.[0].name} />
                        <ProfileDetail label={screenDefaults.profileTaluka} value={currentFarmerData.taluka_id?.[0].name} />
                        <ProfileDetail label={screenDefaults.profileVillage} value={currentFarmerData.village_id?.[0].name} />
                      </Box>
                    </Grid>
                  </Grid>

                  
                    <Grid container xs={12}>                      
                      {/* Edit/Delete */}
                      <Grid
                        item
                        xs={12}
                        alignItems="center"
                      >
                        <Box
                          style={profileBlockStyles.functionButtonContainer}
                          data-test-id={"profile-container"}
                        >
                          <Button
                            component="span"
                            aria-label="edit-profile"
                            style={profileBlockStyles.buttonFunction}
                            data-testid={"edit-profile"}
                            onClick={() => {
                              this.handleFarmerEditClick({ attributes: this.state.currentFarmerData, meta: this.state.currentFarmerMeta });
                            }}
                          >
                            <Typography
                              style={profileBlockStyles.buttonFunctionTxt}
                            >
                              {screenDefaults.buttonText1}
                            </Typography>
                          </Button>

                          <Button
                            component="span"
                            aria-label="delete-profile"
                            style={{
                              ...profileBlockStyles.buttonFunction,
                              ...profileBlockStyles.buttonDeleteFunction,
                            }}
                            data-testid={"delete-profile"}
                            onClick={() => {
                              this.handleFarmerDelete();
                            }}
                          >
                            <Typography
                              style={profileBlockStyles.buttonFunctionTxt}
                            >
                              {screenDefaults.buttonText2}
                            </Typography>
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>                  
                </Grid>

              </Grid>
              {this.disaplyLeftBlock()}
            </Grid>

          </Container>
          {/* ADD-EDIT MODAL START */}
          <Dialog
            open={this.state.userModal}
            aria-labelledby="customized-dialog-title"
            PaperProps={{
              style: { borderRadius: 12, width: "712px" },
            }}
            maxWidth={"md"}
          >
            <div
              style={{
                alignItems: "center",
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <DialogTitle style={{padding:"32px 32px 19px 32px"}}>
                  <Typography variant="subtitle1">
                    {this.getAddUserModalTitleTxt()}
                  </Typography>
                </DialogTitle>
              </div>
              <div style={{ marginRight: 20 }}>
                <IconButton
                  data-testid="modalCloseBtnTestid"
                  aria-label="close"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <DialogContent dividers className="modalBodyWrapper">
              <Box className="userFormBodyWrapper">
                <Grid
                  container
                  justifyContent="center"
                  direction="row"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="formfieldWrapper"
                  >
                    <FormLabel className="customLabelField">
                      User Type
                    </FormLabel>
                    <FormControl fullWidth className="userTypeFormControl">
                      <Select
                        className="farmerFormDDClass"
                        value={this.state.seletedUserTypeAddUser}
                        data-testid="createUserModalDropdown"
                        color="secondary"
                        onChange={(e: any) =>
                          this.updateSeletedUserTypeAddUser(e.target.value)
                        }
                        displayEmpty
                        variant="outlined"
                        disabled={this.editModalStatus()}
                        inputProps={{ "aria-label": "Without label" }}
                        readOnly={this.editModalStatus()}
                      >
                        <MenuItem value={"Farmers"}>Farmers</MenuItem>
                        <MenuItem value={"FieldExecutive"}>
                          Field Executive
                        </MenuItem>
                        <MenuItem value={"Admin"}>Admin</MenuItem>
                        {!this.state.isLoggedUserAdmin ? <MenuItem value={"SA"}>Super Admin</MenuItem> : (<></>)}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {this.state.seletedUserTypeAddUser === "SA" ||
                  this.state.seletedUserTypeAddUser === "Admin" ? (
                  <AdminUserForm
                    validationSchemaAdminobj={SchemaAdminFormADD}
                    adminInitialValues={this.state.adminInitialValues}
                    handleSubmitAdmin={this.handleSubmitAdmin}
                    getErrorMessage={getErrorMessage}
                    getCustomErrorMessage={getCustomErrorMessage}
                    getPasswordType={this.getPasswordType}
                    getHelperText={getHelperText}
                    getEyeIconPassword={this.getEyeIconPassword}
                    getEyeIconConfirmPassword={this.getEyeIconConfirmPassword}
                    getConfirmPasswordType={this.getConfirmPasswordType}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    showEyeIconConfirm={this.showEyeIconConfirm}
                    getshowEyeIcon={this.getshowEyeIcon}
                    disableSubmitBtn={this.state.addAdminLoading || this.state.addSALoading}
                  />
                ) : (
                  <></>
                )}


                {this.state.seletedUserTypeAddUser === "FieldExecutive" ? (
                  <FieldExecutiveForm
                    feInitialValues={this.state.feInitialValues}
                    handleSubmitFEUser={this.handleSubmitFEUser}
                    validationSchemaFEobj={SchemaFieldExecutiveForm}
                    getErrorMessage={getErrorMessage}
                    getCustomErrorMessage={getCustomErrorMessage}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    getHelperText={getHelperText}
                    getMobileDeviceModelFEData={
                      this.state.getMobileDeviceModelFEData
                    }
                    showTableFilterModal={this.showVillageFilterModal}
                    anchorCurrentTarget={this.anchorCurrentTarget}
                    getLocationsListFEData={this.state.getLocationsListFEData}
                    selectedLocationArr={this.state.selectedLocationArr}
                    assignVillagePlaceholder={this.assignVillagePlaceholder}
                    villageNameAvatar={this.villageNameAvatar}
                    onFeLocationsChange={this.onFeLocationsChange}
                    handleVillageDelete={this.handleVillageDelete}
                    disableSubmitBtn={this.state.addFELoading}
                  />
                ) : (
                  <></>
                )}

                {this.state.seletedUserTypeAddUser === "Farmers" ? (
                  <FarmerUserForm
                    data-testid='addUserFarmerFormViewFarmer'                    
                    farmerInitialValues={this.state.farmerInitialValues}
                    handleSubmitFarmer={this.handleSubmitFarmer}
                    validationSchemaFarmerobj={SchemaFarmerForm}
                    getErrorMessage={getErrorMessage}
                    getCustomErrorMessage={getCustomErrorMessage}
                    getHelperText={getHelperText}
                    keyDownInt={keyDownInt}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    keyDown={keyDown}
                    getEducationFarmerData={this.state.getEducationFarmerData}
                    getNumberBelongsToData={this.state.getNumberBelongsToMenus}
                    getMobileDeviceModelFEData={this.state.getMobileDeviceModelFEData}
                    fetchDistrict={this.fetchDistrict}
                    disableStateFarmer={this.state.disableStateFarmer}
                    getStateData={this.state.getStateData}
                    getDistrictFarmerValue={this.getDistrictFarmerValue}
                    fetchTaluka={this.fetchTaluka}
                    getDistrictData={this.state.getDistrictData}
                    disableDistrictFarmerValue={this.disableDistrictFarmerValue}
                    getTalukaFarmerValue={this.getTalukaFarmerValue}
                    fetchVillage={this.fetchVillage}
                    disableTalukaFarmerValue={this.disableTalukaFarmerValue}
                    getTalukaData={this.state.getTalukaData}
                    disableVillageFarmerValue={this.disableVillageFarmerValue}
                    getVillageFarmerValue={this.getVillageFarmerValue}
                    getVillageData={this.state.getVillageData}
                    FElistData={this.state.FElistData}
                    selectedFEformData={this.state.selectedFEformData}
                    handleAssignFEchange={this.handleAssignFEchange}
                    assignFEPlaceholder={this.assignFEPlaceholder}
                    disableSubmitBtn={this.state.addFarmerLoading || this.state.updateFarmerLoading}
                  />
                ) : (
                  <></>
                )}
                {/* FILTER-ASSIGN VILLAGE STARTS */}
                <AssignVillageFilter
                  data-testid={'assignVillageFilterViewFE'}
                  anchorElVillageFilter={this.state.anchorElVillageFilter}
                  isVillageFilterOpen={this.state.openVillageFilter}
                  isAccordianError={this.state.isAccordianErrorVF}
                  isDisabled={this.state.isDisabledVF}
                  expanded={this.state.expandedVF}
                  handleVillageFilterClear={() => this.handleVillageFilterClearVF()}
                  closeVillageFilter={() => this.closeVillageFilterVF()}
                  handleSubmitAccordion={() => this.handleSubmitAccordionVF()}
                  handleChangePanelTaluka={this.handleChangePanelVF("panel3")}
                  handleChangePanelVillage={this.handleChangePanelVF("panel4")}
                  handleChangePanelState={this.handleChangePanelVF("panel1")}
                  handleChangePanelDistrict={this.handleChangePanelVF("panel2")}
                  opacityStateCalculator={this.opacityStateCalculatorViewForm}
                  handleChangeStateField={this.handleChangeStateFieldVF}
                  opacityDistrictCalculator={this.opacityDistrictCalculatorViewForm}
                  handleChangeDistrictField={this.handleChangeDistrictFieldVF}
                  opacityTalukaCalculator={this.opacityTalukaCalculatorViewForm}
                  handleChangeTalukaField={this.handleChangeTalukaFieldVF}
                  opacityVillageCalculator={this.opacityVillageCalculatorViewForm}
                  selectedState={this.state.selectedStateVF}
                  handleChangeVillageField={this.handleChangeVillageFieldVF}
                  handleStateSearch={this.handleStateSearchVF}
                  stateInputValue={this.state.stateInputValueVF}
                  selectedDistrict={this.state.selectedDistrictVF}
                  filteredStates={this.state.filteredStatesVF}
                  handleDistrictSearch={this.handleDistrictSearchVF}
                  districtInputValue={this.state.districtInputValueVF}
                  selectedTaluka={this.state.selectedTalukaVF}
                  talukaInputValue={this.state.talukaInputValueVF}
                  filteredDistricts={this.state.filteredDistrictsVF}
                  filteredTalukas={this.state.filteredTalukasVF}
                  handleTalukaSearch={this.handleTalukaSearchVF}
                  villageInputValue={this.state.villageInputValueVF}
                  selectedVillage={this.state.selectedVillageVF}
                  filteredVillages={this.state.filteredVillagesVF}
                  handleVillageSearch={this.handleVillageSearchVF}
                  getDistrictsVF={this.getDistrictsVF}
                  getTalukasVF={this.getTalukasVF}
                  getVillagesVF={this.getVillagesVF}
                />
                {/* FILTER-ASSIGN VILLAGE ENDS */}
              </Box>
            </DialogContent>
          </Dialog>
          {/* ADD EDIT MODAL END */}
          {/* CROP-MAPPING MODAL STARTS */}          
          {/* DELETE-ALERT-BOX FOR FE START */}
          <CustomModal
            open={this.state.openDeleteFarmerModal}
            title={"Delete User"}
            description={" Are you sure? You want to delete this User?"}
            confirmHandler={this.deleteFarmerUser}
            confirmTitle={this.state.deleteFarmerLoading ? "Deleting..." : "Delete"}
            onClose={this.handleClose}
            disableConfirmHandler={this.state.deleteFarmerLoading}
          />
          {/* DELETE ALERT BOX END */}
          {/* ERROR-MODAL STARTS */}
          <ErrorModal
            open={this.state.errorModalOpen}
            errorTitle="Error"
            errorSubTitle={this.state.errorModalMsg}
            BtnTitle="Okay"
            handleBtnClick={this.handleErrorModalClose}
          />
          {/* ERROR-MODAL ENDS */}
          {this.state.isShare && <LinkShare isShare={this.state.isShare}
            handleShareLink={this.handleShareLink} linkShare={this.state.linkShare}
            messageHeading={this.state.messageHeading} />}


          {/* SUCCESS-MODAL STARTS */}
          <ErrorModal
            open={this.state.successModalOpen}
            errorTitle="Success"
            errorSubTitle={this.state.successModalMsg}
            BtnTitle="Okay"
            handleBtnClick={this.handleSuccessModalClose}
          />
          {/* SUCCESS-MODAL ENDS */}
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

export const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "11%",
    top: "80%",
    width: "2.5rem",
    height: "2.35rem",
    padding: ".8rem",
    backgroundColor: "#F0EBE0",
    borderRadius: "50%",
    color: "#EB8F06",
  },
}));

// Customizable Area Start
const profileBlockStyles = {
  profileContainer: {
    backgroundColor: "#F8F6F1",
    borderRadius: "12px",
    width: "100%",
    padding: "16px",
    marginBottom: "12px",
  } as React.CSSProperties,

  profileContainerSecond: {
    width: "100%",
    borderTop: "2px solid #F0EBE0",
    paddingTop: "20px",
  } as React.CSSProperties,

  profileSubTitle: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "uppercase",
    color: "#1C2324",
    opacity: 0.64,
    marginTop: "16px",
    marginBottom: "14px"
  } as React.CSSProperties,

  avatarContainer: {
    padding: "0.5rem",
    width: "10rem",
    objectFit: "cover",
    height: "9.5rem",
    marginBottom: "8px",
  } as React.CSSProperties,

  profileAvatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    fontSize: "72px",
    marginTop: "24px",
  } as React.CSSProperties,

  profileIconButton: {
    backgroundColor: "#F0EBE0",
    borderRadius: "50%",
  } as React.CSSProperties,

  profileAvatar: {
    width: "10rem",
    height: "10rem",
    backgroundColor: "#108B88",
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "72px",
    lineHeight: "86px",
    textTransform: "uppercase",
    color: "#FFFFFF",
  } as React.CSSProperties,

  avatarTypographyTitle: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    maxWidth: "100%",
    lineHeight: "36px",
    color: "#1C2324",
    wordWrap: "break-word",
    textAlign: "center",
  } as React.CSSProperties,

  avatarTypographyContact: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "center",
  } as React.CSSProperties,

  profileLabelContainerAdhar: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
    marginTop: "14px",
  } as React.CSSProperties,

  profileLabelContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
    marginTop: "16px",
  } as React.CSSProperties,

  profileLabel: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#1C2324",
    opacity: 0.64,
  } as React.CSSProperties,

  profileLabelRight: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2324",
    opacity: 0.64,
  } as React.CSSProperties,

  labelSpan: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
  } as React.CSSProperties,

  profileLabelValue: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    textTransform: "capitalize",
  } as React.CSSProperties,

  profileLabelValueRight: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#1C2324",
  } as React.CSSProperties,

  customFunctionContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
  } as React.CSSProperties,

  functionButtonContainer: {
    display: "flex",
    gap: "1.5rem",
    marginTop: "15px",
    marginBottom: "14px",
    justifyContent: "center",
  } as React.CSSProperties,

  buttonFunction: {
    backgroundColor: "#DF5200",
    borderRadius: "1.85rem",
    paddingBlock: ".3rem",
    paddingInline: "2rem",
    padding: "8px 40px",
    height: "40px",
  } as React.CSSProperties,

  buttonFunctionTxt: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#fff",
  } as React.CSSProperties,

  buttonDeleteFunction: {
    backgroundColor: "#B3261E",
  } as React.CSSProperties,
};
// Customizable Area End

// Customizable Area Start
const adminViewStyle = {
  maincard: {
    background: "#F8F6F1",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const webStyle = {
  addUserModalBtnStyle:{
    width: "185px",
    borderRadius: "28px",
    fontWeight: 700,
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    background: "#DF5200",
    fontSize: "18px",
    color: "#fff",
    padding: "14px 32px",
    boxShadow: "none",
  } as React.CSSProperties,
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  },
};
// Customizable Area End
