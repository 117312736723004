import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Grid,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { closeIconSvg } from "./assets";
// IMPORT ACTIVITY COMPONENT
import GapFillingActivity from "./GapFillingActivity.web";
import WeedManagement from "./WeedManagement.web";
import NutrientManagement from "./NutrientManagementActivity.web";
import PestManagmentActivity from "./PestManagmentActivity.web";
import Harvest from "./HarvestActivity.web";
import Sell from "./SellActivity.web";
import CropFailure from "./CropFailure.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import CfcropactivitiesController, {
  IListActivityItem,
  Props,
  configJSON,
} from "./CfcropactivitiesController";
import IrrigationActivity from "./IrrigationActivity.web";
import PreSowingActivity from "./PreSowingActivity.web";

export default class Cfcropactivities extends CfcropactivitiesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  addActivityModal = () => {
    return (
      <Grid item>
        <Modal
          data-testid='add-activity-modal-testid'
          open={this.props.isAddActivity}
          onClose={() => {
            this.props.handleAddActivityModal();
            this.setState({ selectActivity: "" });
          }}
          style={webStyle.secondaryModalBox}
          BackdropProps={{
            style: webStyle.modalBackdropProps,
          }}
        >
          <Box style={webStyle.modalBoxStyle}>
            <Box style={{ padding: "32px", overflow:"auto" }}>
              <Box style={webStyle.addActivityModalHeader}>
                <Typography
                  style={{
                    ...webStyle.faqheading,
                    fontSize: "20px",
                    lineHeight: "30px",
                  }}
                >
                  Select Activity
                </Typography>
                <IconButton
                  data-testid="add-modal-close-btn-testid"
                  style={{ padding: 0 }}
                  onClick={() => {
                    this.props.handleAddActivityModal();
                    this.setState({ selectActivity: "" });
                  }}
                >
                  <img src={closeIconSvg} style={webStyle.closeIcon} />
                </IconButton>
              </Box>
              <ActivityBoxWrapper>
                {[
                  ...configJSON.leftSideActivities,
                  ...configJSON.rightSideActivities,
                ]
                  .sort((a, b) => a.order - b.order)
                  .map((item:IListActivityItem) => {
                    return (
                      <Box
                        key={item.id}
                        className={
                          this.state.selectActivity == item.key
                            ? "selectedItem"
                            : ""
                        }
                        style={webStyle.activityItem}
                        data-testid={`select-activity-box-testid-${item.id}`}
                        onClick={() => {
                          this.setState({ selectActivity: item.key });
                        }}
                      >
                        <img src={item.icon} style={webStyle.activityImage} />
                        <Typography style={webStyle.activityLabel}>
                          {item.name}
                        </Typography>
                      </Box>
                    );
                  })}
                <Button
                  variant="contained"
                  className="modalSubmitButton"
                  fullWidth
                  style={{
                    boxShadow: "none",
                    marginTop: "24px",
                    marginInline: "16px",
                  }}
                  onClick={this.handleSelectActivity}
                  disabled={!this.state.selectActivity}
                  data-testid="select-activity-btn-testid"
                >
                  Select
                </Button>
              </ActivityBoxWrapper>
            </Box>
          </Box>
        </Modal>
      </Grid>
    );
  };


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.addActivityModal()}
        {
         (<GapFillingActivity
            accountId={this.props.accountId}
            handleAddCropModalCallback={this.props.handleAddCropModalCallback}
            handleOpenFarmModalCallback={this.props.handleOpenFarmModalCallback}
            id={""}
            navigation={null}
            isGapFillingActivity={this.state.selectedActivity == "GAP_FILLING"}
            farmList={[]}
            cropList={[]}
            handleClose={this.handleCloseAddActivityModal}
            isEditActivity={this.props.isEditActivity && this.state.selectedActivity == "GAP_FILLING"}
            selectedActivityFarmId={this.props.selectedActivityFarmId}
            selectedActivityCropId={this.props.selectedActivityCropId}
            selectedyear={this.props.selectedyear}
          />)
        }
         {(this.state.selectedActivity == "WEED_MANAGEMENT" && (this.state.addActivity || this.props.isEditActivity) ) && (
          <WeedManagement
            accountId={this.props.accountId}
            handleAddCropModalCallback={this.props.handleAddCropModalCallback}
            handleOpenFarmModalCallback={this.props.handleOpenFarmModalCallback} 
            id={""}
            isWeedManagementModal={this.state.addActivity || this.props.isEditActivity}
            handleClose={this.handleCloseAddActivityModal}
            isEditActivity={this.props.isEditActivity}
            selectedActivityFarmId={this.props.selectedActivityFarmId}
            selectedActivityCropId={this.props.selectedActivityCropId}
            selectedyear={this.props.selectedyear}
          />
        )}
        {(this.state.selectedActivity == "CROP_FAILURE" && (this.state.addActivity || this.props.isEditActivity))  &&  (
          <CropFailure 
            id={""}
            accountId={this.props.accountId}
            isCropFailureModal={this.state.addActivity || this.props.isEditActivity}
            isEditActivity={this.props.isEditActivity}
            selectedActivityFarmId={this.props.selectedActivityFarmId}
            selectedActivityCropId={this.props.selectedActivityCropId}
            selectedyear={this.props.selectedyear}
            handleClose={this.handleCloseAddActivityModal}
            handleAddCropModalCallback={this.props.handleAddCropModalCallback}
            handleOpenFarmModalCallback={this.props.handleOpenFarmModalCallback} 
            onCreateOrUpdate={(status) => this.onChildCreateOrUpdate(status)}
          />
        )}
        {<PreSowingActivity
          navigation={undefined} id={""}
          userAccountId={this.props.accountId}
          isPreSowingModalOpen={this.state.selectedActivity === 'PRE_SOWING'}
          handleCloseAddActivityModal={this.handleCloseAddActivityModal}
          openAddCropModalfn={this.props.handleAddCropModalCallback} // ADD FARM
          openAddFarmModalfn={this.props.handleOpenFarmModalCallback} // ADD CROP
          isEditPreSowingModalOpen={this.props.isEditActivity && this.state.selectedActivity == "PRE_SOWING"} // EDIT
          activityFarmId={String(this.props.selectedActivityFarmId)} // FARM ID
          activityCropId={String(this.props.selectedActivityCropId)} // CROP ID
          selectedyear={this.props.selectedyear} // SELECTED YEAR IF REQUIRES
        />}
        
        {<NutrientManagement
          userAccountId={this.props.accountId}
          isNutrientModalOpen={this.state.selectedActivity == "NUTRIENT_MANAGEMENT"}
          selectedActivity={this.state.selectedActivity}
          handleCloseAddActivityModal={this.handleCloseAddActivityModal}
          // OPEN FUNCTIONS
          openAddCropModalfn={this.props.handleAddCropModalCallback}
          openAddFarmModalfn={this.props.handleOpenFarmModalCallback} 
          // TEST ID
          data-testid="nutrient-modal-component"
          // EDIT 
          isEditNutrientModalOpen={this.props.isEditActivity && this.state.selectedActivity == "NUTRIENT_MANAGEMENT"}
          activityFarmId={this.props.selectedActivityFarmId} // FARM ID
          activityCropId={this.props.selectedActivityCropId} // CROP ID ARR
          selectedyear={this.props.selectedyear} // SELECTED YEAR IF REQUIRES
        />}

        {(this.state.selectedActivity == "PEST_MANAGEMENT" && (this.state.addActivity || this.props.isEditActivity) ) && (
          <PestManagmentActivity
            accountId={this.props.accountId}
            selectedActivity={this.state.selectedActivity}
            isPestManagmentActivity={this.state.selectedActivity == "PEST_MANAGEMENT"}
            handleCloseAddActivityModal={this.handleCloseAddActivityModal}
            isEditActivity={this.props.isEditActivity}
            selectedActivityFarmId={this.props.selectedActivityFarmId}
            selectedActivityCropId={this.props.selectedActivityCropId}
            selectedyear={this.props.selectedyear}
            handleAddCropModalCallback={this.props.handleAddCropModalCallback}
            handleOpenFarmModalCallback={this.props.handleOpenFarmModalCallback} 
          />)}

        {<Harvest
          userAccountId={this.props.accountId}
          isHarvestModalOpen={this.state.selectedActivity == "HARVEST"}
          selectedActivity={this.state.selectedActivity}
          handleCloseAddActivityModal={this.handleCloseAddActivityModal}
          openAddCropModalfn={this.props.handleAddCropModalCallback} // ADD FARM
          openAddFarmModalfn={this.props.handleOpenFarmModalCallback} // ADD CROP
          // EDIT 
          isEditHarvestModalOpen={this.props.isEditActivity && this.state.selectedActivity == "HARVEST"}
          activityFarmId={String(this.props.selectedActivityFarmId)} // FARM ID
          activityCropId={String(this.props.selectedActivityCropId)} // CROP ID
          selectedyear={this.props.selectedyear} // SELECTED YEAR IF REQUIRES
        />}

        {<Sell
          userAccountId={this.props.accountId}
          isSellModalOpen={this.state.selectedActivity == "SALE"}
          selectedActivity={this.state.selectedActivity}
          handleCloseAddActivityModal={this.handleCloseAddActivityModal}
          openAddCropModalfn={this.props.handleAddCropModalCallback} // ADD FARM
          openAddFarmModalfn={this.props.handleOpenFarmModalCallback} // ADD CROP
          // EDIT 
          isEditSellModalOpen={this.props.isEditActivity && this.state.selectedActivity == "SALE"}
          activityFarmId={String(this.props.selectedActivityFarmId)} // FARM ID
          activityCropId={String(this.props.selectedActivityCropId)} // CROP ID
          selectedyear={this.props.selectedyear} // SELECTED YEAR IF REQUIRES
        />}

        {(this.state.selectedActivity == "IRRIGATION" && (this.state.addActivity || this.props.isEditActivity) ) && (
        <IrrigationActivity
          accountId={this.props.accountId}
          selectedActivity={this.state.selectedActivity}
          isIrrigationModal={this.state.addActivity || this.props.isEditActivity}
          handleCloseAddActivityModal={this.handleCloseAddActivityModal}
          isEditActivity={this.props.isEditActivity}
          selectedActivityFarmId={this.props.selectedActivityFarmId}
          selectedActivityCropId={this.props.selectedActivityCropId}
          selectedyear={this.props.selectedyear}
          handleAddCropModalCallback={this.props.handleAddCropModalCallback}
          handleOpenFarmModalCallback={this.props.handleOpenFarmModalCallback} 
          handleEditFarm={this.props.handleEditFarm}
        />)}
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ActivityBoxWrapper = styled(Box)({
  marginTop: "24px",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  "@media (min-width: 540px)": {
    "& > div:first-child": {
      borderTopLeftRadius: "10px !important",
      borderRight: "0px !important",
      borderBottom: "0px !important",
    },
    "& > div:nth-child(3)": {
      borderTopRightRadius: "10px !important",
      borderLeft: "0px !important",
      borderBottom: "0px !important",
    },
    "& > div:nth-child(5)": {
      border: "0px !important",
    },
    "& > div:nth-child(7)": {
      borderBottomLeftRadius: "10px !important",
      borderRight: "0px !important",
      borderTop: "0px !important",
    },
    "& > div:nth-child(9)": {
      borderBottomRightRadius: "10px !important",
      borderLeft: "0px !important",
      borderTop: "0px !important",
    },
  },
  "@media (min-width: 373px) and (max-width: 540px)": {
    "& > div:nth-child(even)": {
      borderLeft: "0px !important",
    },
    "& > div:nth-child(9)": {
      borderTop: "0px !important",
      borderBottomLeftRadius: "10px !important",
      borderBottomRightRadius: "10px !important",
    },
    "& > div:nth-child(1)": {
      borderTopLeftRadius: "10px !important",
    },
    "& > div:nth-child(2)": {
      borderTopRightRadius: "10px !important",
    },
    "& div:nth-child(1),div:nth-child(2),div:nth-child(3),div:nth-child(4),div:nth-child(5),div:nth-child(6),":
      {
        borderBottom: "0px !important",
      },
  },
  "@media (max-width: 372px)": {
    "& > div:nth-child(9)": {
      borderBottomLeftRadius: "10px !important",
      borderBottomRightRadius: "10px !important",
    },
    "& > div:nth-child(1)": {
      borderTopLeftRadius: "10px !important",
      borderTopRightRadius: "10px !important",
    },
    "& div:nth-child(1),div:nth-child(2),div:nth-child(3),div:nth-child(4),div:nth-child(5),div:nth-child(6),div:nth-child(7),div:nth-child(8)":
      {
        borderBottom: "0px !important",
      },
  },
  "& .selectedItem": {
    outline: "2px solid #DF5200 !important",
    borderRadius: 12,
    outlineOffset: "-2px",
  },
});

const webStyle = {
  secondaryModalBox: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%",
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  } as React.CSSProperties,
  modalBoxStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 528,
    width: "95%",
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "12px",
    background: "#fff",
    maxHeight: "95%",
    overflow: "hidden",
  } as React.CSSProperties,
  activityLabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "center",
    marginTop: "4px",
    color: "#1C2324",
  } as React.CSSProperties,
  AddCardBoxSuccess: {
    borderRadius: "12px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    display: "flex",
    maxWidth: "712px",
    flexDirection: "column",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxHeight: "95%",
    overflow: "hidden",
  } as React.CSSProperties,
  boxContainer: {
    fontSize: "16px",
    height: "100%",
    overflowY: "auto",
    overflowX: 'hidden'
  } as React.CSSProperties,
  cropHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '32px 32px 0 32px'
  } as React.CSSProperties,
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  } as React.CSSProperties,
  headerTitle: {
    color: "#1C2324",
    fontFamily: "IBM Plex Sans",
    fontSize: "20px",
    fontWeight: 500,
  } as React.CSSProperties,
  headerImage: {
    width: "48px",
    height: "48px",
    objectFit: "cover",
  } as React.CSSProperties,
  inputFieldStyle: {
    height: "56px",
  } as React.CSSProperties,
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  } as React.CSSProperties,
  cropSubmitButton: {
    borderRadius: "28px",
    background: "#DF5200",
    width: "100%",
    paddingBlock: "14px",
  } as React.CSSProperties,
  cropSubmitButtonText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 8,
  } as React.CSSProperties,
  addActivityModalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  } as React.CSSProperties,
  closeIcon: {
    width: "24px",
    height: "24px",
  } as React.CSSProperties,
  activityItem: {
    width: "142px",
    height: "142px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #F0EBE0",
    paddingInline: "14px",
    cursor: "pointer",
  } as React.CSSProperties,
  activityImage: {
    width: "56px",
    height: "56px",
  } as React.CSSProperties,
  faqheading: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#1C2324",
    fontFamily: configJSON.fontFamily,
  } as React.CSSProperties,
};
// Customizable Area End
