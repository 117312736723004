import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { serialize } from "object-to-formdata";
import { CropType, ILatitudeLongitude, UnitAreaAttributes, UnitAreaTypes } from "../../../components/src/interface.web";
import { getPolygonCentroid } from "../../../components/src/FarmMapping/FarmMapping.web";
import { defaultQuanitityUnit } from "../../../components/src/helper";

const userCurrentLocation = localStorage.getItem('userLocationFarmMapping');
const initalValueUserLocation = userCurrentLocation ? JSON.parse(userCurrentLocation) :{ lat: 22.7, lng: 75.8 }

let intialFormValues={
  farm_image:  undefined, 
  farm_mapping_image: "",
  name: '',
  ownership_of_land_id: "",
  account_id: 0,
  farm_area: '',
  unit_farm_area_id: "",
  survey_number: "",
  soil_texture_id: "",
  land_type_id: "",
  latitude_longitude:""
}

type OptionType = Array<{
  id: number,
  label: string,
  label_english: string,
  label_hindi: string,
  label_gujrati: string,
  active: boolean,
}>
 
export type ImageTypes= File | undefined | string

export type initalValueTypeAddFarm = {
  farm_image:ImageTypes,
  farm_mapping_image: ImageTypes,
  name: string,
  ownership_of_land_id: string | number, 
  account_id?: number,
  farm_area: string ,
  unit_farm_area_id: string  | undefined,
  survey_number: string,
  soil_texture_id: string  | number,
  land_type_id: string  | number,
}

type LatlngType ={
  lat: number,
  lng: number
}

export interface LandDetailDataType {
  id: string
  type: string
  attributes: LandDetailDataTypeAttributes
}

export interface CreateFarmResponse{
  data?:Record<string, unknown>,
  errors?:string[],
  error?:string
}

export interface LandDetailDataTypeAttributes {
  name: string
  survey_number: string
  ownership_of_land_id: OwnershipOfLandId
  farm_area: string
  unit_farm_area_id: UnitAreaAttributes
  soil_texture_id: SoilTextureId[]
  land_type_id: LandTypeId[]
  account_id: number
  created_at: string
  updated_at: string
  latitude_longitude: string | null
  fe_update_count: number
  farmer_update_count: number
  search_farm_name: string | null
  farm_image: FarmImage
  farm_mapping_image: FarmMappingImage
  flag:boolean
}

export interface OwnershipOfLandId {
  id: number | string
  label: string
  label_english: string
  label_hindi: string
  label_gujrati: string
  active: boolean
  created_at: string
  updated_at: string
}

export interface UnitFarmAreaId {
  id: number |string
  label: string
  label_english: string
  label_hindi: string
  label_gujrati: string
  active: boolean
  created_at: string
  updated_at: string
}

export interface SoilTextureId {
  id: number |string
  label: string
  label_english: string
  label_hindi: string
  label_gujrati: string
  active: boolean
  created_at: string
  updated_at: string
}

export interface LandTypeId {
  id: number | string
  label: string
  label_english: string
  label_hindi: string
  label_gujrati: string
  active: boolean
  created_at: string
  updated_at: string
}

export interface FarmImage {
  id: number
  url: string
  default_image: boolean
}

export interface FarmMappingImage {
  id: number
  url: string
  default_image: boolean
}


const initalLatLng = { lat: 0, lng: 0 };


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  accountId: number
  getList: () => void;
  selectedFarmId:string | number;
  isFarmEdit:boolean
  handleCloseModal:()=>void
  handleEditFarm:(e:React.MouseEvent<SVGSVGElement>,id:string |number)=>void,
  handleAddCropModalCallback:()=>void
  fetchCropsData:()=>void
  isOpenAddFarmProp:boolean
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isOpenAddfarme: boolean,
  //map
  OpenFarmMapping: boolean,
  coordinates: Array<LatlngType> | string,
  markerDefault: LatlngType,
  farmMappingFile: File | string,
  tempCoordinates: Array<LatlngType>,
  userLocation: LatlngType,
  owershipList: OptionType,
  soilTextureList: OptionType,
  irrigationList: OptionType,
  measureFarmerUnit: UnitAreaTypes[],
  addFarmeModalInitalValue: initalValueTypeAddFarm,
  isLoader: boolean,
  selectedLandData:LandDetailDataType | null,
  customMessageTitle:string,
  customMessage:string,
  cropsData:CropType[],
  loadCrops:boolean,
  isDeleteFarm:boolean,
  isDeletingFarm:boolean,
  editedFarmImage:boolean,
  sowingYear:  number,
  farmImageUploading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfmultiplefarmsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  owerLandtypeId: string = "";
  typeofIrrigationId: string = "";
  getUnitOfMeasureFarmerId: string = "";
  soilTexturesId: string = "";
  createFarmId: string =  "";
  updateFarmId:string="";
  getLandDetailId:string= "";
  getCropsUnderLandId:string="";
  deleteLandDetailId:string="";
  updateFarmImageId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isOpenAddfarme: false,
      //map 
      OpenFarmMapping: false,
      userLocation: initalValueUserLocation,
      coordinates: [],
      markerDefault: initalLatLng,
      farmMappingFile: "",
      tempCoordinates:[],

      owershipList: [],
      irrigationList: [],
      soilTextureList: [],
      measureFarmerUnit:[],
      addFarmeModalInitalValue : intialFormValues,
      isLoader: false,
      selectedLandData:null,
      cropsData:[],
      customMessageTitle:"",
      customMessage:"",
      loadCrops:false,
      isDeleteFarm:false,
      isDeletingFarm:false,
      editedFarmImage:false,
      sowingYear:new Date().getFullYear(),
      farmImageUploading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
      
    if(apiRequestCallId && responseJson){
      switch(apiRequestCallId){
        case this.owerLandtypeId:{
          return this.setState({ owershipList: responseJson })
        };
        case this.typeofIrrigationId:{
          return this.setState({ irrigationList: responseJson })
        };
        case this.soilTexturesId:{
          return this.setState({ soilTextureList: responseJson })
        };
        case this.getUnitOfMeasureFarmerId:{
          if(responseJson?.data){
            return this.setState({ measureFarmerUnit: responseJson.data,
              addFarmeModalInitalValue:{...intialFormValues,unit_farm_area_id:defaultQuanitityUnit(responseJson.data,"acre").unitId}
            }) 
          }
        };
        case this.createFarmId:{
          this.handlecreateFarmIdResponse(responseJson)
          break;
        }
        case this.getLandDetailId:
            this.handleGetLandDetailData(responseJson);
            break;  
        case this.updateFarmId:
            this.handleUpdateFarmIdResponse(responseJson)
            break;
         case this.getCropsUnderLandId:
          if(responseJson?.data){
            this.setState({cropsData: responseJson.data.filter((item:LandDetailDataType)=>item.attributes.flag)})
          }
          this.setState({loadCrops:false})
          break ;
         case this.deleteLandDetailId:
          this.handleDeleteFarmIdResponse(responseJson)
          break;
        case this.updateFarmImageId:
          if(responseJson?.data){
            this.setState({selectedLandData:responseJson.data,editedFarmImage:true,farmImageUploading:false})
            this.props.getList()
          }
         break;    
      }
    }
    
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
};

  // Customizable Area Start

  componentDidUpdate(prevProps: Props, prevState: S) {
    if(this.props.isOpenAddFarmProp !== prevProps.isOpenAddFarmProp){
      this.setState({isOpenAddfarme:this.props.isOpenAddFarmProp})
      this.getOwerList();
      this.getSoilTextures();
      this.gettypeofIrrigation();
      this.getUnitOfMeasureFarmer();
      this.setState({
        addFarmeModalInitalValue:
        {
          ...intialFormValues,
          unit_farm_area_id: defaultQuanitityUnit(this.state.measureFarmerUnit, "acre").unitId
        }
      }) 
    }
    if(this.state.isOpenAddfarme !== this.state.isOpenAddfarme && !this.state.isOpenAddfarme){
      this.props.handleCloseModal()
    }
    if( (this.props.selectedFarmId && prevProps.selectedFarmId !== this.props.selectedFarmId) || (this.props.isFarmEdit !== prevProps.isFarmEdit)){
      this.getOwerList();
      this.getSoilTextures();
      this.gettypeofIrrigation();
      this.getUnitOfMeasureFarmer();  
      this.getLandDetailData(this.props.selectedFarmId)
    }
    if(this.props.selectedFarmId && prevProps.selectedFarmId !== this.props.selectedFarmId && !this.props.isFarmEdit){
      this.setState({sowingYear:new Date().getFullYear()})
      this.getCropsUnderLand(this.props.selectedFarmId)
    }
    if(this.state.sowingYear !== prevState.sowingYear){
      this.getCropsUnderLand(this.props.selectedFarmId)
    }
  }

  getCurrentLocation = () => {
    const success = (pos: { coords: { latitude: number; longitude: number; }; }) => {
      this.setState({
        userLocation: {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        }
      }, () => {
        localStorage.setItem('userLocationFarmMapping', JSON.stringify(this.state.userLocation))
      })
    }
    const error = () => {
      this.setState({
        userLocation: initalLatLng
      })
    }
    if(navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }

  toggleAddFarmeModal = () =>{
    this.setState((prevState) => ({  isOpenAddfarme: !prevState.isOpenAddfarme, addFarmeModalInitalValue:{...intialFormValues,unit_farm_area_id:this.getDefaultUnitFarmArea(this.state.measureFarmerUnit,"acre")} }));
    this.props.handleCloseModal()
  }

  toggleMapModal = () => this.setState((prevState) => ({  OpenFarmMapping: !prevState.OpenFarmMapping }));

  uploadFile = (file: FileList | null, setFieldValue: (key: string, file: File) => void, variableName: string) => file?.length && setFieldValue(variableName, file[0]);

  onSaveAddFarmMapping = (coordinatesHash:string, file: File, url: string, savedCoordinates: {lat: number, lng: number}[], setFieldValue: (key: string, value: string) => void, variableName: string) => {
    this.setState({ OpenFarmMapping: false,  farmMappingFile: file, tempCoordinates: savedCoordinates });
    setFieldValue(variableName, url);
    setFieldValue("latitude_longitude",coordinatesHash)
  }

  getError = (touched: boolean| undefined, error: string | undefined ) => touched && error
  //apiCall
  getOwerList = () => {
    this.owerLandtypeId = this.apiCall(configJSON.getMethodType, configJSON.owerListEndpoit);
  }

  getSoilTextures = () => {
    this.soilTexturesId = this.apiCall(configJSON.getMethodType, configJSON.soilTexturesEndPoint);
  }

  getUnitOfMeasureFarmer = () => {
    this.getUnitOfMeasureFarmerId = this.apiCall(configJSON.getMethodType, `${configJSON.unitDropdownEndPonit}?activity=farms&dropdown=unit_of_area_farms`);
  }

  gettypeofIrrigation = () => {
    this.typeofIrrigationId = this.apiCall(configJSON.getMethodType, configJSON.typeofIrrigationEndPoint);
  }

  createFarm = (_body: initalValueTypeAddFarm ) => {
    let body={..._body}
    this.setState({ isLoader: true });
    body.account_id = this.props.accountId;
    body.name= body.name.trim()
    body.survey_number=body?.survey_number?.trim()
    body.farm_mapping_image =  this.state.farmMappingFile || undefined;
    if(typeof body.farm_image =="string" && body.farm_image?.startsWith("http")){
      delete body.farm_image
    }
    const data = serialize({ land_detail : body}, { indices: true }); 
    if(this.props.isFarmEdit){
      this.updateFarmId = this.apiCall(configJSON.putMethodType, `${configJSON.landdetailsEndPoint}/${this.props.selectedFarmId}`,data, true)
    }else{
      this.createFarmId = this.apiCall(configJSON.postMethodType, configJSON.landdetailsEndPoint,data, true)
    }
  }


  setCoordinates = (latitude_longitude: string) => {
    if (latitude_longitude && String(latitude_longitude).length > 0) {
      const latLongString = JSON.parse(latitude_longitude);
      // convert string to array of object
      let coordinatesArrayTemp: ILatitudeLongitude[] = [];
      for (let i = 0; i < Object.keys(latLongString as ILatitudeLongitude).length; i++) {
        coordinatesArrayTemp.push(latLongString[i]);
      }
      // re-change key names of array
      let CoordinatesArrFinal = [];
      if (coordinatesArrayTemp.length > 0) {
        // Loop through each object in the original array and modify the keys
        for (const coordinate of coordinatesArrayTemp) {
          const modifiedCoord = {
            "lat": coordinate.latitude,
            "lng": coordinate.longitude
          };
          CoordinatesArrFinal.push(modifiedCoord);
        }
      }
      if (CoordinatesArrFinal.length > 0) {
        const centroidTemp = getPolygonCentroid(CoordinatesArrFinal)
        this.setState({ markerDefault: centroidTemp })
      }

      return CoordinatesArrFinal;
    } else {
      return []
    }
  }

  handleGetLandDetailData=(res:{data:LandDetailDataType})=>{
    if(!res?.data){
      return
    }
    if(this.props.isFarmEdit){
      const {name,survey_number,account_id,farm_area,ownership_of_land_id,unit_farm_area_id,soil_texture_id,land_type_id,farm_image,farm_mapping_image,latitude_longitude}=res.data.attributes
      let formData={name,survey_number,account_id,farm_area,farm_mapping_image:farm_mapping_image?.url || "",ownership_of_land_id:ownership_of_land_id.id,unit_farm_area_id:unit_farm_area_id?.id,soil_texture_id:soil_texture_id[0].id,land_type_id:land_type_id[0].id,farm_image:farm_image?.url}
      let coordinates:LatlngType[];
      if(latitude_longitude){
        try {
          coordinates=this.setCoordinates(latitude_longitude)
        } catch (error) {
          coordinates=[]
        }
      }else{
        coordinates=[]
      }
      this.setState({isOpenAddfarme:true,addFarmeModalInitalValue:formData,tempCoordinates:coordinates,coordinates})

    }
    this.setState({selectedLandData:res.data})
  }

  getLandDetailData(landDetailID:string | number){
    if(!landDetailID) return
    this.getLandDetailId = this.apiCall(configJSON.getMethodType, `${configJSON.landdetailsEndPoint}/${landDetailID}`,"", true)
  }

  getCropsUnderLand=(landDetailID :string | number)=>{
    if(!landDetailID) return
    this.setState({loadCrops:true})
    this.getCropsUnderLandId = this.apiCall(configJSON.getMethodType, `${configJSON.cropsEndPoint}?account_id=${this.props.accountId}&land_detail_id=${landDetailID}&sowing_year=${this.state.sowingYear}`,"", true)
  }

  handleDeleteFarm=()=>{
    this.setState({isDeleteFarm:true})
  }
  handleCreateFarm=()=>{
      this.setState({isLoader:false, farmMappingFile: "", tempCoordinates:[],isOpenAddfarme: false,addFarmeModalInitalValue :intialFormValues,customMessage:"Farm created successfully",customMessageTitle:"Success"})
  }
  handleDeleteFarmResponse=()=>{
      this.setState({isDeleteFarm:false,isDeletingFarm:false,customMessage:"Farm Deleted successfully",customMessageTitle:"Success"})
      this.props.fetchCropsData()
  }
  hanleCancel=()=>{
    this.setState({isDeleteFarm:false})
  }

  deleteFarmApi=()=>{
    if(!this.props.selectedFarmId) return
    this.setState({isDeletingFarm:true})
    this.deleteLandDetailId = this.apiCall(configJSON.deleteMethosTYpe, `${configJSON.landdetailsEndPoint}/${this.props.selectedFarmId}`,"", true)
  }


  handleEditFarmMapping=()=>{
    this.setState({ OpenFarmMapping: true, coordinates: this.state.tempCoordinates })
  }

  handleUpdateFarm=()=>{
      this.setState({isLoader :false,farmMappingFile: "", isOpenAddfarme: false,addFarmeModalInitalValue :intialFormValues,customMessage:"Farm updated successfully",customMessageTitle:"Success"});
  }

  handleCloseCustomModal=()=>{
    if(this.state.customMessageTitle.toLowerCase() === "success"){
      this.handleFetchFarmList()
    }
    this.setState({customMessage:"",customMessageTitle:""})
  }

  handleFetchFarmList=()=>{
    this.props.getList()
    this.props.handleCloseModal()
  }


  handleFarmImageChange=(files:FileList | null)=>{
        if(!files) return
        this.setState({farmImageUploading:true})
        const data = serialize({ land_detail : {farm_image:files[0],account_id:this.props.accountId}}, { indices: true }); 
        this.updateFarmImageId = this.apiCall(configJSON.putMethodType, `${configJSON.landdetailsEndPoint}/${this.props.selectedFarmId}`,data, true)
  }

  handleYearChange=(value:number)=>{
    this.setState((prev)=>({sowingYear:prev.sowingYear + value}))
  }

  handlecreateFarmIdResponse=(responseJson:CreateFarmResponse)=>{
    let error=responseJson?.error || responseJson?.errors
    if(error){
      this.handleRenderError(error)
    }
    if(responseJson?.data){
      this.handleCreateFarm()
    }
  }

  handleDeleteFarmIdResponse=(responseJson:CreateFarmResponse)=>{
    let error=responseJson?.error || responseJson?.errors
    if(error){
      this.handleRenderError(error)
      this.setState({isDeletingFarm:false})
    }
    if(responseJson?.data){
        this.handleDeleteFarmResponse()
    }
  }

  handleUpdateFarmIdResponse=(responseJson:CreateFarmResponse)=>{
    let error=responseJson?.error || responseJson?.errors
    if(error){
      this.handleRenderError(error)
    }
    if(responseJson?.data){
      this.handleUpdateFarm()
    }
  }

  handleRenderError=(error:string[] | string)=>{
    if(typeof error === "string"){
      this.setState({customMessageTitle:"Error",customMessage:error,isLoader:false})
      return 
    }
    if(Array.isArray(error) && typeof error[0] === "string"){
      this.setState({customMessageTitle:"Error",customMessage:error[0],isLoader:false})
      return
    }
    if(Array.isArray(error) &&  typeof error[0] === "object"){
      this.setState({customMessageTitle:"Error",customMessage:error[0][Object.keys(error[0])[0]],isLoader:false})
    }
  }


  getDefaultUnitFarmArea=(data:UnitAreaTypes[],value:string)=>{
      if(data.length ==1){
       return data[0]?.id
      }
      return data.find((item)=>item.attributes.unit_name==value)?.id
  }

  apiCall = (method: string, endpoint: string, body?: string | FormData, headerWithContent?: boolean) => {
    let header: { token: string | null, "Content-Type"?: string } = {
        token: localStorage.getItem("tokenn"),
    };

    if (!headerWithContent) {
        header["Content-Type"] = "application/json";
    }

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );

    if (body) {
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
};

  // Customizable Area End
}
