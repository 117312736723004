import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Input,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Tooltip,
  Avatar,
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogContent,
  FormLabel,
  Badge,
  // Customizable Area End
} from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom"

// Customizable Area Start
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "./AdminConsole.web.css";
import "./AdminConsoleFEUserScreen.web.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { Visibility, PhotoCamera } from "@material-ui/icons";
import { AddUserPlusIconSVG, imgIconFilter, linkSeparatorSVG } from "./assets";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// Customizable Area End

import AdminConsoleFEController, {
  Props,
  configJSON,
} from "./AdminConsoleFEUserScreenController";
import CustomModal from "../../../components/src/customModal.web";
import AdminUserForm from "../../../components/src/AdminUserForm.web";
import FieldExecutiveForm from "../../../components/src/FieldExecutiveForm.web";
import FarmerUserForm from "../../../components/src/FarmerForm.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import { CameraStyledBadge, UserProfileStyledBadge, farmerColumns, themeFE, totalAreaCottonValue } from "../../../components/src/AdminConsoleHelper";
import { SchemaAdminFormADD, SchemaFarmerForm, SchemaFieldExecutiveForm, parseImages } from "../../../components/src/helper";
import AssignVillageFilter from "../../../components/src/AssignVillageFilter";
import UserTableFilter from "../../../components/src/UserTableFilter";
import ImageCropEditor from "../../../components/src/ImageCropper";
import { IuserStatusData } from "../../../components/src/interface.web";

const { screenDefaultsTexts: screenDefaults } = configJSON;

export default class AdminConsoleFEUserProfile extends AdminConsoleFEController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getAddUserModalTitleTxt = () => {
    return this.state.openEditUserModal === true ? "Edit User" : "Add New User";
  };

  getSubmitFormBtnTxt = () => {
    const updateBtnTxt = this.state.updateFELoading || this.state.updateFarmerLoading ? "Updating..." : "Update User"
    const addBtnTxt = this.state.addFarmerLoading || this.state.addFELoading || this.state.addAdminLoading || this.state.addSALoading ? "Adding..." : "Add User"
    return (this.state.openEditUserModal === true ? updateBtnTxt : addBtnTxt)
  };

  handleSubmitAdmin = (values: any) => {
    if (this.state.seletedUserTypeAddUser === "SA") {
      this.addSuperAdmin(values);
    } else {
      this.addAdmin(values);
    }
  };

  handleSubmitFEUser = (values: any) => {
    this.state.openEditUserModal
      ? this.updateFieldExecutive(values)
      : this.addFieldExecutive(values);
  };

  handleSubmitFarmer = (values: any) => {
    this.state.openEditUserModal
      ? this.updateFarmer(values)
      : this.addFarmer(values);
  };

  getErrorMessage = (touched: any, errors: any, value: string) => {
    return touched[value] && Boolean(errors[value]);
  };

  getCustomErrorMessage = (touched: any, errors: any, value: string) => {
    return (
      touched[value] &&
      errors[value] && (
        <div>
          <Typography className="text-danger" style={{ fontSize: "0.75rem" }}>
            {errors[value]}
          </Typography>
        </div>
      )
    );
  };
  getHelperText = (touched: any, errors: any, value: string) => {
    return touched[value] && errors[value];
  };

  getEyeIconPassword = () => {
    return this.state.showEyeIconPassword ? (
      <Visibility style={webStyle.customDateIcon} />
    ) : (
      <VisibilityOff style={webStyle.customDateIcon} />
    );
  };

  getEyeIconConfirmPassword = () => {
    return this.state.showEyeIconConfirmPassword ? (
      <Visibility style={webStyle.customDateIcon} />
    ) : (
      <VisibilityOff style={webStyle.customDateIcon} />
    );
  };

  getshowEyeIcon = () => {
    return this.setState({
      showEyeIconPassword: !this.state.showEyeIconPassword,
    });
  };

  showEyeIconConfirm = () => {
    return this.setState({
      showEyeIconConfirmPassword: !this.state.showEyeIconConfirmPassword,
    });
  };

  assignFEPlaceholder = () => {
    return this.state.selectedFEformData ? "" : "Search by field executive name";
  };

  showFEListTable = () => {
    return (
      <DataGrid
        data-testid="feTableTestid"
        disableSelectionOnClick
        style={{ height: "inherit" }}
        disableColumnMenu
        rowHeight={46}
        rows={this.state.feListData}
        columns={farmerColumns(
          (data: any) => this.handleFarmerEditClick(data),
          (data: IuserStatusData) => this.handleFarmerDelete(data),
          (data: IuserStatusData,status:boolean) => this.handleChangeUpdateUserStatus(data,status)
        )}
        pageSize={this.state.fePageSize}
        onPageSizeChange={(page: any) => {
          this.setState({ fePageSize: page });
        }}
        loading={this.state.feListDataLoading}
        rowsPerPageOptions={[15, 30, 50]}
        getRowId={(row: any) => row.id}
        rowCount={this.state.totalSearchedCount}
        page={this.state.feTableCurrentPage}
        onPageChange={(page: any) => {
          this.setState({
            feTableCurrentPage: page,
          });
        }}
      />
    );
  };

  villageNameAvatar = (index: number, option: any) => {
    return (
      <>
        <Avatar
          style={{
            backgroundColor: `${index % 2 === 0 ? "#108B88" : "#EB8F06"}`,
            color: "#fff",
            height: "28px",
            width: "28px",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "18px",
          }}
        >
          {option?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </>
    );
  };

  feProfileSection = () =>
    this.state.feProfileImage?.icon && <ImageCropEditor open={this.state.cropImageModal} src={this.state.feProfileImage.icon} onClose={this.handleCloseCropModalFE} title="Field Executive profile" buttonText="Submit" handleUploadImage={this.handleSubmitFEProgfileImage} />



  render() {
    // Customizable Area Start
    const { navigation, id } = this.props;
    const { currentFEData } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={navigation} id={id}>
        <ThemeProvider theme={themeFE}>
          <Container className="mainConatiner" maxWidth={"xl"}>
            {this.feProfileSection()}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Box display="flex" alignItems="center" gridGap="10px">
                  <Typography
                    style={{
                      fontStyle: "normal",
                      lineHeight: "48px",
                      fontWeight: 600,
                      fontSize: "32px",
                      color: "#1C2324",
                    }}
                  >
                    Users
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row-reverse"
                alignItems="center"
                gridGap={5}
              >
                <Button
                  variant="contained"
                  style={profileStyles.addUserModalBtnStyle}
                  data-testid="addUserModalBtn"
                  onClick={() => this.showUserModal()}
                >
                  <img src={AddUserPlusIconSVG} alt="+" style={{ marginRight: "8px" }} />Add User
                </Button>
              </Box>
            </Box>

            <Box className="breadcrumbNav">
              <Breadcrumbs
                separator={<img src={linkSeparatorSVG} alt={"separator"} />}
                aria-label="breadcrumb"
              >
                <Link style={{ textDecoration: "none" }} className="menuLinkUser" to="/users" >
                  Users
                </Link>
                <Typography className="menuTextUser">View Profile</Typography>
              </Breadcrumbs>
            </Box>

            <Box mt={2.5}>
              <Grid
                container
                justifyContent="space-between"
                // spacing={3}
                direction="column"
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    item
                    container
                    style={profileStyles.profileContainer}
                    justifyContent="space-between"
                  // spacing={3}
                  >
                    <Grid item xs={12} sm={12} md={2}>
                      <Box style={profileStyles.profileAvatarContainer}>
                        <input type="file" id="upload" data-test-id="uploadProfileButton" onChange={(event) => event.currentTarget?.files?.length && this.onProfileImageUpload(event)} accept="image/*" style={{ display: 'none' }} />
                        <Box>
                          <label htmlFor="upload" style={{ display: 'flex', alignItems: 'center' }}>

                            <UserProfileStyledBadge
                              overlap="rectangular"
                              badgeContent={
                                <PhotoCamera
                                  fontSize="small"
                                  style={profileStyles.profileIconButton}
                                />
                              }
                              data-testid="badge-content"
                            >
                              <IconButton
                                disableRipple={true}
                                aria-label="camera-edit_icon"
                                style={profileStyles.avatarContainer}
                              >
                                <Avatar
                                  style={profileStyles.profileAvatar}
                                  src={currentFEData.avatar?.url}
                                >
                                  {currentFEData.first_name
                                    ? currentFEData.first_name[0]
                                    : ""}
                                </Avatar>
                              </IconButton>
                            </UserProfileStyledBadge>
                          </label>

                        </Box>
                        <Box className="profileName">
                          <Tooltip title={`${currentFEData.first_name} ${currentFEData.last_name}`}>
                            <Typography
                              noWrap
                              style={profileStyles.avatarTypographyTitle}
                              data-testid="profile-name"
                            >{`${currentFEData.first_name} ${currentFEData.last_name}`}</Typography>
                          </Tooltip>
                          <Typography
                            noWrap
                            style={profileStyles.avatarTypographyContact}
                          >
                            {`+${currentFEData.country_code}-${currentFEData.phone_number}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      container
                      justifyContent="space-between"
                      spacing={1}
                      style={profileStyles.headingGridContainer}
                    >
                      <Grid item xs={12} sm={6} md={4}>
                        <Box style={profileStyles.profileLabelContainer}>
                          <Box component="span" style={profileStyles.labelSpan}>
                            <Box style={profileStyles.profileLabel}>
                              {screenDefaults.profileTextEmail}&#58;
                            </Box>
                            <Tooltip title={currentFEData.email}>
                              <Typography
                                noWrap
                                style={{
                                  ...profileStyles.profileLabelValue,
                                  ...{ maxWidth: "70%" },
                                }}
                              >
                                {currentFEData.email}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box component="span" style={profileStyles.labelSpan}>
                            <Box style={profileStyles.profileLabel}>
                              {screenDefaults.profileTextUsername}&#58;
                            </Box>
                            <Typography
                              noWrap
                              style={profileStyles.profileLabelValue}
                            >
                              {currentFEData.user_name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box style={profileStyles.profileLabelContainer}>
                          <Box component="span" style={profileStyles.labelSpan}>
                            <Box style={profileStyles.profileLabel}>
                              {screenDefaults.profileTextDOB}&#58;
                            </Box>
                            <Typography
                              noWrap
                              style={profileStyles.profileLabelValue}
                            >
                              {moment(currentFEData.date_of_birth).format(
                                "DD MMM YYYY"
                              )}
                            </Typography>
                          </Box>

                          <Box component="span" style={profileStyles.labelSpan}>
                            <Box style={profileStyles.profileLabel}>
                              {screenDefaults.profileTextDevice}&#58;
                            </Box>
                            <Typography
                              noWrap
                              style={profileStyles.profileLabelValue}
                            >
                              {currentFEData.mobile_type_id?.[0]?.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box style={profileStyles.profileLabelContainer}>
                          <Box component="span" style={profileStyles.labelSpan}>
                            <Box style={profileStyles.profileLabel}>
                              {screenDefaults.profileTextLocation}&#58;
                            </Box>
                            <Typography
                              noWrap
                              style={profileStyles.profileLabelValue}
                            >
                              {this.state.currentFEData.location}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      alignItems="flex-end"
                      justifyContent="flex-end"
                    >
                      <Box
                        style={profileStyles.functionButtonContainer}
                        className="feActionButtons"
                        data-test-id={"profile-container"}
                      >
                        <Button
                          component="span"
                          aria-label="edit-profile"
                          disableRipple={true}
                          style={profileStyles.buttonFunction}
                          data-testid={"edit-profile"}
                          onClick={() => {
                            this.handleFeEditClick(currentFEData);
                          }}
                        >
                          <Typography style={profileStyles.buttonFunctionTxt}>
                            {screenDefaults.buttonText1}
                          </Typography>
                        </Button>

                        <Button
                          component="span"
                          aria-label="delete-profile"
                          disableRipple={true}
                          style={{
                            ...profileStyles.buttonFunction,
                            ...profileStyles.buttonDeleteFunction,
                          }}
                          data-testid={"delete-profile"}
                          onClick={() => {
                            this.handleFEDelete();
                          }}
                        >
                          <Typography style={profileStyles.buttonFunctionTxt}>
                            {screenDefaults.buttonText2}
                          </Typography>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {/* 3 Sub Card */}
                <Grid
                  style={{ marginTop: "4px", marginBottom: "0px" }}
                  container
                  direction="row"
                  className="feCardsParent"
                >
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={6}
                    sm={12}
                    xs={12}
                    className="infocardContainer"
                  >
                    <Box className="infocard">
                      <Box className="infocardContent">
                        <Box className="setContentLine">
                          <Box className="leftSubdiv">
                            <Typography className="subTitleInfoCard">
                              Change role:
                            </Typography>
                            <Typography className="mainTitleInfoCard">
                              Field Executive
                            </Typography>
                          </Box>
                          <Box sx={feViewStyle.expandMoreArrow}>
                            <Typography className="expandArrowInfoCard">
                              {/* <ExpandMore /> */}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={6}
                    sm={12}
                    xs={12}
                    className="infocardContainer"
                  >
                    <Box className="infocard">
                      <Box className="infocardContent">
                        <Box className="setContentLine">
                          <Box className="leftSubdiv">
                            <Typography className="subTitleInfoCard">
                              Total number of
                            </Typography>
                            <Typography className="mainTitleInfoCard">
                              Farmers
                            </Typography>
                          </Box>
                          <Box className="fontBlue">
                            <Typography
                              data-testid="farmer-card"
                              className="countTxtInfoCard"
                            >
                              {this.state.totalFarmerCount}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={6}
                    sm={12}
                    xs={12}
                    className="infocardContainer"
                  >
                    <Box className="infocard">
                      <Box className="infocardContent">
                        <Box className="setContentLine">
                          <Box className="leftSubdiv">
                            <Typography className="subTitleInfoCard">
                              Total number of
                            </Typography>
                            <Typography className="mainTitleInfoCard">
                              Villages
                            </Typography>
                          </Box>
                          <Box className="fontBlue">
                            <Typography
                              data-testid="fe-card"
                              className="countTxtInfoCard"
                            >
                              {this.state.totalVillagesCount}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={6}
                    sm={12}
                    xs={12}
                    className="infocardContainer"
                  >
                    <Box className="infocard">
                      <Box className="infocardContent">
                        <Box className="setContentLine">
                          <Box
                            className="leftSubdiv"
                            style={{ minWidth: "40%" }}
                          >
                            <Typography className="subTitleInfoCard">
                              Total area of
                            </Typography>
                            <Typography className="mainTitleInfoCard">
                              Cotton
                            </Typography>
                          </Box>
                          <Box className="fontBlue">
                            <Typography className="countTxtInfoCard">
                              {totalAreaCottonValue(this.state.totalCottonCount)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box mt={1} sx={feViewStyle.tableWrapper}>
              <Box mb={2} sx={feViewStyle.tableFilterWrapper}>
                <Grid
                  container
                  direction="row"
                  className="feFarmerSearch"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item lg={4} md={5} sm={6}>
                    <Typography
                      style={{ fontSize: "24px" }}
                      className="mainTitleCard"
                    >
                      List of Farmers
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    md={7}
                    sm={12}
                    style={{ paddingRight: "0px" }}
                  >
                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      <Box
                        style={{
                          padding: "7px",
                          background: "#FCFAF7",
                          borderRadius: "28px",
                          border: "2px solid #F0EBE0",
                          width: "100%",
                        }}
                      >
                        <Input
                          id="searchInputId"
                          type="search"
                          name="searchInput"
                          disableUnderline
                          fullWidth
                          autoComplete="off"
                          className="tableSearchInputfilter"
                          data-testid="tableInputSearchTestid"
                          placeholder="Search by name"
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon
                                style={{ color: "#1C2324", opacity: 0.48 }}
                              />
                            </InputAdornment>
                          }
                          onChange={(e: any) =>
                            this.setState({
                              seachInputValue: e.target.value,
                              feTableCurrentPage: 0,
                            })
                          }
                          value={this.state.seachInputValue}
                        />
                      </Box>
                      <Box>
                        <IconButton data-testid="tableFilterIconBtnTestid"
                          onClick={(event) => { this.setState({ anchorElUserTableFilter: event.currentTarget, openTableFilterPopup: true }) }}
                        >
                          <Badge variant="dot" color="primary"
                            className="filterApplied" invisible={this.state.hideBadge}
                          >
                            <img src={imgIconFilter} />
                          </Badge>

                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box className="tableGirdDiv" sx={feViewStyle.mainTable}>
                {this.showFEListTable()}
              </Box>
            </Box>
          </Container>
          {/* DELETE-ALERT-BOX START */}
          <CustomModal
            open={this.state.openDeleteModal}
            title={"Delete User"}
            description={" Are you sure? You want to delete this User?"}
            confirmHandler={this.deleteUser}
            confirmTitle={this.state.deleteFarmerLoading ? "Deleting..." : "Delete"}
            onClose={this.handleClose}
            disableConfirmHandler={this.state.deleteFarmerLoading}
          />
          {/* DELETE-ALERT-BOX END */}

          {/* DELETE-ALERT-BOX FOR FE START */}
          <CustomModal
            open={this.state.openDeleteFEModal}
            title={"Delete User"}
            description={" Are you sure? You want to delete this User?"}
            confirmHandler={this.deleteFEUser}
            confirmTitle={this.state.deleteFELoading ? "Deleting..." : "Delete"}
            onClose={this.handleClose}
            disableConfirmHandler={this.state.deleteFELoading}
          />
          {/* DELETE-ALERT-BOX END */}
          {/* ADD-EDIT USER MODAL START */}
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={this.state.userModal}
            maxWidth={"md"}
            PaperProps={{
              style: { borderRadius: 12, width: "712px" },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <DialogTitle style={{padding:"32px 32px 19px 32px"}}>
                  <Typography variant="subtitle1">
                    {this.getAddUserModalTitleTxt()}
                  </Typography>
                </DialogTitle>
              </div>
              <div style={{ marginRight: 20 }}>
                <IconButton
                  aria-label="close"
                  data-testid="modalCloseBtnTestid"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <DialogContent dividers className="modalBodyWrapper">
              <Box className="userFormBodyWrapper">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="formfieldWrapper"
                  >
                    <FormLabel className="customLabelField">
                      User Type
                    </FormLabel>
                    <FormControl fullWidth className="userTypeFormControl">
                      <Select
                        value={this.state.seletedUserTypeAddUser}
                        variant="outlined"
                        className="farmerFormDDClass"
                        color="secondary"
                        data-testid="createUserModalDropdown"
                        onChange={(e: any) =>
                          this.updateSeletedUserTypeAddUser(e.target.value)
                        }
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        readOnly={this.editModalStatus()}
                        disabled={this.editModalStatus()}
                      >
                        <MenuItem value={"Farmers"}>Farmers</MenuItem>
                        <MenuItem value={"FieldExecutive"}>
                          Field Executive
                        </MenuItem>
                        <MenuItem value={"Admin"}>Admin</MenuItem>
                        {!this.state.isLoggedUserAdmin ? <MenuItem value={"SA"}>Super Admin</MenuItem> : (<></>)}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {this.state.seletedUserTypeAddUser === "Admin" ||
                  this.state.seletedUserTypeAddUser === "SA" ? (
                  <AdminUserForm
                    adminInitialValues={this.state.adminInitialValues}
                    validationSchemaAdminobj={SchemaAdminFormADD}
                    handleSubmitAdmin={this.handleSubmitAdmin}
                    getErrorMessage={this.getErrorMessage}
                    getCustomErrorMessage={this.getCustomErrorMessage}
                    getHelperText={this.getHelperText}
                    getPasswordType={this.getPasswordType}
                    getEyeIconPassword={this.getEyeIconPassword}
                    getEyeIconConfirmPassword={this.getEyeIconConfirmPassword}
                    getConfirmPasswordType={this.getConfirmPasswordType}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    getshowEyeIcon={this.getshowEyeIcon}
                    showEyeIconConfirm={this.showEyeIconConfirm}
                    disableSubmitBtn={this.state.addAdminLoading || this.state.addSALoading}
                  />
                ) : (
                  <></>
                )}

                {this.state.seletedUserTypeAddUser === "FieldExecutive" ? (
                  <FieldExecutiveForm
                    feInitialValues={this.state.feInitialValues}
                    validationSchemaFEobj={SchemaFieldExecutiveForm}
                    handleSubmitFEUser={this.handleSubmitFEUser}
                    getErrorMessage={this.getErrorMessage}
                    getCustomErrorMessage={this.getCustomErrorMessage}
                    getHelperText={this.getHelperText}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    getMobileDeviceModelFEData={
                      this.state.getMobileDeviceModelFEData
                    }
                    showTableFilterModal={this.showVillageFilterModal}
                    anchorCurrentTarget={this.anchorCurrentTarget}
                    getLocationsListFEData={this.state.getLocationsListFEData}
                    selectedLocationArr={this.state.selectedLocationArr}
                    onFeLocationsChange={this.onFeLocationsChange}
                    assignVillagePlaceholder={this.assignVillagePlaceholder}
                    villageNameAvatar={this.villageNameAvatar}
                    handleVillageDelete={this.handleVillageDelete}
                    disableSubmitBtn={this.state.addFELoading || this.state.updateFELoading}
                  />
                ) : (
                  <></>
                )}
                {this.state.seletedUserTypeAddUser === "Farmers" ? (
                  <FarmerUserForm
                    data-testid='addUserFarmerForm'
                    farmerInitialValues={this.state.farmerInitialValues}
                    validationSchemaFarmerobj={SchemaFarmerForm}
                    handleSubmitFarmer={this.handleSubmitFarmer}
                    getErrorMessage={this.getErrorMessage}
                    getCustomErrorMessage={this.getCustomErrorMessage}
                    getHelperText={this.getHelperText}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    keyDown={this.keyDown}
                    keyDownInt={this.keyDownInt}
                    getEducationFarmerData={this.state.getEducationFarmerData}
                    disableStateFarmer={this.state.disableStateFarmer}
                    fetchDistrict={this.fetchDistrict}
                    getStateData={this.state.getStateData}
                    getDistrictFarmerValue={this.getDistrictFarmerValue}
                    fetchTaluka={this.fetchTaluka}
                    getDistrictData={this.state.getDistrictData}
                    disableDistrictFarmerValue={this.disableDistrictFarmerValue}
                    getTalukaFarmerValue={this.getTalukaFarmerValue}
                    fetchVillage={this.fetchVillage}
                    disableTalukaFarmerValue={this.disableTalukaFarmerValue}
                    getTalukaData={this.state.getTalukaData}
                    getVillageFarmerValue={this.getVillageFarmerValue}
                    disableVillageFarmerValue={this.disableVillageFarmerValue}
                    getVillageData={this.state.getVillageData}                    
                    FElistData={this.state.FElistData}                    
                    getNumberBelongsToData={this.state.getNumberBelongsToData}
                    getMobileDeviceModelFEData={this.state.getMobileDeviceModelFEData}
                    selectedFEformData={this.state.selectedFEformData}
                    handleAssignFEchange={this.handleAssignFEchange}
                    assignFEPlaceholder={this.assignFEPlaceholder}                  
                    disableSubmitBtn={this.state.addFarmerLoading || this.state.updateFarmerLoading}                    
                  />
                ) : (
                  <></>
                )}
              </Box>
            </DialogContent>
          </Dialog>
          {/* ADD-EDIT USER MODAL END */}          
          {/* FILTER-ASSIGN VILLAGE STARTS */}
          <AssignVillageFilter
            data-testid={'assignVillageFilterViewFE'}
            anchorElVillageFilter={this.state.anchorElVillageFilter}
            isVillageFilterOpen={this.state.openVillageFilter}
            isAccordianError={this.state.isAccordianErrorVF}
            isDisabled={this.state.isDisabledVF}
            expanded={this.state.expandedVF}
            handleVillageFilterClear={() => this.handleVillageFilterClearVF()}
            closeVillageFilter={() => this.closeVillageFilterVF()}
            handleSubmitAccordion={() => this.handleSubmitAccordionVF()}
            handleChangePanelVillage={this.handleChangePanelVF("panel4")}
            handleChangePanelTaluka={this.handleChangePanelVF("panel3")}
            handleChangePanelDistrict={this.handleChangePanelVF("panel2")}
            handleChangePanelState={this.handleChangePanelVF("panel1")}
            handleChangeStateField={this.handleChangeStateFieldVF}
            opacityStateCalculator={this.opacityStateCalculatorVF}
            handleChangeDistrictField={this.handleChangeDistrictFieldVF}
            opacityDistrictCalculator={this.opacityDistrictCalculatorVF}
            handleChangeTalukaField={this.handleChangeTalukaFieldVF}
            opacityTalukaCalculator={this.opacityTalukaCalculatorVF}
            handleChangeVillageField={this.handleChangeVillageFieldVF}
            opacityVillageCalculator={this.opacityVillageCalculatorVF}
            stateInputValue={this.state.stateInputValueVF}
            selectedState={this.state.selectedStateVF}
            filteredStates={this.state.filteredStatesVF}
            handleStateSearch={this.handleStateSearchVF}
            districtInputValue={this.state.districtInputValueVF}
            selectedDistrict={this.state.selectedDistrictVF}
            filteredDistricts={this.state.filteredDistrictsVF}
            handleDistrictSearch={this.handleDistrictSearchVF}
            talukaInputValue={this.state.talukaInputValueVF}
            selectedTaluka={this.state.selectedTalukaVF}
            filteredTalukas={this.state.filteredTalukasVF}
            handleTalukaSearch={this.handleTalukaSearchVF}
            villageInputValue={this.state.villageInputValueVF}
            selectedVillage={this.state.selectedVillageVF}
            filteredVillages={this.state.filteredVillagesVF}
            handleVillageSearch={this.handleVillageSearchVF}
            getDistrictsVF={this.getDistrictsVF}
            getTalukasVF={this.getTalukasVF}
            getVillagesVF={this.getVillagesVF}
          />
          {/* FILTER-ASSIGN VILLAGE ENDS */}

          {/* ERROR-MODAL STARTS */}
          <ErrorModal
            open={this.state.errorModalOpen}
            errorTitle="Error"
            errorSubTitle={this.state.errorModalMsg}
            BtnTitle="Okay"
            handleBtnClick={this.handleErrorModalClose}
          />
          {/* ERROR-MODAL ENDS */}
          {/* SUCCESS-MODAL STARTS */}
          <ErrorModal
            open={this.state.successModalOpen}
            errorTitle="Success"
            errorSubTitle={this.state.successModalMsg}
            BtnTitle="Okay"
            handleBtnClick={this.handleCloseSuccessModal}
          />
          {/* SUCCESS-MODAL ENDS */}
          <UserTableFilter
            data-testid={'UserTableFilterViewFE'}
            openTableFilter={this.state.openTableFilterPopup}
            anchorEl={this.state.anchorElUserTableFilter}
            HideFEFilter={true}
            filterHasError={this.state.filterHasError}
            isDisabled={this.state.isDisabled}
            expanded={this.state.expanded}
            handleSubmitFilter={this.handleSubmitFilter}
            handleVillageFilterClear={this.handleVillageFilterClear}
            handleCloseFilter={this.handleCloseTableFilterPopup}
            handleChangeZtoA={this.handleChangeZtoA}
            handleChangeAtoZ={this.handleChangeAtoZ}
            sortDescending={this.state.sortDescending}
            sortAscending={this.state.sortAscending}
            filteredStates={this.state.filteredStates}
            selectedState={this.state.selectedState}
            stateInputValue={this.state.stateInputValue}
            filteredDistricts={this.state.filteredDistricts}
            selectedDistrict={this.state.selectedDistrict}
            districtInputValue={this.state.districtInputValue}
            filteredTalukas={this.state.filteredTalukas}
            selectedTaluka={this.state.selectedTaluka}
            talukaInputValue={this.state.talukaInputValue}
            filteredVillages={this.state.filteredVillages}
            selectedVillage={this.state.selectedVillage}
            villageInputValue={this.state.villageInputValue}
            handleChangeStateField={this.handleChangeStateField}
            opacityStateCalculator={this.opacityStateCalculator}
            handleStateSearch={this.handleStateSearch}
            handleChangePanelState={this.handleChangePanel("panel1")}
            handleChangeDistrictField={this.handleChangeDistrictField}
            opacityDistrictCalculator={this.opacityDistrictCalculator}
            handleDistrictSearch={this.handleDistrictSearch}
            handleChangePanelDistrict={this.handleChangePanel("panel2")}
            handleChangeTalukaField={this.handleChangeTalukaField}
            opacityTalukaCalculator={this.opacityTalukaCalculator}
            handleTalukaSearch={this.handleTalukaSearch}
            handleChangePanelTaluka={this.handleChangePanel("panel3")}
            handleChangeVillageField={this.handleChangeVillageField}
            opacityVillageCalculator={this.opacityVillageCalculator}
            handleVillageSearch={this.handleVillageSearch}
            handleChangePanelVillage={this.handleChangePanel("panel4")}
          />
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}



// Customizable Area Start
// Customizable Area Start
const profileStyles = {
  rootGrid: {
    flexGrow: 1,
    width: "100%",
    // height: "100%"
  } as React.CSSProperties,

  headingGridContainer: {
    padding: "20px 0 0 5px",
  } as React.CSSProperties,

  profileContainer: {
    padding: "24px 24px 24px 14px",
    backgroundColor: "#F8F6F1",
    borderRadius: "12px",
    height: "100%",
  } as React.CSSProperties,

  heading: {
    fontSize: "2rem",
    fontFamily: screenDefaults.fontFamily,
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "3rem",
    color: "#1C2324",
    marginBlockEnd: "1.5rem",
  } as React.CSSProperties,

  avatarContainer: {
    padding: "0.5rem",
    width: "7rem",
    objectFit: "cover",
    height: "6.5rem",
  } as React.CSSProperties,

  profileIconButton: {
    backgroundColor: "#F0EBE0",
    borderRadius: "50%",
    fontSize: "1rem",
  } as React.CSSProperties,

  profileAvatar: {
    width: "6rem",
    height: "6rem",
    backgroundColor: "#108B88",
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "3rem",
    lineHeight: "3.5rem",
    textTransform: "uppercase",
    color: "#FFFFFF",
  } as React.CSSProperties,

  profileAvatarContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    textAlign: "center",
    alignItems: "center",
  } as React.CSSProperties,

  avatarTypographyContact: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: ".85rem",
    lineHeight: "22px",
    textAlign: "center",
  } as React.CSSProperties,

  avatarTypographyTitle: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#1C2324",
  } as React.CSSProperties,

  profileDetails: {
    display: "flex",
    gap: "1rem",
    width: "100%",
  } as React.CSSProperties,

  profileLabelContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1.2rem",
  } as React.CSSProperties,

  profileLabel: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    opacity: 0.64,
  } as React.CSSProperties,

  labelSpan: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
  } as React.CSSProperties,

  customFunctionContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
  } as React.CSSProperties,

  functionButtonContainer: {
    display: "flex",
    gap: "1.5rem",
    marginTop: "-35px",
  } as React.CSSProperties,

  profileLabelValue: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "23px",
    color: "#1C2324",
  } as React.CSSProperties,

  buttonFunction: {
    backgroundColor: "#DF5200",
    borderRadius: "1.85rem",
    paddingBlock: ".3rem",
    paddingInline: "2rem",
    width: "100%",
    height: "2rem",
  } as React.CSSProperties,

  buttonFunctionTxt: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "22px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#fff",
  } as React.CSSProperties,

  buttonDeleteFunction: {
    backgroundColor: "#B3261E",
  } as React.CSSProperties,

  addUserModalBtnStyle: {
    height:"56px",
    width: "185px",
    borderRadius: "28px",
    fontWeight: 700,
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    background: "#DF5200",
    fontSize: "18px",
    color: "#fff",
    padding: "14px 32px",
    boxShadow: "none",
  } as React.CSSProperties,
};
// Customizable Area End

// Customizable Area Start
const feViewStyle = {
  tableFilterWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "25px",
  },
  tableWrapper: {
    background: "#F8F6F1",
    border: "2px",
    borderRadius: "12px",
  },
  mainTable: {
    height: "583px",
  },
  maincard: {
    background: "#F8F6F1",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expandMoreArrow: {
    colot: "#1C2324",
    fontSize: "14px",
  },
  photoCamera: {
    width: 60,
    height: 60,
    color: "#1C2324",
    opacity: 0.5,
  },
};
// Customizable Area End

// Customizable Area Start
const webStyle = {
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  },
  hide: {
    display: "none",
  },
  badgeCardRoot: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    minHeight: "150",
    padding: "0",
    marginTop: "8px",
    marginBottom: "20px",
  },
  badgeButton: {
    backgroundColor: "rgba(28, 35, 36, .56)",
    padding: "0.375rem",
  },
};
// Customizable Area End
