// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ICreatedHarvest, IInitailValueHarvest, INutrientManagementName, ISelectCrop, ISelectFarm, ISelectFarmDataResponse, IUnitDropdown, IUnitDropdownResponse } from "../../../components/src/interface.web";
import moment from "moment";
import { defaultQuanitityUnit, returnTruthyString, getSingleCropSowingDate } from "../../../components/src/helper";

export const configJSON = require("./config");

const isValueFalsy = (value: unknown) => value === "" || value === null || value === undefined
export interface Props {
    isHarvestModalOpen: boolean,
    selectedActivity: string | null | number,
    handleCloseAddActivityModal: () => void,
    userAccountId: string | number,
    openAddCropModalfn: () => void,
    openAddFarmModalfn: () => void,
    // EDIT
    isEditHarvestModalOpen: boolean,
    activityFarmId: string, // FARM ID
    activityCropId: string, // CROP ID
    selectedyear: number
}

export interface IRequestBody {
    harvest: ICreateHarvestBody[]
}
export interface ICreateHarvestBody {
    "land_detail_id"?: string | number
    "crop_id"?: string | number
    "id"?: string | number
    "date_of_picking": string
    "quantity_picked": string | number
    "unit_of_quantity_picked_id": string | number
    "total_picking_cost": string | number
    "storage_type_id": string | number
}
interface S {
    authToken: string | null;
    openHarvestModal: boolean,
    minimumDate: Date;
    // DROPDOWN STATES
    farmNamesList: ISelectFarm[],
    cropNamesList: ISelectCrop[],
    unitOfPickedQuantityList: IUnitDropdown[],
    storageTypesList: INutrientManagementName[],
    // FORMS
    selectedFarmId: string | number,
    selectedFarmIdError: string,
    selectedCropId: string | number,
    selectedCropIdError: string,
    harvestForms: IInitailValueHarvest[],
    disableAddAnotherBtn: boolean,
    // ERROR
    isErrorModalOpen: boolean,
    ErrorModalTitle: string,
    ErrorModalMessage: string,
    // CREATE API
    createHarvestActivityLoading: boolean,
    // GET ACTIVITY DETAILS
    getActivityDetailsLoading: boolean,
    // EDIT
    editHarvestActivityLoading: boolean,
    // CUSTOM MODAL
    isCustomModalOpen: boolean
}
interface SS { }

const initialHarvestFormValue = {
    id:"",
    date_of_picking: new Date(),
    quantity_picked_value: "",
    quantity_picked_unit_id: "",
    total_picking_cost: "",
    harvest_expanse_kg: "",
    harvest_expanse_label: "kg",
    storage_type_id: "",
    errors: {
        date_of_picking: "",
        quantity_picked_value: "",
        quantity_picked_unit_id: "",
        total_picking_cost: "",
        harvest_expanse_kg: "",
        storage_type_id: "",
    }
}
// Customizable Area End

export default class HarvestActivityController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    fetchFarmNamesDataApiCallId: string = "";
    fetchCropNamesDataApiCallId: string = "";
    fetchPickedQuantityUnitDataApiCallId: string = "";
    fetchStorageTypesApiCallId: string = "";
    createHarvestActivityApiCallId: string = "";
    editHarvestActivityApiCallId: string = "";
    getActivityDetailsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            openHarvestModal: this.props.isHarvestModalOpen,
            minimumDate: moment().subtract(360, "days").toDate(),
            // DROPDOWN STATES
            farmNamesList: [],
            cropNamesList: [],
            unitOfPickedQuantityList: [],
            storageTypesList: [],
            // FORMS
            selectedFarmId: "",
            selectedFarmIdError: "",
            selectedCropId: "",
            selectedCropIdError: "",
            harvestForms: [initialHarvestFormValue],
            disableAddAnotherBtn: true,
            // ERROR
            isErrorModalOpen: false,
            ErrorModalTitle: "",
            ErrorModalMessage: "",
            // CREATE API
            createHarvestActivityLoading: false,
            // GET ACTIVITY DETAILS
            getActivityDetailsLoading: false,
            // EDIT
            editHarvestActivityLoading: false,
            // CUSTOM MODAL
            isCustomModalOpen: false
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.fetchFarmNamesDataApiCallId:
                        this.handleFetchFarmNamesDataApiCallId(responseJson);
                        break;
                    case this.fetchCropNamesDataApiCallId:
                        this.handleFetchCropNamesDataApiCallId(responseJson);
                        break;
                    case this.fetchPickedQuantityUnitDataApiCallId:
                        this.handleFetchPickedQuantityUnitDataApiCallId(responseJson);
                        break;
                    case this.fetchStorageTypesApiCallId:
                        this.handleFetchStorageTypesApiCallId(responseJson);
                        break;
                    // CREATE 
                    case this.createHarvestActivityApiCallId:
                        this.handleCreateHarvestActivityApiCallId(responseJson)
                        break;
                    // FETCH
                    case this.getActivityDetailsApiCallId:
                        this.handleGetActivityDetailsApiCallId(responseJson)
                        break;
                    // EDIT
                    case this.editHarvestActivityApiCallId:
                        this.handleEditHarvestActivityApiCallId(responseJson)
                        break;
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<S>,
        snapshot?: SS | undefined
    ): void {
        if ((prevProps.isHarvestModalOpen !== this.props.isHarvestModalOpen) && this.props.isHarvestModalOpen && !this.props.isEditHarvestModalOpen) {
            this.setState({ getActivityDetailsLoading: false })
            this.resetInitialFormValues()
            this.fetchFarmData(this.props.userAccountId);
            this.fetchPickedQuantityUnitData()
            this.fetchStorageTypesData()
        }
        if (prevProps.isEditHarvestModalOpen !== this.props.isEditHarvestModalOpen && this.props.isEditHarvestModalOpen) {            
            this.fetchFarmData(this.props.userAccountId);
            this.fetchCropsData(this.props.activityFarmId)
            this.fetchPickedQuantityUnitData()
            this.fetchStorageTypesData()
            this.setState({ getActivityDetailsLoading: true })
        }
    }

    // API CALLS
    fetchFarmData = (userAccountId: string | number | null) => {
        if (!userAccountId) return
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
            type: "admin_user"
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchFarmNamesDataApiCallId = requestMessage.messageId;

        const endpoint = `${configJSON.fetchFarmListDataAPiEndPoint}?user_id=${this.props.userAccountId}`
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fetchCropsData = (farmId: string | number) => {
        if (!this.props.userAccountId || !farmId) return;

        this.setState({ cropNamesList: [] })
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
            type: "admin_user",
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchCropNamesDataApiCallId = requestMessage.messageId;

        const endpoint = `${configJSON.fetchCropListDataApiEndPoint}?farm_id=${farmId}&user_id=${this.props.userAccountId}`;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    fetchPickedQuantityUnitData = () => {
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchPickedQuantityUnitDataApiCallId = requestMessage.messageId;
        const endpoint = `${configJSON.unitDropdownAPIEndPoint}?activity=${'harvest'}&dropdown=${'unit_of_quantity_picked_harvest'}`

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fetchStorageTypesData = () => {
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchStorageTypesApiCallId = requestMessage.messageId;

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.storageTypesAPIEndPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createHarvestActivity = (requestBody: IRequestBody) => {
        if (!this.props.userAccountId) return
        this.setState({ createHarvestActivityLoading: true })
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createHarvestActivityApiCallId = requestMessage.messageId;
        const endpoint = `admin/bx_block_farm_dairy/harvests?account_id=${this.props.userAccountId}`

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    editHarvestActivity = (requestBody: IRequestBody) => {
        if (!this.props.userAccountId) return
        this.setState({ editHarvestActivityLoading: true })
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.editHarvestActivityApiCallId = requestMessage.messageId;
        const endpoint = `admin/bx_block_farm_dairy/harvests?account_id=${this.props.userAccountId}`

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    fetchActivityDetails = () => {
        this.setState({ getActivityDetailsLoading: true })
        this.resetInitialFormValues()
        const farmId = this.props.activityFarmId
        const cropId = this.props.activityCropId
        if (farmId && this.props.userAccountId) {
            const headers = {
                token: this.state.authToken,
                "Content-Type": configJSON.validationApiContentType,
            };
            const endpoint = `admin/bx_block_farm_dairy/harvests?account_id=${this.props.userAccountId}&year=${this.props.selectedyear}&land_detail_id=${farmId}&crop_id=${cropId}`

            const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getActivityDetailsApiCallId = reqMessage.messageId;

            reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
            reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(reqMessage.id, reqMessage);
        } else {
            this.setState({ getActivityDetailsLoading: false })
        }
    }

    // HANDLE API CALLS STARTS
    handleFetchFarmNamesDataApiCallId = (responseJson: ISelectFarmDataResponse) => {
        if (responseJson && responseJson.data && responseJson.data.length) {
            this.setState({ farmNamesList: responseJson.data })
        } else {
            this.setState({ farmNamesList: [] })
        }
    }
    handleFetchCropNamesDataApiCallId = (responseJson: { data: ISelectCrop[] }) => {
        if (responseJson && responseJson.data && responseJson.data.length) {
            this.setState({ cropNamesList: responseJson.data },() => {
                if(this.props.isEditHarvestModalOpen){
                  this.fetchActivityDetails();
                }
            });
        } else {
            this.setState({ cropNamesList: [] });
        }
    };
    handleFetchPickedQuantityUnitDataApiCallId = (responseJson: IUnitDropdownResponse | null | undefined) => {
        if (responseJson && responseJson.data.length > 0) {
            this.setState({ unitOfPickedQuantityList: responseJson.data })
            if (this.props.isHarvestModalOpen) {
                this.setState({
                    harvestForms: [{
                        ...initialHarvestFormValue,
                        quantity_picked_unit_id: defaultQuanitityUnit(responseJson.data, "kg").unitId
                    }]
                })

            }
        } else {
            this.setState({ unitOfPickedQuantityList: [] })
        }
    }
    handleFetchStorageTypesApiCallId = (responseJson: INutrientManagementName[] | null | undefined) => {
        if (responseJson && responseJson.length > 0) {
            this.setState({ storageTypesList: responseJson })
        } else {
            this.setState({ storageTypesList: [] })
        }
    }
    handleCreateHarvestActivityApiCallId = (response: { errors: string[] } | { data: ICreatedHarvest }[]) => {
        if (response && 'errors' in response && !Array.isArray(response)) {
            this.setState({
                createHarvestActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: response.errors[0],
            })
        } else if (Array.isArray(response) && response.length > 0) {
            this.setState({
                createHarvestActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Success",
                ErrorModalMessage: "Harvest Activity added Successfully.",
            })
            this.handleCloseHarvestActivityModal()
        }
    }
    handleEditHarvestActivityApiCallId = (response: { errors: string[] } | { data: ICreatedHarvest }[]) => {
        if (response && 'errors' in response && !Array.isArray(response)) {
            this.setState({
                editHarvestActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: response.errors[0],
            })
        } else if (Array.isArray(response) && response.length > 0) {
            this.setState({
                editHarvestActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Success",
                ErrorModalMessage: "Harvest Activity updated Successfully.",
            })
            this.handleCloseHarvestActivityModal()
        }
    }
    handleGetActivityDetailsApiCallId = (response: { data: ICreatedHarvest[] } | undefined) => {
        if (response && response.data && response.data.length > 0) {
            this.setFormsData(response.data)
        } else {
            this.setState({ getActivityDetailsLoading: false })
        }
    }
    // HANDLE API CALLS ENDS

    // RESET FUNCTIONS
    resetInitialFormValues = () => {
        this.setState({            
            selectedFarmId: "",
            selectedFarmIdError: "",
            selectedCropId: "",
            selectedCropIdError: "",
            harvestForms: [{
                ...initialHarvestFormValue,
                quantity_picked_unit_id: defaultQuanitityUnit(this.state.unitOfPickedQuantityList, "kg").unitId
            }],
            disableAddAnotherBtn: true,
            openHarvestModal: false,
            minimumDate: moment().subtract(360, 'days').toDate(),
        })
        this.handleCloseCustomModal()
    }

    // ACTIVITY MODAL FUNCTIONS
    openHarvestActivityModal = () => {
        return this.props.isHarvestModalOpen || this.state.openHarvestModal
    }
    handleCloseHarvestActivityModal = () => {
        this.resetInitialFormValues()
        this.setState({ getActivityDetailsLoading: false })
        this.props.handleCloseAddActivityModal()
    }

    openAddFarm = () => {
        this.handleCloseHarvestActivityModal()
        this.props.openAddFarmModalfn()
    }
    openAddCrop = () => {
        this.handleCloseHarvestActivityModal()
        this.props.openAddCropModalfn()
    }
    // ERROR MODAL FUNCTIONS
    handleCloseErrorModal = () => {
        this.setState({
            isErrorModalOpen: false,
            ErrorModalMessage: ""
        })
    }
    // CUSTOM MODAL CLOSE
    handleCloseCustomModal = () => {
        this.setState({
            isCustomModalOpen: false
        })
    }
    setFormsData = (activityData: ICreatedHarvest[]) => {
        let updateInitalValue: IInitailValueHarvest[] = []
        const filteredData = activityData.filter((item) => item.attributes.freeze_record !== true);
        updateInitalValue = filteredData.map((item) => {            
            
            let date_of_picking = String(moment(item.attributes.date_of_picking))
            const momentObject = moment(item.attributes.date_of_picking);
            const dateObject = momentObject.toDate();
            let quantity_picked_value = Number(item.attributes.quantity_picked)
            let quantity_picked_unit_id = returnTruthyString(item.attributes.unit_of_quantity_picked_id[0]?.id)
            let total_picking_cost = Number(item.attributes.total_picking_cost)
            let harvest_expanse_kg = Number(item.attributes.harvest_expences_kg)
            let storage_type_id = returnTruthyString(item.attributes.storage_type_id && item.attributes.storage_type_id[0]?.id)
            let harvest_expanse_label = returnTruthyString(item.attributes.default_unit_of_quantity_picked_id[0]?.name)
            return {
                id: returnTruthyString(item.attributes?.id),
                date_of_picking: date_of_picking,
                quantity_picked_value: quantity_picked_value,
                quantity_picked_unit_id: quantity_picked_unit_id,
                total_picking_cost: total_picking_cost,
                harvest_expanse_kg: harvest_expanse_kg,
                harvest_expanse_label: harvest_expanse_label,
                storage_type_id: storage_type_id,
                errors: {
                    date_of_picking: this.validateMinDate(moment(dateObject).toDate(), this.props.activityCropId as string),
                    quantity_picked_value: "",
                    quantity_picked_unit_id: "",
                    total_picking_cost: "",
                    harvest_expanse_kg: "",
                    storage_type_id: "",
                }
            }
        })

        this.setState({
            selectedFarmId: this.props.activityFarmId ? this.props.activityFarmId : "",
            selectedCropId: this.props.activityCropId ? this.props.activityCropId : "",
            disableAddAnotherBtn: this.isCropTypeMono(this.props.activityCropId ? this.props.activityCropId : "",),
            harvestForms: updateInitalValue,
            getActivityDetailsLoading: false,
            minimumDate: getSingleCropSowingDate(this.props.activityCropId as string, this.state.cropNamesList)
            ?? moment().subtract(360, 'days').toDate(),
        })
    }

    validateMinDate = (selectedDate: Date, cropValue?: string) => {
        const minimumDate = (cropValue && getSingleCropSowingDate(cropValue, this.state.cropNamesList)) || moment().subtract(361, 'days').toDate();
        return (selectedDate >= minimumDate && selectedDate <= new Date()) ? "" : "Please enter valid date";
    };

    // FORM FUNCTIONS
    handleChangeSelectFarm = (selectedFarmValue: string | number | unknown) => {
        const updatedFormValues = this.state.harvestForms.map((item) => ({
            ...item,
            errors: { ...item.errors, date_of_picking: this.validateMinDate(item.date_of_picking as Date, "") },
        }));
        this.setState({
            selectedFarmId: selectedFarmValue as string,
            selectedFarmIdError: this.validateSelectFarmValue(selectedFarmValue as string).errorMessage,
            selectedCropId: "",
            selectedCropIdError: "",
            minimumDate: moment().subtract(360, 'days').toDate(),
            harvestForms: updatedFormValues,
        }, () => {
            this.fetchCropsData(this.state.selectedFarmId)
        })
    }
    validateSelectFarmValue = (selectedFarmValue: string | number | null) => {
        let errorMessage = ""
        if (isValueFalsy(selectedFarmValue)) {
            errorMessage = "Please Select Farm Name"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    handleChangeSelectCrop = (value: string | number | unknown) => {
        const minimumDate = getSingleCropSowingDate(value as string, this.state.cropNamesList) || moment().subtract(360, 'days').toDate();
        const updatedFormValues = [...this.state.harvestForms];
        this.state.harvestForms.map((item, index) => {
          updatedFormValues[index] = {
            ...updatedFormValues[index],
            quantity_picked_unit_id: defaultQuanitityUnit(this.state.unitOfPickedQuantityList, "kg").unitId,
            errors: {
              ...updatedFormValues[index].errors,
              date_of_picking: this.validateDateOfPickingValue(item.date_of_picking as Date, value as string).errorMessage,
            }
          };
        });
        this.setState({ 
            selectedCropId: value as string, 
            selectedCropIdError: this.validateSelectCropValue(value as string).errorMessage,
            disableAddAnotherBtn: this.isCropTypeMono(value as string),
            harvestForms: updatedFormValues,
            minimumDate 
        })
    }
    validateSelectCropValue = (selectedCropValue: string | number | null) => {
        let errorMessage = ""
        if (isValueFalsy(selectedCropValue)) {
            errorMessage = "Please Select Crop Name"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }

    isCropTypeMono = (cropId: string | number) => {
        let isCropTypeMono = false

        const matchedObj = this.state.cropNamesList.find((item) => (String(item.id) === String(cropId)) && String(item.attributes.crop_name_id?.harvest_type).includes("mono"))
        if (matchedObj && cropId) {
            isCropTypeMono = true
        } else {
            isCropTypeMono = false
        }

        return isCropTypeMono
    }


    // VALIDATE DATE OF PICKING
    validateDateOfPickingValue = (pickingDate: string | Date | null, cropValue?: string) => {
        let errorMessage = ""
        const today = moment().endOf('day');
        const minDate = cropValue && getSingleCropSowingDate(cropValue, this.state.cropNamesList) || moment().subtract(361, 'days').toDate();
        if (isValueFalsy(pickingDate) || !moment(pickingDate).isValid()) {
            errorMessage = "Please enter valid date"
        }
        if(moment(pickingDate) < moment(minDate)){
            errorMessage = "Please enter valid date"
        }
        if (moment(pickingDate).isAfter(today)) {
            errorMessage = "Date can not be more than Today's Date"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateDateOfPickingValue = (name: string, value: unknown, formIndex: number) => {
        const DateOfPickingValueError = this.validateDateOfPickingValue(value as string, this.state.selectedCropId as string).errorMessage
        this.setFormValue(name, value, formIndex, DateOfPickingValueError)
    }

    // VALIDATE PICKING QUANTITY - VALUE
    validateQuantityPickedValue = (quantityValue: string | number) => {
        let errorMessage = ""
        const floatValue = parseFloat(String(quantityValue));

        if (isValueFalsy(quantityValue)) {
            errorMessage = "Please enter Picking Quantity"
        }
        if (floatValue < 0) {
            errorMessage = "Minimum allowed value is 0"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateQuantityPickedValue = (name: string, value: unknown, formIndex: number) => {
        const QuantityPickedValueError = this.validateQuantityPickedValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, QuantityPickedValueError)
    }

    // VALIDATE PICKING QUANITITY - UNIT
    validateQuantityPickedUnit = (quantityUnit: string | number) => {
        let errorMessage = ""
        if (isValueFalsy(quantityUnit)) {
            errorMessage = "Please select Quantity Unit"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateQuantityPickedUnit = (name: string, value: unknown, formIndex: number) => {
        const QuantityPickedUnitError = this.validateQuantityPickedUnit(value as string).errorMessage
        this.setFormValue(name, value, formIndex, QuantityPickedUnitError)
    }

    // VALIDATE TOTAL PICKING COST
    validateTotalPickingCostValue = (pickingCost: string | number) => {
        let errorMessage = ""
        // Convert the value to an integer
        const floatValue = parseFloat(String(pickingCost));

        if (isValueFalsy(pickingCost)) {
            errorMessage = "Please enter Total Picking Cost"
        }
        if (isNaN(floatValue) && !isValueFalsy(pickingCost)) {
            errorMessage = "Please enter valid Value"
        }
        if (floatValue < 0) {
            errorMessage = "Minimum allowed value is 0"
        }
        // ADD MIN MAX VALIDATION IF REQUIRED
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateTotalPickingCostValue = (name: string, value: unknown, formIndex: number) => {
        const TotalPickingCostValueError = this.validateTotalPickingCostValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, TotalPickingCostValueError)
    }

    // VALIDATE STORAGE TYPE
    validateStorageTypeValue = (storageType: string | number) => {
        let errorMessage = ""
        // ADD VALIDATION IF REQUIRED
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateStorageTypeValue = (name: string, value: unknown, formIndex: number) => {
        const StorageTypeValueError = this.validateStorageTypeValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, StorageTypeValueError)
    }

    handleChange = (name: string, value: unknown, formIndex: number) => {
        switch (name) {
            case "date_of_picking":
                this.updateDateOfPickingValue(name, value, formIndex)
                break;
            case "quantity_picked_value":
                this.updateQuantityPickedValue(name, value, formIndex)
                break;
            case "quantity_picked_unit_id":
                this.updateQuantityPickedUnit(name, value, formIndex)
                break;
            case "total_picking_cost":
                this.updateTotalPickingCostValue(name, value, formIndex)
                break;
            case "storage_type_id":
                this.updateStorageTypeValue(name, value, formIndex)
                break;
        }
    };
    setFormValue = (name: string, value: unknown, formIndex: number, errorMessage: string) => {
        const updatedFormValues = [...this.state.harvestForms];

        updatedFormValues[formIndex] = {
            ...updatedFormValues[formIndex],
            [name]: value,
            errors: {
                ...updatedFormValues[formIndex].errors,
                [name]: errorMessage
            },
        };
        this.setState({ harvestForms: updatedFormValues });

    }
    isFarmAndCropValueIsValidated = () => {
        let isBothValueValidated = false;
        const farmValue = this.validateSelectFarmValue(this.state.selectedFarmId)
        const cropValue = this.validateSelectCropValue(this.state.selectedCropId)

        this.setState({
            selectedFarmIdError: farmValue.errorMessage,
            selectedCropIdError: cropValue.errorMessage
        })

        if (!farmValue.isError && !cropValue.isError) {
            isBothValueValidated = true
        }
        return isBothValueValidated
    }

    handleAddAnotherEntry = () => {
        if (!this.isFormComplete()) {
            this.setState({
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: "Please fill the existing forms with valid data."
            })
        } else {
            let existingForms = [...this.state.harvestForms];
            existingForms.push({
                ...initialHarvestFormValue,
                quantity_picked_unit_id: defaultQuanitityUnit(this.state.unitOfPickedQuantityList, "kg").unitId
            });
            this.setState({ harvestForms: existingForms });
        }
    };

    handleRemoveEntry = (index: number) => {
        let existingForms = this.state.harvestForms;
        existingForms.splice(index, 1);
        this.setState({ harvestForms: existingForms });
    };

    setAllFieldErrors = () => {
        const existingForms = [...this.state.harvestForms];

        const updatedForms = existingForms.map((form) => {
            return {
                ...form,
                errors: {
                    ...form.errors,
                    date_of_picking: this.validateDateOfPickingValue(form.date_of_picking, this.state.selectedCropId as string).errorMessage,
                    quantity_picked_value: this.validateQuantityPickedValue(form.quantity_picked_value).errorMessage,
                    quantity_picked_unit_id: this.validateQuantityPickedUnit(form.quantity_picked_unit_id).errorMessage,
                    total_picking_cost: this.validateTotalPickingCostValue(form.total_picking_cost).errorMessage,
                    storage_type_id: this.validateStorageTypeValue(form.storage_type_id).errorMessage,
                }
            }
        })
        this.setState({ harvestForms: updatedForms })
    }

    isAllFormDataIsValidated = () => {
        this.setAllFieldErrors()
        let allValidationPassed = false

        let validatedFormArrayBoolean: Boolean[] = []

        const allFormsData = [...this.state.harvestForms];

        allFormsData.forEach((form, index) => {
            let eachFormFieldsValidated = false
            const DateOfPickingValueError = this.validateDateOfPickingValue(form.date_of_picking, this.state.selectedCropId as string).isError
            const QuantityPickedValueError = this.validateQuantityPickedValue(form.quantity_picked_value).isError
            const QuantityPickedUnitError = this.validateQuantityPickedUnit(form.quantity_picked_unit_id).isError
            const TotalPickingCostValueError = this.validateTotalPickingCostValue(form.total_picking_cost).isError
            const StorageTypeValueError = this.validateStorageTypeValue(form.storage_type_id).isError

            if (!DateOfPickingValueError && !QuantityPickedValueError && !QuantityPickedUnitError &&
                !TotalPickingCostValueError && !StorageTypeValueError
            ) {
                eachFormFieldsValidated = true
            }
            validatedFormArrayBoolean.push(eachFormFieldsValidated)
        })

        allValidationPassed = validatedFormArrayBoolean.every(value => value === true)

        return allValidationPassed
    }
    reShapeBodyDataArray = () => {
        // CHECK ADD SCENARIO
        let reShapedArray = []
        const formStateData = [...this.state.harvestForms]

        reShapedArray = formStateData.map((eachFormData) => {
            return {
                // SET ID FOR EDIT CASE
                ...(this.props.isHarvestModalOpen && eachFormData.id &&
                {
                    "id": eachFormData.id ? eachFormData.id : ""
                }),
                // RESTRICT UPDATE FARM AND CROP WHILE EDIT
                ...(!Boolean(eachFormData.id) &&
                {
                    "land_detail_id": this.state.selectedFarmId,
                    "crop_id": this.state.selectedCropId,
                }),
                "date_of_picking": moment(eachFormData.date_of_picking).format("DD/MM/YYYY"),
                "quantity_picked": Number(eachFormData.quantity_picked_value),
                "unit_of_quantity_picked_id": Number(eachFormData.quantity_picked_unit_id),
                "total_picking_cost": Number(eachFormData.total_picking_cost),
                "storage_type_id": eachFormData.storage_type_id,
            }
        })
        return reShapedArray;

    }

    proceedSubmitfunction = () => {
        this.setState({
            isCustomModalOpen: false,
        })
        const bodyDataArray: ICreateHarvestBody[] = this.reShapeBodyDataArray()
        const requestBody: IRequestBody = {
            "harvest": bodyDataArray
        }
        if (this.props.isEditHarvestModalOpen) {
            this.editHarvestActivity(requestBody)
        } else {
            this.createHarvestActivity(requestBody)
        }
    }

    triggerCustomModal = () => {
        this.setState({
            isCustomModalOpen: true,
        })
    }

    isFormComplete = () => {
        let valid = true;
        if (!this.isFarmAndCropValueIsValidated()) {
          valid = false;
        }
        if (!this.isAllFormDataIsValidated()) {
          valid = false;
        }
        return valid;
    };

    onSubmitHarvestForm = () => {
        if (!this.isFormComplete()) {
            this.setState({
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: "Please fill the existing forms with valid data."
            })
        } else {
            if (this.state.disableAddAnotherBtn) {
                this.triggerCustomModal()
            } else {
                this.proceedSubmitfunction()
            }    
        }
    }
    // Customizable Area End
}