// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ICreatedSell, ICropDetails, IInitailValueSell, ISelectFarm, ISelectFarmDataResponse, ISimpleDropdown, IUnitDropdown, IUnitDropdownResponse } from "../../../components/src/interface.web";
import moment from "moment";
import { defaultQuanitityUnit, returnTruthyString, getSingleCropSowingDate } from "../../../components/src/helper";

export const configJSON = require("./config");
const isValueFalsy = (value: unknown) => value === "" || value === null || value === undefined

const initialSellValues = {
    "id": "",
    "date_of_sell": new Date(),
    "quantity_of_crop_sold_value": "",
    "quantity_of_crop_sold_unit_id": "",
    "crop_price_value": "",
    "crop_price_unit": "kg",
    "total_amount": "",
    "distance_farmer_buyer_value": "",
    "distance_farmer_buyer_unit_id": "",
    "buyer_name": "",
    "transportation_cost": "",
    "vehicle_type_id": "",
    "labor_cost_load_unload": "",
    "errors": {
        "date_of_sell": "",
        "quantity_of_crop_sold_value": "",
        "quantity_of_crop_sold_unit_id": "",
        "crop_price_value": "",
        "total_amount": "",
        "distance_farmer_buyer_value": "",
        "distance_farmer_buyer_unit_id": "",
        "buyer_name": "",
        "transportation_cost": "",
        "vehicle_type_id": "",
        "labor_cost_load_unload": "",
    }
}

export interface Props {
    isSellModalOpen: boolean,
    selectedActivity: string | null | number,
    handleCloseAddActivityModal: () => void,
    userAccountId: string | number,
    openAddCropModalfn: () => void,
    openAddFarmModalfn: () => void,
    // EDIT
    isEditSellModalOpen: boolean,
    activityFarmId: string, // FARM ID
    activityCropId: string, // CROP ID
    selectedyear: number
}
interface S {
    authToken: string | null;
    openSellModal: boolean,
    minimumDate: Date;
    // DROPDOWN STATES
    farmNamesList: ISelectFarm[],
    cropNamesList: any[],
    unitQuantityOfCottonList: IUnitDropdown[],
    unitDistanceFarmerToBuyerList: IUnitDropdown[],
    vehicleTypesList: ISimpleDropdown[],
    // FORMS
    selectedFarmId: string | number,
    selectedFarmIdError: string,
    selectedCropId: string | number,
    selectedCropIdError: string,
    sellForms: IInitailValueSell[],
    initialFormState: IInitailValueSell,
    // ERROR MODAL
    isErrorModalOpen: boolean,
    ErrorModalTitle: string,
    ErrorModalMessage: string,
    // CREATE
    createActivityLoading: boolean
    // FETCH
    getActivityDetailsLoading: boolean
    // EDIT
    editActivityLoading: boolean
}
interface SS { }
// Customizable Area End

export default class SellActivityController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    fetchFarmNamesDataApiCallId: string = "";
    fetchCropNamesDataApiCallId: string = "";
    fetchUnitQuantityOfCottonDataApiCallId: string = ""; // UNIT - QUANTITY OF COTTON
    fetchUnitDistanceFarmerToBuyerDataApiCallId: string = ""; // UNIT - DISTANCE FARMER TO BUYER
    fetchVehicleTypesDataApiCallId: string = ""; // VEHICLE TYPE
    createSellActivityApiCallId: string = ""; // CREATE
    fetchSellActivityApiCallId: string = ""; // FETCH
    editSellActivityApiCallId: string = ""; // EDIT

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            openSellModal: this.props.isSellModalOpen,
            minimumDate: moment().subtract(360, 'days').toDate(),
            // DROPDOWN STATES
            farmNamesList: [],
            cropNamesList: [],
            unitQuantityOfCottonList: [],
            unitDistanceFarmerToBuyerList: [],
            vehicleTypesList: [],
            initialFormState: initialSellValues,
            // FORMS
            selectedFarmId: "",
            selectedFarmIdError: "",
            selectedCropId: "",
            selectedCropIdError: "",
            sellForms: [initialSellValues],
            // ERROR MODAL
            isErrorModalOpen: false,
            ErrorModalTitle: "Error",
            ErrorModalMessage: "",
            // CREATE
            createActivityLoading: false,
            // FETCH
            getActivityDetailsLoading: false,
            // EDIT
            editActivityLoading: false
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start            
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            switch (apiRequestCallId) {
                case this.fetchFarmNamesDataApiCallId:
                    this.handleFetchFarmNamesDataApiCallId(responseJson);
                    break;
                case this.fetchCropNamesDataApiCallId:
                    this.handleFetchCropNamesDataApiCallId(responseJson);
                    break;
                case this.fetchUnitQuantityOfCottonDataApiCallId:
                    this.handleFetchUnitQuantityOfCottonDataApiCallId(responseJson);
                    break;
                case this.fetchUnitDistanceFarmerToBuyerDataApiCallId:
                    this.handleFetchUnitDistanceFarmerToBuyerDataApiCallId(responseJson);
                    break;
                case this.fetchVehicleTypesDataApiCallId:
                    this.handleFetchVehicleTypesDataApiCallId(responseJson);
                    break;
                // CREATE 
                case this.createSellActivityApiCallId:
                    this.handleCreateSellActivityRes(responseJson);
                    break;
                // FETCH 
                case this.fetchSellActivityApiCallId:
                    this.handleFetchSellActivityApiCallId(responseJson)
                    break;
                // EDIT
                case this.editSellActivityApiCallId:
                    this.handleEditSellActivityApiCallId(responseJson)
                    break;

            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<S>,
        snapshot?: SS | undefined
    ): void {
        if ((prevProps.isSellModalOpen !== this.props.isSellModalOpen) && this.props.isSellModalOpen && !this.props.isEditSellModalOpen) {
            this.resetInitialFormValues()
            this.fetchFarmData(this.props.userAccountId);
            this.fetchUnitQuantityOfCottonData()
            this.fetchUnitDistanceFarmerToBuyerData()
            this.fetchVehicleTypesData()            
        }
        if ((prevProps.isEditSellModalOpen !== this.props.isEditSellModalOpen) && this.props.isEditSellModalOpen) {            
            this.setState({ getActivityDetailsLoading: true})  
            this.fetchFarmData(this.props.userAccountId);
            this.fetchCropsData(this.props.activityFarmId)
            this.fetchUnitQuantityOfCottonData()
            this.fetchUnitDistanceFarmerToBuyerData()
            this.fetchVehicleTypesData() 
        }

    }

    // HANDLE FUNCTIONS API CALLS
    handleFetchFarmNamesDataApiCallId = (responseJson: ISelectFarmDataResponse) => {
        if (responseJson && responseJson.data && responseJson.data.length) {
            this.setState({ farmNamesList: responseJson.data })
        } else {
            this.setState({ farmNamesList: [] })
        }
    }

    handleFetchCropNamesDataApiCallId = (responseJson: { data: ICropDetails[] }) => {
        if (responseJson && responseJson.data && responseJson.data.length) {
            this.setState({ cropNamesList: responseJson.data } ,() => {
                if(this.props.isEditSellModalOpen){
                    this.fetchSellActivityDetails()
                }
            }
        );
        } else {
            this.setState({ cropNamesList: [] });
        }
    };

    handleFetchUnitQuantityOfCottonDataApiCallId = (responseJson: IUnitDropdownResponse | null | undefined) => {
        if (responseJson && responseJson.data.length > 0) {
            this.setState({ unitQuantityOfCottonList: responseJson.data })
            if (this.props.isSellModalOpen) {
                this.setState({
                    initialFormState: {
                        ...this.state.initialFormState,
                        quantity_of_crop_sold_unit_id: defaultQuanitityUnit(responseJson.data, "kg").unitId
                    },
                    sellForms: [{
                        ...this.state.initialFormState,
                        quantity_of_crop_sold_unit_id: defaultQuanitityUnit(responseJson.data, "kg").unitId
                    }]
                })
            }
        } else {
            this.setState({ unitQuantityOfCottonList: [], })
        }
    }

    handleFetchUnitDistanceFarmerToBuyerDataApiCallId = (responseJson: IUnitDropdownResponse | null | undefined) => {
        if (responseJson && responseJson.data.length > 0) {
            this.setState({ unitDistanceFarmerToBuyerList: responseJson.data })
            if (this.props.isSellModalOpen) {
                this.setState({
                    initialFormState: {
                        ...this.state.initialFormState,
                        distance_farmer_buyer_unit_id: defaultQuanitityUnit(responseJson.data, "km").unitId
                    },
                    sellForms: [{
                        ...this.state.initialFormState,
                        distance_farmer_buyer_unit_id: defaultQuanitityUnit(responseJson.data, "km").unitId
                    }]
                })
            }
        } else {
            this.setState({ unitDistanceFarmerToBuyerList: [], })
        }
    }

    handleFetchVehicleTypesDataApiCallId = (responseJson: ISimpleDropdown[] | null | undefined) => {
        if (responseJson && responseJson.length > 0) {
            this.setState({ vehicleTypesList: responseJson })
        } else {
            this.setState({ vehicleTypesList: [] })
        }
    }

    handleCreateSellActivityRes = (response: { errors: string[] } | { data: any }[]) => {
        if (response && 'errors' in response && !Array.isArray(response)) {
            this.setState({
                createActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: response.errors[0],
            })
        } else if (Array.isArray(response)) {
            this.setState({
                createActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Success",
                ErrorModalMessage: "Sell Activity added Successfully.",
            })
            this.handleCloseSellActivityModal()
        }
    }

    handleFetchSellActivityApiCallId = (response: { data: ICreatedSell[] } | undefined) => {
        if (response && response.data && response.data.length > 0) {
            this.setFormsData(response.data)
        } else {
            this.setState({ getActivityDetailsLoading: false })
        }
    }

    handleEditSellActivityApiCallId = (response: { errors: string[] } | { data: any }[]) => {
        if (response && 'errors' in response && !Array.isArray(response)) {
            this.setState({
                editActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: response.errors[0],
            })
        } else if (Array.isArray(response)) {
            this.setState({
                editActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Success",
                ErrorModalMessage: "Sell Activity updated Successfully.",
            })
            this.handleCloseSellActivityModal()
        }
    }
    // API CALLS FUNCTIONS
    fetchFarmData = (userAccountId: string | number | null) => {
        if (!userAccountId) return
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
            type: "admin_user"
        };

        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchFarmNamesDataApiCallId = requestMsg.messageId;

        const endpoint = `${configJSON.fetchFarmListDataAPiEndPoint}?user_id=${this.props.userAccountId}`
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    fetchCropsData = (farmId: string | number) => {
        if (!this.props.userAccountId || !farmId) return;

        this.setState({ cropNamesList: [] })
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
            type: "admin_user",
        };

        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchCropNamesDataApiCallId = requestMsg.messageId;

        const endpoint = `${configJSON.fetchCropListDataApiEndPoint}?farm_id=${farmId}&user_id=${this.props.userAccountId}`;
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    };

    fetchUnitQuantityOfCottonData = () => {
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchUnitQuantityOfCottonDataApiCallId = requestMsg.messageId;
        const endpoint = `${configJSON.unitDropdownAPIEndPoint}?activity=${'sell'}&dropdown=${'unit_of_quantity_of_cotton_sold_sell'}`

        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    fetchUnitDistanceFarmerToBuyerData = () => {
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchUnitDistanceFarmerToBuyerDataApiCallId = requestMsg.messageId;
        const endpoint = `${configJSON.unitDropdownAPIEndPoint}?activity=${'sell'}&dropdown=${'unit_of_distance_sell'}`

        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    fetchVehicleTypesData = () => {
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchVehicleTypesDataApiCallId = requestMsg.messageId;

        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.vehicleTypesAPIEndPoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    createSellActivity = (requestBody: any) => {
        if(!this.props.userAccountId) return

        this.setState({ createActivityLoading: true })
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
            "type": "admin_user"
        };

        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createSellActivityApiCallId = requestMsg.messageId;

        const endpoint = `mobile_and_web/farm_dairy/activity_sells?user_id=${this.props.userAccountId}`
        
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    } 

    fetchSellActivityDetails = () => {
        this.setState({ getActivityDetailsLoading: true })
        this.resetInitialFormValues()
        const farmId = this.props.activityFarmId
        const cropId = this.props.activityCropId
        const userAccountId = this.props.userAccountId
        const currentSelectedYear = this.props.selectedyear
        if (farmId && cropId && this.props.userAccountId) {
            const headers = {
                token: this.state.authToken,
                "Content-Type": configJSON.validationApiContentType,
                type: "admin_user" 
            };
            const endpoint = `mobile_and_web/farm_dairy/activity_sells?user_id=${userAccountId}&year=${currentSelectedYear}&land_id=${farmId}&crop_id=${cropId}`

            const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.fetchSellActivityApiCallId = reqMessage.messageId;

            reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
            reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(reqMessage.id, reqMessage);
        } else {
            this.setState({ getActivityDetailsLoading: false })
        }
    }

    editSellActivity  = (requestBody: any) => {
        if(!this.props.userAccountId) return

        this.setState({ editActivityLoading: true })
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
            "type": "admin_user"
        };

        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.editSellActivityApiCallId = requestMsg.messageId;

        const endpoint = `mobile_and_web/farm_dairy/activity_sells?user_id=${this.props.userAccountId}`
        
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    } 
    // ACTIVITY MODAL FUNCTIONS
    openSellActivityModal = () => {
        return this.props.isSellModalOpen || this.state.openSellModal
    }
    handleCloseSellActivityModal = () => {
        this.props.handleCloseAddActivityModal()
        this.resetInitialFormValues()
    }
    resetInitialFormValues = () => {
        this.setState({
            openSellModal: false,
            selectedFarmId: "",
            selectedFarmIdError: "",
            selectedCropId: "",
            selectedCropIdError: "",
            sellForms: [this.state.initialFormState],
            minimumDate:  moment().subtract(360, 'days').toDate(),
        })
    }
    // ERROR MODAL FUNCTIONS
    handleCloseErrorModal = () => {
        this.setState({
            isErrorModalOpen: false,
            ErrorModalMessage: ""
        })
    }

    openAddFarm = () => {
        this.handleCloseSellActivityModal()
        this.props.openAddFarmModalfn()
    }
    openAddCrop = () => {
        this.handleCloseSellActivityModal()
        this.props.openAddCropModalfn()
    }

    setFormsData = (activityData: ICreatedSell[]) => {
        let updateInitalValue = []
        const filteredData = activityData.filter((item) => item.attributes.freeze_record !== true);
        updateInitalValue = filteredData.map((item) => {            
            
            const date_of_sell = String(moment(item.attributes.date_of_sell))
            const momentObject = moment(item.attributes.date_of_sell);
            const dateObject = momentObject.toDate();
            const quantity_of_crop_sold_value = Number(item.attributes.quantity_of_crop_sold)
            const quantity_unit = returnTruthyString(item.attributes.unit_of_quantity_of_crop_sold_id && item.attributes.unit_of_quantity_of_crop_sold_id[0]?.id)
            const crop_price_unit = returnTruthyString(item.attributes.default_unit_crop_info && item.attributes.default_unit_crop_info[0]?.name)
            const crop_price = Number(item.attributes.crop_price)
            const total_amount = Number(item.attributes.total_amount)
            const distance_value = Number(item.attributes.distance_from_farmer_location_to_buyer)
            const distance_unit = returnTruthyString(item.attributes.unit_of_distance_location_id && item.attributes.unit_of_distance_location_id[0]?.id)
            
            const buyer_name = returnTruthyString(item.attributes.buyer_name)
            const transportation_cost = Number(item.attributes.transportation_cost)
            const vehicle_type_id = Number(item.attributes.vehical_type_id && item.attributes.vehical_type_id[0]?.id)
            const labor_cost_load_unload = Number(item.attributes.labor_cost_of_load_and_unload)            

            return {
                id: returnTruthyString(item?.id),
                "date_of_sell": date_of_sell,
                "quantity_of_crop_sold_value": quantity_of_crop_sold_value,
                "quantity_of_crop_sold_unit_id": quantity_unit,
                "crop_price_value": crop_price,
                "crop_price_unit" : crop_price_unit,
                "total_amount": total_amount,
                "distance_farmer_buyer_value": distance_value,
                "distance_farmer_buyer_unit_id": distance_unit,
                "buyer_name": buyer_name,
                "transportation_cost": transportation_cost,
                "vehicle_type_id": vehicle_type_id,
                "labor_cost_load_unload": labor_cost_load_unload,
                "errors": {
                    "date_of_sell": this.validateMinDate(moment(dateObject).toDate(), this.props.activityCropId as string),
                    "quantity_of_crop_sold_value": "",
                    "quantity_of_crop_sold_unit_id": "",
                    "crop_price_value": "",
                    "total_amount": "",
                    "distance_farmer_buyer_value": "",
                    "distance_farmer_buyer_unit_id": "",
                    "buyer_name": "",
                    "transportation_cost": "",
                    "vehicle_type_id": "",
                    "labor_cost_load_unload": "",
                }
            }
        })

        this.setState({
            selectedFarmId: this.props.activityFarmId ? this.props.activityFarmId : "",
            selectedCropId: this.props.activityCropId ? this.props.activityCropId : "",            
            sellForms: updateInitalValue,
            getActivityDetailsLoading: false, 
            minimumDate: getSingleCropSowingDate(this.props.activityCropId as string, this.state.cropNamesList)
            ?? moment().subtract(360, 'days').toDate(),
        })
    }

    validateMinDate = (selectedDate: Date, cropValue?: string) => {
        const minimumDate = (cropValue && getSingleCropSowingDate(cropValue, this.state.cropNamesList)) || moment().subtract(361, 'days').toDate();
        return (selectedDate >= minimumDate && selectedDate <= new Date()) ? "" : "Please enter valid date";
    };

    // FORM FUNCTIONS
    handleChangeSelectFarm = (selectedFarmValue: string | number | unknown) => {
        const updatedFormValues = this.state.sellForms.map((item) => ({
            ...item,
            errors: { ...item.errors, date_of_sell: this.validateMinDate(item.date_of_sell as Date, "") },
          }));
        this.setState({
            selectedFarmId: selectedFarmValue as string,
            selectedFarmIdError: this.validateSelectFarmValue(selectedFarmValue as string).errorMessage,
            selectedCropId: "",
            selectedCropIdError: "",
            minimumDate: moment().subtract(360, 'days').toDate(),
            sellForms: updatedFormValues,
        }, () => {
            this.fetchCropsData(this.state.selectedFarmId)
        })
    }
    validateSelectFarmValue = (selectedFarmValue: string | number | null) => {
        let errorMessage = ""
        if (isValueFalsy(selectedFarmValue)) {
            errorMessage = "Please Select Farm Name"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    handleChangeSelectCrop = (value: string | number | unknown) => {
        const minimumDate = getSingleCropSowingDate(value as string, this.state.cropNamesList) || moment().subtract(360, 'days').toDate();
        this.setState({ selectedCropId: value as string, selectedCropIdError: "", minimumDate })
        const updatedFormValues = [...this.state.sellForms];
        this.state.sellForms.map((item, index) => {
          updatedFormValues[index] = {
            ...updatedFormValues[index],
            errors: {
              ...updatedFormValues[index].errors,
              date_of_sell: this.validateDateOfSellValue(item.date_of_sell as Date, value as string).errorMessage,
            }
          };
        });
        this.setState({ sellForms: updatedFormValues });
    }
    validateSelectCropValue = (selectedCropValue: string | number | null) => {
        let errorMessage = ""
        if (isValueFalsy(selectedCropValue)) {
            errorMessage = "Please Select Crop Name"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }

    // VALIDATE DATE OF SELL
    validateDateOfSellValue = (sellDate: string | Date | null, cropValue?: string) => {
        let errorMessage = ""
        const today = moment().endOf('day');
        const minDate = cropValue && getSingleCropSowingDate(cropValue, this.state.cropNamesList) || moment().subtract(361, 'days').toDate();

        if (isValueFalsy(sellDate) || !moment(sellDate).isValid()) {
            errorMessage = "Please enter valid date"
        }
        if(moment(sellDate) < moment(minDate)){
            errorMessage = "Please enter valid date"
        }
        if (moment(sellDate).isAfter(today)) {
            errorMessage = "Date can not be more than Today's Date"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateDateOfSellValue = (name: string, value: unknown, formIndex: number) => {
        const DateOfSellValueError = this.validateDateOfSellValue(value as string, this.state.selectedCropId as string).errorMessage
        this.setFormValue(name, value, formIndex, DateOfSellValueError)
    }

    // VALIDATE QUANTITY OF COTTON - VALUE
    validateQuantityOfCottonValue = (quantityValue: string | number) => {
        let errorMessage = ""
        const floatValue = parseFloat(String(quantityValue));

        if (isValueFalsy(quantityValue)) {
            errorMessage = "Please enter Quantity of Crop"
        }
        if (isNaN(floatValue) && !isValueFalsy(quantityValue)) {
            errorMessage = "Please enter valid Numeric value"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateQuantityOfCottonValue = (name: string, value: unknown, formIndex: number) => {
        const QuantityOfCottonValueError = this.validateQuantityOfCottonValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, QuantityOfCottonValueError)
    }

    // VALIDATE QUANTITY OF COTTON - UNIT
    validateQuantityOfCottonUnit = (quantityUnit: string | number) => {
        let errorMessage = ""
        if (isValueFalsy(quantityUnit)) {
            errorMessage = "Please select Quantity Unit"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateQuantityOfCottonUnit = (name: string, value: unknown, formIndex: number) => {
        const QuantityOfCottonUnitError = this.validateQuantityOfCottonUnit(value as string).errorMessage
        this.setFormValue(name, value, formIndex, QuantityOfCottonUnitError)
    }

    // VALIDATE COTTON PRICE - VALUE
    validateCottonPriceValue = (cottonPrice: string | number) => {
        let errorMessage = ""
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateCottonPriceValue = (name: string, value: unknown, formIndex: number) => {
        const CottonPriceValueError = this.validateCottonPriceValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, CottonPriceValueError)
    }


    // VALIDATE TOTAL AMOUNT
    validateTotalAmountValue = (totalAmount: string | number) => {
        let errorMessage = ""
        // Convert the value to an integer
        const floatValue = parseFloat(String(totalAmount));

        if (isValueFalsy(totalAmount)) {
            errorMessage = "Please enter Total Amount"
        }
        if (isNaN(floatValue) && !isValueFalsy(totalAmount)) {
            errorMessage = "Please enter valid Value"
        }
        if (floatValue < 500) {
            errorMessage = "Minimum allowed value is 500"
        }
        if (floatValue > 1000000) {
            errorMessage = "Maximum allowed value is 10,00,000"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateTotalAmountValue = (name: string, value: unknown, formIndex: number) => {
        const TotalAmountValueError = this.validateTotalAmountValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, TotalAmountValueError)
    }

    // VALIDATE DISTANCE FARMER TO BUYER - VALUE
    validateDistanceFarmerBuyerValue = (distanceValue: string | number) => {
        let errorMessage = ""
        const floatValue = parseFloat(String(distanceValue));

        if (isValueFalsy(distanceValue)) {
            errorMessage = "Please enter Distance"
        }
        if (isNaN(floatValue) && !isValueFalsy(distanceValue)) {
            errorMessage = "Please enter valid Numeric value"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateDistanceFarmerBuyerValue = (name: string, value: unknown, formIndex: number) => {
        const DistanceFarmerBuyerValueError = this.validateDistanceFarmerBuyerValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, DistanceFarmerBuyerValueError)
    }

    // VALIDATE DISTANCE FARMER TO BUYER - UNIT
    validateDistanceFarmerBuyerUnit = (distanceUnit: string | number) => {
        let errorMessage = ""
        if (isValueFalsy(distanceUnit)) {
            errorMessage = "Please select Distance Unit"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateDistanceFarmerBuyerUnit = (name: string, value: unknown, formIndex: number) => {
        const DistanceFarmerBuyerUnitError = this.validateDistanceFarmerBuyerUnit(value as string).errorMessage
        this.setFormValue(name, value, formIndex, DistanceFarmerBuyerUnitError)
    }

    // VALIDATE BUYER NAME 
    validateBuyerNameValue = (buyerName: string) => {
        let errorMessage = ""
        const alphaNumericRegex = /^[a-zA-Z0-9 ]+$/;

        if (isValueFalsy(buyerName) || isValueFalsy(buyerName.trim())) {
            errorMessage = "Please enter Buyer Name"
        }
        if (!alphaNumericRegex.test(buyerName.trim()) && !isValueFalsy(buyerName.trim())) {
            errorMessage = "Please enter AlphaNumeric characters only"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateBuyerNameValue = (name: string, value: unknown, formIndex: number) => {
        const buyernameValue = String(value).trimLeft() as string
        const BuyerNameValueError = this.validateBuyerNameValue(value as string).errorMessage
        this.setFormValue(name, buyernameValue, formIndex, BuyerNameValueError)
    }

    // VALIDATE TRANSPORTATION COST
    validateTransportationCostValue = (transportationCost: string | number) => {
        let errorMessage = ""
        // Convert the value to an integer
        const floatValue = parseFloat(String(transportationCost));

        if (isValueFalsy(transportationCost)) {
            errorMessage = "Please enter Transportation Cost"
        }
        if (isNaN(floatValue) && !isValueFalsy(transportationCost)) {
            errorMessage = "Please enter valid Value"
        }
        if (floatValue < 100) {
            errorMessage = "Minimum allowed value is 100"
        }
        if (floatValue > 200000) {
            errorMessage = "Maximum allowed value is 200000"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateTransportationCostValue = (name: string, value: unknown, formIndex: number) => {
        const TransportationCostValueError = this.validateTransportationCostValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, TransportationCostValueError)
    }

    // VALIDATE VEHICLE TYPE
    validateVehicleTypeValue = (vehicleType: string | number) => {
        let errorMessage = ""
        if (isValueFalsy(vehicleType)) {
            errorMessage = "Please select Vehicle Type"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateVehicleTypeValue = (name: string, value: unknown, formIndex: number) => {
        const VehicleTypeValueError = this.validateVehicleTypeValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, VehicleTypeValueError)
    }

    // VALIDATE LABOR LOAD UNLOAD COST
    validateLaborCostValue = (laborCost: string | number) => {
        let errorMessage = ""
        // Convert the value to an integer
        const floatValue = parseFloat(String(laborCost));

        if (isValueFalsy(laborCost)) {
            errorMessage = "Please enter Labor Cost"
        }
        if (isNaN(floatValue) && !isValueFalsy(laborCost)) {
            errorMessage = "Please enter valid Value"
        }
        if (floatValue < 0) {
            errorMessage = "Minimum allowed value is 0"
        }
        if (floatValue > 1000000) {
            errorMessage = "Maximum allowed value is 10,00,000"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateLaborCostValue = (name: string, value: unknown, formIndex: number) => {
        const LaborCostValueError = this.validateLaborCostValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, LaborCostValueError)
    }

    handleChange = (name: string, value: unknown, formIndex: number) => {
        switch (name) {
            case "date_of_sell":
                this.updateDateOfSellValue(name, value, formIndex)
                break;
            case "quantity_of_crop_sold_value":
                this.updateQuantityOfCottonValue(name, value, formIndex)
                break;
            case "quantity_of_crop_sold_unit_id":
                this.updateQuantityOfCottonUnit(name, value, formIndex)
                break;
            case "crop_price_value":
                this.updateCottonPriceValue(name, value, formIndex)
                break;
            case "total_amount":
                this.updateTotalAmountValue(name, value, formIndex)
                break;
            case "distance_farmer_buyer_value":
                this.updateDistanceFarmerBuyerValue(name, value, formIndex)
                break;
            case "distance_farmer_buyer_unit_id":
                this.updateDistanceFarmerBuyerUnit(name, value, formIndex)
                break;
            case "buyer_name":
                this.updateBuyerNameValue(name, value, formIndex)
                break;
            case "transportation_cost":
                this.updateTransportationCostValue(name, value, formIndex)
                break;
            case "vehicle_type_id":
                this.updateVehicleTypeValue(name, value, formIndex)
                break;
            case "labor_cost_load_unload":
                this.updateLaborCostValue(name, value, formIndex)
                break;
        }
    }

    setFormValue = (name: string, value: unknown, formIndex: number, errorMessage: string) => {
        const updatedFormValues = [...this.state.sellForms];

        updatedFormValues[formIndex] = {
            ...updatedFormValues[formIndex],
            [name]: value,
            errors: {
                ...updatedFormValues[formIndex].errors,
                [name]: errorMessage
            },
        };
        this.setState({ sellForms: updatedFormValues });

    }

    handleAddAnotherEntry = () => {
        if (!this.isFormComplete()) {
            this.setState({
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: "Please fill the existing forms with valid data."
            })
        } else {
            let existingForms = [...this.state.sellForms];
            existingForms.push(this.state.initialFormState);
            this.setState({ sellForms: existingForms });
        }
    };
    handleRemoveEntry = (index: number) => {
        let existingForms = this.state.sellForms;
        existingForms.splice(index, 1);
        this.setState({ sellForms: existingForms });
    };
    isFarmAndCropValueIsValidated = () => {
        let isBothValueValidated = false;
        const farmValue = this.validateSelectFarmValue(this.state.selectedFarmId)
        const cropValue = this.validateSelectCropValue(this.state.selectedCropId)

        this.setState({
            selectedFarmIdError: farmValue.errorMessage,
            selectedCropIdError: cropValue.errorMessage
        })

        if (!farmValue.isError && !cropValue.isError) {
            isBothValueValidated = true
        }
        return isBothValueValidated
    }

    setAllFieldErrors = () => {
        const existingForms = [...this.state.sellForms];

        const updatedForms = existingForms.map((form) => {
            return {
                ...form,
                errors: {
                    ...form.errors,
                    date_of_sell: this.validateDateOfSellValue(form.date_of_sell, this.state.selectedCropId as string).errorMessage,
                    quantity_of_crop_sold_value: this.validateQuantityOfCottonValue(form.quantity_of_crop_sold_value).errorMessage,
                    quantity_of_crop_sold_unit_id: this.validateQuantityOfCottonUnit(form.quantity_of_crop_sold_unit_id).errorMessage,
                    crop_price_value: this.validateCottonPriceValue(form.crop_price_value).errorMessage,
                    total_amount: this.validateTotalAmountValue(form.total_amount).errorMessage,
                    distance_farmer_buyer_value: this.validateDistanceFarmerBuyerValue(form.distance_farmer_buyer_value).errorMessage,
                    distance_farmer_buyer_unit_id: this.validateDistanceFarmerBuyerUnit(form.distance_farmer_buyer_unit_id).errorMessage,
                    buyer_name: this.validateBuyerNameValue(form.buyer_name).errorMessage,
                    transportation_cost: this.validateTransportationCostValue(form.transportation_cost).errorMessage,
                    vehicle_type_id: this.validateVehicleTypeValue(form.vehicle_type_id).errorMessage,
                    labor_cost_load_unload: this.validateLaborCostValue(form.labor_cost_load_unload).errorMessage
                }
            }
        })
        this.setState({ sellForms: updatedForms })
    }

    isAllFormDataIsValidated = () => {
        this.setAllFieldErrors()
        let allValidationPassed = false

        let validatedFormArrayBoolean: Boolean[] = []

        const allFormsData = [...this.state.sellForms];

        allFormsData.forEach((form, index) => {
            let eachFormFieldsValidated = false
            const DateOfSellValueError = this.validateDateOfSellValue(form.date_of_sell, this.state.selectedCropId as string).isError
            const QuantityOfCottonValueError = this.validateQuantityOfCottonValue(form.quantity_of_crop_sold_value).isError
            const QuantityOfCottonUnitError = this.validateQuantityOfCottonUnit(form.quantity_of_crop_sold_unit_id).isError
            const CottonPriceValueError = this.validateCottonPriceValue(form.crop_price_value).isError
            const TotalAmountValueError = this.validateTotalAmountValue(form.total_amount).isError
            const DistanceFarmerBuyerValueError = this.validateDistanceFarmerBuyerValue(form.distance_farmer_buyer_value).isError
            const DistanceFarmerBuyerUnitError = this.validateDistanceFarmerBuyerUnit(form.distance_farmer_buyer_unit_id).isError
            const BuyerNameValueError = this.validateBuyerNameValue(form.buyer_name).isError
            const TransportationCostValueError = this.validateTransportationCostValue(form.transportation_cost).isError
            const VehicleTypeValueError = this.validateVehicleTypeValue(form.vehicle_type_id).isError
            const LaborCostValueError = this.validateLaborCostValue(form.labor_cost_load_unload).isError

            if (!DateOfSellValueError && !QuantityOfCottonValueError && !QuantityOfCottonUnitError &&
                !CottonPriceValueError && !TotalAmountValueError &&
                !DistanceFarmerBuyerValueError && !DistanceFarmerBuyerUnitError && !BuyerNameValueError &&
                !TransportationCostValueError && !VehicleTypeValueError && !LaborCostValueError
            ) {
                eachFormFieldsValidated = true
            }
            validatedFormArrayBoolean.push(eachFormFieldsValidated)
        })

        allValidationPassed = validatedFormArrayBoolean.every(value => value === true)

        return allValidationPassed
    }

    isFormComplete = () => {
        let valid = true;
        if (!this.isFarmAndCropValueIsValidated()) {
          valid = false;
        }
        if (!this.isAllFormDataIsValidated()) {
          valid = false;
        }
        return valid;
    };

    onSubmitSellForm = () => {
        if (!this.isFormComplete()) {
            this.setState({
                isErrorModalOpen: true,
                ErrorModalMessage: "Please fill the existing forms with valid data."
            })
        } else {
            const bodyDataArray = this.reShapeBodyData()
            const requestBody = {
                "sell_activity": bodyDataArray
            }
            if (this.props.isEditSellModalOpen) {
                this.editSellActivity(requestBody)
            } else {
                this.createSellActivity(requestBody)
            }
        }
    }

    reShapeBodyData = () => {

        let reShapedArray = []
        const formStateData = [...this.state.sellForms]

        reShapedArray = formStateData.map((eachFormData) => {
            return {
                // SET ID FOR EDIT CASE
                ...(this.props.isEditSellModalOpen && eachFormData.id &&
                {
                    "id": eachFormData.id ? eachFormData.id : ""
                }),
                // RESTRICT UPDATE FARM AND CROP WHILE EDIT
                ...(!Boolean(eachFormData.id) &&
                {
                    "land_detail_id": this.state.selectedFarmId,
                    "crop_id": this.state.selectedCropId,
                }),
                "date_of_sell": moment(eachFormData.date_of_sell).format("DD/MM/YYYY"),
                "quantity_of_crop_sold": eachFormData.quantity_of_crop_sold_value,
                "unit_of_quantity_of_crop_sold_id": Number(eachFormData.quantity_of_crop_sold_unit_id),
                "total_amount": Number(eachFormData.total_amount),
                "distance_from_farmer_location_to_buyer": Number(eachFormData.distance_farmer_buyer_value),
                "unit_of_distance_location_id": Number(eachFormData.distance_farmer_buyer_unit_id),
                "buyer_name": String(eachFormData.buyer_name).trim(),
                "transportation_cost": Number(eachFormData.transportation_cost),
                "labor_cost_of_load_and_unload": Number(eachFormData.labor_cost_load_unload),
                "vehical_type_id": Number(eachFormData.vehicle_type_id)
            }
        })
        return reShapedArray;
    }

    // Customizable Area End
}