import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  ICropDetails,
  ISelectFarm,
  ISelectCrop,
  ISelectFarmDataResponse,
  IIntialValueIrrigation,
  UnitFarmAreaId as IIrrigationType,
  IUnitDropdownResponse,
  IUnitDropdown,
  IIntialValueIrrigationError,
  IIrrigationResponse,
} from "../../../components/src/interface.web";
import moment from "moment";
import { getSowingDate } from "../../../components/src/helper";

const initialIrrigationValues = {
  source_of_irrigation: "",
  type_of_irrigation: "",
  date: new Date(),
  hours_of_irrigation: "",
  labor_cost: "",
  horizontal_distance: "",
  motor_in_hp: "",
  depth_of_pump: "",
  irrigation_cost: "",
  number_of_nozzles: "",
  spacing_of_drippers: "",
  drip_irrigated_area: "",
  row_spacing: "",
  dripper_discharge: "",
  //UNITS
  unit_horizontal_distance: "",
  unit_motor_in_hp: "",
  unit_depth_of_pump: "",
  unit_spacing_of_drippers: "",
  unit_drip_irrigated_area: "",
  unit_row_spacing: "",
  unit_dripper_discharge: "",
  errors: {
    source_of_irrigation: "",
    type_of_irrigation: "",
    date: "",
    hours_of_irrigation: "",
    labor_cost: "",
    horizontal_distance: "",
    motor_in_hp: "",
    depth_of_pump: "",
    irrigation_cost: "",
    number_of_nozzles: "",
    spacing_of_drippers: "",
    drip_irrigated_area: "",
    row_spacing: "",
    dripper_discharge: "",
    //UNITS
    unit_horizontal_distance: "",
    unit_motor_in_hp: "",
    unit_depth_of_pump: "",
    unit_spacing_of_drippers: "",
    unit_drip_irrigated_area: "",
    unit_row_spacing: "",
    unit_dripper_discharge: "",
  }
};
// Customizable Area End

export const configJSON = require("./config");
type FarmIdTypes = string | undefined;
export interface Props {
  // Customizable Area Start
  isIrrigationModal: boolean,
  selectedActivity: string | null | number,
  accountId: string | number;
  isEditActivity: boolean;
  selectedActivityFarmId: FarmIdTypes;
  selectedActivityCropId: any;
  selectedyear: number;
  handleCloseAddActivityModal: () => void,
  handleAddCropModalCallback: () => void;
  handleOpenFarmModalCallback: () => void;
  handleEditFarm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string | number) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  farmNamesList: ISelectFarm[],
  cropNamesList: ISelectCrop[],
  formLandDetailId: string | unknown;
  formCropId: string | unknown;
  formLandDetailIdError: string;
  formCropIdError: string;
  authToken: string | null;
  cropNameList: ICropDetails[];
  loader: boolean;
  rainFedFarm: boolean;
  minimumDate: Date; 
  //DROPDOWNS
  irrigationTypeList: IIrrigationType[],
  irrigationSourceList: IIrrigationType[],
  unitOfHorizontalDistanceList: IUnitDropdown[],
  unitOfMotorList: IUnitDropdown[],
  unitOfDepthPumpList: IUnitDropdown[],
  unitOfSpacingDrippersList: IUnitDropdown[],
  unitOfDripAreaList: IUnitDropdown[],
  unitOfRowSpacingList: IUnitDropdown[],
  unitOfDripperDischargeList: IUnitDropdown[],
  // FORMS
  initialIrrigationValues: IIntialValueIrrigation[];
  latestIrrigation: IIntialValueIrrigation;
  selectedFarmId: string | number,
  selectedFarmIdError: string,
  selectedCropIdArr: any[],
  selectedCropIdError: string,
  // ERROR
  isErrorModalOpen: boolean,
  ErrorModalTitle: string,
  ErrorModalMessage: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class IrrigationActivityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchFarmNamesDataApiCallId: string = "";
  fetchCropNamesDataApiCallId: string = "";
  fetchSourceOfIrrigationApiCallId: string = "";
  fetchIrrigationTypeApiCallId: string = "";
  fetchHorizontalDistanceUnitDataApiCallId: string = "";
  fetchMotorInHPUnitDataApiCallId: string = "";
  fetchDepthOfPumpUnitDataApiCallId: string = "";
  fetchSpacingOfDrippersUnitDataApiCallId: string = "";
  fetchDripIrrigatedAreaUnitDataApiCallId: string = "";
  fetchRowSpacingUnitDataApiCallId: string = "";
  fetchDripDischargeUnitDataApiCallId: string = "";
  createIrrigationActivityApiCallId: string = "";
  fetchLatestIrrigationRecordApiCallId: string = "";
  fetchActivityDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      farmNamesList: [],
      cropNamesList: [],
      formLandDetailId: "",
      formLandDetailIdError: "",
      formCropId: "",
      formCropIdError: "",
      cropNameList: [],
      authToken: localStorage.getItem("tokenn"),
      loader: false,
      rainFedFarm: false,
      initialIrrigationValues: [initialIrrigationValues],
      latestIrrigation: initialIrrigationValues,
      minimumDate: moment().subtract(360, 'days').toDate(),
      //DROPDOWNS
      irrigationTypeList: [],
      irrigationSourceList: [],
      unitOfHorizontalDistanceList: [],
      unitOfMotorList: [],
      unitOfDepthPumpList: [],
      unitOfSpacingDrippersList: [],
      unitOfDripAreaList: [],
      unitOfRowSpacingList: [],
      unitOfDripperDischargeList: [],
      // FORMS
      selectedFarmId: "",
      selectedFarmIdError: "",
      selectedCropIdArr: [],
      selectedCropIdError: "",
      // ERROR
      isErrorModalOpen: false,
      ErrorModalTitle: "",
      ErrorModalMessage: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    // Customizable Area Start
    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.fetchFarmNamesDataApiCallId:
          this.handleFetchFarmNamesDataApiCallId(responseJson);
          break;
        case this.fetchCropNamesDataApiCallId:
          this.handleFetchCropNamesDataApiCallId(responseJson);
          break;
        case this.fetchIrrigationTypeApiCallId:
          this.handleFetchIrrigationTypeResponse(responseJson);
          break;
        case this.fetchSourceOfIrrigationApiCallId:
          this.handleFetchSourceOfIrrigationApiResponse(responseJson);
          break;
        case this.fetchHorizontalDistanceUnitDataApiCallId:
          this.handleFetchDistanceUnitResponse(responseJson);
          break;
        case this.fetchMotorInHPUnitDataApiCallId:
          this.handleFetchMotorInHPUnitResponse(responseJson);
          break;
        case this.fetchDepthOfPumpUnitDataApiCallId:
          this.handleFetchDepthOfPumpUnitResponse(responseJson);
          break;
        case this.fetchSpacingOfDrippersUnitDataApiCallId:
          this.handleFetchSpacingOfDrippersUnitResponse(responseJson);
          break;
        case this.fetchDripIrrigatedAreaUnitDataApiCallId:
          this.handleFetchDripIrrigatedAreaUnitResponse(responseJson);
          break;
        case this.fetchRowSpacingUnitDataApiCallId:
          this.handleFetchRowSpacingUnitResponse(responseJson);
          break;
        case this.fetchDripDischargeUnitDataApiCallId:
          this.handleFetchDripDischargeUnitResponse(responseJson);
          break;
        case this.createIrrigationActivityApiCallId:
          this.handleCreateIrrigationApiResponse(responseJson);
          break;
        case this.fetchLatestIrrigationRecordApiCallId:
          this.handleFetchLastIrrigationRecordResponse(responseJson)
          break;
        case this.fetchActivityDetailsApiCallId:
          this.handleFetchActivityDetailsApiCallId(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.resetErrorObject(initialIrrigationValues.errors)
    if (this.props.isEditActivity) {
      this.fetchFarmData(this.props.accountId);
      this.fetchCropsData(this.props.accountId);
      this.fetchSourceOfIrrigationData()
      this.fetchTypeOfIrrigationData()
      this.fetchHorizontalDistanceUnitData()
      this.fetchMotorInHPUnitData()
      this.fetchDepthOfPumpUnitData()
      this.fetchSpacingOfDrippersUnitData()
      this.fetchDripIrrigatedAreaUnitData()
      this.fetchRowToRowSpacingUnitData()
      this.fetchDripperDischargeUnitData()
    }
    if (!this.props.isEditActivity) {
      this.fetchFarmData(this.props.accountId);
      this.fetchSourceOfIrrigationData()
      this.fetchTypeOfIrrigationData()
      this.fetchHorizontalDistanceUnitData()
      this.fetchMotorInHPUnitData()
      this.fetchDepthOfPumpUnitData()
      this.fetchSpacingOfDrippersUnitData()
      this.fetchDripIrrigatedAreaUnitData()
      this.fetchRowToRowSpacingUnitData()
      this.fetchDripperDischargeUnitData()
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (prevState.selectedFarmId !== this.state.selectedFarmId) {
      this.fetchCropsData(this.props.accountId);
    }
  }

  // API CALLS STARTS
  fetchFarmData = (accountId: string | number | null) => {
    if (!accountId) return
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user"
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFarmNamesDataApiCallId = requestMessage.messageId;
    const endpoint = `/mobile_and_web/farm_dairy/selected_farm?user_id=${this.props.accountId}`
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchCropsData = (accountID: string | number) => {
    this.setState({ cropNamesList: [] })
    const headers = {
      token: this.state.authToken,
      type: "admin_user",
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchCropNamesDataApiCallId = requestMessage.messageId;
    const endpoint = `mobile_and_web/farm_dairy/selected_crop?farm_id=${this.state.selectedFarmId ?? this.props.selectedActivityFarmId}&user_id=${this.props.accountId}`;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchSourceOfIrrigationData = () => {
    const headers = {
      token: this.state.authToken, "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchSourceOfIrrigationApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_profile_bio/source_irrigations");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchTypeOfIrrigationData = () => {
    const headers = {
      token: this.state.authToken, "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchIrrigationTypeApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_profile_bio/type_irrigations");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchHorizontalDistanceUnitData = () => {
    const headers = { token: this.state.authToken, "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchHorizontalDistanceUnitDataApiCallId = requestMessage.messageId;
    const endpoint = `admin/bx_block_dropdown/unit_dropdowns?activity=irrigation&dropdown=unit_of_horizontal_distance_irrigation`
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchMotorInHPUnitData = () => {
    const headers = { token: this.state.authToken, "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchMotorInHPUnitDataApiCallId = requestMessage.messageId;
    const endpoint = `admin/bx_block_dropdown/unit_dropdowns?activity=irrigation&dropdown=unit_of_motor_irrigation`
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchDepthOfPumpUnitData = () => {
    const headers = { token: this.state.authToken, "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchDepthOfPumpUnitDataApiCallId = requestMessage.messageId;
    const endpoint = `admin/bx_block_dropdown/unit_dropdowns?activity=irrigation&dropdown=unit_of_depth_of_pump_irrigation`
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchSpacingOfDrippersUnitData = () => {
    const headers = { token: this.state.authToken, "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchSpacingOfDrippersUnitDataApiCallId = requestMessage.messageId;
    const endpoint = `admin/bx_block_dropdown/unit_dropdowns?activity=irrigation&dropdown=unit_of_spacing_of_drippers_irrigation`
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchDripIrrigatedAreaUnitData = () => {
    const headers = { token: this.state.authToken, "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchDripIrrigatedAreaUnitDataApiCallId = requestMessage.messageId;
    const endpoint = `admin/bx_block_dropdown/unit_dropdowns?activity=irrigation&dropdown=unit_of_irrigated_area_irrigation`
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchRowToRowSpacingUnitData = () => {
    const headers = { token: this.state.authToken, "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchRowSpacingUnitDataApiCallId = requestMessage.messageId;
    const endpoint = `admin/bx_block_dropdown/unit_dropdowns?activity=irrigation&dropdown=unit_of_row_to_row_spacing_irrigation`
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchDripperDischargeUnitData = () => {
    const headers = { token: this.state.authToken, "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchDripDischargeUnitDataApiCallId = requestMessage.messageId;
    const endpoint = `admin/bx_block_dropdown/unit_dropdowns?activity=irrigation&dropdown=unit_of_dripper_discharge_irrigation`
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createIrrigationActivity = () => {
    this.setState({ loader: true })
    let formValues = [...this.state.initialIrrigationValues];
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user"
    };

    let formData = formValues.map((item) => {
      return {
        ...(item.id ? { id: item.id } : {}),
        source_irrigation_id: item.source_of_irrigation,
        type_irrigation_id: item.type_of_irrigation,
        date_of_irrigation: moment(item.date).format("YYYY-MM-DD"),

        hours_of_irrigation: item.hours_of_irrigation,
        labor_cost: item.labor_cost,
        horizontal_distance: item.horizontal_distance,
        motor_capacity_in_hp: item.motor_in_hp,
        depth_of_pump: item.depth_of_pump,
        unit_of_horizontal_distance_id: item.unit_horizontal_distance,
        unit_of_motor_capacity_in_hp_id: item.unit_motor_in_hp,
        unit_of_depth_of_pump_id: item.unit_depth_of_pump,

        irrigation_cost: item.irrigation_cost,
        number_of_nozels: item.number_of_nozzles,

        spacing_of_dripper: item.spacing_of_drippers,
        drip_irrigated_area: item.drip_irrigated_area,
        row_to_row_spacing: item.row_spacing,
        dripper_discharge: item.dripper_discharge,
        unit_of_spacing_of_dripper_id: item.unit_spacing_of_drippers,
        unit_of_drip_irrigated_area_id: item.unit_drip_irrigated_area,
        unit_of_row_to_row_spacing_id: item.unit_row_spacing,
        unit_of_dripper_discharge_id: item.unit_dripper_discharge,

        account_id: this.props.accountId,
        crop_ids: this.state.selectedCropIdArr,
        land_detail_id: this.state.selectedFarmId,
      };
    });

    let httpBody = { farm_dairy: { irrigation: formData } };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createIrrigationActivityApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/mobile_and_web/farm_dairy/activity_irrigations?user_id=${this.props.accountId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchLatestIrrigationRecord = (farmId: string | number, cropIds: number[]) => {
    const { selectedyear, accountId } = this.props;
    const headers = { token: this.state.authToken, type: "admin_user", "Content-Type": configJSON.validationApiContentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchLatestIrrigationRecordApiCallId = requestMessage.messageId;
    const endpoint = `mobile_and_web/farm_dairy/activity_irrigations/irrigation_history?land_detail_ids=${farmId}&crop_ids=${cropIds}&year=${selectedyear}&user_id=${accountId}&for_latest=true`
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchActivityDetails = () => {
    const farmId = this.props.selectedActivityFarmId
    const cropIds = this.props.selectedActivityCropId
    if (farmId && cropIds && this.props.accountId) {
      const headers = { token: this.state.authToken, type: "admin_user", "Content-Type": configJSON.validationApiContentType };
      const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.fetchActivityDetailsApiCallId = reqMessage.messageId;
      const endpoint = `mobile_and_web/farm_dairy/activity_irrigations/irrigation_history?land_detail_ids=${farmId}&crop_ids=${cropIds}&year=${this.props.selectedyear}&user_id=${this.props.accountId}&for_edit=${true}`
      reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
      reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
      runEngine.sendMessage(reqMessage.id, reqMessage);
    }
  }
  //API CALLS ENDS

  // HANDLE API CALLS STARTS
  handleFetchFarmNamesDataApiCallId = (responseJson: ISelectFarmDataResponse) => {
    if (responseJson && responseJson.data && responseJson.data.length) {
      this.setState({ farmNamesList: responseJson.data })
    } else {
      this.setState({ farmNamesList: [] })
    }
  }

  handleFetchCropNamesDataApiCallId = (responseJson: { data: ISelectCrop[] }) => {
    if (responseJson && responseJson.data && responseJson.data.length) {
      this.setState({ cropNamesList: responseJson.data },() => {
        if (this.props.isEditActivity) {
          this.fetchActivityDetails();
        }
      });
    } else {
      this.setState({ cropNamesList: [] });
    }
  };

  handleFetchSourceOfIrrigationApiResponse = (responseJson: IIrrigationType[]) => {
    if (responseJson) {
      this.setState({ irrigationSourceList: responseJson })
    } else {
      this.setState({ irrigationSourceList: [] })
    }
  }

  handleFetchIrrigationTypeResponse = (responseJson: IIrrigationType[]) => {
    if (responseJson && responseJson.length > 0) {
      this.setState({ irrigationTypeList: responseJson })
    } else {
      this.setState({ irrigationTypeList: [] })
    }
  }

  handleFetchDistanceUnitResponse = (response: IUnitDropdownResponse | null | undefined) => {
    let updatedFormValues = [...this.state.initialIrrigationValues];
    if (response && response?.data.length > 1) {
      updatedFormValues[0].unit_horizontal_distance = this.getDefaultValue(response.data, "Meter") ?? ""
      this.setState({ unitOfHorizontalDistanceList: response.data });
    } else if (response?.data.length === 1) {
      const unitId = response.data[0]?.id;
      updatedFormValues = updatedFormValues.map((item) => ({
        ...item,
        unit_horizontal_distance: unitId ?? null,
      }));
      this.setState({ unitOfHorizontalDistanceList: response.data, initialIrrigationValues: updatedFormValues });
    } else {
      this.setState({ unitOfHorizontalDistanceList: [] });
    }
  }

  handleFetchMotorInHPUnitResponse = (response: IUnitDropdownResponse | null | undefined) => {
    let updatedFormValues = [...this.state.initialIrrigationValues];
    if (response && response?.data.length > 1) {
      this.setState({ unitOfMotorList: response.data });
    } else if (response?.data.length === 1) {
      const unitId = response.data[0]?.id;
      updatedFormValues = updatedFormValues.map((item) => ({
        ...item,
        unit_motor_in_hp: unitId ?? null,
      }));
      this.setState({ unitOfMotorList: response.data, initialIrrigationValues: updatedFormValues });
    } else {
      this.setState({ unitOfMotorList: [] });
    }
  }

  handleFetchDepthOfPumpUnitResponse = (response: IUnitDropdownResponse | null | undefined) => {
    let updatedFormValues = [...this.state.initialIrrigationValues];
    if (response && response?.data.length > 1) {
      this.setState({ unitOfDepthPumpList: response.data });
    } else if (response?.data.length === 1) {
      const unitId = response.data[0]?.id;
      updatedFormValues = updatedFormValues.map((item) => ({
        ...item,
        unit_depth_of_pump: unitId ?? null,
      }));
      this.setState({ unitOfDepthPumpList: response.data, initialIrrigationValues: updatedFormValues });
    } else {
      this.setState({ unitOfDepthPumpList: [] });
    }
  }

  handleFetchSpacingOfDrippersUnitResponse = (response: IUnitDropdownResponse | null | undefined) => {
    let updatedFormValues = [...this.state.initialIrrigationValues];
    if (response && response?.data.length > 1) {
      this.setState({ unitOfSpacingDrippersList: response.data });
    } else if (response?.data.length === 1) {
      const unitId = response.data[0]?.id;
      updatedFormValues = updatedFormValues.map((item) => ({
        ...item,
        unit_spacing_of_drippers: unitId ?? null,
      }));
      this.setState({ unitOfSpacingDrippersList: response.data, initialIrrigationValues: updatedFormValues });
    } else {
      this.setState({ unitOfSpacingDrippersList: [] });
    }
  }

  handleFetchDripIrrigatedAreaUnitResponse = (response: IUnitDropdownResponse | null | undefined) => {
    let updatedFormValues = [...this.state.initialIrrigationValues];
    if (response && response?.data.length > 1) {
      this.setState({ unitOfDripAreaList: response.data });
    } else if (response?.data.length === 1) {
      const unitId = response.data[0]?.id;
      updatedFormValues = updatedFormValues.map((item) => ({
        ...item,
        unit_drip_irrigated_area: unitId ?? null,
      }));
      this.setState({ unitOfDripAreaList: response.data, initialIrrigationValues: updatedFormValues });
    } else {
      this.setState({ unitOfDripAreaList: [] });
    }
  }

  handleFetchRowSpacingUnitResponse = (response: IUnitDropdownResponse | null | undefined) => {
    let updatedFormValues = [...this.state.initialIrrigationValues];
    if (response && response?.data.length > 1) {
      this.setState({ unitOfRowSpacingList: response.data });
    } else if (response?.data.length === 1) {
      const unitId = response.data[0]?.id;
      updatedFormValues = updatedFormValues.map((item) => ({
        ...item,
        unit_row_spacing: unitId ?? null,
      }));
      this.setState({ unitOfRowSpacingList: response.data, initialIrrigationValues: updatedFormValues });
    } else {
      this.setState({ unitOfRowSpacingList: [] });
    }
  }

  handleFetchDripDischargeUnitResponse = (response: IUnitDropdownResponse | null | undefined) => {
    let updatedFormValues = [...this.state.initialIrrigationValues];
    if (response && response?.data.length > 1) {
      this.setState({ unitOfDripperDischargeList: response.data });
    } else if (response?.data.length === 1) {
      const unitId = response.data[0]?.id;
      updatedFormValues = updatedFormValues.map((item) => ({
        ...item,
        unit_dripper_discharge: unitId ?? null,
      }));
      this.setState({ unitOfDripperDischargeList: response.data, initialIrrigationValues: updatedFormValues });
    } else {
      this.setState({ unitOfDripperDischargeList: [] });
    }
  }

  handleCreateIrrigationApiResponse = (response: any) => {
    this.setState({ loader: false})
    if (response?.errors?.length || response.error) {
      this.setState({
        isErrorModalOpen: true,
        ErrorModalTitle: "Error",
        ErrorModalMessage: response.errors[0] || "Something went wrong!",
      })
    } else if (response[0].data) {
      this.setState({
        isErrorModalOpen: true,
        ErrorModalTitle: "Success",
        ErrorModalMessage: `Irrigation activity ${this.props.isEditActivity ? "updated" : "added"} successfully.`,
      })
    }
  }

  handleFetchLastIrrigationRecordResponse = (response: { data: IIrrigationResponse[] }) => {
    if (response?.data?.length > 0) {
      this.formateLatestData(response.data)
    } else {
      this.setState({
        initialIrrigationValues: [initialIrrigationValues], latestIrrigation: initialIrrigationValues
      })
    }
  }

  handleFetchActivityDetailsApiCallId = (response: { data: IIrrigationResponse[] }) => {
    if (response?.data?.length > 0) {
      this.formateEditData(response.data)
    }
    this.fetchLatestIrrigationRecord(this.props.selectedActivityFarmId ?? this.state.selectedFarmId , this.props.selectedActivityCropId )

  }
  // HANDLE API CALLS ENDS

  formateLatestData = (dataArray: IIrrigationResponse[]) => {
    let updateInitalValue = []
    updateInitalValue = dataArray.map((item: IIrrigationResponse) => {
      const irrigationType = item.attributes.type_irrigation_id ? item.attributes.type_irrigation_id[0].name.toLowerCase() : "";
      if (irrigationType === "flood" || irrigationType === "sprinklers" || irrigationType === "drip") {
        return {
          source_of_irrigation: item.attributes.source_irrigation_id ? item.attributes.source_irrigation_id[0].id.toString() : "",
          type_of_irrigation: item.attributes.type_irrigation_id ? item.attributes.type_irrigation_id[0].id.toString() : "",
          date: new Date(),
          hours_of_irrigation: item.attributes.hours_of_irrigation.toString(),
          labor_cost: item.attributes.labor_cost?.toString() ?? "",
          horizontal_distance: item.attributes.horizontal_distance.toString(),
          motor_in_hp: item.attributes.motor_capacity_in_hp.toString(),
          depth_of_pump: item.attributes.depth_of_pump.toString(),
          irrigation_cost: item.attributes.irrigation_cost?.toString() ?? "",
          number_of_nozzles: item.attributes.number_of_nozels?.toString() ?? "",
          spacing_of_drippers: item.attributes.spacing_of_dripper?.toString() ?? "",
          drip_irrigated_area: item.attributes.drip_irrigated_area?.toString() ?? "",
          row_spacing: item.attributes.row_to_row_spacing?.toString() ?? "",
          dripper_discharge: item.attributes.dripper_discharge?.toString() ?? "",
          unit_horizontal_distance: item.attributes.unit_of_horizontal_distance ? item.attributes.unit_of_horizontal_distance.id.toString() : "",
          unit_motor_in_hp: item.attributes.unit_of_motor_capacity_in_hp ? item.attributes.unit_of_motor_capacity_in_hp.id.toString() : "",
          unit_depth_of_pump: item.attributes.unit_of_depth_of_pump ? item.attributes.unit_of_depth_of_pump.id.toString() : "",
          unit_spacing_of_drippers: item.attributes.unit_of_spacing_of_dripper ? item.attributes.unit_of_spacing_of_dripper.id.toString() : "",
          unit_drip_irrigated_area: item.attributes.unit_of_drip_irrigated_area ? item.attributes.unit_of_drip_irrigated_area.id.toString() : "",
          unit_row_spacing: item.attributes.unit_of_row_to_row_spacing ? item.attributes.unit_of_row_to_row_spacing.id.toString() : "",
          unit_dripper_discharge: item.attributes.unit_of_dripper_discharge ? item.attributes.unit_of_dripper_discharge.id.toString() : "",
          errors: {
            source_of_irrigation: "",
            type_of_irrigation: "",
            date: "",
            hours_of_irrigation: "",
            labor_cost: "",
            horizontal_distance: "",
            motor_in_hp: "",
            depth_of_pump: "",
            irrigation_cost: "",
            number_of_nozzles: "",
            spacing_of_drippers: "",
            drip_irrigated_area: "",
            row_spacing: "",
            dripper_discharge: "",
            unit_horizontal_distance: "",
            unit_motor_in_hp: "",
            unit_depth_of_pump: "",
            unit_spacing_of_drippers: "",
            unit_drip_irrigated_area: "",
            unit_row_spacing: "",
            unit_dripper_discharge: "",
          }
        }
      } else {
        return initialIrrigationValues;
      }
    })
    if(!this.props.isEditActivity) {
      this.setState({
        initialIrrigationValues: updateInitalValue, latestIrrigation: updateInitalValue[0]
      })
    }
    this.setState({
      latestIrrigation: updateInitalValue[0]
    })
  }

  formateEditData = (dataArray: IIrrigationResponse[]) => {
    let updateInitalValue = []
    const filteredData = dataArray.filter((item) => item.attributes.freeze_record !== true);
    updateInitalValue = filteredData.map((item: IIrrigationResponse) => {
      const momentObject = moment(item.attributes.date_of_irrigation);
      const dateObject = momentObject.toDate();
      return {
        id: item.id,
        source_of_irrigation: item.attributes.source_irrigation_id ? item.attributes.source_irrigation_id[0].id.toString() : "",
        type_of_irrigation: item.attributes.type_irrigation_id ? item.attributes.type_irrigation_id[0].id.toString() : "",
        date: dateObject,
        hours_of_irrigation: item.attributes.hours_of_irrigation?.toString() ?? "",
        labor_cost: item.attributes.labor_cost?.toString() ?? "",
        horizontal_distance: item.attributes.horizontal_distance?.toString(),
        motor_in_hp: item.attributes.motor_capacity_in_hp?.toString(),
        depth_of_pump: item.attributes.depth_of_pump?.toString(),
        irrigation_cost: item.attributes.irrigation_cost?.toString() ?? "",
        number_of_nozzles: item.attributes.number_of_nozels?.toString() ?? "",
        spacing_of_drippers: item.attributes.spacing_of_dripper?.toString() ?? "",
        drip_irrigated_area: item.attributes.drip_irrigated_area?.toString() ?? "",
        row_spacing: item.attributes.row_to_row_spacing?.toString() ?? "",
        dripper_discharge: item.attributes.dripper_discharge?.toString() ?? "",
        unit_horizontal_distance: item.attributes.unit_of_horizontal_distance ? item.attributes.unit_of_horizontal_distance.id.toString() : "",
        unit_motor_in_hp: item.attributes.unit_of_motor_capacity_in_hp ? item.attributes.unit_of_motor_capacity_in_hp.id.toString() : "",
        unit_depth_of_pump: item.attributes.unit_of_depth_of_pump ? item.attributes.unit_of_depth_of_pump.id.toString() : "",
        unit_spacing_of_drippers: item.attributes.unit_of_spacing_of_dripper ? item.attributes.unit_of_spacing_of_dripper.id.toString() : "",
        unit_drip_irrigated_area: item.attributes.unit_of_drip_irrigated_area ? item.attributes.unit_of_drip_irrigated_area.id.toString() : "",
        unit_row_spacing: item.attributes.unit_of_row_to_row_spacing ? item.attributes.unit_of_row_to_row_spacing.id.toString() : "",
        unit_dripper_discharge: item.attributes.unit_of_dripper_discharge ? item.attributes.unit_of_dripper_discharge.id.toString() : "",
        errors: {
          source_of_irrigation: "",
          type_of_irrigation: "",
          date: this.validateMinDate(moment(dateObject).toDate(), this.props.selectedActivityCropId as number[]),
          hours_of_irrigation: "",
          labor_cost: "",
          horizontal_distance: "",
          motor_in_hp: "",
          depth_of_pump: "",
          irrigation_cost: "",
          number_of_nozzles: "",
          spacing_of_drippers: "",
          drip_irrigated_area: "",
          row_spacing: "",
          dripper_discharge: "",
          unit_horizontal_distance: "",
          unit_motor_in_hp: "",
          unit_depth_of_pump: "",
          unit_spacing_of_drippers: "",
          unit_drip_irrigated_area: "",
          unit_row_spacing: "",
          unit_dripper_discharge: "",
        }
      }
    })
    this.setState({
      selectedFarmId: this.props.selectedActivityFarmId ? this.props.selectedActivityFarmId : "",
      selectedCropIdArr: this.props.selectedActivityCropId?.length ?  this.props.selectedActivityCropId : [],
      initialIrrigationValues: updateInitalValue,
      minimumDate: getSowingDate(this.props.selectedActivityCropId as number[], this.state.cropNamesList) ?? moment().subtract(360, 'days').toDate(),
    })
  }

  resetErrorObject(data: IIntialValueIrrigationError) {
    Object.keys(data).forEach((objKey) => {
      if (objKey !== "date") {
        data[objKey as keyof IIntialValueIrrigationError] = "";
      }
    });
    return data;
  }

  getDefaultValue = (areaUnitList: IUnitDropdown[], valueName: string) => {
    let defaultValue = ""
    const acreObject = areaUnitList.find((each) => each.attributes.unit_name.toLowerCase() === valueName.toLowerCase())
    if (acreObject) {
      defaultValue = acreObject.id
    } else {
      defaultValue = areaUnitList[0]?.id
    }
    return defaultValue;
  }

  updateUnitBasedOnListLength = (unitList: IUnitDropdown[], unitIdKey: string, newInitialFormValues: IIntialValueIrrigation) => {
    if (unitList.length === 1) {
      const unitId = unitList[0]?.id;
      return {
        ...newInitialFormValues,
        [unitIdKey]: unitId ?? null,
        errors: {
          ...newInitialFormValues.errors,
        },
      };
    } else {
      return newInitialFormValues;
    }
  };

  handleInputChange = (
    event: { target: { name: string; value: number | string | Date } },
    index: number,
    isOptional?: boolean,
    isMinMax?: boolean,
    isIrrigationField?: boolean
  ) => {
    const { name, value } = event.target;
    const updatedFormValues = [...this.state.initialIrrigationValues];
    if (isIrrigationField) {
      let errors = this.resetErrorObject(updatedFormValues[index].errors)
      updatedFormValues[index] = {
        id: "", source_of_irrigation: updatedFormValues[index].source_of_irrigation, type_of_irrigation: value as string, date: new Date(), hours_of_irrigation: "", labor_cost: "", horizontal_distance: "", motor_in_hp: "", depth_of_pump: "", irrigation_cost: "", number_of_nozzles: "", spacing_of_drippers: "", drip_irrigated_area: "", row_spacing: "", dripper_discharge: "", unit_horizontal_distance: "", unit_motor_in_hp: "", unit_depth_of_pump: "", unit_spacing_of_drippers: "", unit_drip_irrigated_area: "", unit_row_spacing: "", unit_dripper_discharge: "",
        errors: {
          ...errors
        },
      };
      let newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfHorizontalDistanceList, "unit_horizontal_distance", updatedFormValues[index]);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfMotorList, "unit_motor_in_hp", newInitialFormValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfDepthPumpList, "unit_depth_of_pump", newInitialFormValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfSpacingDrippersList, "unit_spacing_of_drippers", newInitialFormValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfDripAreaList, "unit_drip_irrigated_area", newInitialFormValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfRowSpacingList, "unit_row_spacing", newInitialFormValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfDripperDischargeList, "unit_dripper_discharge", newInitialFormValues);

      if (this.state.unitOfHorizontalDistanceList.length === 1 &&
        this.state.unitOfMotorList.length === 1 &&
        this.state.unitOfDepthPumpList.length === 1 &&
        this.state.unitOfSpacingDrippersList.length === 1 &&
        this.state.unitOfDripAreaList.length === 1 &&
        this.state.unitOfRowSpacingList.length === 1 &&
        this.state.unitOfDripperDischargeList) {
        const unitIdHorizontal = this.state.unitOfHorizontalDistanceList[0]?.id;
        const unitIdMotor = this.state.unitOfMotorList[0]?.id;
        const unitIdDepth = this.state.unitOfDepthPumpList[0]?.id;
        const unitIdSpacing = this.state.unitOfSpacingDrippersList[0]?.id;
        const unitIdArea = this.state.unitOfDripAreaList[0]?.id;
        const unitIdRow = this.state.unitOfRowSpacingList[0]?.id;
        const unitIdDischarge = this.state.unitOfDripperDischargeList[0]?.id;

        newInitialFormValues = {
          ...initialIrrigationValues,
          unit_horizontal_distance: unitIdHorizontal ?? null,
          unit_motor_in_hp: unitIdMotor ?? null,
          unit_depth_of_pump: unitIdDepth ?? null,
          unit_spacing_of_drippers: unitIdSpacing ?? null,
          unit_drip_irrigated_area: unitIdArea ?? null,
          unit_row_spacing: unitIdRow ?? null,
          unit_dripper_discharge: unitIdDischarge ?? null,
          errors: {
            ...initialIrrigationValues.errors,
            [name]: value && ""
          }
        };
      }
      updatedFormValues[index] = {
        ...newInitialFormValues,
        [name]: value ?? null,
        errors: { ...newInitialFormValues.errors, [name]: value && "" }
      };
    }
    else {
      if (name in updatedFormValues[index]) {
        const { error, newError } = this.handleErrorText(value, name, index, isOptional, isMinMax);
        updatedFormValues[index] = {
          ...updatedFormValues[index],
          [name]: value ?? null,
          errors: {
            ...updatedFormValues[index].errors,
            [name]: error,
            ...(newError || {})
          }
        };
      }
    }
    this.setState({ initialIrrigationValues: updatedFormValues });
  };

  handleErrorText = (value: number | Date | string, fieldName: string, index: number, isOptional?: boolean, isMinMax?: boolean,) => {
    let error = "";
    let newError: { [key: string]: string } | null = null;
    error = this.handleMinMaxErrors(fieldName, value, isOptional, isMinMax)

    if (fieldName == "horizontal_distance") {
      let fuelValueErrors = this.handleQuantityOrUnit("horizontal_distance", "unit_horizontal_distance", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }
    if (fieldName == "unit_horizontal_distance") {
      let fuelValueErrors = this.handleQuantityOrUnit("unit_horizontal_distance", "horizontal_distance", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }

    if (fieldName == "motor_in_hp") {
      let fuelValueErrors = this.handleQuantityOrUnit("motor_in_hp", "unit_motor_in_hp", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }
    if (fieldName == "unit_motor_in_hp") {
      let fuelValueErrors = this.handleQuantityOrUnit("unit_motor_in_hp", "motor_in_hp", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }

    if (fieldName == "depth_of_pump") {
      let fuelValueErrors = this.handleQuantityOrUnit("depth_of_pump", "unit_depth_of_pump", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }
    if (fieldName == "unit_depth_of_pump") {
      let fuelValueErrors = this.handleQuantityOrUnit("unit_depth_of_pump", "depth_of_pump", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }

    if (fieldName == "spacing_of_drippers") {
      let fuelValueErrors = this.handleQuantityOrUnit("spacing_of_drippers", "unit_spacing_of_drippers", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }
    if (fieldName == "unit_spacing_of_drippers") {
      let fuelValueErrors = this.handleQuantityOrUnit("unit_spacing_of_drippers", "spacing_of_drippers", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }

    if (fieldName == "drip_irrigated_area") {
      let fuelValueErrors = this.handleQuantityOrUnit("drip_irrigated_area", "unit_drip_irrigated_area", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }
    if (fieldName == "unit_drip_irrigated_area") {
      let fuelValueErrors = this.handleQuantityOrUnit("unit_drip_irrigated_area", "drip_irrigated_area", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }

    if (fieldName == "row_spacing") {
      let fuelValueErrors = this.handleQuantityOrUnit("row_spacing", "unit_row_spacing", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }
    if (fieldName == "unit_row_spacing") {
      let fuelValueErrors = this.handleQuantityOrUnit("unit_row_spacing", "row_spacing", value as string, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }

    if (fieldName == "dripper_discharge") {
      let fuelValueErrors = this.handleQuantityOrUnit("dripper_discharge", "unit_dripper_discharge", value as string, index, true)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }
    if (fieldName == "unit_dripper_discharge") {
      let fuelValueErrors = this.handleQuantityOrUnit("unit_dripper_discharge", "dripper_discharge", value as string, index, true)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }

    if (fieldName == "date") {
      error = value ? this.validateMinDate(value as Date, this.state.selectedCropIdArr) : "Please enter Date";
    }
    return { error, newError };
  };

  handleQuantityOrUnit = (errorKey: keyof IIntialValueIrrigation, secondaryFieldKey: keyof IIntialValueIrrigation, value: string, index: number, isOptional?: boolean) => {
    const { initialIrrigationValues } = this.state;
    let error = value ? "" : `Please enter ${errorKey.replace(/_/g, " ").replace("unit", "")}`;
    let newError = {
      [secondaryFieldKey]: initialIrrigationValues[index][secondaryFieldKey] ? "" : `Please enter ${errorKey.replace(/_/g, " ").replace("unit", "")}`
    };

    if (!value && !initialIrrigationValues[index][secondaryFieldKey] && isOptional) {
      error = "";
      newError = {[secondaryFieldKey]: ""};
    }

    if (!value && !initialIrrigationValues[index][errorKey] && isOptional) {
      error = ""; newError = { [secondaryFieldKey]: "" }
    }
    if (!value && isOptional && initialIrrigationValues[index][errorKey] && this.state.unitOfDripperDischargeList.length === 1) {
      error = "";
    }

    return { error, newError };
  };

  handleMinMaxErrors = (fieldName: string, value: number | Date | string, isOptional?: boolean, isMinMax?: boolean) => {
    const fieldErrors = {
      hours_of_irrigation: { min: 0, max: 100, message: "Hours of Irrigation should not be more than 100" },
      irrigation_cost: { min: 0, max: 50000, message: "Irrigation cost should not be more than 10000" },
      labor_cost: { min: 50, max: 30000, message: "Labor cost should be between 50 and 30000" },
      number_of_nozzles: { min: 0, max: 120, message: "Number of nozzles should not be more than 120" },
    };

    const fieldConfig = fieldErrors[fieldName as keyof typeof fieldErrors];
    if (fieldConfig) {
      if (value) {
        return isMinMax ? "" : this.handleNestedCondition(Number(value), fieldConfig.min, fieldConfig.max, fieldConfig.message);
      } else {
        return isOptional ? "" : `Please enter ${fieldName.replace(/_/g, " ")}`;
      }
    } else {
      return "";
    }
  };

  handleNestedCondition = (value: number, minValue: number, maxValue: number, message: string) => {
    if (value < minValue || value > maxValue) {
      return message
    } else {
      return ""
    }
  }

  getLabelFromId = (valueId: string | number, unitList: IUnitDropdown[]) => {
    let label = ""
    const firstUnitId = unitList[0]?.id;
    label = !firstUnitId ? "" : unitList[0]?.attributes.name;

    return label
  }


  handleAddAnotherEntry = () => {
    if (!this.isFormComplete()) {
      this.setState({
        isErrorModalOpen: true,
        ErrorModalTitle: "Error",
        ErrorModalMessage: "Please fill the existing forms with valid data."
      })
    } else if (this.state.latestIrrigation) {
      let cloneFormValues = [...this.state.initialIrrigationValues];
      this.resetErrorObject(initialIrrigationValues.errors)
      cloneFormValues.push(this.state.latestIrrigation);
      this.setState({ initialIrrigationValues: cloneFormValues });
    } 
    else {
      let cloneFormValues = [...this.state.initialIrrigationValues];
      this.resetErrorObject(initialIrrigationValues.errors)

      let newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfHorizontalDistanceList, "unit_horizontal_distance", initialIrrigationValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfMotorList, "unit_motor_in_hp", newInitialFormValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfDepthPumpList, "unit_depth_of_pump", newInitialFormValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfSpacingDrippersList, "unit_spacing_of_drippers", newInitialFormValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfDripAreaList, "unit_drip_irrigated_area", newInitialFormValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfRowSpacingList, "unit_row_spacing", newInitialFormValues);
      newInitialFormValues = this.updateUnitBasedOnListLength(this.state.unitOfDripperDischargeList, "unit_dripper_discharge", newInitialFormValues);

      if (this.state.unitOfHorizontalDistanceList.length === 1 &&
        this.state.unitOfMotorList.length === 1 &&
        this.state.unitOfDepthPumpList.length === 1 &&
        this.state.unitOfSpacingDrippersList.length === 1 &&
        this.state.unitOfDripAreaList.length === 1 &&
        this.state.unitOfRowSpacingList.length === 1 &&
        this.state.unitOfDripperDischargeList) {
        const unitIdHorizontal = this.state.unitOfHorizontalDistanceList[0]?.id;
        const unitIdMotor = this.state.unitOfMotorList[0]?.id;
        const unitIdDepth = this.state.unitOfDepthPumpList[0]?.id;
        const unitIdSpacing = this.state.unitOfSpacingDrippersList[0]?.id;
        const unitIdArea = this.state.unitOfDripAreaList[0]?.id;
        const unitIdRow = this.state.unitOfRowSpacingList[0]?.id;
        const unitIdDischarge = this.state.unitOfDripperDischargeList[0]?.id;

        newInitialFormValues = {
          ...initialIrrigationValues,
          unit_horizontal_distance: unitIdHorizontal ?? null,
          unit_motor_in_hp: unitIdMotor ?? null,
          unit_depth_of_pump: unitIdDepth ?? null,
          unit_spacing_of_drippers: unitIdSpacing ?? null,
          unit_drip_irrigated_area: unitIdArea ?? null,
          unit_row_spacing: unitIdRow ?? null,
          unit_dripper_discharge: unitIdDischarge ?? null,
          errors: {
            ...initialIrrigationValues.errors,
          }
        };
      }

      cloneFormValues.push(newInitialFormValues);
      this.setState({ initialIrrigationValues: cloneFormValues });
    }
  };

  checkValidationFlood = (entry: IIntialValueIrrigation) => {
    entry.errors.source_of_irrigation = !entry.source_of_irrigation ? "Please select source of Irrigation" : "";
    entry.errors.type_of_irrigation = !entry.type_of_irrigation ? "Please select type of Irrigation" : "";
    entry.errors.date = this.validateMinDate(entry.date, this.state.selectedCropIdArr as number[]) ? "Please enter date" : "";
    entry.errors.hours_of_irrigation =  this.handleCostOnSubmit("hours_of_irrigation", entry.hours_of_irrigation)
    entry.errors.labor_cost = this.handleCostOnSubmit("labor_cost",entry.labor_cost) 
    entry.errors.horizontal_distance = !(entry.horizontal_distance && entry.unit_horizontal_distance) ? "Please enter horizontal distance" : ""
    entry.errors.motor_in_hp = !(entry.motor_in_hp && entry.unit_motor_in_hp) ? "Please enter motor in hp" : ""
    entry.errors.depth_of_pump = !(entry.depth_of_pump && entry.unit_depth_of_pump) ? "Please enter depth of pump" : ""

    return !entry.errors.depth_of_pump && !entry.errors.motor_in_hp && !entry.errors.horizontal_distance && !entry.errors.labor_cost && !entry.errors.hours_of_irrigation && !entry.errors.date && !entry.errors.type_of_irrigation && !entry.errors.source_of_irrigation;
  }

  checkValidationSprinkler = (entry: IIntialValueIrrigation) => {
    entry.errors.source_of_irrigation = !entry.source_of_irrigation ? "Please select source of Irrigation" : "";
    entry.errors.type_of_irrigation = !entry.type_of_irrigation ? "Please select type of Irrigation" : "";
    entry.errors.date = this.validateMinDate(entry.date, this.state.selectedCropIdArr as number[]) ? "Please enter date" : "";
    entry.errors.hours_of_irrigation = this.handleCostOnSubmit("hours_of_irrigation", entry.hours_of_irrigation)
    entry.errors.labor_cost = this.handleCostOnSubmit("labor_cost",entry.labor_cost) 
    entry.errors.irrigation_cost = this.handleCostOnSubmit("irrigation_cost",entry.irrigation_cost)
    entry.errors.number_of_nozzles =  this.handleCostOnSubmit("number_of_nozzles", entry.number_of_nozzles)
    entry.errors.horizontal_distance = !(entry.horizontal_distance && entry.unit_horizontal_distance) ? "Please enter horizontal distance" : ""
    entry.errors.motor_in_hp = !(entry.motor_in_hp && entry.unit_motor_in_hp) ? "Please enter motor in hp" : ""
    entry.errors.depth_of_pump = !(entry.depth_of_pump && entry.unit_depth_of_pump) ? "Please enter depth of pump" : ""

    return !entry.errors.depth_of_pump && !entry.errors.motor_in_hp && !entry.errors.horizontal_distance && !entry.errors.number_of_nozzles && !entry.errors.irrigation_cost && !entry.errors.labor_cost && !entry.errors.hours_of_irrigation && !entry.errors.date && !entry.errors.type_of_irrigation && !entry.errors.source_of_irrigation;
  }

  checkValidationDrip = (entry: IIntialValueIrrigation) => {
    entry.errors.source_of_irrigation = !entry.source_of_irrigation ? "Please select source of Irrigation" : "";
    entry.errors.type_of_irrigation = !entry.type_of_irrigation ? "Please select type of Irrigation" : "";
    entry.errors.date = this.validateMinDate(entry.date, this.state.selectedCropIdArr as number[]) ? "Please enter date" : "";
    entry.errors.hours_of_irrigation =  this.handleCostOnSubmit("hours_of_irrigation", entry.hours_of_irrigation)
    entry.errors.labor_cost = this.handleCostOnSubmit("labor_cost",entry.labor_cost) 
    entry.errors.irrigation_cost = this.handleCostOnSubmit("irrigation_cost",entry.irrigation_cost)
    entry.errors.spacing_of_drippers = !(entry.spacing_of_drippers && entry.unit_spacing_of_drippers) ? "Please enter spacing of drippers" : ""
    entry.errors.drip_irrigated_area = !(entry.drip_irrigated_area && entry.unit_drip_irrigated_area) ? "Please enter drip Irrigated area" : ""
    entry.errors.row_spacing = !(entry.row_spacing && entry.unit_row_spacing) ? "Please enter row spacing" : ""
    if (entry.dripper_discharge) {
      entry.errors.dripper_discharge = !entry.unit_dripper_discharge ? "Please enter dripper discharge" : ""
    }
    entry.errors.horizontal_distance = !(entry.horizontal_distance && entry.unit_horizontal_distance) ? "Please enter horizontal distance" : ""
    entry.errors.motor_in_hp = !(entry.motor_in_hp && entry.unit_motor_in_hp) ? "Please enter motor in hp" : ""
    entry.errors.depth_of_pump = !(entry.depth_of_pump && entry.unit_depth_of_pump) ? "Please enter depth of pump" : ""

    return !entry.errors.depth_of_pump && !entry.errors.motor_in_hp && !entry.errors.horizontal_distance && !entry.errors.dripper_discharge && !entry.errors.row_spacing && !entry.errors.drip_irrigated_area && !entry.errors.spacing_of_drippers && !entry.errors.irrigation_cost && !entry.errors.labor_cost && !entry.errors.hours_of_irrigation && !entry.errors.date && !entry.errors.type_of_irrigation && !entry.errors.source_of_irrigation;
  }

  checkDefaultForm = (entry: IIntialValueIrrigation) => {
    entry.errors.source_of_irrigation = !entry.source_of_irrigation ? "Please select source of Irrigation" : "";
    entry.errors.type_of_irrigation = !entry.type_of_irrigation ? "Please select type of Irrigation" : "";
    entry.errors.date = this.validateMinDate(entry.date, this.state.selectedCropIdArr as number[]) ? "Please enter date" : "";
    entry.errors.date = !entry.date ? "Please enter date" : "";
    entry.errors.hours_of_irrigation = this.handleCostOnSubmit("hours_of_irrigation", entry.hours_of_irrigation)
    entry.errors.labor_cost = this.handleCostOnSubmit("labor_cost",entry.labor_cost) 
    entry.errors.horizontal_distance = !(entry.horizontal_distance && entry.unit_horizontal_distance) ? "Please enter horizontal distance" : ""
    entry.errors.motor_in_hp = !(entry.motor_in_hp && entry.unit_motor_in_hp) ? "Please enter motor in hp" : ""
    entry.errors.depth_of_pump = !(entry.depth_of_pump && entry.unit_depth_of_pump) ? "Please enter depth of pump" : ""

    return !entry.errors.depth_of_pump && !entry.errors.motor_in_hp && !entry.errors.horizontal_distance && !entry.errors.labor_cost && !entry.errors.hours_of_irrigation && !entry.errors.source_of_irrigation && !entry.errors.type_of_irrigation && !entry.errors.date;
  }

  handleCostOnSubmit = (fieldName: string, fieldValue: string) => {
    if (!fieldValue) {
      return `Please enter ${fieldName.replace(/_/g, " ")}`;
    }
    return this.handleMinMaxErrors(fieldName, fieldValue);
  };

  isDataComplete = () => {
    const { initialIrrigationValues, irrigationTypeList } = this.state;
    let valid = true;
    const updatedFormValues = [...initialIrrigationValues];
    const floodId: number | undefined = irrigationTypeList.find((objItem: IIrrigationType) => objItem.label.toLowerCase().includes("flood"))?.id;
    const sprinklerId: number | undefined = irrigationTypeList.find((objItem: IIrrigationType) => objItem.label.toLowerCase().includes("sprinkler"))?.id;
    const dripId: number | undefined = irrigationTypeList.find((objItem: IIrrigationType) => objItem.label.toLowerCase().includes("drip"))?.id;
    for (const entry of updatedFormValues) {
      switch (entry.type_of_irrigation.toString()) {
        case (floodId?.toString()):
          valid = valid && this.checkValidationFlood(entry);
          break;
        case (sprinklerId?.toString()):
          valid = valid && this.checkValidationSprinkler(entry)
          break;
        case (dripId?.toString()):
          valid = valid && this.checkValidationDrip(entry)
          break;
        default:
          valid = valid && this.checkDefaultForm(entry)
          break;
      }
    }
    if (!valid) {
      this.setState({ initialIrrigationValues: updatedFormValues });
    }
    return valid;
  }

  handleRemoveActivity = (index: number) => {
    let newinitialIrrigationValues = this.state.initialIrrigationValues;
    newinitialIrrigationValues.splice(index, 1);
    this.setState({ initialIrrigationValues: newinitialIrrigationValues });
  };

  resetInitialFormValues = () => {
    this.setState({
      selectedFarmId: "",
      selectedFarmIdError: "",
      selectedCropIdArr: [],
      selectedCropIdError: "",
      initialIrrigationValues: [
        initialIrrigationValues,
      ]
    })
  }

  handleCloseIrrigationActivityModal = () => {
    this.props.handleCloseAddActivityModal()
    this.resetInitialFormValues()
  }

  handleCloseErrorModal = () => {
    this.setState({
      isErrorModalOpen: false,
      ErrorModalTitle: "",
      ErrorModalMessage: "",

    })
    if (this.state.ErrorModalTitle.toLocaleLowerCase() == "success") {
      this.handleCloseIrrigationActivityModal()
    }
  }

  isFormComplete = () => {
    let valid = true;
    if (!this.state.selectedFarmId) {
      valid = false;
      this.setState((prev) => ({
        ...prev,
        selectedFarmIdError: "Please select Farm",
      }));
    }
    if (!this.state.selectedCropIdArr.length) {
      valid = false;
      this.setState((prev) => ({
        ...prev,
        selectedCropIdError: "Please select Crop",
      }));
    }
    if (!this.isDataComplete()) {
      valid = false;
    }
    return valid;
  }
  onSubmitIrrigationForm = () => {
    if (!this.isFormComplete()) {
      this.setState({
        isErrorModalOpen: true,
        ErrorModalTitle: "Error",
        ErrorModalMessage: "Please fill the existing forms with valid data.",
      });
      return;
    }
    this.createIrrigationActivity()
  }

  handleOpenRainFedModel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
    this.props.handleCloseAddActivityModal()
    this.props.handleEditFarm(event as React.MouseEvent<HTMLButtonElement, MouseEvent>, this.state.selectedFarmId)
  }

  validateMinDate = (selectedDate: Date, cropValue?: number[]) => {
    const minimumDate = (cropValue && cropValue.length > 0 && getSowingDate(cropValue, this.state.cropNamesList)) || moment().subtract(361, 'days').toDate();
    return (selectedDate >= minimumDate && selectedDate <= new Date()) ? "" : "Please enter valid date";
  };


  handleFarmChange = (value: string) => {
    const updatedFormValues = this.state.initialIrrigationValues.map((item) => ({
      ...item,
      errors: { ...item.errors, date: this.validateMinDate(item.date, []) },
    }));
    const landTypeName = this.state.farmNamesList.find((item) => item.attributes?.id == value)?.attributes?.land_type?.[0]?.name;


    this.setState({
      selectedFarmId: value as string,
      selectedCropIdArr: [],
      selectedFarmIdError: "",
      minimumDate: moment().subtract(360, 'days').toDate(),
      rainFedFarm: landTypeName === "Rainfed",
      initialIrrigationValues: updatedFormValues,
    }, () => {
      this.fetchCropsData(this.props.accountId);
    });
  };

  handleChangeSelectCrop = (value: string[] | number[] | unknown) => {
    const minimumDate = getSowingDate(value as number[], this.state.cropNamesList) || moment().subtract(360, 'days').toDate();
    this.setState({  selectedCropIdArr: value as number[],
      selectedCropIdError: "", minimumDate })
    const updatedFormValues = [...this.state.initialIrrigationValues];
    this.state.initialIrrigationValues.map((item, index) => {
      updatedFormValues[index] = {
        ...updatedFormValues[index],
        errors: {
          ...updatedFormValues[index].errors,
          date: this.validateMinDate(item.date, value as number[]),
        }
      };
    });
    this.fetchLatestIrrigationRecord(this.state.selectedFarmId, value as number[])
    this.setState({ initialIrrigationValues: updatedFormValues });
  }

  handleAddCrop = () => {
    this.props.handleCloseAddActivityModal();
    this.props.handleAddCropModalCallback();
  };
  handleAddFarm = ()  => {
    this.props.handleCloseAddActivityModal();
    this.props.handleOpenFarmModalCallback();
  };
  // Customizable Area End
}
