import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getSingleCropSowingDate } from "../../../components/src/helper";
import {
  ActivityDataTypes,
  ICropDetails,
  ICropName,
  IDropdownData,
  UserFarm,
} from "../../../components/src/interface.web";
import moment from "moment";
export interface IntialGapFillingValuesTypes {
  id?:string
  date: Date;
  labor_cost: string;
  price_of_seed: string;
  errors: IntialGapFillingValuesTypesError;
}
interface IntialGapFillingValuesTypesError {
  date: string;
  labor_cost: string;
  price_of_seed: string;
  [key: string]: string;
}

interface GapFillingReqBodyData {
  date: string;
  labor_cost: string;
  price_of_seed: string;
  crop_id: unknown;
  account_id: string | number;
  land_detail_id: unknown;
}

interface InvalidRecords {
  errors: string[];
  error:string;
  message?: string;
}

interface GapFillingReqBody {
  gap_filling: GapFillingReqBodyData[];
}

const intialGapFillingValues = {
  date: new Date(),
  labor_cost: "",
  price_of_seed: "",
  errors: {
    date: "",
    labor_cost: "",
    price_of_seed: "",
  },
};

type FarmIdTypes = string | undefined;
type CropIdTypes = string | string[] | undefined;

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose: () => void;
  isGapFillingActivity: boolean;
  farmList: UserFarm[];
  cropList: ICropName[];
  accountId: string | number;
  handleAddCropModalCallback: () => void;
  handleOpenFarmModalCallback:() => void;
  isEditActivity: boolean;
  selectedActivityFarmId: FarmIdTypes;
  selectedActivityCropId: CropIdTypes;
  selectedyear: number;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  minimumDate: Date;
  // Customizable Area Start
  formLandDetailId: string | unknown;
  formCropId: string | unknown;
  formLandDetailIdError: string;
  formCropIdError: string;
  gapFillingFormValues: IntialGapFillingValuesTypes[];
  customMessage: string;
  customMessageTitle: string;
  authToken: string | null;
  cropNameList: ICropDetails[];
  createGapFillingLoading: boolean;
  farmNamesList: UserFarm[];
  getActivityDetailsLoading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GapFillingActivityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchCropNameDataApiCallId: string = "";
  createGapFillingApiCallId: string = "";
  fetchFarmListDataApiCallId: string = "";
  fetchFarmAndCropActivitiesApiCallID: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      minimumDate: moment().subtract(360, 'days').toDate(),
      // Customizable Area Start
      formLandDetailId: "",
      formLandDetailIdError: "",
      formCropId: "",
      formCropIdError: "",
      gapFillingFormValues: [
        JSON.parse(JSON.stringify(intialGapFillingValues)),
      ],
      customMessage: "",
      customMessageTitle: "",
      authToken: localStorage.getItem("tokenn"),
      cropNameList: [],
      createGapFillingLoading: false,
      farmNamesList: [],
      getActivityDetailsLoading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.fetchCropNameDataApiCallId:
            this.handleFetchCropListDataApiCallId(responseJson);
            break;
          case this.createGapFillingApiCallId:
            this.handleCreateGapFilling(responseJson);
            break;
          case this.fetchFarmListDataApiCallId:
            this.handleFetchFarmListDataApiCallId(responseJson);
            break;
          case this.fetchFarmAndCropActivitiesApiCallID:
            this.fetchFarmAndCropActivitiesResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (this.props.isGapFillingActivity !== prevProps.isGapFillingActivity && this.props.isGapFillingActivity && this.props.accountId){
      this.fetchFarmListData(this.props.accountId);
    }
    if (prevState.formLandDetailId !== this.state.formLandDetailId && !this.props.isEditActivity) {
      this.fetchCropNameData(this.state.formLandDetailId as string);
    }

    if (
      this.props.isEditActivity !== prevProps.isEditActivity &&
      this.props.isEditActivity
    ) {
      this.fetchCropNameData(this.props.selectedActivityFarmId as string)
    }
  }

  handleFetchCropListDataApiCallId = (response: { data: ICropDetails[] }) => {
    if (response && response.data) {
      this.setState({
        cropNameList: response.data,
      },() => {
        if(this.props.isEditActivity){
          this.fetchFarmAndCropActivities(
            this.props.selectedActivityFarmId,
            this.props.selectedActivityCropId
          );
        }
      });
    } else {
      this.setState({ cropNameList: [] });
    }
  };

  fetchFarmAndCropActivitiesResponse = (response: {data:ActivityDataTypes[]}) => {
    if (this.props.isEditActivity && this.state.cropNameList) {
      const activitiesData = response.data;
      const { land_detail_id, crop_id } = activitiesData[0]?.attributes;
      const filteredData = activitiesData.filter((item) => item.attributes.freeze_record !== true);
      const farmsData = filteredData.map((activity: ActivityDataTypes) => {
        const momentObject = moment(activity.attributes.date);
        const dateObject = momentObject.toDate();
        
        const {date,labor_cost,price_of_seed}=activity.attributes
        return {
          id:activity?.id,
          date: date,
          labor_cost: labor_cost,
          price_of_seed: price_of_seed,
          errors: {
            date: this.validateMinDate(moment(dateObject).toDate(), this.props.selectedActivityCropId as string),
            labor_cost: "",
            price_of_seed: "",
          },
        };
      });
      this.setState({formLandDetailId:land_detail_id[0]?.id,
        formCropId:crop_id[0]?.id,gapFillingFormValues:farmsData,
        getActivityDetailsLoading:false,
        minimumDate: getSingleCropSowingDate(this.props.selectedActivityCropId as string, this.state.cropNameList)
        ?? moment().subtract(360, 'days').toDate(),
      })
    }
  };

  handleCreateGapFilling = (response: {
    data: GapFillingReqBodyData;
    error?: string;
    errors?: string;
    message?: string;
    invalid_records?: InvalidRecords[];
  }) => {
    if (response && response?.data) {
      this.setState({
        createGapFillingLoading: false,
        gapFillingFormValues: [intialGapFillingValues],
        formCropId: "",
        formLandDetailId: "",
        customMessage: `Gap filling activity ${this.props.isEditActivity ? "updated" : "created"} successfully`,
        customMessageTitle: "Success",
        minimumDate: moment().subtract(360, 'days').toDate(),
      });
      this.props.handleClose()
      return;
    }
    let error =
      response?.error ||
      response?.errors ||
      response?.message ||
      response?.invalid_records?.[0]?.errors || response?.invalid_records?.[0]?.error;
    if (error) {
      this.handleRenderError(error);
      this.setState({ createGapFillingLoading: false });
    }
  };

  handleAddAnotherEntryGapFilling = () => {
    if (!this.isFormComplete()) {
      this.setState({
        customMessageTitle: "Error",
        customMessage: "Please complete the existing form first.",
      });
      return;
    }
    let newGapFillingValues = [...this.state.gapFillingFormValues];
    this.resetErrorObject(intialGapFillingValues.errors)
    newGapFillingValues.push(intialGapFillingValues);
    this.setState({ gapFillingFormValues: newGapFillingValues });
  };

  handleRemoveGapFilling = (index: number) => {
    let newGapFillingValues = this.state.gapFillingFormValues;
    newGapFillingValues.splice(index, 1);
    this.setState({ gapFillingFormValues: newGapFillingValues });
  };


  resetErrorObject(data: IntialGapFillingValuesTypesError ) { 
    Object.keys(data).forEach((key) => {
      if (key !== "date") {
        data[key as keyof IntialGapFillingValuesTypesError] = "";
      }
    });
    return data;
  }

  checkValidation = (entry: IntialGapFillingValuesTypes) => {
    entry.errors.labor_cost = !entry.labor_cost ? "Please enter labor cost": "";
    entry.errors.price_of_seed = !entry.price_of_seed ? "Please enter seed cost" : "";
    entry.errors.date = this.validateMinDate(entry.date, this.state.formCropId as string) ? "Please enter date" : "";
    return !entry.errors.labor_cost && !entry.errors.price_of_seed && !entry.errors.date;
  }


  isDataComplete = () => {
    const updatedFormValues = [...this.state.gapFillingFormValues];
    let valid = true;
    for (const entry of updatedFormValues) {
      valid = valid && this.checkValidation(entry);
    }
    if (!valid) {
      this.setState({ gapFillingFormValues: updatedFormValues });
    }
    return valid;
  };

  handleFarmSelect = (value: string) => {
    const updatedFormValues = this.state.gapFillingFormValues.map((item) => ({
      ...item,
      errors: { ...item.errors, date: this.validateMinDate(item.date, "") },
    }));

    this.setState({
      formLandDetailId: value as string,
      formCropId: "",
      formLandDetailIdError: "",
      minimumDate: moment().subtract(360, 'days').toDate(),
      gapFillingFormValues: updatedFormValues,
    }, () => {
      this.fetchCropNameData(value);
    });
  };

  handleCropSelection = (value: string) => {
    const minimumDate = getSingleCropSowingDate(value, this.state.cropNameList) || moment().subtract(360, 'days').toDate();
    this.setState({ formCropId: value, formCropIdError: "", minimumDate })
    const updatedFormValues = [...this.state.gapFillingFormValues];
    this.state.gapFillingFormValues.map((item, index) => {
      updatedFormValues[index] = {
        ...updatedFormValues[index],
        errors: {
          ...updatedFormValues[index].errors,
          date: this.validateMinDate(item.date, value),
        }
      };
    });
    this.setState({ gapFillingFormValues: updatedFormValues });
  }

  validateMinDate = (selectedDate: Date, cropValue?: string) => {
    const minimumDate = (cropValue && getSingleCropSowingDate(cropValue, this.state.cropNameList)) || moment().subtract(361, 'days').toDate();
    return (moment(selectedDate).toDate() >= minimumDate && moment(selectedDate).toDate() <= new Date()) ? "" : "Please enter valid date";
  };

  handleErrorText = (fieldName: string, value: number | Date | string) => {
    let error = "";
    if (fieldName == "labor_cost") {
      error = value ? "": "Please enter labor cost";
    }
    if (fieldName == "price_of_seed") {
      error = value ? "" :"Please enter seed cost";
    }
    if (fieldName == "date") {
      error = value ? this.validateMinDate(value as Date, this.state.formCropId as string) : "Please enter Date";
    }
    return error;
  };

  handleInputChange = (
    e: { target: { name: string; value: number | string | Date } },
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedFormValues = [...this.state.gapFillingFormValues];

    // Ensure that name is a valid property
    if (name in updatedFormValues[index]) {
      updatedFormValues[index] = {
        ...updatedFormValues[index],
        [name]: value ?? "",
        errors: {
          ...updatedFormValues[index].errors,
          [name]:  this.handleErrorText(name, value),
        },
      };
    }

    this.setState({ gapFillingFormValues: updatedFormValues });
  };

  handleCloseCustomModal = () => {
    if (this.state.customMessageTitle.toLocaleLowerCase() == "success") {
      this.props.handleClose();
    }
    this.setState({ customMessage: "", customMessageTitle: "" });
  };

  isFormComplete = () => {
    let valid = true;
    if (!this.state.formLandDetailId) {
      valid = false;
      this.setState((prev) => ({
        ...prev,
        formLandDetailIdError: "Please select Farm",
      }));
    }
    if (!this.state.formCropId) {
      valid = false;
      this.setState((prev) => ({
        ...prev,
        formCropIdError: "Please select Crop",
      }));
    }
    if (!this.isDataComplete()) {
      valid = false;
    }
    return valid;
  };

  handleFormSubmit = () => {
    if (!this.isFormComplete()) {
      this.setState({
        customMessageTitle: "Error",
        customMessage:
          "Please fill the existing forms with valid data.",
      });
      return;
    }
    this.createGapFilling();
  };

  createGapFilling = () => {
    let formValues = [...this.state.gapFillingFormValues];
    let gapFillingData = formValues.map((item) => {
      return {
        ...item,
        date: moment(item.date).format("DD/MM/YYYY"),
        crop_id: this.state.formCropId,
        account_id: this.props.accountId,
        land_detail_id: this.state.formLandDetailId,
      };
    });
    let body = {
      gap_filling: gapFillingData,
    };
    this.createGapFillingApi(body);

  };

  handleRenderError = (error: string[] | string) => {
    if (typeof error === "string") {
      this.setState({ customMessageTitle: "Error", customMessage: error });
      return;
    }
    if (Array.isArray(error) && typeof error[0] === "string") {
      this.setState({ customMessageTitle: "Error", customMessage: error[0] });
      return;
    }
    if (Array.isArray(error) && typeof error[0] === "object") {
      this.setState({
        customMessageTitle: "Error",
        customMessage: error[0][Object.keys(error[0])[0]],
      });
    }
  };

  handleAddCrop = () => {
    this.props.handleClose();
    this.props.handleAddCropModalCallback();
  };

  handleAddFarm = () => {
    this.props.handleClose();
    this.props.handleOpenFarmModalCallback();
  };

  handleFetchFarmListDataApiCallId = (response: { data: UserFarm[] }) => {
    if (response && response.data) {
      this.setState({ farmNamesList: response.data });
    } else {
      this.setState({ farmNamesList: [] });
    }
  };

  handleCloseModal=()=>{
    this.setState({
      gapFillingFormValues: [intialGapFillingValues],
      formCropId: "",
      formLandDetailId: "",
      formCropIdError: "",
      formLandDetailIdError: "",
      minimumDate: moment().subtract(360, 'days').toDate(),
    })
    this.props.handleClose()
  }

  fetchFarmListData = (accountID: string | number) => {
    if (!accountID) return;
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user",
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFarmListDataApiCallId = reqMessage.messageId;
    const endpoint = `${configJSON.fetchFarmListDataAPiEndPoint}?user_id=${accountID}`;
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  fetchCropNameData = (farmId: string | number) => {
    if (!this.props.accountId || !farmId ) return;
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user",
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchCropNameDataApiCallId = reqMessage.messageId;
    const endpoint = `${configJSON.fetchCropListDataApiEndPoint}?farm_id=${farmId}&user_id=${this.props.accountId}`;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  // FETCH ACTIVITIE ASSOCIATED WITH FARM AND CROP

  fetchFarmAndCropActivities = (farmId: FarmIdTypes, cropId: CropIdTypes) => {
    if (!farmId && !cropId) return;
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFarmAndCropActivitiesApiCallID = reqMessage.messageId;
    const endpoint = `${configJSON.gapFillingApiEndPonit}?account_id=${this.props.accountId}&year=${this.props.selectedyear}&land_detail_ids=${this.props.selectedActivityFarmId}&crop_ids=${this.props.selectedActivityCropId}`;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  // CREATE AND UPDATE GAP FILLING API
  createGapFillingApi = (body: GapFillingReqBody) => {
    this.setState({ createGapFillingLoading: true });
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createGapFillingApiCallId = reqMessage.messageId;

    // ENDPOINT
    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gapFillingActivityEndPoint
    );
    // HEADER
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    // BODY
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    // METHOD
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST_METHOD_TYPE
    );
    // RUN
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };
  // Customizable Area End
}
