import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Input,
    InputAdornment,
    Paper,
    Tabs,
    Tab,
    IconButton,
    TextField,
    FormControl,
    FormLabel,
    Badge,
    Card,
    CardMedia,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid
} from "@material-ui/core";
import CropsDropdownController, { ILanguageObject, Props, configJSON } from "./CropsDropdownController.web";
import SearchIcon from "@material-ui/icons/Search";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AddSVG, CheckedRadioSVG, RemoveSVG, UnCheckedRadioSVG } from "../assets";
import { DropdownKey, formateDataSimple, formateUnitData, keyDown, returnImgUrl } from "../../../../components/src/helper";
import { Edit, AddAPhoto, KeyboardArrowDown } from "@material-ui/icons";
import { DataNotFound, LoadingButton, NoMenuDataFound, renderErrorMessage } from "../../../../components/src/AdminConsoleHelper";
import { LoaderCenter } from "../../../../components/src/AdminConsoleHelper";
import { IHeadings } from "../../../../components/src/interface.web";
import SimpleDropdown from "../Registration/SimpleDropdown.web";
import UnitComponent from "../Units/UnitComponent.web";
import ClearIcon from '@material-ui/icons/Clear';
import ErrorModal from "../../../../components/src/ErrorModal.web";

// Customizable Area End

export default class CropsDropdown extends CropsDropdownController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeader = () => {
        const { subDropdownName } = DropdownKey()
        return (
            <HeaderBox>
                <Typography style={style.headerTitle}>{subDropdownName}</Typography>
                <SearchBoxWrapper>
                    <Input autoComplete="off" fullWidth
                        disableUnderline
                        className="custom_search_input"
                        data-testid="search_dropdown_testid"
                        type="text"
                        placeholder="Search here..."
                        name="search_dropdown"
                        value={this.state.searchValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleSearch(event.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon style={{ color: "#1C2324", opacity: 0.48, width: 20, height: 20 }}
                                />
                            </InputAdornment>
                        }
                        endAdornment={
                            this.state.searchValue && (
                                <InputAdornment position="end">
                                    <IconButton style={{ padding: 4 }} onClick={() => { this.handleSearch("") }}> <ClearIcon /> </IconButton>
                                </InputAdornment>
                            )
                        }
                    />
                </SearchBoxWrapper>
            </HeaderBox>
        )
    }

    ImageFragmentDefault = ({ attachment }: { attachment: File | undefined | string }) => {
        return attachment ? (
            <>
                <StyledBadge
                    overlap="rectangular"
                    badgeContent={
                        <Box>
                            <input
                                type="file"
                                style={style.hideImageInput}
                                id="edit-icon-badge-file"
                                className="image-contained-class"
                                onChange={(event) => {
                                    const selectedFile = event.target.files && event.target.files[0];
                                    if (selectedFile) {
                                        this.changeImageDefault(selectedFile)
                                    }
                                }}
                                data-testid={"edit_img"}
                                accept="image/*"
                            />
                            <label htmlFor="edit-icon-badge-file">
                                <IconButton
                                    data-testid={"edit_img_btn"}
                                    component="span"
                                    aria-label="edit upload picture"
                                    disableRipple={true}
                                    style={style.badgeButton}
                                >
                                    <Edit fontSize="small" htmlColor="#fff" />
                                </IconButton>
                            </label>
                        </Box>
                    }
                >
                    <Card variant="outlined" style={style.badgeCardRoot} >
                        <CardMedia
                            component="img"
                            image={typeof attachment === "string" ? attachment : URL.createObjectURL(attachment)}
                            alt="cover-images"
                        />
                    </Card>
                </StyledBadge>
            </>
        ) : (
            <>
                <Box style={style.formImageContainer}>
                    <input
                        accept="image/*"
                        type="file"
                        className="image-empty-container-class"
                        style={style.hideImageInput}
                        id="icon-button-file"
                        onChange={(event) => {
                            const selectedFile = event.target.files && event.target.files[0];
                            if (selectedFile) {
                                this.changeImageDefault(selectedFile)
                            }
                        }}
                        data-testid={"add_input_default_testid"}
                    />
                    <Typography className="uploadTxt" style={{ textAlign: 'left' }}>Upload crop photo</Typography>
                    <label htmlFor="icon-button-file" style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                        <IconButton aria-label="upload image" component={"span"} style={{ padding: 0, marginTop: "8px" }}>
                            <AddAPhoto style={style.AddAPhoto} />
                        </IconButton>
                    </label>
                </Box >
            </>
        );
    };
    ImageFragmentForm = ({ attachment, formIndex }: { attachment: File | undefined | string, formIndex: number }) => {

        return attachment ? (
            <>
                <StyledBadge
                    overlap="rectangular"
                    badgeContent={
                        <Box>
                            <input
                                type="file"
                                style={style.hideImageInput}
                                id={`edit-file-input-${formIndex}`}
                                className="image-contained-class"
                                onChange={(event) => {
                                    const selectedFile = event.target.files && event.target.files[0];
                                    this.changeImageForm(formIndex, selectedFile)

                                }}
                                data-testid={`edit_input_testid_${formIndex}`}
                                accept="image/*"
                            />
                            <label htmlFor={`edit-file-input-${formIndex}`}>
                                <IconButton
                                    data-testid={`edit-file-btn-${formIndex}`}
                                    disableRipple={true}
                                    style={style.badgeButton}
                                    component="span"
                                    aria-label="edit upload picture"
                                >
                                    <Edit fontSize="small" htmlColor="#fff" />
                                </IconButton>
                            </label>
                        </Box>
                    }
                >
                    <Card style={style.badgeCardRoot} variant="outlined">
                        <CardMedia
                            component="img"
                            alt="cover-images"
                            image={typeof attachment === "string" ? attachment : URL.createObjectURL(attachment)}
                        />
                    </Card>
                </StyledBadge>
            </>
        ) : (
            <>
                <Box style={style.formImageContainer}>
                    <input
                        className="image-empty-container-class"
                        accept="image/*"
                        type="file"
                        style={style.hideImageInput}
                        id={`add-file-input-${formIndex}`}
                        onChange={(event) => {
                            const selectedFile = event.target.files && event.target.files[0];
                            if (selectedFile) {
                                this.changeImageForm(formIndex, selectedFile)
                            }
                        }}
                        data-testid={`add_input_testid_${formIndex}`}
                    />
                    <Typography className="uploadTxt" style={{ textAlign: 'left' }}>Upload crop photo</Typography>
                    <label htmlFor={`add-file-input-${formIndex}`} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                        <IconButton aria-label="upload image" component={"span"} style={{ padding: 0, marginTop: "8px" }}>
                            <AddAPhoto style={style.AddAPhoto} />
                        </IconButton>
                    </label>
                </Box >
            </>
        );
    };

    renderCropNameDropdown = () => {
        const { CropNameforms, defaultCropForm, selectedCropTab } = this.state
        const existingFormCropName = defaultCropForm[selectedCropTab] as ILanguageObject
        return (
            <>
                <PaperWrapper square>
                    <Tabs
                        className="tabs-wrapper"
                        variant="fullWidth"
                        value={this.state.selectedCropTab}
                        onChange={this.handleChangeTab}
                        data-testid="tab-wrapper"
                    >
                        <CustomTab label="English" value={configJSON.TAB.ENGLISH} data-testid="english-tab" />
                        <CustomTab label="Gujarati" value={configJSON.TAB.GUJARATI} data-testid="gujarati-tab" />
                        <CustomTab label="Hindi" value={configJSON.TAB.HINDI} data-testid="hindi-tab" />
                    </Tabs>
                </PaperWrapper>

                <Box style={{ padding: "0 16px 0px 16px",maxHeight:"370px", overflowY:"auto" }}>
             
                    {CropNameforms.length ? CropNameforms.map((form, formIndex) => {
                        const formCropName = form[selectedCropTab] as ILanguageObject
                        const image_url = returnImgUrl(form?.image as string)
                        
                        return (
                            <>
                            {!form?._destroy ?
                            <Box style={style.inputWrapper} key={formIndex}>
                                <InputBoxWrapper>
                                    <Box style={{ width: "100%",display:"flex", flexDirection:"column"  }} key={formIndex}>
                                        <CustomFormLabel>Crop Photo</CustomFormLabel>
                                        {this.ImageFragmentForm({ attachment: image_url, formIndex})}
                                        {renderErrorMessage(form.error.image)}
                                    </Box>
                                    <Box style={{ width: "100%" }}>
                                        <Box>
                                            <CustomFormLabel>Crop Name</CustomFormLabel>
                                            <FormControl fullWidth style={{ marginTop: "4px" }}>
                                                <CustomTextField
                                                    type="text"
                                                    variant="outlined"
                                                    color="secondary"
                                                    placeholder="Enter name"
                                                    data-testid="crop_name_form"
                                                    name="crop_name_form"
                                                    value={formCropName?.crop_name}
                                                    onChange={(event) => this.handleChangeForm(formIndex, "crop_name", event.target.value, selectedCropTab)}
                                                />
                                                {renderErrorMessage(formCropName?.crop_name_error)}

                                            </FormControl>
                                        </Box>
                                        <Box style={{ marginTop: "8px" }}>
                                            <CustomFormLabel>Crop Days</CustomFormLabel>
                                            <FormControl fullWidth style={{ marginTop: "4px" }}>
                                                <CustomTextField
                                                    type="number"
                                                    variant="outlined"
                                                    color="secondary"
                                                    placeholder="Enter days"
                                                    data-testid="crop_days_form"
                                                    onKeyDown={keyDown}
                                                    name="crop_days_form"
                                                    value={form.crop_days}
                                                    onChange={(event) => this.handleChangeForm(formIndex, "crop_days", event.target.value)}
                                                />
                                                {renderErrorMessage(form.error.crop_days)}
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box style={{ width: "100%" }}>
                                        <Box>
                                            <CustomFormLabel>Crop Season</CustomFormLabel>
                                            <FormControl fullWidth>
                                                <CustomSelectDD
                                                    IconComponent={CustomArrowIcon}
                                                    data-testid="crop_season_form"
                                                    variant="outlined"
                                                    color="secondary"
                                                    style={style.inputFieldStyle}
                                                    inputProps={{ "aria-label": "Without label" }}
                                                    displayEmpty
                                                    name="crop_season_form"
                                                    value={form.crop_season}
                                                    onChange={(event) => this.handleChangeForm(formIndex, "crop_season", event.target.value as string)}
                                                >
                                                    <MenuItem value={""} disabled>
                                                        <span className="opcity-48">Select Season</span>
                                                    </MenuItem>
                                                    {this.state.cropSeasonsData.length > 0 ?
                                                        this.state.cropSeasonsData.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))
                                                        : NoMenuDataFound()
                                                    }
                                                </CustomSelectDD>
                                                {renderErrorMessage(form.error.crop_season)}
                                            </FormControl>
                                        </Box>
                                        <Box style={{ marginTop: "8px" }}>
                                            <CustomFormLabel>Harvest Type:</CustomFormLabel>
                                            <RadioGroup
                                                row
                                                name="harvest_type"
                                                data-testid="harvest_type_form"
                                                style={{ marginTop: "4px" }}
                                                value={form.harvest_type}
                                                onChange={(event) => this.handleChangeForm(formIndex, "harvest_type", event.target.value)}
                                            >
                                                <CustomRadioLabel
                                                    value="mono"
                                                    control={
                                                        <Radio
                                                            icon={<img src={UnCheckedRadioSVG} />}
                                                            checkedIcon={
                                                                <img src={CheckedRadioSVG} />
                                                            }
                                                        />
                                                    }
                                                    label={<Typography style={{ ...style.radioLabel, opacity: 1 }}>Mono</Typography>}
                                                />
                                                <CustomRadioLabel
                                                    value="multi"
                                                    control={
                                                        <Radio
                                                            icon={<img src={UnCheckedRadioSVG} />}
                                                            checkedIcon={
                                                                <img src={CheckedRadioSVG} />
                                                            }
                                                        />
                                                    }
                                                    label={<Typography style={{ ...style.radioLabel, opacity: 1 }}>Multi</Typography>}
                                                />
                                            </RadioGroup>
                                            {renderErrorMessage(form.error.harvest_type)}
                                        </Box>
                                    </Box>

                                </InputBoxWrapper>
                                <Box style={{ padding: "8px 0 0 8px", display: "flex", alignItems: "flex-start" }}>
                                    <IconButton
                                        data-testid={`remove-input-testid-${formIndex}`}
                                        style={style.iconBtn}
                                        onClick={() => { this.handleRemoveInput(form,formIndex) }}
                                    >
                                        <img src={RemoveSVG} />
                                    </IconButton>
                                </Box>
                            </Box>
                            :<></>}
                            </>
                        )
                    }) : <></>}
                    </Box>
                    <Box style={{ padding: "0 16px" }}>

                    <Box style={style.inputWrapper}>
                        <InputBoxWrapper>
                            <Box style={{ width: "100%", display:"flex", flexDirection:"column" }}>
                                <CustomFormLabel>Crop Photo</CustomFormLabel> 
                                {this.ImageFragmentDefault({ attachment: defaultCropForm?.image as string })}
                                {renderErrorMessage(defaultCropForm.error.image)}
                            </Box>
                            <Box style={{ width: "100%" }}>
                                <Box>
                                    <CustomFormLabel>Crop Name</CustomFormLabel>
                                    <FormControl fullWidth style={{ marginTop: "4px" }}>
                                        <CustomTextField
                                            type="text"
                                            variant="outlined"
                                            color="secondary"
                                            placeholder="Enter name"
                                            data-testid="crop_name_default"
                                            name="crop_name"
                                            value={existingFormCropName?.crop_name}
                                            onChange={(event) => this.handleChangeDefault("crop_name", event.target.value, selectedCropTab)}
                                        />
                                        {renderErrorMessage(existingFormCropName?.crop_name_error)}
                                    </FormControl>
                                </Box>
                                <Box style={{ marginTop: "8px" }}>
                                    <CustomFormLabel>Crop Days</CustomFormLabel>
                                    <FormControl fullWidth style={{ marginTop: "4px" }}>
                                        <CustomTextField
                                            type="number"
                                            variant="outlined"
                                            color="secondary"
                                            placeholder="Enter days"
                                            data-testid="crop_days_default"
                                            onKeyDown={keyDown}
                                            name="crop_days_default"
                                            value={defaultCropForm.crop_days}
                                            onChange={(event) => this.handleChangeDefault("crop_days", event.target.value)}
                                        />
                                        {renderErrorMessage(defaultCropForm.error.crop_days)}
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box style={{ width: "100%" }}>
                                <Box>
                                    <CustomFormLabel>Crop Season</CustomFormLabel>
                                    <FormControl fullWidth>
                                        <CustomSelectDD
                                            IconComponent={CustomArrowIcon}
                                            data-testid="crop_season_default"
                                            variant="outlined"
                                            color="secondary"
                                            style={style.inputFieldStyle}
                                            inputProps={{ "aria-label": "Without label" }}
                                            displayEmpty
                                            name="crop_season_default"
                                            value={defaultCropForm.crop_season}
                                            onChange={(event) => this.handleChangeDefault("crop_season", event.target.value as string)}
                                        >
                                            <MenuItem value={""} disabled>
                                                <span className="opcity-48">Select Season</span>
                                            </MenuItem>
                                            {this.state.cropSeasonsData.length > 0 ?
                                                this.state.cropSeasonsData.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))
                                                : NoMenuDataFound()
                                            }
                                        </CustomSelectDD>
                                        {renderErrorMessage(defaultCropForm.error.crop_season)}
                                    </FormControl>
                                </Box>
                                <Box style={{ marginTop: "8px" }}>
                                    <CustomFormLabel>Harvest Type:</CustomFormLabel>
                                    <RadioGroup
                                        row
                                        name="harvest_type"
                                        data-testid="harvest_type_default"
                                        style={{ marginTop: "4px" }}
                                        value={defaultCropForm.harvest_type}
                                        onChange={(event) => this.handleChangeDefault("harvest_type", event.target.value )}
                                    >
                                        <CustomRadioLabel
                                            value="mono"
                                            control={
                                                <Radio
                                                    icon={<img src={UnCheckedRadioSVG} />}
                                                    checkedIcon={
                                                        <img src={CheckedRadioSVG} />
                                                    }
                                                />
                                            }
                                            label={<Typography style={{ ...style.radioLabel, opacity: 1 }}>Mono</Typography>}
                                        />
                                        <CustomRadioLabel
                                            value="multi"
                                            control={
                                                <Radio
                                                    icon={<img src={UnCheckedRadioSVG} />}
                                                    checkedIcon={
                                                        <img src={CheckedRadioSVG} />
                                                    }
                                                />
                                            }
                                            label={<Typography style={{ ...style.radioLabel, opacity: 1 }}>Multi</Typography>}
                                        />
                                    </RadioGroup>
                                    {renderErrorMessage(defaultCropForm.error.harvest_type)}
                                </Box>
                            </Box>

                        </InputBoxWrapper>
                        <Box style={{ padding: "8px 0 0 8px", display: "flex", alignItems: "flex-start" }}>
                            <IconButton
                                data-testid="add-input-testid"
                                style={style.iconBtn}
                                onClick={this.handleAddInput}
                            >
                                <img src={AddSVG} />
                            </IconButton>
                        </Box>
                    </Box>
                    <LoadingButton
                        data-testid="crop-save-btn"
                        btnStyle={style.saveBtn}
                        loadingStyle={style.loadingStyle}
                        title={"SAVE"}
                        isLoading={this.state.submitLoading}
                        onClick={this.handleSubmitCropName}
                    />
                </Box>

            </>
        )
    }
    renderAccordians = (data: IHeadings[]) => {
        return (
            <>
                {data.map((item, itemIndex) => {
                    return (
                        <Box>
                            <CustomAccordian data-testid={`accordian-${itemIndex}`}
                                onChange={this.handleCurrentExpanded(item.heading)}
                                expanded={this.state.currentExpanded === item.heading}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ width: "28px", height: "28px", color: "#1C2324", opacity: 0.72 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography className="sub-dropdown-title">{item.heading}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails className="main-accordian-details-wrapper">
                                    {this.state.dropdownDataLoading ?
                                        <LoaderCenter loadingStyle={{ height: "24px", width: "24px" }} />
                                        : <></>
                                    }
                                    {((!this.state.dropdownDataLoading && this.state.currentExpanded == item.heading)) ?
                                        this.renderAccordianDetails(item) : <></>
                                    }
                                </AccordionDetails>
                            </CustomAccordian>
                        </Box>
                    )
                })}

            </>
        )
    }
    renderAccordianDetails = (item: IHeadings) => {
        if (item.heading == configJSON.CROPS.CROP_NAME) {
            return this.renderCropNameDropdown()
        } else if (item.heading == configJSON.CROPS.UNIT_OF_SEED_QUANTITY ||
            item.heading == configJSON.CROPS.UNIT_OF_BIO_AGENT_QUANTITY ||
            item.heading == configJSON.CROPS.UNIT_OF_AREA
        ) {
            return (
                <UnitComponent
                    MenuList={formateUnitData(this.state.dropdownData)}
                    submitLoading={this.state.submitLoading}
                    onSubmit={this.handleSubmitUnit}
                    openUnitsDrodpowns={this.props.openUnitsDrodpowns}
                    data-testid={`unit-component`}
                />)
        } else {
            return (
                <SimpleDropdown navigation={undefined} id=""
                    dataArray={formateDataSimple(this.state.dropdownData)}
                    submitLoading={this.state.submitLoading}
                    onSubmit={this.handleSubmitSimple}
                    data-testid={`simple-component`}
                />)
        }
    }




    renderDropdowns = () => {
        const { headingsData } = this.state;
        const firstDropdown = headingsData.slice(0, 1);
        const restDropdowns = headingsData.slice(1);
        return (
            <Box>

                <Grid container spacing={2}>
                    {/* CROP NAME */}
                    <Grid item lg={12} sm={12} md={12} style={style.dropdownGrid}>
                        {this.renderAccordians(firstDropdown)}
                    </Grid>
                    {/* REST DROPDOWNS */}
                    <Grid item lg={6} sm={12} md={12} style={style.dropdownGrid}>
                        {this.renderAccordians(restDropdowns.slice(0, Math.ceil(headingsData.length / 2)))}
                    </Grid>
                    <Grid item lg={6} sm={12} md={12} style={style.dropdownGrid}>
                        {this.renderAccordians(restDropdowns.slice(Math.ceil(headingsData.length / 2)))}
                    </Grid>

                </Grid>
            </Box>
        )
    }
    renderErrorMsgModal = () => {
        return (
            <>
                <ErrorModal open={this.state.openMsgModal} errorTitle={this.state.titleMsgModal} errorSubTitle={this.state.descMsgModal}
                    BtnTitle="Okay" handleBtnClick={this.handleCloseMsgModal}
                />
            </>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Box style={style.mainWrapper}>
                    {this.renderHeader()}
                    {this.state.fetchHeadingsLoading ? <LoaderCenter /> : <></>}
                    {(!this.state.fetchHeadingsLoading && this.state.headingsData.length) ? this.renderDropdowns() : <></>}
                    {(!this.state.fetchHeadingsLoading && !this.state.headingsData.length) ? <DataNotFound fontStyle={style.noDataTxt} /> : <></>}
                    {this.state.openMsgModal ? this.renderErrorMsgModal() : <></>}
                </Box>
            </>
            // Customizable Area End

        )
    }
}
// Customizable Area Start   
const HeaderBox = styled(Box)({
    marginBottom: "24px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: "nowrap",
    rowGap: "8px",
    "@media (max-width: 695px)": {
        flexWrap: "wrap",
        width: "100%"
    }
})
const SearchBoxWrapper = styled(Box)({
    minWidth: "300px",
    height: "40px",
    background: "#FCFAF7",
    borderRadius: "28px",
    border: "1px solid #F0EBE0",
    width: "100%",
    '& .custom_search_input>.MuiInputAdornment-positionStart': {
        margin: "0 4px 0 16px !important"
    },
    '& .custom_search_input>.MuiInputBase-input': {
        margin: "2px"
    },
    "@media (min-width: 959px) and (max-width: 1130px)": {
        minWidth: "200px",
    },
    "@media (max-width: 695px)": {
        minWidth: "200px",
        width: "100%"
    }
})

const PaperWrapper = styled(Paper)({
    background: "transparent",
    "& .MuiTabs-root": {
        minHeight: "21px",
    }
})

const CustomFormLabel = styled(FormLabel)({
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#1C2324",
    opacity: 0.64,
    marginBottom: "4px"
})

const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        height: "40px"
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px !important",
        backgroundColor: "#FCFAF7 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #F0EBE0 !important",
    }
})

const CustomTab = styled(Tab)({
    fontWeight: 500,
    fontSize: "14px",
    color: "#1C2324",
    opacity: "0.48 !important",
    borderBottom: "2px solid rgba(28, 35, 36, 0.32)",
    padding: "0px",
    alignItems: "end",
    minHeight: "21px !important",
    minWidth: "0px !important",
    "&.Mui-selected": {
        color: "#DF5200 !important",
        opacity: "1 !important",
    }
})

const InputBoxWrapper = styled(Box)({
    border: "1px solid #F0EBE0",
    backgroundColor: "#F8F6F1",
    borderRadius: "12px",
    padding: "16px",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    width: "100%",
    "@media (min-width: 959px) and (max-width: 1320px)": {
        flexWrap: "wrap"
    },
    "@media (max-width: 695px)": {
        flexWrap: "wrap"
    }
})
const CustomSelectDD = styled(Select)({
    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent !important"
    },
    "& .MuiSelect-iconOutlined": {
        right: "10px !important"
    }
})

const CustomArrowIcon = styled(KeyboardArrowDown)({
    fontSize: "26px",
    color: "#1C2324",
    opacity: 0.40
});

const StyledBadge = styled(Badge)(() =>({
    "& .MuiBadge-badge": {
        right: "20px",
        top: "20px",
        width: "25px",
        height: "20px",
        padding: ".8rem",
    },
}));
const CustomRadioLabel = styled(FormControlLabel)({
    marginRight: "12px",
    marginLeft: "0px",
    "& .MuiIconButton-root": {
        padding: 0,
        marginRight: "4px !important"
    }

})

const CustomAccordian = styled(Accordion)({
    backgroundColor: "#FCFAF7 !important",
    borderRadius: "12px !important",
    "& .MuiAccordionSummary-root": {
        height: "52px !important",
        minHeight: "52px !important",
        padding: "0 12px 0 16px !important"
    },
    "& .MuiAccordionSummary-expandIcon": {
        "padding": "12px !important"
    }
})

const style = {
    mainWrapper: {
        borderRadius: "12px",
        padding: "24px"
    } as React.CSSProperties,
    headerTitle: {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "36px",
        color: "#1C2324",
        width: "100%",
    } as React.CSSProperties,
    inputWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginTop: "16px"
    } as React.CSSProperties,

    inputFieldStyle: {
        height: "40px",
        marginTop: "4px"
    } as React.CSSProperties,

    radioLabel: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2324"
    } as React.CSSProperties,
    // IMAGE INPUT
    hideImageInput: {
        display: "none"
    } as React.CSSProperties,
    badgeButton: {
        backgroundColor: "rgba(28, 35, 36, .56)",
        padding: "4px",
    } as React.CSSProperties,
    badgeCardRoot: {
        width: "100%",
        height: "110px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "12px",
        minHeight: "110",
        padding: "0",
    } as React.CSSProperties,
    formImageContainer: {
        backgroundColor: 'rgb(252, 250, 247)',
        border: '1px solid rgb(240, 235, 224)',
        borderRadius: '0.75rem',
        width: '100%',
        height: '110px',
        padding: '12px',
        minWidth: "185px",
        marginTop: "4px"
    } as React.CSSProperties,
    photoCamera: {
        width: 60,
        height: 60,
        color: "#1C2324",
        opacity: .5
    } as React.CSSProperties,
    AddAPhoto: {
        width: '40px',
        height: '30px',
        color: '#1C2324',
        opacity: 0.32
    } as React.CSSProperties,
    iconBtn: {
        padding: "0"
    } as React.CSSProperties,
    saveBtn: {
        width: "100%",
        height: "40px",
        backgroundColor: "#DF5200",
        color: "#FFFFFF",
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "center",
        fontWeight: 600,
        borderRadius: "28px",
        marginTop: "16px"
    } as React.CSSProperties,
    loadingStyle: {
        color: "#FFFFFF",
        height: "20px",
        width: "20px"
    } as React.CSSProperties,
    dropdownGrid: {
        display: 'flex',
        flexDirection: "column",
        rowGap: "16px",
        width: "100%"
    } as React.CSSProperties,
    // NO DATA FOUND
    noDataTxt: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
        color: "#1C2324",
        textAlign: "center",
        marginTop: "50px"
    } as React.CSSProperties,
}
// Customizable Area End