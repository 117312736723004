import React from "react";
import {
    Box,
    Table,
    TableBody,
    IconButton,
    Typography,
    TableRow,
    TableCell,
    TableHead,
    Tooltip
} from "@material-ui/core";
import { ICreatedNutrient, ICropIds } from "../interface.web";
import { dateDDMMYYYY, returnTruthyString } from "../helper";

interface IProps {
    data: ICreatedNutrient[];
    handleDelete: (id: string) => void;
    handleEdit: (id: string, land_detail_id: string | undefined, crop_id: string | string[] | undefined) => void;
}
const NutrientActivityDetails = ({
    data = [],
    handleDelete,
    handleEdit,
}: IProps) => {
    // RENDER TABLE ROW
    const rendertableRow = (label: string, value: string | Date) => {
        return (
            <TableRow>
                <TableCell
                    style={{
                        ...styleWeb.tableCell,
                        ...styleWeb.opacity64,
                    }}
                >
                    {label}
                </TableCell>
                <TableCell style={styleWeb.tableCell}>{value}</TableCell>
            </TableRow>
        );
    };

    const renderTable = (data: ICreatedNutrient) => {
        const { id, attributes } = data ?? {};
        const { nutrient_name_id, nutrient_managment_id, date_of_application, quantity_of_nutrient, fertilizer_cost, labor_cost, land_detail_id, crop_ids,unit_of_measure_id,freeze_record } = attributes ?? {};

        const farm_id = returnTruthyString(land_detail_id.id)
        const crop_id = crop_ids?.data?.map((crop) => String(crop.id))

        const crop_names_array = crop_ids?.data?.map((crop:ICropIds) => String(crop?.attributes?.crop_name_id?.name)).join(", ")

        const nutrient_name_value = returnTruthyString(nutrient_name_id?.name)
        const nutrient_managment_value = returnTruthyString(nutrient_managment_id?.name)
        const date_of_application_value = dateDDMMYYYY(date_of_application)
        const unit_name = returnTruthyString(unit_of_measure_id?.name)
        const quantity_of_nutrient_value = String(quantity_of_nutrient) + " " + unit_name
        
        const fertilizer_cost_value = "₹ " + String(fertilizer_cost) + "/-"
        const labor_cost_value = "₹ " + String(labor_cost) + "/-"

        const farm_name = String(land_detail_id.name)
        const crop_name = crop_names_array

        const disable_activity = freeze_record

        return (
            <Box style={{ width: "100%" }} key={id}>
                <Table>
                    <TableHead>
                        <TableRow style={styleWeb.tableHead}>
                            <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name}</span> </TableCell>
                            <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name}</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rendertableRow("Nutrient Name", nutrient_name_value)}
                        {rendertableRow("Nutrient Management Type", nutrient_managment_value)}
                        {rendertableRow("Date of Application", date_of_application_value)}
                        {rendertableRow("Nutrient Quantity", quantity_of_nutrient_value)}
                        {rendertableRow("Fertilizer Cost", fertilizer_cost_value)}
                        {rendertableRow("Labor Cost", labor_cost_value)}
                    </TableBody>
                </Table>
                <Box style={styleWeb.tableFooter}>
                    <Box style={styleWeb.iconsbox}>
                        <IconButton style={styleWeb.iconbutton}>
                            <img
                                src={require("../share-icons.svg")}
                                alt="Share in chat"
                                style={{ width: "18px", height: "18px" }}
                            />
                        </IconButton>
                        <Typography style={styleWeb.shareInChat}>Share in Chat</Typography>
                    </Box>
                    <Box style={styleWeb.iconsbox}>
                        <Tooltip title={disable_activity ? "Activity Freezed" : ""}>
                            <Box>
                                <IconButton style={styleWeb.iconbutton}
                                    onClick={() => handleEdit(id, farm_id, crop_id)}
                                    disabled={disable_activity}
                                >
                                    <img
                                        src={require("../edit-icon.svg")}
                                        alt="Edit activity"
                                        style={styleWeb.actionIcon}
                                    />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Tooltip title={disable_activity ? "Activity Freezed" : ""}>
                            <Box>
                                <IconButton
                                    style={styleWeb.iconbutton}
                                    onClick={() => handleDelete(id)}
                                    disabled={disable_activity}
                                >
                                    <img
                                        src={require("../delete-icon.svg")}
                                        alt="Delete activity"
                                        style={styleWeb.actionIcon}
                                    />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        );
    };

    return (
        <>
            {data?.length > 0 ? (
                data.map((item, index) => {
                    return renderTable(item);
                })
            ) : (
                <Box style={{ width: "100%" }}>
                    <Typography style={{ textAlign: "center", marginBottom: "10px" }}>
                        No data found
                    </Typography>
                </Box>
            )}
        </>
    )
};

export default NutrientActivityDetails;

const styleWeb = {
    tableCell: {
        border: "1px solid #F0EBE0",
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#1C2324",
        padding: "8px 12px",
        width: "50%"
    } as React.CSSProperties,
    shareInChat: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#006A77",
        lineHeight: "18px",
    } as React.CSSProperties,
    iconsbox: {
        display: "flex",
        alignItems: "center",
        gap: "4px",
    } as React.CSSProperties,
    actionIcon: {
        width: "20px",
        height: "20px",
    } as React.CSSProperties,
    iconbutton: {
        padding: 0,
    } as React.CSSProperties,
    tableFooter: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px 12px",
    } as React.CSSProperties,
    opacity64: {
        opacity: "64%",
    } as React.CSSProperties,
    tableHead: {
        border: "1px solid #F0EBE0",
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#1C2324",
        padding: "8px 12px",
        width: "100%",
    } as React.CSSProperties,
    highlightedText: {
        fontWeight: 500,
    } as React.CSSProperties,
    headInfo: {
        fontWeight: 400,
        padding: "8px 12px",
        fontSize: "12px",
        color: "#1C2324",
        borderBottom: "none"
    } as React.CSSProperties,
};
