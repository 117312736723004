import React from "react";

import {
  // Customizable Area Start
  Box,
  Input,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Avatar,
  Badge,
  Chip,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "./AdminConsole.web.css";
import { ThemeProvider } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  imgIconGraph,
  imgIconlist,
  imgIconFilter,
  UnchecokedRadioIconSVG,
  ChecokedRadioIconSVG,
  smallCloseIconSVG,
  BackArrowIconSVG,
  AddUserPlusIconSVG,
} from "./assets";
import { DataGrid } from "@mui/x-data-grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import MomentUtils from "@date-io/moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik } from "formik";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import moment from "moment";
import { SchemaFarmerForm, SchemaFieldExecutiveForm, inputMinZero, keyDown, keyDownInt } from "../../../components/src/helper";
import {
  selectMenuItem,
  NoMenuDataFound,
  adminColumns,
  farmerColumns,
  fieldExecutiveColumns,
  superAdminColumns,
  themeUser,
  totalAreaCottonValue,
  villageNameAvatar,
} from "../../../components/src/AdminConsoleHelper";
import ErrorModal from "../../../components/src/ErrorModal.web";
import CustomModal from "../../../components/src/customModal.web";
import AssignVillageFilter from "../../../components/src/AssignVillageFilter";
import {Link} from "react-router-dom"

// Customizable Area End


import AdminConsoleController, {
  Props,
  configJSON,
} from "./AdminConsoleController";
import UserTableFilter from "../../../components/src/UserTableFilter";
import FarmMapping from "../../../components/src/FarmMapping/FarmMapping.web";

export default class AdminConsole extends AdminConsoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getCustomErrorMessage = (touched: any, errors: any, value: string) => {
    return (
      touched[value] &&
      errors[value] && (
        <div>
          <Typography className="text-danger" style={{ fontSize: "0.75rem" }}>
            {errors[value]}
          </Typography>
        </div>
      )
    );
  };

  getEyeIconPassword = () => {
    return this.state.showEyeIconPassword ? (
      <Visibility style={webStyle.customDateIcon} />
    ) : (
      <VisibilityOff style={webStyle.customDateIcon} />
    );
  };

  getEyeIconConfirmPassword = () => {
    return this.state.showEyeIconConfirmPassword ? (
      <Visibility style={webStyle.customDateIcon} />
    ) : (
      <VisibilityOff style={webStyle.customDateIcon} />
    );
  };

  closeFarmMappingModal = () => { this.setState({ OpenFarmMapping: false }) }

  // USER TABLES START
  showFarmerListTable = () => {
    return (
      this.state.selectedUserTable === "farmer" && (
        <DataGrid
          disableColumnMenu
          disableSelectionOnClick
          headerHeight={60}
          data-testid="farmerTableTestid"
          rows={this.state.farmerData}
          style={{ height: "inherit" }}
          rowHeight={46}
          loading={this.state.farmerDataLoading}
          columns={farmerColumns(
            (data: any) => this.handleFarmerEditClick(data),
            (data: any) => this.handleFarmerFEDelete(data),
            (data: any,status:boolean) => this.handleUpdateUserStatus(data,status)
          )}
          pageSize={this.state.farmerPageSize}
          onPageSizeChange={(page: number) => {
            this.setState({ farmerPageSize: page });
          }}
          rowsPerPageOptions={[15, 30, 50]}
          getRowId={(row: any) => row.id}
          rowCount={this.state.totalSearchedCount}
          page={this.state.farmerTableCurrentPage}
          onPageChange={(page: number) => {
            this.setState({ farmerTableCurrentPage: page });
          }}
        />
      )
    );
  };
  showFEListTable = () => {
    return (
      this.state.selectedUserTable === "fieldExecutive" && (
        <>
          <DataGrid
            disableColumnMenu
            disableSelectionOnClick
            style={{ height: "inherit" }}
            rowHeight={46}
            data-testid="fieldExecutiveTableTestid"
            rows={this.state.FeData}
            columns={fieldExecutiveColumns(
              (data: any) => this.handleFeEditClick(data),
              (data: any) => this.handleFarmerFEDelete(data)
            )}
            loading={this.state.FeLoading}
            pageSize={this.state.FePageSize}
            onPageSizeChange={(page: number) => {
              this.setState({ FePageSize: page });
            }}
            rowsPerPageOptions={[15, 30, 50]}
            getRowId={(row: any) => row.id}
            rowCount={this.state.totalSearchedCount}
            page={this.state.FeTableCurrentPage}
            onPageChange={(page: number) => {
              this.setState({ FeTableCurrentPage: page });
            }}
          />
        </>
      )
    );
  };
  showAdminListTable = () => {
    return (
      this.state.selectedUserTable === "admin" && (
        <DataGrid
          data-testid="adminTableTestid"
          disableSelectionOnClick
          style={{ height: "inherit" }}
          disableColumnMenu
          rowHeight={46}
          rows={this.state.adminData}
          columns={adminColumns(
            (data) => this.handleAdminEditClick(data),
            (data) => this.handleAdminDelete(data),
            this.state.isLoggedUserAdmin
          )}
          pageSize={this.state.adminPageSize}
          onPageSizeChange={(page: number) => {
            this.setState({ adminPageSize: page });
          }}
          loading={this.state.adminLoading}
          rowsPerPageOptions={[15, 30, 50]}
          getRowId={(row: any) => row.id}
          rowCount={this.state.totalSearchedCount}
          page={this.state.adminTableCurrentPage}
          onPageChange={(page: number) => {
            this.setState({
              adminTableCurrentPage: page,
            });
          }}
        />
      )
    );
  };
  showSuperAdminListTable = () => {
    return (
      this.state.selectedUserTable === "SuperAdmin" && (
        <DataGrid
          data-testid="SuperAdminTableTestid"
          disableSelectionOnClick
          style={{ height: "inherit" }}
          disableColumnMenu
          rowHeight={46}
          rows={this.state.superAdminData}
          columns={superAdminColumns(
            (data) => this.handleSuperAdminEditClick(data),
            (data) => this.handleAdminDelete(data),
            this.state.isLoggedUserAdmin
          )}
          pageSize={this.state.superAdminPageSize}
          onPageSizeChange={(page: number) => {
            this.setState({ superAdminPageSize: page });
          }}
          loading={this.state.superAdminLoading}
          rowsPerPageOptions={[15, 30, 50]}
          getRowId={(row: any) => row.id}
          rowCount={this.state.totalSearchedCount}
          page={this.state.superAdminTableCurrentPage}
          onPageChange={(page: number) => {
            this.setState({
              superAdminTableCurrentPage: page,
            });
          }}
        />
      )
    );
  };
  // USER TABLES END

  // USER FORMS STARTS
  renderUserForm = () => {
    switch (this.state.seletedUserTypeForm) {
      case "Farmers":
        return this.showFarmerForm()
      case "FieldExecutive":
        return this.showFEForm()
      case "Admin":
      case "SuperAdmins":
        return this.showAdminForm()
      default:
        return <></>
    }
  }
  showFarmerForm = () => {
    return (
      <>
        <Formik
          data-testid="farmersFormikTestid"
          initialValues={this.state.farmerInitialValues}
          validationSchema={SchemaFarmerForm}
          onSubmit={(values) => {
            this.handleSubmitFarmer(values);
          }}
          validateOnChange
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleSubmit,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                className="customFormSpacing"
              >                
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  style={{marginTop:"8px"}}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="firstnameFarmerId"
                      className="customLabelField"
                    >
                      First Name
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        data-testid="firstnameFarmerTestId"
                        style={webStyle.customInputField}
                        placeholder="Enter First Name"
                        type="text"
                        name="firstnameFarmer"
                        variant="outlined"
                        color="secondary"
                        value={values.firstnameFarmer}
                        onChange={handleChange}
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "firstnameFarmer"
                        )}
                        helperText={this.getHelperText(
                          touched,
                          errors,
                          "firstnameFarmer"
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="middlenameFarmerId"
                      className="customLabelField"
                    >
                      Middle Name
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        data-testid="middlenameFarmerTestId"
                        style={webStyle.customInputField}
                        placeholder="Enter Middle Name"
                        type="text"
                        name="middlenameFarmer"
                        variant="outlined"
                        color="secondary"
                        value={values.middlenameFarmer}
                        onChange={handleChange}
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "middlenameFarmer"
                        )}
                        helperText={this.getHelperText(
                          touched,
                          errors,
                          "middlenameFarmer"
                        )}
                      />
                    </FormControl>
                  </Grid>                  
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="surnameFarmerId"
                      className="customLabelField"
                    >
                      Surname
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        data-testid="surnameFarmerTestId"
                        style={webStyle.customInputField}
                        placeholder="Enter Surname"
                        type="text"
                        name="surnameFarmer"
                        variant="outlined"
                        color="secondary"
                        value={values.surnameFarmer}
                        onChange={handleChange}
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "surnameFarmer"
                        )}
                        helperText={this.getHelperText(
                          touched,
                          errors,
                          "surnameFarmer"
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="mobileNumberFarmerId"
                      className="customLabelField"
                    >
                      Mobile Number
                    </FormLabel>
                    <FormControl fullWidth>
                      <Input
                        disableUnderline
                        className="custom-mobile-input"
                        style={{
                          marginTop: "8px",
                          padding: "8px 8px 8px 0",
                          background: "#FCFAF7",
                          borderRadius: "12px",
                          border: "1px solid #F0EBE0",
                          height:"56px"
                        }}
                        placeholder="Enter Mobile Number"
                        type="text"
                        name="mobileNumberFarmer"
                        startAdornment={
                          <InputAdornment position="start">
                            <Box
                              style={{
                                padding: "0 16px",
                                borderRight: "1px solid #F0EBE0",
                                marginRight: "8px"
                              }}
                            >
                              <p>+91</p>
                            </Box>
                          </InputAdornment>
                        }
                        value={values.mobileNumberFarmer}
                        onChange={handleChange}
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "mobileNumberFarmer"
                        )}
                      />
                      {this.getCustomErrorMessage(
                        touched,
                        errors,
                        "mobileNumberFarmer"
                      )}
                    </FormControl>
                  </Grid>                  
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} >
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="formfieldWrapper">
                    <FormLabel
                      id="numberBelongsToFarmerId"
                      className="customLabelField"
                    >
                      Number belongs to
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        data-testid="numberBelongsToFarmerTestid"
                        value={values.numberBelongsToFarmer || ""}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="numberBelongsToFarmer"
                        color="secondary"
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "numberBelongsToFarmer"
                        )}
                      >
                        {selectMenuItem("Select Option")}
                        {this.state.getNumberBeongsToData.length ?
                          this.state.getNumberBeongsToData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`getNumberBeongsToDataTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}

                      </Select>
                      {this.getCustomErrorMessage(
                        touched,
                        errors,
                        "numberBelongsToFarmer"
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="formfieldWrapper">
                    <FormLabel id="mobileTypeFarmerId" className="customLabelField" >
                      Mobile Type
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        data-testid="mobileTypeFarmerTestid"
                        value={values.mobileTypeFarmer || ""}
                        displayEmpty
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        color="secondary"
                        name="mobileTypeFarmer"                        
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Without label" }}                        
                        error={this.getErrorMessage( touched, errors, "mobileTypeFarmer" )}
                      >
                        {selectMenuItem("Select Option")}
                        {this.state.getMobileDeviceModelFEData.length ?
                          this.state.getMobileDeviceModelFEData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`getMobileDeviceModelFEDataTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}
                      </Select>
                      {this.getCustomErrorMessage( touched, errors, 
                        "mobileTypeFarmer" )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  direction="row"                  
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="adharcardNumberFarmerId"
                      className="customLabelField"
                    >
                      Aadhar Card <span style={{fontStyle:"italic",fontSize:"12px"}}>- Optional</span>
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        style={webStyle.customInputField}
                                                placeholder="Enter Aadhar Card Number"
                        type="number"
                        name="adharcardNumberFarmer"
                        variant="outlined"
                        color="secondary"
                        onKeyDown={keyDownInt}
                        inputProps={inputMinZero}
                        value={values.adharcardNumberFarmer}
                        onChange={handleChange}
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "adharcardNumberFarmer"
                        )}
                        helperText={this.getHelperText(
                          touched,
                          errors,
                          "adharcardNumberFarmer"
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="dateOfBirthFarmerId"
                      className="customLabelField"
                    >
                      Date of Birth
                    </FormLabel>
                    <FormControl fullWidth style={{ marginTop: "8px" }}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          className="customDatePicker"
                          clearable
                          data-testid="dateOfBirthTestid"
                          value={values.dateOfBirthFarmer}
                          placeholder="DD/MM/YYYY"
                          onChange={(date) =>
                            setFieldValue("dateOfBirthFarmer", date)
                          }
                          maxDate={moment().subtract(18, "years").toDate()}
                          invalidDateMessage={`Invalid date`}
                          format="DD/MM/yyyy"
                          name="dateOfBirthFarmer"
                          inputVariant="outlined"
                          color="secondary"
                          error={this.getErrorMessage(
                            touched,
                            errors,
                            "dateOfBirthFarmer"
                          )}
                          helperText={this.getHelperText(
                            touched,
                            errors,
                            "dateOfBirthFarmer"
                          )}
                          keyboardIcon={
                            <DateRangeIcon style={webStyle.customDateIcon} />
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>                
                </Grid>

                <Grid container spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  
                >
                  <Grid item className="formfieldWrapper"
                    xs={12} sm={12}
                    md={6} lg={6} xl={6}                    
                  >
                    <FormLabel id="totalFamilyMemberFarmerId"
                      className="customLabelField"
                    >
                      Total Family Members
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        style={webStyle.customInputField}
                        inputProps={{ inputMode: "numeric", min: 1 }}
                        placeholder="Family Members"
                        type="number"
                        name="totalFamilyMemberFarmer"
                        variant="outlined"
                        onKeyDown={keyDownInt}
                        color="secondary"
                        value={values.totalFamilyMemberFarmer}
                        onChange={handleChange}
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "totalFamilyMemberFarmer"
                        )}
                        helperText={this.getHelperText(
                          touched,
                          errors,
                          "totalFamilyMemberFarmer"
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="educationFarmerId"
                      className="customLabelField"
                    >
                      Education
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        data-testid="educationFarmerTestid"
                        value={values.educationFarmer || ""}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="educationFarmer"
                        color="secondary"
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "educationFarmer"
                        )}
                      >
                        {selectMenuItem("Select Education")}
                        {this.state.getEducationFarmerData.length ?
                          this.state.getEducationFarmerData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`educationFarmerMenusTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )): NoMenuDataFound()}
                      </Select>
                      {this.getCustomErrorMessage(
                        touched,
                        errors,
                        "educationFarmer"
                      )}
                    </FormControl>
                  </Grid>
                </Grid>                

                <Box style={{ margin: "8px 0 20px 0px" }}>
                  <Box style={{
                    display: "flex",
                    justifyContent: "start",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                    <Box>
                      <FormLabel className="customLabelField" style={{ marginRight: "24px" }}>
                        Select Gender:
                      </FormLabel>
                    </Box>
                    <Box>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="userGenderId"
                          name="genderFarmer"
                          value={values.genderFarmer}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            style={{marginRight:"12px"}}
                            value="male"
                            control={
                              <Radio
                                icon={<img src={UnchecokedRadioIconSVG} />}
                                checkedIcon={
                                  <img src={ChecokedRadioIconSVG} />
                                }
                              />
                            }
                            label="Male"
                          />
                          <FormControlLabel
                            style={{marginRight:"12px"}}
                            value="female"
                            control={
                              <Radio
                                icon={<img src={UnchecokedRadioIconSVG} />}
                                checkedIcon={
                                  <img src={ChecokedRadioIconSVG} />
                                }
                              />
                            }
                            label="Female"
                          />
                          <FormControlLabel
                            style={{marginRight:"12px"}}
                            value="others"
                            control={
                              <Radio
                                icon={<img src={UnchecokedRadioIconSVG} />}
                                checkedIcon={
                                  <img src={ChecokedRadioIconSVG} />
                                }
                              />
                            }
                            label="Other"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                  {this.getCustomErrorMessage(
                    touched,
                    errors,
                    "genderFarmer"
                  )}
                </Box>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel id="stateFarmerId" className="customLabelField">
                      State
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        value={values.stateFarmer || ""}
                        className="mt-8 farmerFormDDClass"
                        data-testid="stateFarmerTestid"
                        variant="outlined"
                        disabled={this.state.disableStateFarmer}
                        name="stateFarmer"
                        color="secondary"
                        onChange={async (e: any) => {
                          setFieldValue("stateFarmer", e.target.value, true);
                          await setFieldTouched("stateFarmer", true);
                          setFieldValue("districtFarmer", "", true);
                          setFieldValue("talukaFarmer", "", true);
                          setFieldValue("villageFarmer", "", true);
                          this.fetchDistrict(e.target.value);
                        }}
                        displayEmpty
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "stateFarmer"
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >                        
                        {selectMenuItem("Select State")}
                        {this.state.getStateData.length ?
                          this.state.getStateData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`stateSubTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}
                      </Select>
                      {this.getCustomErrorMessage(
                        touched,
                        errors,
                        "stateFarmer"
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="districtFarmerId"
                      className="customLabelField"
                    >
                      District
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        value={this.getDistrictFarmerValue(values) || ""}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="districtFarmer"
                        data-testid="districtFarmerTestid"
                        color="secondary"
                        onChange={async (e: any) => {
                          setFieldValue("districtFarmer", e.target.value, true);
                          await setFieldTouched("districtFarmer", true);
                          setFieldValue("talukaFarmer", "", true);
                          setFieldValue("villageFarmer", "", true);
                          this.fetchTaluka(e.target.value);
                        }}
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "districtFarmer"
                        )}
                        displayEmpty
                        disabled={this.disableDistrictFarmerValue()}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {selectMenuItem("Select District")}
                        {this.state.getDistrictData.length ?
                          this.state.getDistrictData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`distSubTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}
                      </Select>
                      {this.getCustomErrorMessage(
                        touched,
                        errors,
                        "districtFarmer"
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >                  
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel id="talukaFarmerId" className="customLabelField">
                      Taluka
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        value={this.getTalukaFarmerValue(values)  || ""}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="talukaFarmer"
                        data-testid="talukaFarmerTestid"
                        color="secondary"
                        onChange={async (e: any) => {
                          setFieldValue("talukaFarmer", e.target.value, true);
                          await setFieldTouched("talukaFarmer", true);
                          setFieldValue("villageFarmer", "", true);
                          this.fetchVillage(e.target.value);
                        }}
                        displayEmpty
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "talukaFarmer"
                        )}
                        disabled={this.disableTalukaFarmerValue()}
                        inputProps={{ "aria-label": "Without label" }}
                      >                        
                        {selectMenuItem("Select Taluka")}
                        {this.state.getTalukaData.length ?
                          this.state.getTalukaData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`talukaSubTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )): NoMenuDataFound()}
                      </Select>
                      {this.getCustomErrorMessage(
                        touched,
                        errors,
                        "talukaFarmer"
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="villageFarmerId"
                      className="customLabelField"
                    >
                      Village
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        value={this.getVillageFarmerValue(values)  || ""}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="villageFarmer"
                        data-testid="villageFarmerTestid"
                        color="secondary"
                        onChange={async (e: any) => {
                          await setFieldValue("villageFarmer", e.target.value);
                          setFieldTouched("villageFarmer", true);
                        }}
                        displayEmpty
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "villageFarmer"
                        )}
                        disabled={this.disableVillageFarmerValue()}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {selectMenuItem("Select Village")}
                        {this.state.getVillageData.length > 0 ?
                          this.state.getVillageData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`villageSubTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )): NoMenuDataFound()}
                      </Select>
                      {this.getCustomErrorMessage(
                        touched,
                        errors,
                        "villageFarmer"
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Box id="assignFieldExecutiveId">
                  <Typography className="userModalBodySubHeading">
                    Assign Field Executive
                  </Typography>
                  <Box>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="formfieldWrapper"
                      >
                        <FormLabel
                          id="emailAddressAdminId"
                          className="customLabelField"
                        >
                          Assign Field Executive
                        </FormLabel>
                        <FormControl fullWidth>
                          <Autocomplete
                            data-testid="assigneFETestid"
                            className="customAutoComplete mt-8"
                            filterSelectedOptions // remove selected FE from the list
                            options={this.state.FElistData}
                            getOptionLabel={(option: any) =>
                              option.first_name + " " + option.last_name
                            }
                            value={this.state.selectedFEformData}
                            onChange={this.handleAssignFEchange}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                color="secondary"
                                placeholder={this.assignFEPlaceholder()}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box className="modalSubmitButtonWrapper">
                  <Button
                    variant="contained"
                    data-testid="FEsubmitBtnTestid"
                    className="modalSubmitButton"
                    fullWidth
                    type="submit"
                    disabled={this.state.addFarmerLoading || this.state.updateFarmerLoading}
                  >
                    {this.getSubmitFormBtnTxt()}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </>
    )
  };
  showFEForm = () => {
    return (
      <>
        <Formik
          data-testid="fieldExecutiveFormikTestid"
          initialValues={this.state.feInitialValues}
          validationSchema={SchemaFieldExecutiveForm}
          onSubmit={this.handleSubmitFieldExecutive}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="customFormSpacing"
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="firstnameFEId" className="customLabelField">
                    First Name
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={webStyle.customInputField}
                      placeholder="Enter First Name"
                      type="text"
                      name="firstnameFE"
                      variant="outlined"
                      color="secondary"
                      value={values.firstnameFE}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "firstnameFE"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "firstnameFE"
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="lastnameFEId" className="customLabelField">
                    Last Name
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={webStyle.customInputField}
                      placeholder="Enter Last Name"
                      type="text"
                      name="lastnameFE"
                      variant="outlined"
                      color="secondary"
                      value={values.lastnameFE}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "lastnameFE"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "lastnameFE"
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="mobileNumberFEId" className="customLabelField">
                    Mobile Number
                  </FormLabel>
                  <FormControl fullWidth>
                    <Input
                      disableUnderline
                      fullWidth
                      style={{
                        marginTop: "8px",
                        padding: "8px 8px 8px 0",
                        background: "#FCFAF7",
                        borderRadius: "12px",
                        border: "1px solid #F0EBE0",
                        height:"56px"
                      }}
                      placeholder="Enter Mobile Number"
                      type="text"
                      className="custom-mobile-input"
                      name="mobileNumberFE"
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            style={{
                              padding: "0 16px",
                              borderRight: "1px solid #F0EBE0",
                              marginRight: "8px"
                            }}
                          >
                            <p>+91</p>
                          </Box>
                        </InputAdornment>
                      }
                      value={values.mobileNumberFE}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "mobileNumberFE"
                      )}
                    />
                    {this.getCustomErrorMessage(
                      touched,
                      errors,
                      "mobileNumberFE"
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="locationFEId" className="customLabelField">
                    Location
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={webStyle.customInputField}
                      placeholder="Enter Village, State"
                      type="text"
                      name="locationFE"
                      variant="outlined"
                      color="secondary"
                      value={values.locationFE}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "locationFE"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "locationFE"
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="dateOfBirthFEId" className="customLabelField">
                    Date of Birth
                  </FormLabel>
                  <FormControl fullWidth style={{ marginTop: "8px" }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        className="customDatePicker"
                        clearable
                        data-testid="dateOfBirthFETestid"
                        value={values.dateOfBirthFE}
                        placeholder="DD/MM/YYYY"
                        onChange={(date) =>
                          setFieldValue("dateOfBirthFE", date)
                        }
                        maxDate={moment().subtract(18, "years").toDate()}
                        invalidDateMessage={`Invalid date`}
                        format="DD/MM/yyyy"
                        inputVariant="outlined"
                        color="secondary"
                        name="dateOfBirthFE"
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "dateOfBirthFE"
                        )}
                        helperText={this.getHelperText(
                          touched,
                          errors,
                          "dateOfBirthFE"
                        )}
                        keyboardIcon={
                          <DateRangeIcon style={webStyle.customDateIcon} />
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="emailAddressFEId" className="customLabelField">
                    Email Address
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={webStyle.customInputField}
                      placeholder="Enter Email Id"
                      type="email"
                      name="emailAddressFE"
                      variant="outlined"
                      color="secondary"
                      value={values.emailAddressFE}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "emailAddressFE"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "emailAddressFE"
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="usernameFEId" className="customLabelField">
                    Username
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={webStyle.customInputField}
                      placeholder="Enter Username"
                      type="text"
                      name="usernameFE"
                      variant="outlined"
                      color="secondary"
                      value={values.usernameFE}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "usernameFE"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "usernameFE"
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel
                    id="mobileDeviceModelFEId"
                    className="customLabelField"
                  >
                    Mobile Device Model
                  </FormLabel>
                  <FormControl fullWidth>
                    <Select
                      value={values.mobileDeviceModelFE || ""}
                      variant="outlined"
                      className="mt-8 farmerFormDDClass"
                      name="mobileDeviceModelFE"
                      color="secondary"
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "mobileDeviceModelFE"
                      )}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        <span className="opcity-48">
                          Select Mobile Device Model
                        </span>
                      </MenuItem>
                      {this.state.getMobileDeviceModelFEData.length > 0 &&
                        this.state.getMobileDeviceModelFEData.map(
                          (ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`getMobileDeviceModelFEData-${ech.id}`}
                            >
                              {ech?.name}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    {this.getCustomErrorMessage(
                      touched,
                      errors,
                      "mobileDeviceModelFE"
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{ paddingRight: "0px" }}
                >
                  <FormLabel
                    id="assignVillageFEId"
                    className="customLabelField"
                  >
                    Assign Village
                  </FormLabel>
                  <FormControl fullWidth>
                    <Autocomplete
                      className="customAutoComplete mt-8"
                      multiple
                      data-testid="assigneVillageTestid"
                      options={this.state.AllVillageListData}
                      getOptionLabel={(option: any) => option.name}
                      value={this.state.selectedLocationArr}
                      onChange={this.onFeLocationsChange}
                      filterSelectedOptions
                      ChipProps={{
                        avatar: (
                          <Avatar
                            style={{
                              background: "#108B88",
                              color: "#fff",
                            }}
                          />
                        ),
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          color="secondary"
                          placeholder={this.assignVillagePlaceholder()}
                        />
                      )}
                      renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                          <Chip
                            style={{
                              backgroundColor: "#F0EBE0",
                              color: "#1C2324",
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "24px",
                            }}
                            key={option.id}
                            avatar={villageNameAvatar(index, option)}
                            label={option.name}
                            {...getTagProps({ index })}
                            data-testid={`villageDeleteTestid-${index}`}
                            onDelete={(event: any) =>
                              this.handleVillageDelete(event, option)
                            }
                            deleteIcon={<img src={smallCloseIconSVG} />}
                          />
                        ));
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop: "18px",
                    padding: "0px",
                  }}
                >
                  <Box>
                    <IconButton
                      data-testid="iconBtnFE"
                      onClick={(event) => {
                        this.setState({
                          isVillageFilterOpen: true,
                          anchorElVillageFilter: event.currentTarget,
                        });
                      }}
                    >
                      <img src={imgIconFilter} />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>

              <Box className="modalSubmitFEButtonWrapper">
                <Button
                  variant="contained"
                  className="modalSubmitButton"
                  fullWidth
                  type="submit"
                  disabled={this.state.addFELoading || this.state.updateFELoading}
                >
                  {this.getSubmitFormBtnTxt()}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </>
      )
  };
  showAdminForm = () => {
    return (    
      <>
        <Formik
          data-testid="adminFormikTestid"
          initialValues={this.state.adminInitialValues}
          validationSchema={this.handleAdminVlidationSchema()}
          onSubmit={(values) => {
            this.handleSubmitAdmin(values);
          }}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="customFormSpacing"
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="firstnameAdminId" className="customLabelField">
                    First Name
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={webStyle.customInputField}
                      placeholder="Enter First Name"
                      type="text"
                      name="firstnameAdmin"
                      variant="outlined"
                      color="secondary"
                      value={values.firstnameAdmin}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "firstnameAdmin"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "firstnameAdmin"
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="lastnameAdminId" className="customLabelField">
                    Last Name
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={webStyle.customInputField}
                      placeholder="Enter Last Name"
                      type="text"
                      name="lastnameAdmin"
                      variant="outlined"
                      color="secondary"
                      value={values.lastnameAdmin}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "lastnameAdmin"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "lastnameAdmin"
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel
                    id="mobileNumberAdminId"
                    className="customLabelField"
                  >
                    Mobile Number
                  </FormLabel>
                  <FormControl fullWidth>
                    <Input
                      disableUnderline
                      fullWidth
                      style={{
                        marginTop: "8px",
                        padding: "8px 8px 8px 0",
                        background: "#FCFAF7",
                        borderRadius: "12px",
                        border: "1px solid #F0EBE0",
                        height:"56px"
                      }}
                      placeholder="Enter Mobile Number"
                      type="text"
                      className="custom-mobile-input"
                      name="mobileNumberAdmin"
                      value={values.mobileNumberAdmin}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            style={{
                              padding: "0 16px",
                              borderRight: "1px solid #F0EBE0",
                              marginRight: "8px"
                            }}
                          >
                            <p>+91</p>
                          </Box>
                        </InputAdornment>
                      }
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "mobileNumberAdmin"
                      )}
                    />
                    {this.getCustomErrorMessage(
                      touched,
                      errors,
                      "mobileNumberAdmin"
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel
                    id="dateOfBirthAdminId"
                    className="customLabelField"
                  >
                    Date of Birth
                  </FormLabel>
                  <FormControl fullWidth style={{ marginTop: "8px" }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        required
                        className="customDatePicker"
                        clearable
                        data-testid="dateOfBirthAdminTestid"
                        value={values.dateOfBirthAdmin}
                        maxDate={moment().subtract(18, "years").toDate()}
                        invalidDateMessage={`Invalid date`}
                        placeholder="DD/MM/YYYY"
                        onChange={(date) =>
                          setFieldValue("dateOfBirthAdmin", date)
                        }
                        format="DD/MM/yyyy"
                        inputVariant="outlined"
                        color="secondary"
                        name="dateOfBirthAdmin"
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "dateOfBirthAdmin"
                        )}
                        helperText={this.getHelperText(
                          touched,
                          errors,
                          "dateOfBirthAdmin"
                        )}
                        keyboardIcon={
                          <DateRangeIcon style={webStyle.customDateIcon} />
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel
                    id="emailAddressAdminId"
                    className="customLabelField"
                  >
                    Email Address
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={webStyle.customInputField}
                      placeholder="Enter Email Id"
                      type="email"
                      name="emailAddressAdmin"
                      variant="outlined"
                      color="secondary"
                      value={values.emailAddressAdmin}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "emailAddressAdmin"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "emailAddressAdmin"
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="usernameAdminId" className="customLabelField">
                    Username
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={webStyle.customInputField}
                      placeholder="Enter Username"
                      type="text"
                      name="usernameAdmin"
                      variant="outlined"
                      color="secondary"
                      value={values.usernameAdmin}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "usernameAdmin"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "usernameAdmin"
                      )}
                      InputProps={{
                        autoComplete: "new-username",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel id="passwordAdminId" className="customLabelField">
                    Password
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      autoComplete="off"
                      style={webStyle.customInputField}
                      placeholder="Enter Password"
                      type={this.getPasswordType()}
                      name="passwordAdmin"
                      variant="outlined"
                      color="secondary"
                      value={values.passwordAdmin}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "passwordAdmin"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "passwordAdmin"
                      )}
                      data-testid="showPasswordTestid"
                      InputProps={{
                        autoComplete: "new-password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-testid="showPasswordIconButton"
                              onClick={() => {
                                this.setState({
                                  showEyeIconPassword:
                                    !this.state.showEyeIconPassword,
                                });
                              }}
                              edge="end"
                            >
                              {this.getEyeIconPassword()}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="formfieldWrapper"
                >
                  <FormLabel
                    id="confirmPasswordAdminId"
                    className="customLabelField"
                  >
                    Confirm Password
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={webStyle.customInputField}
                      placeholder="Enter Confirm Password"
                      type={this.getConfirmPasswordType()}
                      name="confirmPasswordAdmin"
                      variant="outlined"
                      color="secondary"
                      value={values.confirmPasswordAdmin}
                      onChange={handleChange}
                      error={
                        (touched?.confirmPasswordAdmin ||
                          touched?.passwordAdmin) &&
                        Boolean(errors?.confirmPasswordAdmin)
                      }
                      helperText={
                        (touched.confirmPasswordAdmin ||
                          touched?.passwordAdmin) &&
                        errors.confirmPasswordAdmin
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-testId="showConfirmPasswordTestid"
                              onClick={() => {
                                this.setState({
                                  showEyeIconConfirmPassword:
                                    !this.state.showEyeIconConfirmPassword,
                                });
                              }}
                              edge="end"
                            >
                              {this.getEyeIconConfirmPassword()}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box className="modalSubmitButtonWrapper">
                <Button
                  variant="contained"
                  data-testid="adminFormSubmitBtnTestid"
                  className="modalSubmitButton"
                  fullWidth
                  type="submit"
                  disabled={this.state.addAdminLoading || this.state.updateAdminLoading || this.state.addSALoading || this.state.updateSALoading}
                >
                  {this.getSubmitFormBtnTxt()}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </>
    )
  };
  // USER FORMS ENDS
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation, id } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={navigation} id={id}>
        <ThemeProvider theme={themeUser}>
          <Box className="mainContainerWrapper">
            <Box display="flex" justifyContent="space-between" alignItems="center" >
              <Box>
                <Box display="flex" gridGap="10px" alignItems="center">
                  <Typography
                    style={{
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "48px",
                      color: "#1C2324",
                    }}
                  >
                    Users
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  style={webStyle.addUserModalBtnStyle}
                  onClick={() => this.showUserModal()}
                  data-testid="addUserModalBtn"
                >
                  <img src={AddUserPlusIconSVG} alt="+" style={{ marginRight: "8px" }} />Add User
                </Button>
              </Box>
            </Box>

            <Box mt={4.5}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Link style={{textDecoration:"none",color:"#1C2324"}} to="/users-farm-diary" >
                    <Box sx={webStyle.maincard}>
                      <Grid container spacing={4}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={3} md={3} className="centerDivContentTotal" >
                          <img src={imgIconlist} className="customImgFarmDiary" />
                        </Grid>
                        <Grid item xs={5} md={5} className="centerDivContent" style={{ padding: "20px" }} >
                          <Box className="centerContentTxt">
                            <Typography className="customContentCenter">
                              Farm Diary Entries
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} style={{ padding: "39px" }}>
                          <Box sx={webStyle.font}>
                            <Typography className="cottonAreaTxt">
                              {this.state.totalFarmDiary}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Link>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Link style={{textDecoration:"none",color:"#1C2324"}} to="/users-farm-surveys" >
                    <Box sx={webStyle.maincard}>
                      <Grid container spacing={4}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={3} md={3} className="centerDivContentTotal" >
                          <img src={imgIconGraph} className="customImgFilledSurveys" />
                        </Grid>
                        <Grid item xs={5} md={5} className="centerDivContent" style={{ padding: "20px" }} >
                          <Box className="centerContentTxt">
                            <Typography className="customContentCenter">
                              Total Filled Surveys
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} style={{ padding: "39px" }}>
                          <Box sx={webStyle.font}>
                            <Typography className="cottonAreaTxt">
                              {this.state.totalSurveyCount}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Link>
                </Grid>
              </Grid>
              {/* 3 Sub Card */}
              <Grid
                container
                direction="row"
                spacing={3}
                style={{ marginTop: "4px" }}
              >
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Box className="maincard">
                    <Box className="subMaincard">
                      <Box className="setContentLine">
                        <Box className="leftSubdiv">
                          <Typography className="subTitleCard">
                            Total number of
                          </Typography>
                          <Typography className="mainTitleCard">
                            Farmers
                          </Typography>
                        </Box>
                        <Box className="fontBlue">
                          <Typography className="cottonAreaTxt">
                            {this.checkNumber(this.state.totalFarmers)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Box className="maincard">
                    <Box className="subMaincard">
                      <Box className="setContentLine">
                        <Box className="leftSubdiv">
                          <Typography className="subTitleCard">
                            Total number of
                          </Typography>
                          <Typography className="mainTitleCard">
                            Field Executives
                          </Typography>
                        </Box>
                        <Box className="fontBlue">
                          <Typography className="cottonAreaTxt">
                            {this.checkNumber(this.state.totalFieldExecutives)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Box className="maincard">
                    <Box className="subMaincard">
                      <Box className="setContentLine">
                        <Box className="leftSubdiv">
                          <Typography className="subTitleCard">
                            Total area of
                          </Typography>
                          <Typography className="mainTitleCard">
                            Cotton
                          </Typography>
                        </Box>
                        <Box className="fontBlue">
                          <Typography className="cottonAreaTxt">
                            {totalAreaCottonValue(this.state.totalAreaOfCotton)}                            
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box mt={5} sx={webStyle.tableWrapper}>
              <Box mb={2} sx={webStyle.tableFilterWrapper}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                >
                  <Grid item lg={4} md={5} sm={6}>
                    <FormControl>
                      <Select
                        className="userSelectionMenu"
                        value={this.state.selectedUserTable}
                        onChange={(e: any) => this.setUserTypeTable(e.target.value)}
                        data-testid="tableSelectionMenuTestid"
                        disableUnderline
                        inputProps={{ disableUnderline: true }}
                      >
                        <MenuItem value={"farmer"}>{configJSON.AdminConsoleDefaults.LIST_FARMER}</MenuItem>
                        <MenuItem value={"fieldExecutive"}>{configJSON.AdminConsoleDefaults.LIST_FE}</MenuItem>
                        <MenuItem value={"admin"}>{configJSON.AdminConsoleDefaults.LIST_ADMIN}</MenuItem>
                        <MenuItem value={"SuperAdmin"}>{configJSON.AdminConsoleDefaults.LIST_SUPER_ADMIN}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={8} md={7} sm={12} style={{ paddingRight: "0px" }} >
                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      <Box style={{
                        padding: "7px",
                        background: "#FCFAF7",
                        borderRadius: "28px",
                        border: "2px solid #F0EBE0",
                        width: "100%",
                      }}
                      >
                        <Input
                          id="searchTableId"
                          disableUnderline
                          autoComplete="off"
                          fullWidth
                          className="tableSearchInputfilter"
                          data-testid="tableSearchTestid"
                          placeholder="Search by name"
                          type="search"
                          name="searchField"
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon
                                style={{ color: "#1C2324", opacity: 0.48 }}
                              />
                            </InputAdornment>
                          }
                          value={this.state.searchFilterValue}
                          onChange={(e: any) => {
                            this.setState(
                              {
                                searchFilterValue: e.target.value,
                                farmerTableCurrentPage: 0,
                                FeTableCurrentPage: 0,
                                adminTableCurrentPage: 0,
                              },
                              () => {
                                this.debouncedSearchTable();
                              }
                            );
                          }}
                        />
                      </Box>
                      <Box>
                        <IconButton
                          data-testid="tableFilterIconBtnTestid"
                          onClick={(event) => {
                            this.setState({ openTableFilterUT: true, anchorEl: event.currentTarget });
                          }}
                          disabled={this.state.selectedUserTable === "admin" || this.state.selectedUserTable === "SuperAdmin"}
                        >
                          <Badge
                            variant="dot"
                            color="primary"
                            className="filterApplied"
                            invisible={this.state.hideBadgeUT}
                          >
                            <img src={imgIconFilter} />
                          </Badge>
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box className="tableGirdDiv" sx={webStyle.mainTable}>
                {this.showFarmerListTable()}
                {this.showFEListTable()}
                {this.showAdminListTable()}
                {this.showSuperAdminListTable()}
              </Box>
            </Box>

            {/* ADD-EDIT USER MODAL START */}
            <Dialog
              aria-labelledby="customized-dialog-title"
              open={this.state.userModal}
              maxWidth={"md"}
              PaperProps={{
                style: { borderRadius: 12, width: "712px" },
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box style={{ padding: "32px 32px 19px 32px" }}>
                  <Typography variant="subtitle1">
                    {this.getAddUserModalTitleTxt()}
                  </Typography>
                </Box>
                <div style={{ marginRight: 20 }}>
                  <IconButton
                    aria-label="close"
                    data-testid="modalCloseBtnTestid"
                    onClick={this.handleClose}
                  >
                    <CloseIcon style={{color:"#1c2324"}}/>
                  </IconButton>
                </div>
              </div>
              <DialogContent dividers className="modalBodyWrapper">
                <Box className="userFormBodyWrapper">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="formfieldWrapper"
                    >
                      <FormLabel className="customLabelField">
                        User Type
                      </FormLabel>
                      <FormControl fullWidth className="userTypeFormControl">
                        <Select
                          value={this.state.seletedUserTypeForm}
                          variant="outlined"
                          className="farmerFormDDClass"
                          color="secondary"
                          data-testid="createUserModalDropdown"
                          onChange={(e: any) =>
                            this.setUserTypeForm(e.target.value)
                          }
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          readOnly={this.editModalStatus()}
                          disabled={this.editModalStatus()}
                        >
                          <MenuItem value={"Farmers"}>Farmer</MenuItem>
                          <MenuItem value={"FieldExecutive"}>Field Executive</MenuItem>
                          <MenuItem value={"Admin"}>Admin</MenuItem>
                          {!this.state.isLoggedUserAdmin ? (<MenuItem value={"SuperAdmins"}>Super Admin</MenuItem>) : (<></>)}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {this.renderUserForm()}                  
                </Box>
              </DialogContent>
            </Dialog>
            {/* ADD-EDIT USER MODAL END */}

            {/* FILTER-ASSIGN VILLAGE STARTS */}
            <AssignVillageFilter
              data-testid={'assignVillageFilterUserDashboard'}
              isVillageFilterOpen={this.state.isVillageFilterOpen}
              anchorElVillageFilter={this.state.anchorElVillageFilter}
              closeVillageFilter={() => this.closeVillageFilter()}
              isAccordianError={this.state.isAccordianError}
              isDisabled={this.state.isDisabled}
              expanded={this.state.expanded}
              handleChangePanelState={this.handleChangePanel("panel1")}
              handleChangePanelDistrict={this.handleChangePanel("panel2")}
              handleChangePanelTaluka={this.handleChangePanel("panel3")}
              handleChangePanelVillage={this.handleChangePanel("panel4")}
              selectedState={this.state.selectedState}
              stateInputValue={this.state.stateInputValue}
              handleStateSearch={this.handleStateSearch}
              filteredStates={this.state.filteredStates}
              handleChangeStateField={this.handleChangeStateField}
              selectedDistrict={this.state.selectedDistrict}
              districtInputValue={this.state.districtInputValue}
              handleDistrictSearch={this.handleDistrictSearch}
              filteredDistricts={this.state.filteredDistricts}
              handleChangeDistrictField={this.handleChangeDistrictField}
              selectedTaluka={this.state.selectedTaluka}
              talukaInputValue={this.state.talukaInputValue}
              handleTalukaSearch={this.handleTalukaSearch}
              filteredTalukas={this.state.filteredTalukas}
              handleChangeTalukaField={this.handleChangeTalukaField}
              selectedVillage={this.state.selectedVillage}
              villageInputValue={this.state.villageInputValue}
              handleVillageSearch={this.handleVillageSearch}
              filteredVillages={this.state.filteredVillages}
              handleChangeVillageField={this.handleChangeVillageField}
              handleSubmitAccordion={() => this.handleSubmitAccordion()}
              handleVillageFilterClear={() => this.handleVillageFilterClear()}
              opacityStateCalculator={this.opacityStateCalculator}
              opacityDistrictCalculator={this.opacityDistrictCalculator}
              opacityTalukaCalculator={this.opacityTalukaCalculator}
              opacityVillageCalculator={this.opacityVillageCalculator}
            />
            {/* FILTER-ASSIGN VILLAGE ENDS */}


            <UserTableFilter
              data-testid={'userTableFilterUserDashboard'}
              openTableFilter={this.state.openTableFilterUT}
              anchorEl={this.state.anchorEl}
              HideFEFilter={this.state.HideFEFilter}
              handleCloseFilter={this.handleCloseTableFilterPopupUT}
              handleVillageFilterClear={this.handleVillageFilterClearUT}
              handleSubmitFilter={this.handleSubmitFilterUT}
              expanded={this.state.expandedUT}
              handleChangePanelState={this.handleChangePanelUT("panel1")}
              handleChangePanelDistrict={this.handleChangePanelUT("panel2")}
              handleChangePanelTaluka={this.handleChangePanelUT("panel3")}
              handleChangePanelVillage={this.handleChangePanelUT("panel4")}
              handleChangeAtoZ={this.handleChangeAtoZUT}
              handleChangeZtoA={this.handleChangeZtoAUT}
              isDisabled={this.state.isDisabledUT}
              sortAscending={this.state.sortAscendingUT}
              sortDescending={this.state.sortDescendingUT}
              filteredStates={this.state.filteredStatesUT}
              filteredDistricts={this.state.filteredDistrictsUT}
              filteredTalukas={this.state.filteredTalukasUT}
              filteredVillages={this.state.filteredVillagesUT}
              selectedState={this.state.selectedStateUT}
              selectedDistrict={this.state.selectedDistrictUT}
              selectedTaluka={this.state.selectedTalukaUT}
              selectedVillage={this.state.selectedVillageUT}
              stateInputValue={this.state.stateInputValueUT}
              districtInputValue={this.state.districtInputValueUT}
              talukaInputValue={this.state.talukaInputValueUT}
              villageInputValue={this.state.villageInputValueUT}
              handleChangeStateField={this.handleChangeStateFieldUT}
              handleChangeDistrictField={this.handleChangeDistrictFieldUT}
              handleChangeTalukaField={this.handleChangeTalukaFieldUT}
              handleChangeVillageField={this.handleChangeVillageFieldUT}
              handleStateSearch={this.handleStateSearchUT}
              handleDistrictSearch={this.handleDistrictSearchUT}
              handleTalukaSearch={this.handleTalukaSearchUT}
              handleVillageSearch={this.handleVillageSearchUT}
              opacityStateCalculator={this.opacityStateCalculatorUT}
              opacityDistrictCalculator={this.opacityDistrictCalculatorUT}
              opacityTalukaCalculator={this.opacityTalukaCalculatorUT}
              opacityVillageCalculator={this.opacityVillageCalculatorUT}
              handleChangePanelFE={this.handleChangePanelUT("panel5")}
              filteredFieldExecutive={this.state.filteredFieldExecutiveUT}
              selectedFieldExecutive={this.state.selectedFieldExecutiveUT}
              fieldExecutiveInputValue={this.state.fieldExecutiveInputValueUT}
              handleChangeFieldExecutiveField={this.handleChangeFieldExecutiveFieldUT}
              handleFieldExectiveSearch={this.handleFieldExectiveSearchUT}
              opacityFieldExecutiveCalculator={this.opacityFieldExecutiveCalculatorUT}
              filterHasError={this.state.filterHasErrorUT}
            />


            {/* DELETE-ALERT-BOX START */}
            <CustomModal
              open={this.state.openDeleteModal}
              title={configJSON.AdminConsoleDefaults.deleteUserTitle}
              description={
                configJSON.AdminConsoleDefaults.deleteUserDescription
              }
              confirmHandler={this.deleteUser}
              disableConfirmHandler={this.state.deleteFarmerFEUserLoading}
              confirmTitle={this.state.deleteFarmerFEUserLoading ? "Deleting..." : "Delete"}
              onClose={this.handleClose}
            />
            <CustomModal
              open={this.state.openDeleteModalAdmin}
              title={configJSON.AdminConsoleDefaults.deleteUserTitle}
              description={
                configJSON.AdminConsoleDefaults.deleteUserDescription
              }
              confirmHandler={this.deleteAdminUsers}
              disableConfirmHandler={this.state.deleteAdminSAUserLoading}
              confirmTitle={this.state.deleteAdminSAUserLoading ? "Deleting..." : "Delete"}
              onClose={this.handleClose}
            />
            {/* DELETE-ALERT-BOX END */}

            {/* ERROR-MODAL STARTS */}
            <ErrorModal
              open={this.state.errorModalOpen}
              errorTitle="Error"
              errorSubTitle={this.state.errorModalMsg}
              BtnTitle="Okay"
              handleBtnClick={this.handleErrorModalClose}
            />
            {/* ERROR-MODAL ENDS */}
            {/* CROP-MAPPING MODAL STARTS */}
            <Dialog
              open={this.state.OpenFarmMapping}
              aria-labelledby="customized-dialog-title"
              PaperProps={{
                style: { borderRadius: 12, width: "712px" },
              }}
              maxWidth={"md"}
            >
              <DialogTitle>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={() => {
                    this.setState({ OpenFarmMapping: false, })
                  }}
                    data-testid="backFarmMappingbtn"
                    style={{
                      padding: 0,
                      marginRight: "16px"
                    }}
                  >
                    <img src={BackArrowIconSVG} />
                  </IconButton>
                  <Typography variant="subtitle1">
                    {this.state.openEditUserModal ? "Edit Farm Mapping" : "Add Farm Mapping"}
                  </Typography>
                </Box>
              </DialogTitle>
              <DialogContent style={{ padding: 0 }}>
                <FarmMapping
                  onSave={this.onSaveFarmMapping}
                  CoordinatesArr={this.state.coordinates ? this.state.coordinates : this.state.tempCoordinates}
                  userLocation={this.state.userLocation}
                  OpenFarmMapping={this.state.OpenFarmMapping}
                  markerDefault={!this.state.coordinates.length ? this.state.userLocation : this.state.markerDefault}
                  isEditModal={this.state.openEditUserModal}
                  closeFarmMapping={this.closeFarmMappingModal}
                />
              </DialogContent>
            </Dialog>
            {/* CROP-MAPPING MODAL ENDS */}
            {/* SUCCESS-MODAL STARTS */}
            <ErrorModal
              open={this.state.successModalOpen}
              errorTitle="Success"
              errorSubTitle={this.state.successModalMsg}
              BtnTitle="Okay"
              handleBtnClick={this.handleErrorModalClose}
            />
            {/* ERROR-MODAL ENDS */}
          </Box>
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  center: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    alignItem: "center",
  },
  contentCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  tableWrapper: {
    background: "#F8F6F1",
    border: "2px",
    borderRadius: "12px",
    marginTop: "24px"
  },
  tableFilterWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "25px",
  },
  mainTable: {
    height: "583px",
  },
  maincard: {
    padding: "12px 15px",
    background: "#F8F6F1",
    border: "2px solid #F0EBE0",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageIcons: {
    width: "107px",
    height: "96px",
  },
  font: {
    position: "relative",
    "&::after": {
      content: "' '",
      width: "2px",
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "-10px",
      background:
        "linear-gradient(180deg, rgba(235, 230, 215, 0.29) 0%, #EBE6D7 49.79%, rgba(235, 230, 215, 0.31) 100%)",
    },
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  customInputField: {
    margin: "8px 0 0 0",
  },
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  },
  hide: {
    display: "none",
  },
  photoCamera: {
    width: 60,
    height: 60,
    color: "#1C2324",
    opacity: 0.5,
  },
  badgeCardRoot: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    minHeight: "150",
    padding: "0",
    marginTop: "8px",
    marginBottom: "20px",
  },
  badgeButton: {
    backgroundColor: "rgba(28, 35, 36, .56)",
    padding: "0.375rem",
  },
  addUserModalBtnStyle: {
    height:"56px",
    width: "185px",
    borderRadius: "28px",
    fontWeight: 700,
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    background: "#DF5200",
    fontSize: "18px",
    color: "#fff",
    padding: "14px 32px",
    boxShadow: "none",
  }
};
// Customizable Area End
