import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
import {
  debounce,
  SchemaAdminFormADD,
  SchemaAdminFormEDIT,
} from "../../../components/src/helper";
import { DropDownType } from "../../../components/src/AdminConsoleHelper";
import { IrequestBodyUserStatus, IresponseUpdateUserStatus, IuserStatusData } from "../../../components/src/interface.web";

// Customizable Area End

export const configJSON = require("./config");
const userLocation = localStorage.getItem('userLocationFarmMapping');
export interface Props {
  navigation: any;
  id: string;
  location?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Native file States
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  TokenValue: null | string;
  loggedInUserRole: string | null;
  isLoggedUserAdmin: boolean;

  // TOTAL BOX INTERFACE
  totalFarmDiary: any;
  totalSurveyCount: number;
  totalFarmers: any;
  totalFieldExecutives: any;
  totalAdmins: any;
  totalAreaOfCotton: any;
  totalAreaCotton: any;

  // USER TABLE FARMER
  farmerData: any[];
  farmerDataLoading: boolean;
  farmerPageSize: number;
  farmerTableCurrentPage: number;
  // USER TABLE FE
  FeData: any[];
  FeLoading: boolean;
  FePageSize: number;
  FeTableCurrentPage: number;
  // USER TABLE ADMIN
  adminData: any[];
  adminLoading: boolean;
  adminPageSize: number;
  adminTableCurrentPage: number;
  // USER TABLE SUPER-ADMIN
  superAdminData: any[];
  superAdminLoading: boolean;
  superAdminPageSize: number;
  superAdminTableCurrentPage: number;

  successAddSuperAdmin: any;
  successUpdateSuperAdmin: any;
  superAdminRowData: any;

  searchFilterValue: string;

  // FARMER FORM API DATA INTERFACE
  getNumberBeongsToData: DropDownType[];
  getEducationFarmerData: any;
  getStateData: any[];
  getDistrictData: any[];
  getTalukaData: any[];
  getVillageData: any[];
  getFEData: any[];
  getMobileDeviceModelFEData: any;


  // ADD USER MOCAL INTERFACE
  userModal: boolean;
  selectedUserTable: string;
  seletedUserTypeForm: string;
  selectedDate: any;

  openEditUserModal: boolean;
  adminRowData: any;
  adminInitialValues: any;
  feRowData: any;
  feInitialValues: any;
  selectedLocationArr: any[];
  farmerRowData: any;

  successAddFarmer: any;
  successUpdateFarmer: any;

  successAddFE: any;
  successUpdateFE: any;

  successAddAdmin: any;
  successUpdateAdmin: any;

  farmerInitialValues: any;
  // USER STATUS
  userStatusData: IuserStatusData | null;

  totalSearchedCount: any;
  openDeleteModal: boolean;

  FElistData: any[];
  AllVillageListData: any[];
  selectedFEformData: any;
  showEyeIconPassword: boolean;
  showEyeIconConfirmPassword: boolean;
  farmerFormErrors: any;
  disableStateFarmer: boolean;

  // DELETE MODAL INTERFACE
  deleteUserData: any;
  deleteUserLoading: boolean;
  deleteAdminUserData: any;
  openDeleteModalAdmin: boolean;
  // ERROR MODAL INTERFACE
  errorModalOpen: boolean;
  errorModalMsg: string;
  // VILLAGE FILTER INTERFACE STARTS
  isVillageFilterOpen: boolean;
  anchorElVillageFilter: any;
  expanded: any;
  selectedState: any;
  selectedDistrict: any;
  selectedTaluka: any;
  selectedVillage: any;
  initialStates: any[];
  initalDistricts: any[];
  initialTalukas: any[];
  initialVillages: any[];
  filteredStates: any[];
  filteredDistricts: any[];
  filteredTalukas: any[];
  filteredVillages: any[];
  stateInputValue: string;
  districtInputValue: string;
  talukaInputValue: string;
  villageInputValue: string;
  isDisabled: any;
  isAccordianError: any;
  deletedVillagesList: any[];
  defaultAssignedVillages: any[];
  // TABLE FILTER INTERFACE
  anchorEl: any;
  openTableFilterUT: boolean;
  HideFEFilter: boolean;
  expandedUT: any;
  savedSortAscendingUT: boolean,
  savedSortDescendingUT: boolean,
  savedIsDisabledUT: any,
  savedStateUT: any,
  savedDistrictUT: any,
  savedTalukaUT: any,
  savedVillageUT: any,
  savedFieldExecutiveUT: any,
  sortAscendingUT: boolean,
  sortDescendingUT: boolean,
  initialStatesUT: any[],
  initalDistrictsUT: any[],
  initialTalukasUT: any[],
  initialVillagesUT: any[],
  initialFieldExectiveUT: any[],
  filteredStatesUT: any[],
  filteredDistrictsUT: any[],
  filteredTalukasUT: any[],
  filteredVillagesUT: any[],
  filteredFieldExecutiveUT: any[],
  selectedStateUT:
  {
    name: "Select State",
    id: "",
  },
  selectedDistrictUT: {
    name: "Select District",
    id: "",
  },
  selectedTalukaUT: {
    name: "Select Taluka",
    id: "",
  },
  selectedVillageUT: {
    name: "Select Village",
    id: "",
  },
  selectedFieldExecutiveUT: {
    user_name: "Select Field Executive",
    first_name: "Select",
    last_name: "Field Executive",
    id: ""
  },
  stateInputValueUT: "",
  districtInputValueUT: "",
  talukaInputValueUT: "",
  villageInputValueUT: "",
  fieldExecutiveInputValueUT: "",
  isDisabledUT: {
    state: boolean,
    district: boolean,
    taluka: boolean,
    village: boolean,
    fe: boolean,
  },
  filterHasErrorUT: boolean
  hideBadgeUT: boolean
  // CROP-MAPPING
  OpenFarmMapping: boolean
  latlngHash: any
  farmMappingURL: any;
  coordinates: any[];
  markerDefault: any;
  farmMappingFile: any
  tempCoordinates:any[]
  // SUCCESS MODAL INTERFACE
  successModalOpen: boolean;
  successModalMsg: string;
  // LOADING BTN INTERFACE
  addFarmerLoading: boolean;
  updateFarmerLoading: boolean;
  addFELoading: boolean;
  updateFELoading: boolean;
  addAdminLoading: boolean;
  updateAdminLoading: boolean;
  addSALoading: boolean;
  updateSALoading: boolean;
  deleteFarmerFEUserLoading:boolean;
  deleteAdminSAUserLoading:boolean;  
  userLocation: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFarmerApiCallId: string = "";
  getFeApiCallId: string = "";
  getAdminApiCallId: string = "";
  getSuperAdminApiCallId: string = "";
  addSuperAdminApiCallId: string = "";
  updateSuperAdminApiCallId: string = "";
  addAdminApiCallId: string = "";
  updateAdminApiCallId: string = "";
  addFEApiCallId: string = "";
  updateFEApiCallId: string = "";
  addFarmerApiCallId: string = "";
  updateFarmerApiCallId: string = "";
  getNumberBeongsToApiCallId: string = "";
  getEducationFarmerApiCallId: string = "";
  getStateApiCallId: string = "";
  getDistrictApiCallId: string = "";
  getTalukaApiCallId: string = "";
  getVillageApiCallId: string = "";
  getMobileDeviceModelFEApiCallId: string = "";
  getFElistApiCallId: string = "";
  getAllVillageListApiCallId: string = "";
  deleteUserApiCallId: string = "";
  deleteAdminUserApiCallId: string = "";
  // ASSIGN VILLAGE FILTER STARTS
  getVillageFilterStateData: string = "";
  getVillageFilterDistrictData: string = "";
  getVillageFilterTalukaData: string = "";
  getVillageFilterVillageData: string = "";
  // USER TABLE FILTER STARTS
  getFieldExecutiveDataApiUTCallId: string = "";
  getVillageDataApiUTCallId: string = "";
  getTalukaDataApiUTCallId: string = "";
  getDistrictDataApiUTCallId: string = "";
  getStateDataApiUTCallId: string = "";
  // NEW
  userStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // Native File States
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      TokenValue: localStorage.getItem("tokenn"),
      loggedInUserRole: localStorage.getItem("user_role"),
      isLoggedUserAdmin: true,
      userModal: false,
      selectedUserTable: "farmer",
      seletedUserTypeForm: "Farmers",
      selectedDate: new Date(),

      // FARMER USER TABLE
      farmerData: [],
      farmerDataLoading: true,
      farmerPageSize: 15,
      farmerTableCurrentPage: 0,
      totalFarmers: 0,
      // FE USER TABLE
      FeData: [],
      FeLoading: true,
      FePageSize: 15,
      FeTableCurrentPage: 0,
      totalFieldExecutives: "",
      // ADMIN USER TABLE
      adminData: [],
      adminLoading: true,
      adminPageSize: 15,
      adminTableCurrentPage: 0,
      totalSurveyCount: 0,
      totalAreaOfCotton: "",
      totalAdmins: "",
      // SUPER-ADMIN USER TABLE
      superAdminData: [],
      superAdminLoading: true,
      superAdminPageSize: 15,
      superAdminTableCurrentPage: 0,

      successAddSuperAdmin: null,
      successUpdateSuperAdmin: null,
      superAdminRowData: {},

      deleteAdminUserData: null,
      openDeleteModalAdmin: false,

      totalAreaCotton: "",
      totalFarmDiary: "",
      searchFilterValue: "",

      openEditUserModal: false,
      adminRowData: {},
      adminInitialValues: {
        firstnameAdmin: "",
        lastnameAdmin: "",
        mobileNumberAdmin: "",
        emailAddressAdmin: "",
        usernameAdmin: "",
        dateOfBirthAdmin: null,
        passwordAdmin: null,
        confirmPasswordAdmin: null,
      },
      feRowData: {},
      feInitialValues: {
        firstnameFE: "",
        lastnameFE: "",
        mobileNumberFE: "",
        locationFE: "",
        dateOfBirthFE: null,
        emailAddressFE: "",
        usernameFE: "",
        mobileDeviceModelFE: "",
      },
      selectedLocationArr: [],
      farmerRowData: {},
      // USER STATUS
      userStatusData: null,

      successAddFarmer: {},
      successUpdateFarmer: {},
      successAddFE: {},
      successUpdateFE: {},
      successAddAdmin: {},
      successUpdateAdmin: {},
      farmerInitialValues: {
        firstnameFarmer: "",
        middlenameFarmer: "",
        surnameFarmer: "",
        mobileNumberFarmer: "",
        adharcardNumberFarmer: "",
        dateOfBirthFarmer: null,
        totalFamilyMemberFarmer: "",
        educationFarmer: "",
        stateFarmer: "",
        genderFarmer: "male",
        districtFarmer: "",
        talukaFarmer: "",
        villageFarmer: "",
        // NEW STATES
        numberBelongsToFarmer: "",
        mobileTypeFarmer: "",
      },
      getNumberBeongsToData: [],
      getEducationFarmerData: [],
      getStateData: [],
      getDistrictData: [],
      getTalukaData: [],
      getVillageData: [],
      getFEData: [],

      getMobileDeviceModelFEData: [],
      totalSearchedCount: 0,
      openDeleteModal: false,
      deleteUserData: null,
      deleteUserLoading: false,
      FElistData: [],
      AllVillageListData: [],
      selectedFEformData: null,
      showEyeIconPassword: true,
      showEyeIconConfirmPassword: true,
      errorModalOpen: false,
      errorModalMsg: "",
      farmerFormErrors: {},
      disableStateFarmer: false,
      isVillageFilterOpen: false,
      anchorElVillageFilter: null,
      // Village Filter states Starts
      expanded: false,
      selectedState: {
        name: "Select State",
        id: "",
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      initialStates: [],
      initalDistricts: [],
      initialTalukas: [],
      initialVillages: [],
      filteredStates: [],
      filteredDistricts: [],
      filteredTalukas: [],
      filteredVillages: [],
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      isDisabled: {
        state: false,
        district: true,
        taluka: true,
        village: true,
      },
      isAccordianError: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
      deletedVillagesList: [],
      defaultAssignedVillages: [],
      // Village Filter states Ends

      // USER TABLE FILTER STARTS
      anchorEl: null,
      openTableFilterUT: false,
      expandedUT: false,

      HideFEFilter: false,

      savedSortAscendingUT: false,
      savedSortDescendingUT: false,
      savedIsDisabledUT: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
      },
      savedStateUT:
      {
        name: "Select State",
        id: "",
      },
      savedDistrictUT: {
        name: "Select District",
        id: "",
      },
      savedTalukaUT: {
        name: "Select Taluka",
        id: "",
      },
      savedVillageUT: {
        name: "Select Village",
        id: "",
      },
      savedFieldExecutiveUT: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: ""
      },
      sortAscendingUT: false,
      sortDescendingUT: false,

      initialStatesUT: [],
      initalDistrictsUT: [],
      initialTalukasUT: [],
      initialVillagesUT: [],
      initialFieldExectiveUT: [],

      filteredStatesUT: [],
      filteredDistrictsUT: [],
      filteredTalukasUT: [],
      filteredVillagesUT: [],
      filteredFieldExecutiveUT: [],

      selectedStateUT:
      {
        name: "Select State",
        id: "",
      },
      selectedDistrictUT: {
        name: "Select District",
        id: "",
      },
      selectedTalukaUT: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageUT: {
        name: "Select Village",
        id: "",
      },
      selectedFieldExecutiveUT: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: ""
      },

      stateInputValueUT: "",
      districtInputValueUT: "",
      talukaInputValueUT: "",
      villageInputValueUT: "",
      fieldExecutiveInputValueUT: "",

      isDisabledUT: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
      },
      filterHasErrorUT: false,
      hideBadgeUT: true,
      // USER TABLE FILTER ENDS
      // CROP-MAPPING
      OpenFarmMapping: false,
      latlngHash: null,
      farmMappingURL: null,
      coordinates: [],
      markerDefault: null,
      farmMappingFile: null,
      tempCoordinates:[],
      // SUCCESS MODAL INTERFACE
      successModalOpen: false,
      successModalMsg: "",
      // LOADING BTN INTERFACE
      addFarmerLoading: false,
      updateFarmerLoading: false,
      addFELoading: false,
      updateFELoading: false,
      addAdminLoading: false,
      updateAdminLoading: false,
      addSALoading: false,
      updateSALoading: false,
      deleteFarmerFEUserLoading:false,
      deleteAdminSAUserLoading:false,      
      userLocation: userLocation ? JSON.parse(userLocation) : { lat: 22.7, lng: 75.8 },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.setUsersAPIResponses(apiRequestCallId, responseJson);
        this.setFilterAPIResponses(apiRequestCallId, responseJson);
        this.setUserTableFilterAPIResponses(apiRequestCallId, responseJson);

        switch (apiRequestCallId) {
          case this.getNumberBeongsToApiCallId:
            this.getNumberBeongsToApiCallIdResp(responseJson);
            break;
          case this.getEducationFarmerApiCallId:
            this.getEducationFarmerApiCallIdResp(responseJson);
            break;
          case this.getStateApiCallId:
            this.getStateApiCallIdResp(responseJson);
            break;
          case this.getDistrictApiCallId:
            this.getDistrictApiCallIdResp(responseJson);
            break;
          case this.getTalukaApiCallId:
            this.getTalukaApiCallIdResp(responseJson);
            break;
          case this.getVillageApiCallId:
            this.getVillageApiCallIdResp(responseJson);
            break;
          case this.getFElistApiCallId:
            this.getFElistApiCallIdResp(responseJson);
            break;
          case this.getMobileDeviceModelFEApiCallId:
            this.getMobileDeviceModelFEApiCallIdResp(responseJson);
            break;
          case this.getAllVillageListApiCallId:
            this.getAllVillageListApiCallIdResp(responseJson);
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  // -------------------------------------------------- RESPONSE FUNCTIONS --------------------------------------------------

  // USER CRUD RESPONSE
  setUsersAPIResponses = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      // GET USERS TABLE STARTS
      case this.getFarmerApiCallId:
        this.getFarmerApiCallIdResp(responseJson);
        break;
      case this.getFeApiCallId:
        this.getFeApiCallIdResp(responseJson);
        break;
      case this.getAdminApiCallId:
        this.getAdminApiCallIdResp(responseJson);
        break;
      case this.getSuperAdminApiCallId:
        this.getSuperAdminApiCallIdResp(responseJson);
        break;
      // GET USERS TABLE ENDS
      case this.addFarmerApiCallId:
        this.addFarmerApiCallIdResp(responseJson);
        break;
      case this.updateFarmerApiCallId:
        this.updateFarmerApiCallIdResp(responseJson);
        break;
      case this.userStatusApiCallId:
        this.userStatusApiCallIdResp(responseJson);
        break;
      case this.addFEApiCallId:
        this.addFEApiCallIdResp(responseJson);
        break;
      case this.updateFEApiCallId:
        this.updateFEApiCallIdResp(responseJson);
        break;
      case this.addAdminApiCallId:
        this.addAdminApiCallIdResp(responseJson);
        break;
      case this.updateAdminApiCallId:
        this.updateAdminApiCallIdResp(responseJson);
        break;
      case this.addSuperAdminApiCallId:
        this.addSuperAdminApiCallIdResp(responseJson);
        break;
      case this.updateSuperAdminApiCallId:
        this.updateSuperAdminApiCallIdResp(responseJson);
        break;
      case this.deleteUserApiCallId:
        this.deleteUserApiCallIdResp(responseJson);
        break;
      case this.deleteAdminUserApiCallId:
        this.deleteAdminUserApiCallIdResp(responseJson);
        break;
    }
  };
  getFarmerApiCallIdResp = (responseJson: any) => {
    if (responseJson?.data && responseJson?.meta) {
      const isFilterNotApplied: boolean = this.checkFilterErrorUT()
      if (isFilterNotApplied) {
        this.setState({
          hideBadgeUT: true
        })
      } else {
        this.setState({
          hideBadgeUT: false
        })
      }

      this.setState({
        farmerDataLoading: false,
        farmerData: responseJson.data,
        totalFarmers: responseJson?.meta?.farmers_count,
        totalFieldExecutives: responseJson?.meta?.fe_count,
        totalAdmins: responseJson?.meta?.admins_count,
        totalAreaCotton: responseJson?.meta?.total_area_of_cotton,
        totalFarmDiary: responseJson?.meta?.farm_Dairy_entries,
        totalSearchedCount: responseJson?.meta?.total_searched_count,
        totalSurveyCount: responseJson?.meta?.total_filled_survey || 0,
        totalAreaOfCotton: responseJson?.meta?.total_crop_area_of_cotton,
        openTableFilterUT: false,
      }, () => {
        this.handleCloseTableFilterPopupUT()
      });
    } else {
      this.setState({
        farmerDataLoading: false,
        farmerData: [],
        totalFarmers: 0,
        totalSearchedCount: 0,
      }, () => {
        this.handleCloseTableFilterPopupUT()
      });
    }
  };
  getFeApiCallIdResp = (responseJson: any) => {
    if (responseJson?.data) {
      const isFilterNotApplied: boolean = this.checkFilterErrorUT()
      if (isFilterNotApplied) {
        this.setState({
          hideBadgeUT: true
        })
      } else {
        this.setState({
          hideBadgeUT: false
        })
      }
      this.setState({
        FeLoading: false,
        FeData: responseJson.data,
        totalSearchedCount: responseJson?.meta?.total_searched_count,
        openTableFilterUT: false,
      }, () => {
        this.handleCloseTableFilterPopupUT()
      });
    } else {
      this.setState({ FeLoading: false, FeData: [] });
    }
  };
  getAdminApiCallIdResp = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        adminLoading: false,
        adminData: responseJson.data,
        totalSearchedCount: responseJson?.meta?.total_searched_count,
        openTableFilterUT: false,
      });
    } else {
      this.setState({ adminLoading: false, adminData: [] });
    }
  };
  getSuperAdminApiCallIdResp = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        superAdminLoading: false,
        superAdminData: responseJson.data,
        totalSearchedCount: responseJson?.meta?.total_searched_count,
        openTableFilterUT: false,
      });
    } else {
      this.setState({ superAdminLoading: false, superAdminData: [] });
    }
  };
  addFarmerApiCallIdResp = (responseJson: any) => {
    if (!responseJson.errors?.length) {
      this.setState({
        successAddFarmer: responseJson.data,
        userModal: false,
        successModalOpen:true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_FARMER
      });
      this.setState({ addFarmerLoading: false });
    } else if (responseJson?.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ addFarmerLoading: false });        
      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ addFarmerLoading: false });
      }
    
  };
  updateFarmerApiCallIdResp = (responseJson: any) => {
    if (!responseJson.errors?.length) {
      this.setState({    
        successUpdateFarmer: responseJson.data,
        selectedLocationArr: [],
        deletedVillagesList: [],
        defaultAssignedVillages: [],
        successModalOpen:true,
        successModalMsg: configJSON.SUCCESSMSG.UPDATE_FARMER
      });
      this.handleClose();
      this.setState({ updateFarmerLoading: false })             
    } else if (responseJson?.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ updateFarmerLoading: false });
      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ updateFarmerLoading: false });
      }
    
  };

  userStatusApiCallIdResp = (responseJson: IresponseUpdateUserStatus | undefined) => {
    if(responseJson && responseJson.data){
      const newStatus = !!responseJson.data.attributes.active
      this.handleSuccessUserStatus(newStatus)
    }else{
      this.setState({
        errorModalOpen: true,
        errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        userStatusData: null
      });
    }
  }

  addFEApiCallIdResp = (responseJsonData: any) => {
    if (!responseJsonData.errors?.length) {
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_FE,
        successAddFE: responseJsonData.data,
        userModal: false,
      });
    this.setState({ addFELoading: false });

      this.fetchFElist();
    } else {
      if (responseJsonData?.errors) {
        let newMsg = "";
        let errorMsg: string = responseJsonData.errors[0];
        const rmvTxt = "Accounts villages village";
        if (responseJsonData?.errors[0].includes(rmvTxt)) {
          newMsg = errorMsg.replace(rmvTxt, "");
        } else {
          newMsg = errorMsg;
        }
        this.setState({
          errorModalOpen: true,
          errorModalMsg: newMsg,
        });
    this.setState({ addFELoading: false });

      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
    this.setState({ addFELoading: false });

      }
    }
  };
  updateFEApiCallIdResp = (responseJsonData: any) => {
    if (!responseJsonData.errors?.length) {
      if (responseJsonData.data) {
        this.setState({
          successModalOpen: true,
          successModalMsg: configJSON.SUCCESSMSG.UPDATE_FE,
          successUpdateFE: responseJsonData.data,
        });
    this.setState({ updateFELoading: false });

        this.handleClose();
        this.fetchFElist();
      }
    } else {
      if (responseJsonData?.errors) {
        let newMsg = "";
        let errorMsg: string = responseJsonData.errors[0];
        const rmvTxt = "Accounts villages village";
        if (responseJsonData?.errors[0].includes(rmvTxt)) {
          newMsg = errorMsg.replace(rmvTxt, "");
        } else {
          newMsg = errorMsg;
        }
        this.setState({
          errorModalOpen: true,
          errorModalMsg: newMsg,
        });
    this.setState({ updateFELoading: false });

      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
    this.setState({ updateFELoading: false });

      }
    }
  };
  addAdminApiCallIdResp = (responseJson: any) => {
    if (!responseJson.errors?.length) {
      this.setState({
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_ADMIN,
        successAddAdmin: responseJson.data,
        userModal: false
      });
    this.setState({ addAdminLoading: false });

    } else {
      if (responseJson?.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ addAdminLoading: false });
      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ addAdminLoading: false });
      }
    }
  };
  updateAdminApiCallIdResp = (responseJson: any) => {
    if (!responseJson.errors?.length) {
      if (responseJson.data) {
        this.setState({ successUpdateAdmin: responseJson.data });
        this.setState({
          userModal: false,
          successModalOpen: true,
          successModalMsg: configJSON.SUCCESSMSG.UPDATE_ADMIN,
        });
        this.setState({ updateAdminLoading: false });
      }
    } else {
      if (responseJson?.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ updateAdminLoading: false });
      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ updateAdminLoading: false });
      }
    }
  };
  addSuperAdminApiCallIdResp = (responseJson: any) => {
    if (!responseJson.errors?.length) {
      this.setState({
        successAddSuperAdmin: responseJson.data,
        userModal: false,
        successModalOpen: true,
        successModalMsg: configJSON.SUCCESSMSG.ADD_SA,
      });
      this.setState({ addSALoading: false });
    } else {
      if (responseJson?.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
      this.setState({ addSALoading: false });
      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
      this.setState({ addSALoading: false });
      }
    }
  };
  updateSuperAdminApiCallIdResp = (responseJson: any) => {
    if (!responseJson.errors?.length) {
      if (responseJson.data) {
        this.setState({
          successUpdateSuperAdmin: responseJson.data,
          userModal: false,
          successModalOpen: true,
          successModalMsg: configJSON.SUCCESSMSG.UPDATE_SA,
        });
      this.setState({ updateSALoading: false });

      }
    } else {
      if (responseJson?.errors) {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: responseJson.errors[0],
        });
        this.setState({ updateSALoading: false });
      } else {
        this.setState({
          errorModalOpen: true,
          errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
        });
        this.setState({ updateSALoading: false });
      }
    }
  };
  deleteUserApiCallIdResp =async (responseJson: any) => {
    if (responseJson?.account_user) {
      this.setState({
        successModalOpen: true,
        successModalMsg:
          this.state.deleteUserData?.attributes?.role_id === 2
            ? configJSON.SUCCESSMSG.DELETE_FARMER
            : configJSON.SUCCESSMSG.DELETE_FE,
      });
      (await this.state.deleteUserData?.attributes?.role_id) === 2
        ? this.fetchFarmerData()
        : this.fetchFieldExecutiveData();
      this.handleClose();
      this.setState({ deleteFarmerFEUserLoading: false });
    } else if (responseJson?.data?.base) {
      this.setState({
        errorModalOpen: true,
        errorModalMsg: responseJson?.data?.base[0],
      });
      this.setState({ deleteFarmerFEUserLoading: false });
      this.handleClose();
    } else {
      this.setState({
        errorModalOpen: true,
        errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
      });
      this.setState({ deleteFarmerFEUserLoading: false });
      this.handleClose();
    }
  };
  deleteAdminUserApiCallIdResp =async (responseJson: any) => {
    if (responseJson?.account_user) {
      this.setState({
        successModalOpen: true,
        successModalMsg:
          this.state.deleteAdminUserData?.role === "admin"
            ? configJSON.SUCCESSMSG.DELETE_ADMIN
            : configJSON.SUCCESSMSG.DELETE_SA,
      });     
      (await this.state.deleteAdminUserData?.role) === "admin"
        ? this.fetchAdminData()
        : this.fetchSuperAdminData();
      this.handleClose();
      this.setState({ deleteAdminSAUserLoading: false });
    } else if (responseJson?.data?.base) {
      this.setState({
        errorModalOpen: true,
        errorModalMsg: responseJson?.data?.base[0],
      });
      this.setState({ deleteAdminSAUserLoading: false });
      this.handleClose();
    } else {
      this.setState({
        errorModalOpen: true,
        errorModalMsg: configJSON.AdminConsoleDefaults.ErrorDefault,
      });
      this.setState({ deleteAdminSAUserLoading: false });
      this.handleClose();
    }
  };

  // ASSIGN VILLAGE FILTER RESPONSE
  setFilterAPIResponses = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getVillageFilterStateData:
        this.getVillageFilterStateDataRes(responseJson);
        break;
      case this.getVillageFilterDistrictData:
        this.getVillageFilterDistrictDataRes(responseJson);
        break;
      case this.getVillageFilterTalukaData:
        this.getVillageFilterTalukaDataRes(responseJson);
        break;
      case this.getVillageFilterVillageData:
        this.getVillageFilterVillageDataRes(responseJson);
        break;
    }
  };
  getVillageFilterStateDataRes = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({
        initialStates: responseJson,
        filteredStates: responseJson,
      });
    } else {
      this.setState({
        initialStates: [],
        filteredStates: [],
      });
    }
  };
  getVillageFilterDistrictDataRes = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({
        initalDistricts: responseJson,
        filteredDistricts: responseJson,
        isDisabled: {
          ...this.state.isDisabled,
          district: false,
        },
      });
    } else {
      this.setState({
        initalDistricts: [],
        filteredDistricts: [],
        isDisabled: {
          ...this.state.isDisabled,
          district: false,
        },
      });
    }
  };
  getVillageFilterTalukaDataRes = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({
        initialTalukas: responseJson,
        filteredTalukas: responseJson,
        isDisabled: {
          ...this.state.isDisabled,
          taluka: false,
        },
      });
    } else {
      this.setState({
        initialTalukas: [],
        filteredTalukas: [],
        isDisabled: {
          ...this.state.isDisabled,
          taluka: false,
        },
      });
    }
  };
  getVillageFilterVillageDataRes = (responseJson: any[]) => {
    let villageList: any[] = [];

    if (responseJson?.length > 0) {
      if (this.state.selectedLocationArr.length > 0) {
        villageList = responseJson.filter((el) => {
          return !this.state.selectedLocationArr.find((element) => {
            return element.id === el.id;
          });
        });
      } else {
        villageList = responseJson;
      }
      this.setState({
        initialVillages: villageList,
        filteredVillages: villageList,
        isDisabled: {
          ...this.state.isDisabled,
          village: false,
        },
      });
    } else {
      this.setState({
        initialVillages: [],
        filteredVillages: [],
        isDisabled: {
          ...this.state.isDisabled,
          village: false,
        },
      });
    }

  };

  // USER TABLE FILTER RESPONSE
  setUserTableFilterAPIResponses = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getStateDataApiUTCallId:
        this.getStateDataApiUTCallIdRes(responseJson);
        break;
      case this.getDistrictDataApiUTCallId:
        this.getDistrictDataApiUTCallIdRes(responseJson);
        break;
      case this.getTalukaDataApiUTCallId:
        this.getTalukaDataApiUTCallIdRes(responseJson);
        break;
      case this.getVillageDataApiUTCallId:
        this.getVillageDataApiUTCallIdRes(responseJson);
        break;
      case this.getFieldExecutiveDataApiUTCallId:
        this.getFieldExecutiveDataApiUTCallIdRes(responseJson);
        break;
    }
  }
  getStateDataApiUTCallIdRes = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        initialStatesUT: responseJson,
        filteredStatesUT: responseJson
      })
    } else {
      this.setState({
        initialStatesUT: [],
        filteredStatesUT: []
      })
    }

  }
  getDistrictDataApiUTCallIdRes = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        initalDistrictsUT: responseJson,
        filteredDistrictsUT: responseJson,
        isDisabledUT: {
          ...this.state.isDisabledUT,
          district: false,
        },
      })
    } else {
      this.setState({
        initalDistrictsUT: [],
        filteredDistrictsUT: [],
        isDisabledUT: {
          ...this.state.isDisabledUT,
          district: false,
        },
      })
    }
  }
  getTalukaDataApiUTCallIdRes = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        initialTalukasUT: responseJson,
        filteredTalukasUT: responseJson,
        isDisabledUT: {
          ...this.state.isDisabledUT,
          taluka: false,
        },
      })
    } else {
      this.setState({
        initialTalukasUT: [],
        filteredTalukasUT: [],

        isDisabledUT: {
          ...this.state.isDisabledUT,
          taluka: false,
        },
      })
    }
  }
  getVillageDataApiUTCallIdRes = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        initialVillagesUT: responseJson,
        filteredVillagesUT: responseJson,
        isDisabledUT: {
          ...this.state.isDisabledUT,
          village: false,
        },
      })
    } else {
      this.setState({
        initialVillagesUT: [],
        filteredVillagesUT: [],
        isDisabledUT: {
          ...this.state.isDisabledUT,
          village: false,
        },
      })
    }
  }
  getFieldExecutiveDataApiUTCallIdRes = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        initialFieldExectiveUT: responseJson,
        filteredFieldExecutiveUT: responseJson,
        isDisabledUT: {
          ...this.state.isDisabledUT,
          fe: false,
        },
      })
    } else {
      this.setState({
        initialFieldExectiveUT: [],
        filteredFieldExecutiveUT: [],
        isDisabledUT: {
          ...this.state.isDisabledUT,
          fe: false,
        },
      })
    }
  }

  // FARMER FORM DATA RESPONSE
  getNumberBeongsToApiCallIdResp = (responseJsonData: any[]) => {
    if (responseJsonData && responseJsonData.length) {
      this.setState({ getNumberBeongsToData: responseJsonData });
    } else {
      this.setState({ getNumberBeongsToData: [] });
    }
  };
  getEducationFarmerApiCallIdResp = (responseJsonData: any) => {
    if (responseJsonData !== null) {
      this.setState({ getEducationFarmerData: responseJsonData });
    }
  };
  getStateApiCallIdResp = (responseJsonData: any) => {
    if (responseJsonData !== null) {
      this.setState({
        getStateData: responseJsonData,
      });
    }
  };
  getDistrictApiCallIdResp = (responseJsonData: any) => {
    if (responseJsonData !== null) {
      this.setState({ getDistrictData: responseJsonData });
    }
  };
  getTalukaApiCallIdResp = (responseJsonData: any) => {
    if (responseJsonData !== null) {
      this.setState({ getTalukaData: responseJsonData });
    }
  };
  getVillageApiCallIdResp = (responseJsonData: any) => {
    if (responseJsonData !== null) {
      this.setState({ getVillageData: responseJsonData });
    }
  };
  getFElistApiCallIdResp = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({ FElistData: responseJson });
    } else {
      this.setState({ FElistData: [] });
    }
  };

  // FE FORM DATA RESPONSE
  getMobileDeviceModelFEApiCallIdResp = (responseJson: any) => {
    if (responseJson !== null) {
      this.setState({ getMobileDeviceModelFEData: responseJson });
    }
  };
  getAllVillageListApiCallIdResp = (responseJson: any[]) => {
    if (responseJson?.length) {
      this.setState({ AllVillageListData: responseJson });
    } else {
      this.setState({ AllVillageListData: [] });
    }
  };

  async componentDidMount(): Promise<void> {
    this.setUserAdminRole();
    this.fetchFarmerData();
    this.fetchStates();
    this.fetchNumberBeongsTo();
    this.fetchEducationFarmer();
    this.fetchMobileDeviceModelFE();
    this.fetchFElist();
    this.fetchAllVillageList();
    // Assign Village Filter
    this.getStates();
    // USER TABLE FILTER STARTS
    this.getStatesUT();
    this.getDistrictsUT();
    this.getTalukasUT();
    this.getVillagesUT();
    this.getFieldExecutivesUT()
    // USER TABLE FILTER ENDS
    const pageState = this.props.location.state;
    if(pageState.openModal) {
      this.showUserModal();
    }
  }
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };


  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };


  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (
      prevState.successAddFarmer !== this.state.successAddFarmer ||
      prevState.farmerPageSize !== this.state.farmerPageSize ||
      prevState.farmerTableCurrentPage !== this.state.farmerTableCurrentPage
    ) {
      this.fetchFarmerData();
    }
    if (prevState.successUpdateFarmer !== this.state.successUpdateFarmer) {
      this.fetchFarmerData();
    }
    if (
      prevState.successAddAdmin !== this.state.successAddAdmin ||
      prevState.successUpdateAdmin !== this.state.successUpdateAdmin ||
      prevState.adminPageSize !== this.state.adminPageSize ||
      prevState.adminTableCurrentPage !== this.state.adminTableCurrentPage
    ) {
      this.fetchAdminData();
    }
    if (
      prevState.successAddSuperAdmin !== this.state.successAddSuperAdmin ||
      prevState.successUpdateSuperAdmin !==
      this.state.successUpdateSuperAdmin ||
      prevState.superAdminPageSize !== this.state.superAdminPageSize ||
      prevState.superAdminTableCurrentPage !==
      this.state.superAdminTableCurrentPage
    ) {
      this.fetchSuperAdminData();
    }
    if (
      prevState.successAddFE !== this.state.successAddFE ||
      prevState.FePageSize !== this.state.FePageSize ||
      prevState.FeTableCurrentPage !== this.state.FeTableCurrentPage
    ) {
      this.fetchFieldExecutiveData();
    }
    if (prevState.successUpdateFE !== this.state.successUpdateFE) {
      this.fetchFieldExecutiveData();
    }
  }
  // -------------------------------------------------- HANDLE FUNCTIONS --------------------------------------------------


  // TABLE FILTER FUNCTIONS STARTS
  handleChangeAtoZUT = () => {
    this.setState({
      sortAscendingUT: !this.state.sortAscendingUT,
      sortDescendingUT: false
    });
  }
  handleChangeZtoAUT = () => {
    this.setState({
      sortDescendingUT: !this.state.sortDescendingUT,
      sortAscendingUT: false
    });
  }
  handleFieldExectiveSearchUT = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ fieldExecutiveInputValueUT: e?.target?.value });
    const filteredArray = query
      ? this.state.initialFieldExectiveUT.filter((value: any) =>
        value.first_name.toLowerCase().includes(query) || value.last_name.toLowerCase().includes(query)
      )
      : this.state.initialFieldExectiveUT;
    this.setState({
      filteredFieldExecutiveUT: filteredArray,
    });

  }
  handleTalukaSearchUT = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ talukaInputValueUT: e?.target?.value });
    const TalukafilteredArray = query
      ? this.state.initialTalukasUT.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialTalukasUT;
    this.setState({
      filteredTalukasUT: TalukafilteredArray,
    });
  }
  handleVillageSearchUT = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ villageInputValueUT: e?.target?.value });
    const VillagefilteredArray = query
      ? this.state.initialVillagesUT.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialVillagesUT;
    this.setState({
      filteredVillagesUT: VillagefilteredArray,
    });
  }
  handleDistrictSearchUT = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ districtInputValueUT: e?.target?.value });
    const DistrictfilteredArray = query
      ? this.state.initalDistrictsUT.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initalDistrictsUT;
    this.setState({
      filteredDistrictsUT: DistrictfilteredArray,
    });
  }
  handleStateSearchUT = (e: any) => {
    const query = e?.target?.value?.toLowerCase() || "";
    this.setState({ stateInputValueUT: e?.target?.value });
    const StatefilteredArray = query
      ? this.state.initialStatesUT.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialStatesUT;
    this.setState({
      filteredStatesUT: StatefilteredArray,
    });
  }
  handleChangePanelUT = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({
      expandedUT: newExpanded ? panel : false,
      stateInputValueUT: "",
      districtInputValueUT: "",
      talukaInputValueUT: "",
      villageInputValueUT: "",
      fieldExecutiveInputValueUT: "",
      filteredStatesUT: this.state.initialStatesUT,
      filteredDistrictsUT: this.state.initalDistrictsUT,
      filteredTalukasUT: this.state.initialTalukasUT,
      filteredVillagesUT: this.state.initialVillagesUT,
      filteredFieldExecutiveUT: this.state.initialFieldExectiveUT,

    });
  };
  handleVillageFilterClearUT = () => {
    this.setState({
      openTableFilterUT: false,
      anchorEl: null,
      sortDescendingUT: false,
      sortAscendingUT: false,
      filteredStatesUT: this.state.initialStatesUT,
      filteredDistrictsUT: this.state.initalDistrictsUT,
      filteredTalukasUT: this.state.initialTalukasUT,
      filteredVillagesUT: this.state.initialVillagesUT,
      filteredFieldExecutiveUT: this.state.initialFieldExectiveUT,

      expandedUT: false,
      savedStateUT:
      {
        name: "Select State",
        id: "",
      },
      savedDistrictUT: {
        name: "Select District",
        id: "",
      },
      savedTalukaUT: {
        name: "Select Taluka",
        id: "",
      },
      savedVillageUT: {
        name: "Select Village",
        id: "",
      },
      savedFieldExecutiveUT: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: ""
      },
      savedSortAscendingUT: false,
      savedSortDescendingUT: false,
      savedIsDisabledUT: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
      },
      stateInputValueUT: "",
      districtInputValueUT: "",
      talukaInputValueUT: "",
      villageInputValueUT: "",
      fieldExecutiveInputValueUT: "",
      selectedStateUT:
      {
        name: "Select State",
        id: "",
      },
      selectedDistrictUT: {
        name: "Select District",
        id: "",
      },
      selectedTalukaUT: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageUT: {
        name: "Select Village",
        id: "",
      },
      selectedFieldExecutiveUT: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: ""
      },
      isDisabledUT: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
      },
      filterHasErrorUT: false,
      // RESET USER TABLE STATES
      farmerTableCurrentPage: 0,
      FeTableCurrentPage: 0,
      adminTableCurrentPage: 0,
      searchFilterValue: "",
      hideBadgeUT: true,
    }, () => {
      switch (this.state.selectedUserTable) {
        case "farmer":
          this.fetchFarmerData();
          this.getFieldExecutivesUT()
          this.getDistrictsUT();
          this.getTalukasUT();
          this.getVillagesUT();
          this.getStatesUT()
          break;
        case "fieldExecutive":
          this.fetchFieldExecutiveData();
          this.getDistrictsUT();
          this.getTalukasUT();
          this.getVillagesUT();
          this.getStatesUT()
          break;
        case "admin":
          this.fetchAdminData();
          break;
        case "SuperAdmin":
          this.fetchSuperAdminData();
          break;
      }

    })
  };
  handleCloseTableFilterPopupUT = () => {
    this.setState({
      openTableFilterUT: false,
      expandedUT: false,
      selectedStateUT: this.state.savedStateUT,
      selectedDistrictUT: this.state.savedDistrictUT,
      selectedTalukaUT: this.state.savedTalukaUT,
      selectedVillageUT: this.state.savedVillageUT,
      selectedFieldExecutiveUT: this.state.savedFieldExecutiveUT,

      sortAscendingUT: this.state.savedSortAscendingUT,
      sortDescendingUT: this.state.savedSortDescendingUT,
      isDisabledUT: this.state.savedIsDisabledUT,
      stateInputValueUT: "",
      districtInputValueUT: "",
      talukaInputValueUT: "",
      villageInputValueUT: "",
      fieldExecutiveInputValueUT: "",

      filteredStatesUT: this.state.initialStatesUT,
      filteredDistrictsUT: this.state.initalDistrictsUT,
      filteredTalukasUT: this.state.initialTalukasUT,
      filteredVillagesUT: this.state.initialVillagesUT,
      filteredFieldExecutiveUT: this.state.initialFieldExectiveUT,

      filterHasErrorUT: false
    })
  }
  handleSubmitFilterUT = () => {
    const isError = this.checkFilterErrorUT()
    if (!isError) {
      this.setState({
        expandedUT: false,
        savedIsDisabledUT: this.state.isDisabledUT,
        savedSortAscendingUT: this.state.sortAscendingUT,
        savedSortDescendingUT: this.state.sortDescendingUT,
        savedStateUT: this.state.selectedStateUT,
        savedDistrictUT: this.state.selectedDistrictUT,
        savedTalukaUT: this.state.selectedTalukaUT,
        savedVillageUT: this.state.selectedVillageUT,
        savedFieldExecutiveUT: this.state.selectedFieldExecutiveUT,
      }, () => {
        switch (this.state.selectedUserTable) {
          case "farmer":
            this.setState({ farmerTableCurrentPage: 0 }, () => {
              this.fetchFarmerData();
            });
            break;
          case "fieldExecutive":
            this.setState({ FeTableCurrentPage: 0 }, () => {
              this.fetchFieldExecutiveData();
            });
            break;
        }
      });
    } else {
      return false
    }
  }
  handleChangeFieldUT = (key1: any, key2: any, key3: any, key4: any, key5?: any) => {
    const isDisabledInitialValuesUT = {
      state: true,
      district: true,
      taluka: true,
      village: true,
      fe: true,
    }
    this.setState({
      isDisabledUT: {
        ...isDisabledInitialValuesUT,
        ...(key1 && { [key1]: false }),
        ...(key2 && { [key2]: false }),
        ...(key3 && { [key3]: false }),
        ...(key4 && { [key4]: false }),
        ...(key5 && { [key5]: false }),
      }
    })

  }
  handleChangeStateFieldUT = (stateData: any) => {
    this.setState({
      selectedStateUT: stateData,
      expandedUT: false,
    }, () => {
      this.getDistrictsUT()
    });
    this.handleChangeFieldUT("", "state", "district", "", "")
  }
  handleChangeDistrictFieldUT = (districtData: any) => {
    this.setState({
      selectedDistrictUT: districtData,
      expandedUT: false,
    }, () => {
      this.getTalukasUT()
    });
    this.handleChangeFieldUT(this.state.selectedStateUT?.name !== "Select State" ? "state" : "", "district", "taluka", "", "")

  }
  handleChangeTalukaFieldUT = (talukaData: any) => {
    this.setState({
      selectedTalukaUT: talukaData,
      expandedUT: false,
    }, () => {
      this.getVillagesUT()
    });
    this.handleChangeFieldUT(this.state.selectedStateUT?.name !== "Select State" ? "state" : "", this.state.selectedDistrictUT?.name === "Select District" ? "" : "district", "taluka", "village", "")
  }
  handleChangeVillageFieldUT = (villageData: any) => {
    this.setState({
      selectedVillageUT: villageData,
      expandedUT: false,
    }, () => {
      this.getFieldExecutivesUT()
    });
    this.handleChangeFieldUT(this.state.selectedStateUT?.name !== "Select State" ? "state" : "", this.state.selectedDistrictUT?.name === "Select District" ? "" : "district", this.state.selectedTalukaUT?.name === "Select Taluka" ? "" : "taluka", "village", "fe")
  }
  handleChangeFieldExecutiveFieldUT = (fieldExectiveData: any) => {
    this.setState({
      expandedUT: false,
      selectedFieldExecutiveUT: fieldExectiveData
    });
    this.handleChangeFieldUT(this.state.selectedStateUT?.name !== "Select State" ? "state" : "", this.state.selectedDistrictUT?.name === "Select District" ? "" : "district", this.state.selectedTalukaUT?.name === "Select Taluka" ? "" : "taluka", this.state.selectedVillageUT?.name === "Select Village" ? "" : "village", "fe")
  }
  opacityStateCalculatorUT = () => {
    return this.state.selectedStateUT?.name === "Select State" ? 0.48 : 1
  }
  opacityDistrictCalculatorUT = () => {
    return this.state.selectedDistrictUT?.name === "Select District" ? 0.48 : 1
  }
  opacityTalukaCalculatorUT = () => {
    return this.state.selectedTalukaUT?.name === "Select Taluka" ? 0.48 : 1
  }
  opacityVillageCalculatorUT = () => {
    return this.state.selectedVillageUT?.name === "Select Village" ? 0.48 : 1
  }
  opacityFieldExecutiveCalculatorUT = () => {
    return this.state.selectedFieldExecutiveUT?.first_name + " " + this.state.selectedFieldExecutiveUT?.last_name === "Select Field Executive" ? 0.48 : 1
  }
  checkFilterErrorUT = () => {
    if (this.state.sortAscendingUT ||
      this.state.sortDescendingUT ||
      this.state.selectedStateUT.id !== "" ||
      this.state.selectedDistrictUT.id !== "" ||
      this.state.selectedTalukaUT.id !== "" ||
      this.state.selectedVillageUT.id !== "" ||
      this.state.selectedFieldExecutiveUT.id !== ""
    ) {
      this.setState({
        filterHasErrorUT: false
      })
      return false;
    } else {
      this.setState({
        filterHasErrorUT: true
      })
      return true;
    }
  }
  // TABLE FILTER FUNCTIONS ENDS

  // HANDLE ASSIGN-VILLAGE FILTER FUNCTION STARTS
  closeVillageFilter = () => {
    this.setState({
      isVillageFilterOpen: false,
      expanded: false,
      selectedState: {
        name: "Select State",
        id: "",
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      isDisabled: {
        state: false,
        district: true,
        taluka: true,
        village: true,
      },
      isAccordianError: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
    });
  };
  handleVillageFilterClear = () => {
    this.setState({
      expanded: false,
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      selectedState: {
        name: "Select State",
        id: "",
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      isDisabled: {
        state: false,
        district: true,
        taluka: true,
        village: true,
      },
      isAccordianError: {
        state: false,
        district: false,
        taluka: false,
        village: false,
      },
    });
  }
  handleVillageDelete = (event: any, village: any) => {
    const BEvillageObject: any = this.state.defaultAssignedVillages.find(
      (item: any) => String(item.attributes.village_id) === String(village.id)
    );
    if (BEvillageObject) {
      this.setState({
        deletedVillagesList: [
          ...this.state.deletedVillagesList,
          {
            id: BEvillageObject?.id,
            _destroy: true,
            village_id: BEvillageObject?.attributes.village_id,
          },
        ],
      });
    }
    const { selectedLocationArr } = this.state;
    const updatedOptions = selectedLocationArr.filter(
      (option: any) => option !== village
    );

    this.setState({ selectedLocationArr: updatedOptions });
  };
  handleChangePanel = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({
      expanded: newExpanded ? panel : false,
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
    });
  };
  handleStateSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase() || "";
    this.setState({ stateInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialStates.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialStates;
    this.setState({
      filteredStates: filteredArray,
    });
  };
  handleDistrictSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ districtInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initalDistricts.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initalDistricts;
    this.setState({
      filteredDistricts: filteredArray,
    });
  };
  handleTalukaSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ talukaInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialTalukas.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialTalukas;
    this.setState({
      filteredTalukas: filteredArray,
    });
  };
  handleVillageSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ villageInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialVillages.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialVillages;
    this.setState({
      filteredVillages: filteredArray,
    });
  };
  handleChangeStateField = (stateData: any) => {
    this.setState(
      {
        selectedState: stateData,
        expanded: false,
        selectedDistrict: {
          name: "Select District",
          id: "",
        },
        selectedTaluka: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillage: {
          name: "Select Village",
          id: "",
        },
      },
      () => {
        this.checkAccordianError();
        this.getDistricts();
      }
    );
  };
  handleChangeDistrictField = (districtData: any) => {
    this.setState(
      {
        selectedDistrict: districtData,
        expanded: false,
        selectedTaluka: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillage: {
          name: "Select Village",
          id: "",
        },
      },
      () => {
        this.checkAccordianError();
        this.getTalukas();
      }
    );
  };
  handleChangeTalukaField = (talukaData: any) => {
    this.setState(
      {
        selectedTaluka: talukaData,
        expanded: false,
        selectedVillage: {
          name: "Select Village",
          id: "",
        },
      },
      () => {
        this.checkAccordianError();
        this.getVillages();
      }
    );
  };
  handleChangeVillageField = (villageData: any) => {
    this.setState(
      {
        selectedVillage: villageData,
        expanded: false,
      },
      () => {
        this.checkAccordianError();
      }
    );
  };
  checkAccordianError = () => {
    let isStateEmpty = false;
    let isDistrictEmpty = false;
    let isTalukaEmpty = false;
    let isVillageEmpty = false;

    if (
      this.state.selectedState.id === "" ||
      this.state.selectedDistrict.id === "" ||
      this.state.selectedTaluka.id === "" ||
      this.state.selectedVillage.id === ""
    ) {
      if (this.state.selectedState.id === "") {
        isStateEmpty = true;
      }
      if (this.state.selectedDistrict.id === "") {
        isDistrictEmpty = true;
      }
      if (this.state.selectedTaluka.id === "") {
        isTalukaEmpty = true;
      }
      if (this.state.selectedVillage.id === "") {
        isVillageEmpty = true;
      }
      this.setState({
        isAccordianError: {
          ...this.state.isAccordianError,
          state: isStateEmpty,
          district: isDistrictEmpty,
          taluka: isTalukaEmpty,
          village: isVillageEmpty,
        },
      });
      return true;
    } else {
      this.setState({
        isAccordianError: {
          ...this.state.isAccordianError,
          state: isStateEmpty,
          district: isDistrictEmpty,
          taluka: isTalukaEmpty,
          village: isVillageEmpty,
        },
      });
      return false;
    }
  };
  handleSubmitAccordion = () => {
    const result: boolean = this.checkAccordianError();
    if (result === true) {
      this.setState({
        expanded: false,
      });
    } else {
      const FilterObj = this.state.AllVillageListData.find(
        (item: any) => item.id === this.state.selectedVillage.id
      );
      let newVillageValue = [...this.state.selectedLocationArr];
      newVillageValue.push(FilterObj);
      this.setState(
        {
          selectedLocationArr: newVillageValue,
          isVillageFilterOpen: false,
        },
        () => {
          this.closeVillageFilter();
        }
      );
    }
  };
  opacityStateCalculator = () => {
    return this.state.selectedState.name === "Select State" ? 0.48 : 1
  };
  opacityDistrictCalculator = () => {
    return this.state.selectedDistrict.name === "Select District" ? 0.48 : 1
  };
  opacityTalukaCalculator = () => {
    return this.state.selectedTaluka.name === "Select Taluka" ? 0.48 : 1
  };
  opacityVillageCalculator = () => {
    return this.state.selectedVillage.name === "Select Village" ? 0.48 : 1
  };
  // HANDLE ASSIGN-VILLAGE FILTER FUNCTION ENDS


  setUserAdminRole = () => {
    if (atob(String(this.state.loggedInUserRole)) === "admin") {
      this.setState({ isLoggedUserAdmin: true });
    } else if (atob(String(this.state.loggedInUserRole)) === "super_admin") {
      this.setState({ isLoggedUserAdmin: false });
    } else {
      this.setState({ isLoggedUserAdmin: true });
    }
  };
  setUserTypeTable = (user: string) => {
    this.setState({ selectedUserTable: user, searchFilterValue: "" });
    switch (user) {
      case "farmer":
        this.setState({ seletedUserTypeForm: "Farmers", HideFEFilter: false });
        break;
      case "fieldExecutive":
        this.setState({ seletedUserTypeForm: "FieldExecutive", HideFEFilter: true });
        break;
      case "admin":
        this.setState({ seletedUserTypeForm: "Admin" });
        break;
      case "SuperAdmin":
        this.setState({ seletedUserTypeForm: "SuperAdmins" });
        break;
    }
    this.handleVillageFilterClearUT();
  };

  showUserModal() {
    this.setState({
      openEditUserModal: false,
      selectedLocationArr: [],
      deletedVillagesList: [],
      defaultAssignedVillages: [],
      adminInitialValues: {
        firstnameAdmin: "",
        lastnameAdmin: "",
        mobileNumberAdmin: "",
        emailAddressAdmin: "",
        usernameAdmin: "",
        dateOfBirthAdmin: null,
        passwordAdmin: null,
        confirmPasswordAdmin: null,
      },
      feInitialValues: {
        firstnameFE: "",
        lastnameFE: "",
        mobileNumberFE: "",
        locationFE: "",
        dateOfBirthFE: null,
        emailAddressFE: "",
        usernameFE: "",
        mobileDeviceModelFE: "",
      },    
      farmerInitialValues: {        
        adharcardNumberFarmer: "",
        dateOfBirthFarmer: null,
        totalFamilyMemberFarmer: "",
        educationFarmer: "",
        districtFarmer: "",
        talukaFarmer: "",
        villageFarmer: "",
        firstnameFarmer: "",
        middlenameFarmer: "",
        surnameFarmer: "",
        mobileNumberFarmer: "",
        // NEW STATES 
        numberBelongsToFarmer: "",
        mobileTypeFarmer: "",
        stateFarmer: "",
        genderFarmer: "male",        
      },
      // crop mapping
      farmMappingURL: null,
      coordinates: [],
      markerDefault: null,
      farmMappingFile: null,
      latlngHash: null,
      OpenFarmMapping: false,
      userModal: true,
      tempCoordinates: []
    });
  }
  handleClose = () => {
    this.setState({
      userModal: false,
      // handle farmer form states
      selectedFEformData: null,
      getDistrictData: [],
      getTalukaData: [],
      getVillageData: [],
      disableStateFarmer: false,
      // handle admin form states
      showEyeIconPassword: true,
      showEyeIconConfirmPassword: true,
      // handle fe form states
      selectedLocationArr: [],
      deletedVillagesList: [],
      // handle delete modal state
      openDeleteModal: false,
      deleteUserData: null,
      openDeleteModalAdmin: false,
      deleteAdminUserData: null,
      // crop mapping
      farmMappingURL: null,
      coordinates: [],
      markerDefault: null,
      farmMappingFile: null,
      latlngHash: null,
      OpenFarmMapping: false,
      tempCoordinates: []
    });
    switch (this.state.selectedUserTable) {
      case "farmer":
        this.setState({ seletedUserTypeForm: "Farmers" });
        break;
      case "fieldExecutive":
        this.setState({ seletedUserTypeForm: "FieldExecutive" });
        break;
      case "admin":
        this.setState({ seletedUserTypeForm: "Admin" });
        break;
      case "SuperAdmin":
        this.setState({ seletedUserTypeForm: "SuperAdmins" });
        break;
    }
  };
  onFeLocationsChange = (event: any, values: any) => {
    this.setState({
      selectedLocationArr: values,
    });
  };

  getDistrictFarmerValue = (values: any) => {
    return this.state.getDistrictData?.length ? values?.districtFarmer : "";
  };
  getTalukaFarmerValue = (values: any) => {
    return this.state.getTalukaData?.length ? values?.talukaFarmer : "";
  };
  getVillageFarmerValue = (values: any) => {
    return this.state.getVillageData?.length ? values?.villageFarmer : "";
  };
  disableDistrictFarmerValue = () => {
    return this.state.getStateData.length
      ? false
      : true;
  };
  disableTalukaFarmerValue = () => {
    return this.state.getDistrictData.length 
      ? false
      : true;
  };
  disableVillageFarmerValue = () => {
    return this.state.getTalukaData.length
      ? false
      : true;
  };

  editModalStatus = () => {
    return this.state.openEditUserModal === true ? true : false;
  };
  handleAssignFEchange = (event: any, value: any) => {
    this.setState({ selectedFEformData: value });
  };
  getPasswordType = () => {
    return this.state.showEyeIconPassword ? "password" : "text";
  };
  getConfirmPasswordType = () => {
    return this.state.showEyeIconConfirmPassword ? "password" : "text";
  };

  handleErrorModalClose = () => {
    this.setState({ errorModalOpen: false, errorModalMsg: "", successModalOpen:false,successModalMsg:"" });
  };
  keyDown = (evt: any) =>
    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
  keyDownInt = (evt: any) =>
    ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault();


  debouncedSearchTable = debounce(() => this.fetchUserBasedOnSearch(), 500);
  fetchUserBasedOnSearch = () => {
    switch (this.state.selectedUserTable) {
      case "SuperAdmin":
        this.fetchSuperAdminData();
        break;
      case "admin":
        this.fetchAdminData();
        break;
      case "fieldExecutive":
        this.fetchFieldExecutiveData();
        break;
      case "farmer":
        this.fetchFarmerData();
        break;
    }
  };

  checkNumber = (value: number) => value || 0;

  setUserTypeForm = (userRole: string) => {
    this.setState({ seletedUserTypeForm: userRole });
  };

  handleAdminVlidationSchema = () => {
    return this.state.openEditUserModal
      ? SchemaAdminFormEDIT
      : SchemaAdminFormADD;
  };
  handleSubmitFarmer = (values: any) => {
    this.state.openEditUserModal
      ? this.updateFarmer(values)
      : this.addFarmer(values);
  };
  handleSubmitFieldExecutive = (values: any) => {
    this.state.openEditUserModal
      ? this.updateFieldExecutive(values)
      : this.addFieldExecutive(values);
  };
  handleSubmitAdmin = (values: any) => {
    if (this.state.seletedUserTypeForm === "SuperAdmins") {
      this.state.openEditUserModal
        ? this.updateSuperAdmin(values)
        : this.addSuperAdmin(values);
    } else {
      this.state.openEditUserModal
        ? this.updateAdmin(values)
        : this.addAdmin(values);
    }
  };
  assignFEPlaceholder = () => {
    return this.state.selectedFEformData ? "" : "Search by field executive name";
  };
  assignVillagePlaceholder = () => {
    return this.state.selectedLocationArr.length === 0 ? "Search by village name" : "";
  };
  getAddUserModalTitleTxt = () => {
    return this.state.openEditUserModal === true ? "Edit User" : "Add New User";
  };
  getSubmitFormBtnTxt = () => {
    const updateBtnTxt = this.state.updateFarmerLoading || this.state.updateFELoading || this.state.updateAdminLoading || this.state.updateSALoading ? "Updating..." : "Update User"
    const addBtnTxt = this.state.addFarmerLoading || this.state.addFELoading || this.state.addAdminLoading || this.state.addSALoading ? "Adding..." : "Add User"
    return (this.state.openEditUserModal === true ? updateBtnTxt : addBtnTxt)
  };
  getErrorMessage = (touched: any, errors: any, value: string) => {
    return touched[value] && Boolean(errors[value]);
  };
  getHelperText = (touched: any, errors: any, value: string) => {
    return touched[value] && errors[value];
  };






  stateIDStatus = (data: any) => {
    return data.attributes.state_id?.length ? data.attributes.state_id[0]?.id : "";
  };
  distIDStatus = (data: any) => {
    return data.attributes.district_id?.length ? data.attributes.district_id[0]?.id : "";
  };
  talukaIDStatus = (data: any) => {
    return data.attributes.taluka_id?.length ? data.attributes.taluka_id[0]?.id : "";
  };
  VillageIDStatus = (data: any) => {
    return data.attributes.village_id?.length ? data.attributes.village_id[0]?.id : "";
  };
  educationFarmerStatus = (data: any) => {
    return data.attributes.highest_education_id?.length ? data?.attributes?.highest_education_id[0]?.id : "";
  };
  returnArrayDropdownId = (data:any,keyName:string) =>{
    return (data.attributes[keyName] && data.attributes[keyName].length && data.attributes[keyName][0]) ? data.attributes[keyName][0].id : "";
  }

  // HANDLE TABLE EDIT FUNCTIONS
  handleFarmerEditClick = async (data: any) => {
    let setFE = null;
    let feId = data.attributes.assigned_fe_id;
    if (feId) {
      setFE = this.state.FElistData?.find((item: any) => item.id === feId);
    }
    const farmerDoB = moment(data.attributes?.date_of_birth);
    const stateID = this.stateIDStatus(data);
    if (stateID) {
      this.setState({ disableStateFarmer: true });
    } else {
      this.setState({ disableStateFarmer: false });
    }
    await this.fetchDistrict(stateID);
    const distID = this.distIDStatus(data);
    await this.fetchTaluka(distID);
    const talukaId = this.talukaIDStatus(data);
    await this.fetchVillage(talukaId);
    const villageID = this.VillageIDStatus(data);

    this.setState({
      openEditUserModal: true,
      seletedUserTypeForm: "Farmers",
      farmerRowData: data,
      farmerInitialValues: {
        firstnameFarmer: data.attributes.first_name,
        middlenameFarmer: data.attributes.middle_name,
        surnameFarmer: data.attributes.last_name,
        mobileNumberFarmer: data.attributes.phone_number,
        adharcardNumberFarmer: data.attributes.aadhaar_number,
        dateOfBirthFarmer: farmerDoB,
        totalFamilyMemberFarmer: data?.attributes?.total_family_members,
        educationFarmer: this.educationFarmerStatus(data),
        stateFarmer: stateID,
        genderFarmer: data.attributes.gender,
        districtFarmer: distID,
        talukaFarmer: talukaId,
        villageFarmer: villageID,        
        // NEW STATES
        numberBelongsToFarmer: this.returnArrayDropdownId(data, "number_belongs_to_id"),
        mobileTypeFarmer: this.returnArrayDropdownId(data, "mobile_type_id")
      },
      selectedFEformData: setFE,
      userModal: true,
    });
  };
  handleFeEditClick = (data: any) => {
    const feDoB = data.attributes?.date_of_birth ? moment(data.attributes?.date_of_birth) : null;
    const mobileDevice = data.attributes?.mobile_type_id ? data.attributes?.mobile_type_id[0]?.id : "";

    let tempArr: any = [];
    this.state.AllVillageListData.forEach((each: any) => {
      if (data.attributes.accounts_villages?.length) {
        data.attributes.accounts_villages.forEach((item: any) => {
          if (String(each.id) === String(item.attributes.village_id)) {
            tempArr.push(each);
          }
        });
      }
    });

    this.setState({
      selectedLocationArr: tempArr,
      defaultAssignedVillages: data.attributes.accounts_villages,
    });
    this.setState({
      openEditUserModal: true,
      seletedUserTypeForm: "FieldExecutive",
      feInitialValues: {
        firstnameFE: data.attributes.first_name || "",
        lastnameFE: data.attributes.last_name || "",
        mobileNumberFE: data.attributes.phone_number || "",
        locationFE: data.attributes.location || "",
        dateOfBirthFE: feDoB,
        emailAddressFE: data.attributes.email,
        usernameFE: data.attributes.user_name || "",
        mobileDeviceModelFE: mobileDevice,
      },
      feRowData: data,
      userModal: true,
    });
  };
  handleAdminEditClick = (data: any) => {
    this.setState({
      openEditUserModal: true,
      seletedUserTypeForm: "Admin",
      adminRowData: data,
      adminInitialValues: {
        firstnameAdmin: data.first_name,
        lastnameAdmin: data.last_name,
        mobileNumberAdmin: data.phone_number,
        emailAddressAdmin: data.email,
        usernameAdmin: data.user_name,
        dateOfBirthAdmin: moment(data.dob),
      },
      userModal: true,
    });
  };
  handleSuperAdminEditClick = (data: any) => {
    this.setState({
      openEditUserModal: true,
      seletedUserTypeForm: "SuperAdmins",
      superAdminRowData: data,
      adminInitialValues: {
        firstnameAdmin: data.first_name,
        lastnameAdmin: data.last_name,
        mobileNumberAdmin: data.phone_number,
        emailAddressAdmin: data.email,
        usernameAdmin: data.user_name,
        dateOfBirthAdmin: moment(data.dob),
      },
      userModal: true,
    });
  };

  // HANDLE TABLE DELETE FUNCTIONS
  handleFarmerFEDelete = (data: any) => {
    this.setState({
      deleteUserData: data,
      openDeleteModal: true,
    });
  };
  handleAdminDelete = (data: any) => {
    this.setState({
      deleteAdminUserData: data,
      openDeleteModalAdmin: true,
    });
  };
  // HANDLE USER STATUS
  handleUpdateUserStatus = (data: IuserStatusData,status:boolean) => {
    this.setState({userStatusData:data},() =>{
      this.updateUserStatus(data, status)
    })
  }
  // HANDLE FARM-MAPPING FUNCTION
  onSaveFarmMapping = (latlngHash: any, file: any, url: string, savedCoordinates: any[]) => {
    this.setState({ latlngHash: latlngHash, OpenFarmMapping: false, farmMappingURL: url, farmMappingFile: file, tempCoordinates: savedCoordinates })
  }

  // -------------------------------------------------- API CALLS FUNCTIONS --------------------------------------------------

  // GET TABLE USERS DATA API CALLS
  fetchFarmerData = () => {
    this.setState({ farmerData: [], farmerDataLoading: true });

    let endpoint: string = `${configJSON.getTableAPiEndPoint}?per_page=${Number(this.state.farmerPageSize) * Number(this.state.farmerTableCurrentPage + 1)}&page=${1}&search=${this.state.searchFilterValue}&state_id=${this.state.selectedStateUT?.id}&district_id=${this.state.selectedDistrictUT?.id}&taluka_id=${this.state.selectedTalukaUT?.id}&village_id=${this.state.selectedVillageUT?.id}&list_of_farmers=${true}`;
    if (this.state.sortAscendingUT) {
      endpoint = endpoint + `&sort_by_a_to_z=${true}&field_executive_id=${this.state.selectedFieldExecutiveUT?.id}`
    }
    else if (this.state.sortDescendingUT) {
      endpoint = endpoint + `&sort_by_z_to_a=${true}&field_executive_id=${this.state.selectedFieldExecutiveUT?.id}`
    }
    else {
      endpoint = endpoint + `&field_executive_id=${this.state.selectedFieldExecutiveUT.id}`
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFarmerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchFieldExecutiveData = () => {
    this.setState({ FeData: [], FeLoading: true });

    let endpoint: string = `${configJSON.getTableAPiEndPoint}?per_page=${Number(this.state.FePageSize) * Number(this.state.FeTableCurrentPage + 1)}&page=${1}&search=${this.state.searchFilterValue}&state_id=${this.state.selectedStateUT?.id}&district_id=${this.state.selectedDistrictUT?.id}&taluka_id=${this.state.selectedTalukaUT?.id}&village_id=${this.state.selectedVillageUT?.id}&list_of_fe=${true}`;
    if (this.state.sortAscendingUT) {
      endpoint = endpoint + `&sort_by_a_to_z=${true}`
    }
    else if (this.state.sortDescendingUT) {
      endpoint = endpoint + `&sort_by_z_to_a=${true}`
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchAdminData = () => {
    this.setState({ adminData: [], adminLoading: true });

    let endpoint: string = `${configJSON.getTableAPiEndPoint}?per_page=${Number(this.state.adminPageSize) *
      Number(this.state.adminTableCurrentPage + 1)
      }&page=${1}&search=${this.state.searchFilterValue}&list_of_admins=${true}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getAdminApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchSuperAdminData = () => {
    this.setState({ superAdminData: [], superAdminLoading: true });

    let endpoint: string = `${configJSON.getTableAPiEndPoint}?per_page=${Number(this.state.superAdminPageSize) *
      Number(this.state.superAdminTableCurrentPage + 1)
      }&page=${1}&search=${this.state.searchFilterValue
      }&list_of_super_admins=${true}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getSuperAdminApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // CRUD - FARMER API CALLS
  addFarmer = (farmerBody: any) => {
    this.setState({ addFarmerLoading: true });
    const dobFarmer = moment(farmerBody.dateOfBirthFarmer).format("DD/MM/YYYY");
    const FEid = this.state.selectedFEformData
      ? this.state.selectedFEformData.id
      : null;

    const header = {
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFarmerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_farmer=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    let reqFormdata = new FormData();
    reqFormdata.append("data[attributes][role_id]", "2");
    reqFormdata.append(
      "data[attributes][first_name]",
      farmerBody.firstnameFarmer
    );
    reqFormdata.append(
      "data[attributes][middle_name]",
      farmerBody.middlenameFarmer
    );
    reqFormdata.append("data[attributes][last_name]", farmerBody.surnameFarmer);
    reqFormdata.append(
      "data[attributes][phone_number]",
      farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][full_phone_number]",
      "+91" + farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][aadhaar_number]",
      farmerBody.adharcardNumberFarmer
    );
    reqFormdata.append("data[attributes][date_of_birth]", dobFarmer);
    reqFormdata.append(
      "data[attributes][total_family_members]",
      farmerBody.totalFamilyMemberFarmer
    );
    reqFormdata.append(
      "data[attributes][highest_education_id]",
      farmerBody.educationFarmer
    );
    reqFormdata.append("data[attributes][state_id]", farmerBody.stateFarmer);
    reqFormdata.append("data[attributes][gender]", farmerBody.genderFarmer);
    reqFormdata.append(
      "data[attributes][district_id]",
      farmerBody.districtFarmer
    );
    reqFormdata.append("data[attributes][taluka_id]", farmerBody.talukaFarmer);
    reqFormdata.append(
      "data[attributes][village_id]",
      farmerBody.villageFarmer
    );
    // NEW FIELDS
    reqFormdata.append("data[attributes][number_belongs_to_id]", farmerBody.numberBelongsToFarmer);
    reqFormdata.append("data[attributes][mobile_type_id]", farmerBody.mobileTypeFarmer);
    
    if (this.state.selectedFEformData) {
      reqFormdata.append("data[attributes][assigned_fe_id]", FEid);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateFarmer = (farmerBody: any) => {
    this.setState({ updateFarmerLoading: true });
    const farmerId = this.state.farmerRowData.id;
    const FEid = this.state.selectedFEformData
      ? this.state.selectedFEformData.id
      : null;
    const farmerNewdob = moment(farmerBody.dateOfBirthFarmer).format(
      "DD/MM/YYYY"
    );
    const header = {
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateFarmerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}/${farmerId}?role_farmer=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    let reqFormdata = new FormData();
    reqFormdata.append("data[attributes][role_id]", "2");
    reqFormdata.append(
      "data[attributes][first_name]",
      farmerBody.firstnameFarmer
    );
    reqFormdata.append(
      "data[attributes][middle_name]",
      farmerBody.middlenameFarmer
    );
    reqFormdata.append("data[attributes][last_name]", farmerBody.surnameFarmer);
    reqFormdata.append(
      "data[attributes][phone_number]",
      farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][full_phone_number]",
      "+91" + farmerBody.mobileNumberFarmer
    );
    reqFormdata.append(
      "data[attributes][aadhaar_number]",
      farmerBody.adharcardNumberFarmer
    );
    reqFormdata.append("data[attributes][date_of_birth]", farmerNewdob);
    reqFormdata.append(
      "data[attributes][total_family_members]",
      farmerBody.totalFamilyMemberFarmer
    );
    reqFormdata.append(
      "data[attributes][highest_education_id]",
      farmerBody.educationFarmer
    );
    reqFormdata.append("data[attributes][state_id]", farmerBody.stateFarmer);
    reqFormdata.append("data[attributes][gender]", farmerBody.genderFarmer);
    reqFormdata.append(
      "data[attributes][district_id]",
      farmerBody.districtFarmer
    );
    reqFormdata.append("data[attributes][taluka_id]", farmerBody.talukaFarmer);
    reqFormdata.append(
      "data[attributes][village_id]",
      farmerBody.villageFarmer
    );
    // NEW FIELDS
    reqFormdata.append("data[attributes][number_belongs_to_id]", farmerBody.numberBelongsToFarmer);
    reqFormdata.append("data[attributes][mobile_type_id]", farmerBody.mobileTypeFarmer);

    reqFormdata.append("data[attributes][assigned_fe_id]", FEid);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // CRUD - FE API CALLS
  addFieldExecutive = (feBody: any) => {
    this.setState({ addFELoading: true });

    const dobFe = moment(feBody.dateOfBirthFE).format("DD/MM/YYYY");

    let feLocationsArr: any[] = [];
    if (this.state.selectedLocationArr.length) {
      this.state.selectedLocationArr.forEach((each: any) => {
        feLocationsArr.push({ village_id: each.id });
      });
    } else {
      feLocationsArr = [];
    }
    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };

    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFEApiCallId = requestMessageApi.messageId;

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_fe=${true}`
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBodyData = {
      data: {
        attributes: {
          role_id: 1,
          first_name: feBody.firstnameFE,
          last_name: feBody.lastnameFE,
          phone_number: feBody.mobileNumberFE,
          location: feBody.locationFE,
          accounts_villages_attributes: feLocationsArr,
          date_of_birth: dobFe,
          email: feBody.emailAddressFE,
          user_name: feBody.usernameFE,
          mobile_type_id: feBody.mobileDeviceModelFE,
          total_family_members: 0,
          full_phone_number: "+91" + feBody.mobileNumberFE,
        },
      },
    };

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBodyData)
    );

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  };
  updateFieldExecutive = (feNewBody: any) => {
    this.setState({ updateFELoading: true });

    const feId = this.state.feRowData.id;
    const dobFe = moment(feNewBody.dateOfBirthFE).format("DD/MM/YYYY");
    let feNewLocationsArr: any[] = [];
    if (this.state.selectedLocationArr.length) {
      this.state.selectedLocationArr.forEach((each: any) => {
        const isAssignedVillageIsNew: boolean = !this.state.defaultAssignedVillages?.some(
          (item) => item.attributes.village_id === each.id
        )
        if (isAssignedVillageIsNew) {
          feNewLocationsArr.push({ village_id: each.id });
        }
      });
    } else {
      feNewLocationsArr = [];
    }
    const updatedDeleteArray = this.state.deletedVillagesList?.map((item) => {
      for (const element of this.state.selectedLocationArr) {
        if (element.id === item.village_id) {
          item._destroy = false;
        }
      }
      return item;
    });

    const finalUpdatedVillageList = feNewLocationsArr.concat(
      updatedDeleteArray
    );
    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };

    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateFEApiCallId = requestMessageApi.messageId;

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}/${feId}?role_fe=${true}`
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBodyData = {
      data: {
        attributes: {
          role_id: 1,
          first_name: feNewBody.firstnameFE,
          last_name: feNewBody.lastnameFE,
          phone_number: feNewBody.mobileNumberFE,
          location: feNewBody.locationFE,
          accounts_villages_attributes: finalUpdatedVillageList,
          date_of_birth: dobFe,
          email: feNewBody.emailAddressFE,
          user_name: feNewBody.usernameFE,
          mobile_type_id: feNewBody.mobileDeviceModelFE,
          total_family_members: 0,
          full_phone_number: "+91" + feNewBody.mobileNumberFE,
        },
      },
      assign_farmer_ids: [],
    };
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBodyData)
    );

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  };

  // CRUD - ADMIN API CALLS
  addAdmin = (adminBody: any) => {
    this.setState({ addAdminLoading: true });

    const dob = moment(adminBody.dateOfBirthAdmin).format("DD/MM/YYYY");

    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAdminApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST_METHOD_TYPE
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_admin=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          first_name: adminBody.firstnameAdmin,
          last_name: adminBody.lastnameAdmin,
          email: adminBody.emailAddressAdmin,
          dob: dob,
          full_phone_number: "91" + adminBody.mobileNumberAdmin,
          user_name: adminBody.usernameAdmin,
          password: adminBody.passwordAdmin,
          password_confirmation: adminBody.confirmPasswordAdmin,
        },
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateAdmin = (adminNewBody: any) => {
    this.setState({ updateAdminLoading: true });

    const dob = moment(adminNewBody.dateOfBirthAdmin).format("DD/MM/YYYY");
    const adminId = this.state.adminRowData.id;

    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAdminApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}/${adminId}?role_admin=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          first_name: adminNewBody.firstnameAdmin,
          last_name: adminNewBody.lastnameAdmin,
          email: adminNewBody.emailAddressAdmin,
          dob: dob,
          phone_number: adminNewBody.mobileNumberAdmin,
          full_phone_number: "91" + adminNewBody.mobileNumberAdmin,
          user_name: adminNewBody.usernameAdmin,
          password: adminNewBody.passwordAdmin,
          password_confirmation: adminNewBody.confirmPasswordAdmin,
        },
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // CRUD - SA API CALLS
  addSuperAdmin = (superAdminBody: any) => {
    this.setState({ addSALoading: true });
    const dob = moment(superAdminBody.dateOfBirthAdmin).format("DD/MM/YYYY");

    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addSuperAdminApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST_METHOD_TYPE
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}?add_super_admin=${true}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          first_name: superAdminBody.firstnameAdmin,
          last_name: superAdminBody.lastnameAdmin,
          email: superAdminBody.emailAddressAdmin,
          dob: dob,
          full_phone_number: "91" + superAdminBody.mobileNumberAdmin,
          user_name: superAdminBody.usernameAdmin,
          password: superAdminBody.passwordAdmin,
          password_confirmation: superAdminBody.confirmPasswordAdmin,
        },
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateSuperAdmin = (superAdminNewBody: any) => {
    this.setState({ updateSALoading: true });
    const dob = moment(superAdminNewBody.dateOfBirthAdmin).format("DD/MM/YYYY");
    const superAdminId = this.state.superAdminRowData.id;

    const header = {
      "content-type": configJSON.jsonContentType,
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateSuperAdminApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAPiEndPoint}/${superAdminId}?role_super_admin=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    const requestBody = {
      data: {
        attributes: {
          role: "super_admin",
          first_name: superAdminNewBody.firstnameAdmin,
          last_name: superAdminNewBody.lastnameAdmin,
          email: superAdminNewBody.emailAddressAdmin,
          dob: dob,
          phone_number: superAdminNewBody.mobileNumberAdmin,
          full_phone_number: "91" + superAdminNewBody.mobileNumberAdmin,
          user_name: superAdminNewBody.usernameAdmin,
          password: superAdminNewBody?.passwordAdmin,
          password_confirmation: superAdminNewBody?.confirmPasswordAdmin,
        },
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // DELETE USERS API CALLS
  deleteUser = () => {
    let userId = this.state.deleteUserData?.id;
    this.setState({ deleteFarmerFEUserLoading: true });
    if (userId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      const header = {
        Accept: configJSON.jsonContentType,
        token: this.state.TokenValue,
      };
      this.deleteUserApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteUserApiEndPoint}/${userId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DELETE_METHOD_TYPE
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({ deleteFarmerFEUserLoading: false });
      this.setState({
        errorModalOpen: true,
        errorModalMsg: configJSON.AdminConsoleDefaults.userNotFound,
      });
      return false;
    }
  };
  deleteAdminUsers = () => {
    this.setState({ deleteAdminSAUserLoading: true })
    let userId = this.state.deleteAdminUserData?.id;
    let endpoint = "";
    if (this.state.deleteAdminUserData?.role === "admin") {
      endpoint = `${configJSON.deleteUserApiEndPoint
        }/${userId}?role_admin=${true}`;
    } else if (this.state.deleteAdminUserData?.role === "super_admin") {
      endpoint = `${configJSON.deleteUserApiEndPoint
        }/${userId}?role_super_admin=${true}`;
    }

    if (userId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      const header = {
        Accept: configJSON.jsonContentType,
        token: this.state.TokenValue,
      };
      this.deleteAdminUserApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DELETE_METHOD_TYPE
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({ deleteAdminSAUserLoading: false })
      this.setState({
        errorModalOpen: true,
        errorModalMsg: configJSON.AdminConsoleDefaults.userNotFound,
      });
      return false;
    }
  };

  // FARMER FORM API CALLS
  fetchNumberBeongsTo = () => {
    const requestMessageApi = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getNumberBeongsToApiCallId = requestMessageApi.messageId;
    requestMessageApi.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessageApi.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getNumberBeongsToAPiEndPoint);

    requestMessageApi.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiMethod);

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  }

  fetchEducationFarmer = () => {
    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getEducationFarmerApiCallId = requestMessageApi.messageId;
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationFarmerAPiEndPoint
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  };
  fetchStates = () => {
    this.setState({
      getDistrictData: [],
      getTalukaData: [],
      getVillageData: [],
    });
    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getStateApiCallId = requestMessageApi.messageId;
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateAPiEndPoint
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  };
  fetchDistrict = (stateId: string | number) => {
    if (!stateId || stateId === "") {
      this.setState({
        getDistrictData: [],
        getTalukaData: [],
        getVillageData: [],
      });
      return false;
    }
    this.setState({
      getDistrictData: [],
      getTalukaData: [],
      getVillageData: [],
    });
    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getDistrictApiCallId = requestMessageApi.messageId;
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDistrictAPiEndPoint}?state_id=${stateId}`
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTableAPiMethod
    );

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  };
  fetchTaluka = (districtId: string | number) => {
    this.setState({ getTalukaData: [], getVillageData: [] });

    if (!districtId || districtId === "") {
      this.setState({ getTalukaData: [], getVillageData: [] });
      return false;
    }
    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getTalukaApiCallId = requestMessageApi.messageId;
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTalukaAPiEndPoint}?district_id=${districtId}`
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  };
  fetchVillage = (villageId: string | number) => {
    this.setState({ getVillageData: [] });
    if (!villageId) {
      this.setState({ getVillageData: [], getFEData: [] });
      return false;
    }
    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getVillageApiCallId = requestMessageApi.messageId;
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageAPiEndPoint}?taluka_id=${villageId}`
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  };
  fetchFElist = () => {
    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getFElistApiCallId = requestMessageApi.messageId;
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFEListAPiEndPoint
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET_METHOD_TYPE
    );

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  };

  // FE FORM API CALLS
  fetchMobileDeviceModelFE = () => {
    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.jsonContentType,
      token: this.state.TokenValue,
    };
    this.getMobileDeviceModelFEApiCallId = requestMessageApi.messageId;
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMobileDeviceModelFEAPiEndPoint
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  };
  fetchAllVillageList = () => {
    const requestMessageApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.TokenValue,
    };
    this.getAllVillageListApiCallId = requestMessageApi.messageId;
    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageAPiEndPoint}`
    );

    requestMessageApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );

    runEngine.sendMessage(requestMessageApi.id, requestMessageApi);
  };

  // ASSIGN VILLAGE FILTER API CALLS
  getStates() {
    this.setState({
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      isDisabled: {
        ...this.state.isDisabled,
        district: true,
        village: true,
        taluka: true,
      },
    });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVillageFilterStateData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateList}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getDistricts() {
    let params = this.state.selectedState.id;
    if (this.state.selectedState.id) {
      this.setState({
        selectedDistrict: {
          name: "Select District",
          id: "",
        },
        selectedTaluka: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillage: {
          name: "Select Village",
          id: "",
        },
        isDisabled: {
          ...this.state.isDisabled,
          district: true,
          village: true,
          taluka: true,
        },
      });

      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.TokenValue,
      };

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVillageFilterDistrictData = getValidationsMsg.messageId;
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getDistrictList}?state_id=${params}`
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    } else {
      return false;
    }
  }
  getTalukas() {
    let params = this.state.selectedDistrict.id;
    if (this.state.selectedDistrict.id) {
      this.setState({
        selectedTaluka: {
          name: "Select Taluka",
          id: "",
        },
        selectedVillage: {
          name: "Select Village",
          id: "",
        },
        isDisabled: {
          ...this.state.isDisabled,
          taluka: true,
          village: true,
        },
      });
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.TokenValue,
      };

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVillageFilterTalukaData = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getTalukaList}?district_id=${params}`
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    } else {
      return false;
    }
  }
  getVillages() {
    let params = this.state.selectedTaluka.id;
    if (this.state.selectedTaluka.id) {
      this.setState({
        selectedVillage: {
          name: "Select Village",
          id: "",
        },
        isDisabled: {
          ...this.state.isDisabled,
          village: true,
        },
      });
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.TokenValue,
      };

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVillageFilterVillageData = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVillageList}?taluka_id=${params}`
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );

      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    } else {
      return false;
    }
  }

  // USER TABLE FILTER API CALL - STARTS
  getFieldExecutivesUT() {
    let params = this.state.selectedVillageUT?.id
    this.setState({
      isDisabledUT: {
        ...this.state.isDisabledUT,
        fe: true,
      },
      selectedFieldExecutiveUT: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const reqAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFieldExecutiveDataApiUTCallId = reqAPIMessage.messageId;

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFieldExecutiveList}?village_id=${params}`
    );

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(reqAPIMessage.id, reqAPIMessage);
  }
  getVillagesUT() {
    let params = this.state.selectedTalukaUT?.id
    this.setState({
      isDisabledUT: {
        ...this.state.isDisabledUT,
        village: true,
        fe: true
      },
      selectedVillageUT: {
        name: "Select Village",
        id: "",
      },
      selectedFieldExecutiveUT: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: ""
      },
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const reqAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVillageDataApiUTCallId = reqAPIMessage.messageId;

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageList}?taluka_id=${params}`
    );

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(reqAPIMessage.id, reqAPIMessage);
  }
  getTalukasUT() {
    let params = this.state.selectedDistrictUT?.id
    this.setState({
      isDisabledUT: {
        ...this.state.isDisabledUT,
        taluka: true,
        village: true,
        fe: true
      },
      selectedTalukaUT: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageUT: {
        name: "Select Village",
        id: "",
      },
      selectedFieldExecutiveUT: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: ""
      },
    });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const reqAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTalukaDataApiUTCallId = reqAPIMessage.messageId;

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTalukaList}?district_id=${params}`
    );

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(reqAPIMessage.id, reqAPIMessage);
  }
  getDistrictsUT() {
    let params = this.state.selectedStateUT?.id
    this.setState({

      selectedDistrictUT: {
        name: "Select District",
        id: "",
      },
      isDisabledUT: {
        ...this.state.isDisabledUT,
        district: true,
        village: true,
        taluka: true,
        fe: true
      },
      selectedTalukaUT: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageUT: {
        name: "Select Village",
        id: "",
      },
      selectedFieldExecutiveUT: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: ""
      },
    });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const reqAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDistrictDataApiUTCallId = reqAPIMessage.messageId;
    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDistrictList}?state_id=${params}`
    );

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(reqAPIMessage.id, reqAPIMessage);
  }
  getStatesUT() {
    this.setState({
      selectedDistrictUT: {
        name: "Select District",
        id: "",
      },
      selectedTalukaUT: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillageUT: {
        name: "Select Village",
        id: "",
      },
      selectedFieldExecutiveUT: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: ""
      },
      isDisabledUT: {
        ...this.state.isDisabledUT,
        district: true,
        village: true,
        taluka: true,
        fe: true
      },
    });
    const headers = {
      token: this.state.TokenValue,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStateDataApiUTCallId = reqAPIMessage.messageId;

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateList}`
    );

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    reqAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(reqAPIMessage.id, reqAPIMessage);
  }
  // USER TABLE FILTER ENDS

updateUserStatus = (userData: IuserStatusData, userStatus:boolean) =>{
  const farmerId = userData && userData.id
  if(farmerId){

    const headers = {
      token: this.state.TokenValue,
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const endpoint = `${configJSON.updateUserStatusAPiEndPoint}?id=${farmerId}`    
    const reqAPIMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.userStatusApiCallId = reqAPIMessage.messageId;

    reqAPIMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqAPIMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
  
    const reqBody: IrequestBodyUserStatus = {
      "data": {
        "attributes": {
          "active": userStatus
        }
      }
    } 
    reqAPIMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(reqBody));
    reqAPIMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.putAPiMethod);
  
    runEngine.sendMessage(reqAPIMessage.id, reqAPIMessage);
  }
}

handleSuccessUserStatus = (newStaus:boolean) =>{
if(this.state.userStatusData){
  const farmerData = [...this.state.farmerData]

  const updatedFarmerData = farmerData.map((each) => {
    if(each.id === this.state.userStatusData?.id){
      return {
        ...each,
        attributes: {
          ...each.attributes,
          active: newStaus // Set to the desired value, e.g., true
        }
      };      
    }
    return each;
  })
  this.setState({
    farmerData:updatedFarmerData,
    userStatusData:null
  })
}
  

}
  // Customizable Area End
}
