// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IIntialValuePestManagment, IPestManagementActivity, IIntialValuePestManagmentError, ISelectFarm,ISelectCrop, ISelectFarmDataResponse, IUnitDropdown, IUnitDropdownResponse, ISelectCropAttributesCropTypeId as IDropDownOptions } from "../../../components/src/interface.web";
import moment from "moment";
import { debounce, getSowingDate } from "../../../components/src/helper";
export const configJSON = require("./config");

type FarmIdTypes = string | undefined;
type CropIdTypes = string | string[] | undefined;
export interface Props {
    isPestManagmentActivity: boolean,
    selectedActivity: string | null | number,
    accountId: string | number;
    isEditActivity: boolean;
    selectedActivityFarmId: FarmIdTypes;
    selectedActivityCropId: any;
    selectedyear: number;
    handleCloseAddActivityModal: () => void,
    handleAddCropModalCallback: () => void;
    handleOpenFarmModalCallback: () => void;
}
interface S {
    authToken: string | null;
    searchName: string;
    loader: boolean;
    minimumDate: Date; 
    // DROPDOWN STATES
    farmNamesList: ISelectFarm[],
    cropNamesList: ISelectCrop[],
    unitOfPesticideQuantityList: IUnitDropdown[],
    unitOfBioAgentQuantityList: IUnitDropdown[],
    pestManagementList: IDropDownOptions[],
    bioAgentList: IDropDownOptions[],
    filteredBioAgentList: IDropDownOptions[];
    trapsList: IDropDownOptions[],
    pesticideNameList: IDropDownOptions[],
    filteredPesticideList: IDropDownOptions[];
    // FORMS
    selectedFarmId: string | number,
    selectedFarmIdError: string,
    selectedCropIdArr: any[],
    selectedCropIdError: string,
    pestManagnmentForms: IIntialValuePestManagment[],
    // ERROR
    isErrorModalOpen: boolean,
    ErrorModalTitle: string,
    ErrorModalMessage: string,
}
interface SS { }

const initialPestManagmentFormValue = {
    id: "",
    pest_management: "",
    type_of_trap: "",
    pesticide_name: "",
    bioagent_name: "",
    date: new Date(),
    machine_cost_for_spraying:"",
    number_of_trap:"",
    pesticide_cost:"",
    quantity_of_pesticide:"",
    quantity:"",
    cost_of_input:"",
    cost_of_trap:"",
    labor_cost:"",
    unit_of_quantity_pesticide_id: "",
    unit_of_quantity_id: "",
    errors: {
      pest_management: "",
      type_of_trap: "",
      pesticide_name: "",
      bioagent_name: "",
      date: "",
      machine_cost_for_spraying:"",
      number_of_trap:"",
      pesticide_cost:"",
      quantity_of_pesticide:"",
      quantity:"",
      cost_of_input:"",
      cost_of_trap:"",
      labor_cost:"",
      unit_of_quantity_pesticide_id: "",
      unit_of_quantity_id: "",
    }
}
// Customizable Area End

export default class PestManagmentActivityController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    fetchFarmNamesDataApiCallId: string = "";
    fetchCropNamesDataApiCallId: string = "";
    fetchPesticideQuantityUnitDataApiCallId: string = "";
    fetchBioAgentUnitDataApiCallId: string = "";
    fetchPestManagementApiCallId: string = "";
    fetchBioAgentApiCallId: string = "";
    fetchPesticideNameApiCallId: string = "";
    fetchTypeOfTrapsApiCallId: string = "";
    createPestManagementApiCallId: string = "";
    fetchActivityDetailsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            searchName: "",
            loader: false,
            minimumDate: moment().subtract(360, 'days').toDate(),
            // DROPDOWN STATES
            farmNamesList: [],
            cropNamesList: [],
            unitOfPesticideQuantityList: [],
            unitOfBioAgentQuantityList: [],
            pestManagementList: [],
            bioAgentList: [],
            filteredBioAgentList: [],
            trapsList: [],
            pesticideNameList: [],
            filteredPesticideList: [],
            // FORMS
            selectedFarmId: "",
            selectedFarmIdError: "",
            selectedCropIdArr: [],
            selectedCropIdError: "",
            pestManagnmentForms: [initialPestManagmentFormValue],
            // ERROR
            isErrorModalOpen: false,
            ErrorModalTitle: "",
            ErrorModalMessage: "",
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.fetchFarmNamesDataApiCallId:
                        this.handleFetchFarmNamesDataApiCallId(responseJson);
                        break;
                    case this.fetchCropNamesDataApiCallId:
                        this.handleFetchCropNamesDataApiCallId(responseJson);
                        break;
                    case this.fetchPesticideQuantityUnitDataApiCallId:
                        this.handleFetchPestQtyUnitApiCallId(responseJson);
                        break;
                    case this.fetchBioAgentUnitDataApiCallId:
                        this.handleFetchBioAgentUnitDataApiCallId(responseJson);
                        break;
                    case this.fetchPestManagementApiCallId: 
                        this.handleFetchPestManagementResponse(responseJson);
                        break;
                    case this.fetchBioAgentApiCallId: 
                        this.handleFetchBioAgentResponse(responseJson);
                        break;
                    case this.fetchPesticideNameApiCallId: 
                        this.handleFetchPesticideNameApiResponse(responseJson);
                        break;
                    case this.fetchTypeOfTrapsApiCallId: 
                        this.handleFetchTypeOfTrapsApiResponse(responseJson);
                        break;
                    case this.createPestManagementApiCallId:
                        this.handleCreatePestManagementApiResponse(responseJson);
                        break;
                    case this.fetchActivityDetailsApiCallId:
                        this.handleFetchActivityDetailsApiCallId(responseJson);
                        break;
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.resetErrorObject(initialPestManagmentFormValue.errors)
        if(this.props.isEditActivity) {
            this.fetchFarmData(this.props.accountId);
            this.fetchCropsData(this.props.accountId);
            this.fetchPesticideQuantityUnitData()
            this.fetchBioAgentQuantityUnitData()
            this.fetchPestManagementData()
            this.fetchBioAgentData()
            this.fetchPesticideNamesData()
            this.fetchTypeOfTrapsData()
          }
          if(!this.props.isEditActivity){
            this.fetchFarmData(this.props.accountId);
            this.resetInitialFormValues()
            this.fetchPesticideQuantityUnitData()
            this.fetchBioAgentQuantityUnitData()
            this.fetchPestManagementData()
            this.fetchBioAgentData()
            this.fetchPesticideNamesData()
            this.fetchTypeOfTrapsData()
          }
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<S>,
        snapshot?: SS | undefined
      ): void {
        if (prevState.selectedFarmId !== this.state.selectedFarmId) {
          this.fetchCropsData(this.props.accountId);
        }
    }

    // API CALLS STARTS
    fetchFarmData = (accountId: string | number | null) => {
        if (!accountId) return
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
            type: "admin_user"
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchFarmNamesDataApiCallId = requestMessage.messageId;
        const endpoint = `/mobile_and_web/farm_dairy/selected_farm?user_id=${this.props.accountId}`
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fetchCropsData = (accountID: string | number) => {
        this.setState({ cropNamesList: [] })
        const headers = {
            token: this.state.authToken,
            type: "admin_user",
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchCropNamesDataApiCallId = requestMessage.messageId;
        const endpoint = `mobile_and_web/farm_dairy/selected_crop?farm_id=${this.state.selectedFarmId  ?? this.props.selectedActivityFarmId}&user_id=${this.props.accountId}`;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    fetchPesticideQuantityUnitData = () => {
        const headers = { token: this.state.authToken, "Content-Type": configJSON.validationApiContentType };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchPesticideQuantityUnitDataApiCallId = requestMessage.messageId;
        const endpoint = `admin/bx_block_dropdown/unit_dropdowns?activity=pest_management&dropdown=unit_of_pesticide_quantity_pest_management`
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fetchBioAgentQuantityUnitData = () => {
        const headers = { token: this.state.authToken, "Content-Type": configJSON.validationApiContentType };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchBioAgentUnitDataApiCallId = requestMessage.messageId;
        const endpoint = `admin/bx_block_dropdown/unit_dropdowns?activity=pest_management&dropdown=unit_of_bio_agent_quantity_pest_management`
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fetchPestManagementData = () => {
        const headers = {
            token: this.state.authToken,"Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchPestManagementApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/admin/bx_block_farm_dairy/pest_managments");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fetchBioAgentData = () => {
        const headers = {
            token: this.state.authToken,"Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchBioAgentApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "admin/bx_block_farm_dairy/bio_agents");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    fetchPesticideNamesData = () => {
        const headers = {
            token: this.state.authToken,"Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchPesticideNameApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/admin/bx_block_farm_dairy/pesticides");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fetchTypeOfTrapsData = () => {
        const headers = {
            token: this.state.authToken,"Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchTypeOfTrapsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/admin/bx_block_farm_dairy/type_of_traps");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createPestManagement = () => {
        this.setState({ loader: true})
        let formValues = [...this.state.pestManagnmentForms];
        const headers = {
          token: this.state.authToken,
          "Content-Type": configJSON.validationApiContentType,
          type: "admin_user"
        };
    
        let formData = formValues.map((item) => {
          return {
            ...(item.id ? { id: item.id } : {}),
            pest_managment_id: item.pest_management,
            date_of_application: moment(item.date).format("YYYY-MM-DD"),

            pesticide_id: item.pesticide_name,
            machine_cost_of_spraying: item.machine_cost_for_spraying,
            quantity_pesticide: item.quantity_of_pesticide,
            unit_of_quantity_pesticide_id: item.unit_of_quantity_pesticide_id,
            pesticide_cost: item.pesticide_cost,
            labor_cost: item.labor_cost,
           
            type_of_trap_id: item.type_of_trap,
            number_of_trap: item.number_of_trap,
            cost_of_trap: item.cost_of_trap,

            bio_agent_id: item.bioagent_name,
            quantity: item.quantity,
            quantity_unit_id: item.unit_of_quantity_id,
            cost_of_input: item.cost_of_input,

            account_id: this.props.accountId,
            pest_management_crops_attributes: this.reShapeSelectedCropArray(),
            land_detail_id: this.state.selectedFarmId,
          };
        });
    
        let httpBody = { pest_management: formData };
    
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createPestManagementApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `mobile_and_web/farm_dairy/activity_pest_managements?user_id=${this.props.accountId}`);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    fetchActivityDetails = () => {
        const farmId = this.props.selectedActivityFarmId
        const cropIds = this.props.selectedActivityCropId
        if (farmId && cropIds && this.props.accountId) {
          const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
            type: "admin_user"
          };
    
          const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
          this.fetchActivityDetailsApiCallId = reqMessage.messageId;
          const endpoint = `/mobile_and_web/farm_dairy/activity_pest_managements?user_id=${this.props.accountId}&year=${this.props.selectedyear}&land_id=${farmId}&crop_id=${cropIds}&for_edit=${true}`
    
          reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
          reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
          reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    
          runEngine.sendMessage(reqMessage.id, reqMessage);
        }
      }
    // API CALLS ENDS

    // HANDLE API CALLS STARTS
    handleFetchFarmNamesDataApiCallId = (responseJson: ISelectFarmDataResponse) => {
        if (responseJson && responseJson.data && responseJson.data.length) {
            this.setState({ farmNamesList: responseJson.data })
        } else {
            this.setState({ farmNamesList: [] })
        }
    }

    handleFetchCropNamesDataApiCallId = (responseJson: { data: ISelectCrop[] }) => {
        if (responseJson && responseJson.data && responseJson.data.length) {
            this.setState({ cropNamesList: responseJson.data }, () => {
                if (this.props.isEditActivity) {
                    this.fetchActivityDetails();
                }
            });
        } else {
            this.setState({ cropNamesList: [] });
        }
    };

    handleFetchPestQtyUnitApiCallId = (response: IUnitDropdownResponse | null | undefined) => {
        let updatedFormValues = [...this.state.pestManagnmentForms];
        if (response && response?.data.length > 1) {
          this.setState({ unitOfPesticideQuantityList: response.data });
        } else if (response?.data.length === 1) {
          const unitId = response.data[0]?.id;
          updatedFormValues = updatedFormValues.map((item) => ({
            ...item,
            unit_of_quantity_pesticide_id: unitId ?? null,
          }));
          this.setState({ unitOfPesticideQuantityList: response.data, pestManagnmentForms: updatedFormValues });
        } else {
          this.setState({ unitOfPesticideQuantityList: [] });
        }
    }

    handleFetchBioAgentUnitDataApiCallId = (response: IUnitDropdownResponse | null | undefined) => {
        let updatedFormValues = [...this.state.pestManagnmentForms];
        if (response && response.data.length > 1) {
          this.setState({ unitOfBioAgentQuantityList: response.data });
        } else if (response?.data?.length === 1) {
          const unitId = response.data[0]?.id;
          updatedFormValues = updatedFormValues.map((item) => ({
            ...item,
            unit_of_quantity_id: unitId ?? null,
          }));
          this.setState({ unitOfBioAgentQuantityList: response.data, pestManagnmentForms: updatedFormValues });
        } else {
          this.setState({ unitOfBioAgentQuantityList: [] });
        }
    }

    handleFetchPestManagementResponse = (responseJson: IDropDownOptions[]) => {
        if (responseJson && responseJson.length > 0 ) {
            this.setState({ pestManagementList: responseJson })
        } else {
            this.setState({ pestManagementList: [] })
        }
    }

    handleFetchBioAgentResponse = (responseJson: IDropDownOptions[]) => {
        if (responseJson ) {
            this.setState({ bioAgentList: responseJson,  filteredBioAgentList: responseJson })
        } else {
            this.setState({ bioAgentList: [] , filteredBioAgentList: [] })
        }
    }

    handleFetchPesticideNameApiResponse = (responseJson: IDropDownOptions[]) => {
        if (responseJson ) {
            this.setState({ pesticideNameList: responseJson, filteredPesticideList: responseJson })
        } else {
            this.setState({ pesticideNameList: [], filteredPesticideList: [] })
        }
    }

    handleFetchTypeOfTrapsApiResponse = (responseJson: IDropDownOptions[]) => {
        if (responseJson ) {
            this.setState({ trapsList: responseJson })
        } else {
            this.setState({ trapsList: [] })
        }
    }

    handleCreatePestManagementApiResponse = (response: any) => {
        this.setState({ loader: false})
        if (response?.errors?.length) {
            this.setState({
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: response.errors[0] || "Something went wrong!",
            })
        } else if (response[0].data) {
            this.setState({
                isErrorModalOpen: true,
                ErrorModalTitle: "Success",
                ErrorModalMessage: `Pest management activity ${this.props.isEditActivity ? "updated" : "added"} successfully.`,
            })
        }
    }

    handleFetchActivityDetailsApiCallId = (response: { data: IPestManagementActivity[] }) => {
        if (response?.data?.length > 0) {
            this.formateData(response.data)
        } 
    }

    // HANDLE API CALLS ENDS

    reShapeSelectedCropArray = () => {
        let finalArray = []

        const existingCropIdArray = [...this.state.selectedCropIdArr]
        finalArray = existingCropIdArray.map((cropId) => ({ "crop_id": cropId }))

        return finalArray
    }

    formateData = (dataArray: IPestManagementActivity[]) => {
        let updateInitalValue = []
        const filteredData = dataArray.filter((item) => item.attributes.freeze_record !== true);
        updateInitalValue = filteredData.map((item: IPestManagementActivity) => {
            const momentObject = moment(item.attributes.date_of_application);
            const dateObject = momentObject.toDate();
            return {
                id: item.id,
                pest_management: item.attributes.pest_managment_id ? item.attributes.pest_managment_id[0].id.toString() : "",
                date: dateObject,
                machine_cost_for_spraying: item.attributes.machine_cost_of_spraying?.toString() ?? "",
                type_of_trap: item.attributes.type_of_trap_id ? item.attributes.type_of_trap_id[0].id.toString() : "",
                pesticide_name: item.attributes.pesticide_id ? item.attributes.pesticide_id[0].id.toString() : "" ,
                bioagent_name: item.attributes.bio_agent_id ? item.attributes.bio_agent_id[0].id.toString() : "",
                quantity_of_pesticide: item.attributes.quantity_pesticide?.toString(),
                quantity: item.attributes.quantity?.toString(),
                cost_of_trap: item.attributes.cost_of_trap?.toString(),
                labor_cost: item.attributes.labor_cost?.toString(),
                unit_of_quantity_pesticide_id: item.attributes.unit_of_quantity_pesticide_id ? item.attributes.unit_of_quantity_pesticide_id[0].id.toString() : "",
                unit_of_quantity_id: item.attributes.quantity_unit_id ? item.attributes.quantity_unit_id[0].id.toString() : "",          
                number_of_trap: item.attributes.number_of_trap !== undefined ? item.attributes.number_of_trap?.toString() : null,
                pesticide_cost: item.attributes.pesticide_cost?.toString(),
                cost_of_input: item.attributes.cost_of_input?.toString(),
                
                errors: {
                    pest_management: "",
                    type_of_trap: "",
                    pesticide_name: "",
                    bioagent_name: "",
                    date: this.validateMinDate(moment(dateObject).toDate(), this.props.selectedActivityCropId as number[]),
                    machine_cost_for_spraying:"",
                    number_of_trap:"",
                    pesticide_cost:"",
                    quantity_of_pesticide:"",
                    quantity:"",
                    cost_of_input:"",
                    cost_of_trap:"",
                    labor_cost:"",
                    unit_of_quantity_pesticide_id: "",
                    unit_of_quantity_id: "",
                }
            }
        })
        this.setState({
            selectedFarmId: this.props.selectedActivityFarmId ? this.props.selectedActivityFarmId : "",
            selectedCropIdArr: this.props.selectedActivityCropId?.length ?  this.props.selectedActivityCropId : [],
            pestManagnmentForms: updateInitalValue,
            minimumDate: getSowingDate(this.props.selectedActivityCropId as number[], this.state.cropNamesList) ?? moment().subtract(360, 'days').toDate(),
          })
    }
    
    updateUnitBasedOnListLength = (unitList: IUnitDropdown[], unitIdKey: string) => {
        if (unitList.length === 1) {
          const unitId = unitList[0]?.id;
          return {
            ...initialPestManagmentFormValue,
            [unitIdKey]: unitId ?? null,
            errors: {
              ...initialPestManagmentFormValue.errors,
            },
          };
        }
        return initialPestManagmentFormValue; 
    };
      

    handleInputChange = (
        event: { target: { name: string; value: number | string | Date } },
        index: number,
        isOptional?: boolean,
        isMinMax?: boolean,
        isPestManagementField?: boolean 
    ) => {
        const { name, value } = event.target;
        const updatedFormValues = [...this.state.pestManagnmentForms];
        if(isPestManagementField) {
            let errors = this.resetErrorObject(updatedFormValues[index].errors)
            updatedFormValues[index] = {
                id: "", pest_management: value as string,
                type_of_trap: "", pesticide_name: "",bioagent_name: "", date: new Date(), machine_cost_for_spraying: "", number_of_trap: "",pesticide_cost:"",quantity_of_pesticide:"",  quantity:"", cost_of_input:"", cost_of_trap:"", labor_cost:"", 
                unit_of_quantity_pesticide_id: "", 
                unit_of_quantity_id: "",
                errors: {
                    ...errors,
                },
            };

            let newInitialFormValues = this.updateUnitBasedOnListLength(
                this.state.unitOfPesticideQuantityList,
                "unit_of_quantity_pesticide_id"
            );

            newInitialFormValues = this.updateUnitBasedOnListLength(
                this.state.unitOfBioAgentQuantityList,
                "unit_of_quantity_id"
            );

            if (this.state.unitOfBioAgentQuantityList.length === 1 && this.state.unitOfPesticideQuantityList.length === 1) {
                const unitIdBio = this.state.unitOfBioAgentQuantityList[0]?.id;
                const unitIdPest = this.state.unitOfPesticideQuantityList[0]?.id;
                newInitialFormValues = {
                    ...initialPestManagmentFormValue,
                    unit_of_quantity_id: unitIdBio ?? null,
                    unit_of_quantity_pesticide_id: unitIdPest ?? null,
                    errors: {
                        ...initialPestManagmentFormValue.errors,
                        [name]: value && ""
                    }
                };
            }
            updatedFormValues[index] = {
                ...newInitialFormValues,
                [name]: value ?? null,
                errors: { ...newInitialFormValues.errors, [name]: value && "" }
            };
        }
        else {
            if (name in updatedFormValues[index]) {
            const { error, newError } = this.handleErrorText(value, name, index, isOptional, isMinMax);
            updatedFormValues[index] = {
                ...updatedFormValues[index],
                [name]: value ?? null,
                errors: {
                    ...updatedFormValues[index].errors,
                    [name]: error,
                    ...(newError || {})
                }
            };
        }}
        this.setState({ pestManagnmentForms: updatedFormValues });
    };

    handleErrorText = (value: number | Date | string, fieldName: string, index: number, isOptional?: boolean, isMinMax?: boolean,) => {
        let error = "";
        let newError: { [key: string]: string } | null = null;
        error = this.handleMinMaxErrors(fieldName, value, isOptional, isMinMax)

        if (fieldName == "quantity") {
            let fuelValueErrors = this.handleFuelValueChange(value, index, isOptional)
            error = fuelValueErrors.error;
            newError = fuelValueErrors.newError
        }
        if (fieldName == "unit_of_quantity_id") {
            let fuelValueErrors = this.handleFuelUnitChange(value, index, isOptional)
            error = fuelValueErrors.error;
            newError = fuelValueErrors.newError
        }
        if (fieldName == "quantity_of_pesticide") {
            let fuelValueErrors = this.handlePesticideQuantityChange(value, index, isOptional)
            error = fuelValueErrors.error;
            newError = fuelValueErrors.newError
        }
        if (fieldName == "unit_of_quantity_pesticide_id") {
            let fuelValueErrors = this.handlePesticideUnitChange(value, index, isOptional)
            error = fuelValueErrors.error;
            newError = fuelValueErrors.newError
        }
        if (fieldName == "date") {
            error = value ? this.validateMinDate(value as Date, this.state.selectedCropIdArr) : "Please enter Date";
        }
        return { error, newError };
    };

    handleMinMaxErrors = (fieldName: string, value: number | Date | string, isOptional?: boolean, isMinMax?: boolean) => {
        const fieldErrors = {
            cost_of_input: { min: 0, max: 100000, message: "Cost of input should not be more than 100000" },
            labor_cost: { min: 0, max: 50000, message: "Labor cost should not be more than 50000" },
            pesticide_cost: { min: 0, max: 200000, message: "Pesticide cost should not be more than 200000" },
            machine_cost_for_spraying: { min: 0, max: 100000, message: "Machine cost for spraying should not be more than 10000" },
            number_of_trap: { min: 0, max: 10000, message: "Number of trap should not be more than 10000" },
            cost_of_trap: { min: 0, max: 150000, message: "Cost of trap should not be more than 150000" },
        };

        const fieldConfig = fieldErrors[fieldName as keyof typeof fieldErrors];
        if (fieldConfig) {
            if (value) {
                return isMinMax ? "" : this.handleNestedCondition(Number(value), fieldConfig.min, fieldConfig.max, fieldConfig.message);
            } else {
                return isOptional ? "" : `Please enter ${fieldName.replace(/_/g, " ")}`;
            }
        } else {
            return "";
        }
    };

    handleNestedCondition = (value: number, minValue: number, maxValue: number, message: string) => {
        if (value < minValue || value > maxValue) {
            return message
        } else {
            return ""
        }
    }

    handleFuelValueChange = (value: number | Date | string, index: number, isOptional?: boolean) => {
        const { pestManagnmentForms, unitOfBioAgentQuantityList } = this.state;
        let error = "";
        let newError: { [key: string]: string } | null = null;
        error = value ? "" : "Please enter quantity";
        newError = {
            unit_of_quantity_id: pestManagnmentForms[index].unit_of_quantity_id ? "" : "Please enter quantity"
        }
        if (!value && !pestManagnmentForms[index].unit_of_quantity_id && isOptional) {
            error = ""; newError = { unit_of_quantity_id: "" }
        }
        if (!value && isOptional && pestManagnmentForms[index].unit_of_quantity_id && unitOfBioAgentQuantityList.length === 1) {
            error = "";
        }
        return { error, newError };
    }

    handlePesticideQuantityChange = (value: number | Date | string, index: number, isOptional?: boolean) => {
        const { pestManagnmentForms, unitOfPesticideQuantityList } = this.state;
        let error = "";
        let newError: { [key: string]: string } | null = null;
        error = value ? "" : "Please enter quantity of pesticide";
        newError = {
            unit_of_quantity_pesticide_id: pestManagnmentForms[index].unit_of_quantity_pesticide_id ? "" : "Please enter quantity of pesticide"
        }
        if (!value && !pestManagnmentForms[index].unit_of_quantity_pesticide_id && isOptional) {
            error = ""; newError = { unit_of_quantity_pesticide_id: "" }
        }
        if (!value && isOptional && pestManagnmentForms[index].unit_of_quantity_pesticide_id && unitOfPesticideQuantityList.length === 1) {
            error = "";
        }
        return { error, newError };
    }

    handleFuelUnitChange = (value: number | Date | string, index: number, isOptional?: boolean) => {
        const { pestManagnmentForms, unitOfBioAgentQuantityList } = this.state;
        let error = "";
        let newError: { [key: string]: string } | null = null;
        error = value ? "" : "Please enter quantity";
        newError = {
            quantity: pestManagnmentForms[index].quantity ? "" : "Please enter quantity"
        }
        if (!value && !pestManagnmentForms[index].quantity && isOptional) {
            error = ""; newError = { quantity: "" }
        }
        if (!value && isOptional && pestManagnmentForms[index].quantity && unitOfBioAgentQuantityList.length === 1) {
            error = "";
        }
        return { error, newError }
    }

    handlePesticideUnitChange = (value: number | Date | string, index: number, isOptional?: boolean) => {
        const { pestManagnmentForms, unitOfPesticideQuantityList } = this.state;
        let error = "";
        let newError: { [key: string]: string } | null = null;
        error = value ? "" : "Please enter quantity of pesticide";
        newError = {
            quantity_of_pesticide: pestManagnmentForms[index].quantity_of_pesticide ? "" : "Please enter quantity of pesticide"
        }
        if (!value && !pestManagnmentForms[index].quantity_of_pesticide && isOptional) {
            error = ""; newError = { quantity_of_pesticide: "" }
        }
        if (!value && isOptional && pestManagnmentForms[index].quantity_of_pesticide && unitOfPesticideQuantityList.length === 1) {
            error = "";
        }
        return { error, newError }
    }

    resetErrorObject(data: IIntialValuePestManagmentError) {
        Object.keys(data).forEach((objKey) => {
            if (objKey !== "date") {
                data[objKey as keyof IIntialValuePestManagmentError] = "";
            }
        });
        return data;
    }

    debouncedSearchNutrient = debounce((value: string) => this.handleFilteredBioAgentList(value), 500);

    resetSearchNuturientData = () => {
        this.setState({ searchName: "" }, () => {
            this.debouncedSearchNutrient("")
        })
    }

    handleFilteredBioAgentList = (searchQuery: string) => {
        this.setState({ searchName: searchQuery })
        const query = searchQuery?.toLowerCase() || "";

        const filteredArray = query ? this.state.bioAgentList.filter((value: IDropDownOptions) => value.name_english.toLowerCase().includes(query)) : this.state.bioAgentList;
        this.setState({ filteredBioAgentList: filteredArray });
    }

    handleFilteredPesticideList = (searchQuery: string) => {
        this.setState({ searchName: searchQuery })
        const query = searchQuery?.toLowerCase() || "";

        const filteredArray = query ? this.state.pesticideNameList.filter((value: IDropDownOptions) => value.name_english.toLowerCase().includes(query)) : this.state.pesticideNameList;
        this.setState({ filteredPesticideList: filteredArray });
    }

    getLabelFromId = (valueId: string | number, unitList: IUnitDropdown[]) => {
        let label = ""
        const firstUnitId = unitList[0]?.id;
        label = !firstUnitId ? "" : unitList[0]?.attributes.name;
    
        return label
      }
    
  
    resetInitialFormValues = () => {
        this.setState({
            selectedFarmId: "",
            selectedFarmIdError: "",
            selectedCropIdArr: [],
            selectedCropIdError: "",
            pestManagnmentForms: [
                initialPestManagmentFormValue,
            ]        
        })
    }

    handleCloseHarvestActivityModal = () => {
        this.props.handleCloseAddActivityModal()
        this.resetInitialFormValues()
    }

    // ERROR MODAL FUNCTIONS
    handleCloseErrorModal = () => {
        this.setState({
            isErrorModalOpen: false,
            ErrorModalTitle: "",
            ErrorModalMessage: "",
           
        })
        if (this.state.ErrorModalTitle.toLocaleLowerCase() == "success") {
            this.handleCloseHarvestActivityModal()
        }
        
    }

    isCropTypeMono = (cropId: string | number) => {
        let isCropTypeMono = false

        const matchedObj = this.state.cropNamesList.find((item) => (String(item.id) === String(cropId)) && String(item.attributes.crop_name_id.harvest_type).includes("mono"))
        if (matchedObj && cropId) {
            isCropTypeMono = true
        } else {
            isCropTypeMono = false
        }

        return isCropTypeMono
    }

    setFormValue = (name: string, value: unknown, formIndex: number, errorMessage: string) => {
        const updatedFormValues = [...this.state.pestManagnmentForms];

        updatedFormValues[formIndex] = {
            ...updatedFormValues[formIndex],
            [name]: value,
            errors: {
                ...updatedFormValues[formIndex].errors,
                [name]: errorMessage
            },
        };
        this.setState({ pestManagnmentForms: updatedFormValues });

    }

    checkValidationFoliar = (entry: IIntialValuePestManagment) => {
        entry.errors.pesticide_name = !entry.pesticide_name ? "Please select pesticide name" : "";
        entry.errors.machine_cost_for_spraying = !entry.machine_cost_for_spraying ? "Please enter machine cost for spraying": "";
        entry.errors.date = this.validateMinDate(entry.date, this.state.selectedCropIdArr as number[]) ? "Please enter date" : "";
        entry.errors.quantity_of_pesticide = !(entry.quantity_of_pesticide && entry.unit_of_quantity_pesticide_id)  ? "Please enter pesticide quantity" : ""
        entry.errors.pesticide_cost = !entry.pesticide_cost? "Please enter pesticide cost" : ""
        entry.errors.labor_cost = !entry.labor_cost ? "Please enter labor cost" : ""
        return !entry.errors.labor_cost && !entry.errors.pesticide_cost && !entry.errors.pesticide_name && !entry.errors.machine_cost_for_spraying && !entry.errors.quantity_of_pesticide && !entry.errors.date;
    }

    checkValidationTrap = (entry: IIntialValuePestManagment) => {
        entry.errors.cost_of_trap = !entry.cost_of_trap ? "Please enter cost of trap" : ""
        entry.errors.number_of_trap = !entry.number_of_trap ? "Please enter number of trap" : ""
        entry.errors.type_of_trap = !entry.type_of_trap? "Please select type of trap" : "";
        entry.errors.labor_cost = !entry.labor_cost ? "Please enter labor cost" : ""
        entry.errors.date = this.validateMinDate(entry.date, this.state.selectedCropIdArr as number[]) ? "Please enter date" : "";
        return !entry.errors.cost_of_trap && !entry.errors.number_of_trap && !entry.errors.type_of_trap && !entry.errors.labor_cost && !entry.errors.date;
    }

    checkValidationBioAgent = (entry: IIntialValuePestManagment) => {
        entry.errors.date = this.validateMinDate(entry.date, this.state.selectedCropIdArr as number[]) ? "Please enter date" : "";
        entry.errors.bioagent_name = !entry.bioagent_name ? "Please select bioagent name" : ""
        entry.errors.quantity = !(entry.quantity && entry.unit_of_quantity_id)  ? "Please enter quantity": ""
        entry.errors.cost_of_input = !entry.cost_of_input ? "Please enter cost of input" : ""
        entry.errors.labor_cost = !entry.labor_cost ? "Please enter labor cost" : ""
        return !entry.errors.labor_cost && !entry.errors.cost_of_input && !entry.errors.quantity && !entry.errors.bioagent_name && !entry.errors.date;
    }

    checkDefaultForm = (entry: IIntialValuePestManagment) => {
        entry.errors.pest_management = !entry.pest_management ? "Please select pest managment" : "";
        entry.errors.date = this.validateMinDate(entry.date, this.state.selectedCropIdArr as number[]) ? "Please enter date" : "";
        entry.errors.pesticide_cost = !entry.pesticide_cost? "Please enter pesticide cost" : ""   ;   
        entry.errors.quantity_of_pesticide =  !(entry.quantity_of_pesticide && entry.unit_of_quantity_pesticide_id) ? "Please enter pesticide quantity" : ""
        return !entry.errors.pest_management && !entry.errors.pesticide_cost  && !entry.errors.quantity_of_pesticide && !entry.errors.date
    }

    isDataComplete = () => {
        const { pestManagementList } = this.state;
        let valid = true;
        const updatedFormValues = [...this.state.pestManagnmentForms];
        const foliarId = pestManagementList.find((item: IDropDownOptions) => item.name.toLowerCase().includes("foliar"))?.id;
        const trapId = pestManagementList.find((item: IDropDownOptions) => item.name.toLowerCase().includes("trap"))?.id;
        const bioAgentId = pestManagementList.find((item: IDropDownOptions) => item.name.toLowerCase().includes("bio"))?.id;
        for (const entry of updatedFormValues) {
            switch (entry.pest_management.toString()) {
                case (foliarId?.toString()):
                    valid = valid && this.checkValidationFoliar(entry);
                    break;
                case (trapId?.toString()):
                    valid = valid && this.checkValidationTrap(entry)
                    break;
                case (bioAgentId?.toString()):
                    valid = valid && this.checkValidationBioAgent(entry)
                    break;
                default:
                    valid = valid && this.checkDefaultForm(entry)
                    break;
            }
        }
        if (!valid) {
            this.setState({ pestManagnmentForms: updatedFormValues });
        }
        return valid;
    }
    
    isFormComplete = () => {
        let valid = true;
        if (!this.state.selectedFarmId) {
            valid = false;
            this.setState((prev) => ({
                ...prev,
                selectedFarmIdError: "Please select Farm",
            }));
        }
        if (!this.state.selectedCropIdArr.length) {
            valid = false;
            this.setState((prev) => ({
                ...prev,
                selectedCropIdError: "Please select Crop",
            }));
        }
        if (!this.isDataComplete()) {
            valid = false;
        }
        return valid;
    }

    handleAddAnotherEntry = () => {
         if (!this.isFormComplete() || !this.isDataComplete()) {
             this.setState({
                 isErrorModalOpen: true,
                 ErrorModalTitle: "Error",
                 ErrorModalMessage: "Please fill the existing forms with valid data."
             })
         } else {
            let cloneFormValues = [...this.state.pestManagnmentForms];
            this.resetErrorObject(initialPestManagmentFormValue.errors)
            let newInitialFormValues: IIntialValuePestManagment = initialPestManagmentFormValue;
            if (this.state.unitOfBioAgentQuantityList.length === 1) {
              const unitId = this.state.unitOfBioAgentQuantityList[0]?.id;
              newInitialFormValues = {
                ...initialPestManagmentFormValue,
                unit_of_quantity_id: unitId ?? null,
                errors: {
                  ...initialPestManagmentFormValue.errors
                }
              };
            }
            if (this.state.unitOfPesticideQuantityList.length === 1) {
                const unitId = this.state.unitOfPesticideQuantityList[0]?.id;
                newInitialFormValues = {
                    ...initialPestManagmentFormValue,
                  unit_of_quantity_pesticide_id: unitId ?? null,
                  errors: {
                    ...initialPestManagmentFormValue.errors
                  }
                };
              }
            cloneFormValues.push(newInitialFormValues);
            this.setState({ pestManagnmentForms: cloneFormValues });
         }
    };

    onSubmitPestManagmentForm = () => {
        if (!this.isFormComplete()) {
            this.setState({
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: "Please fill the existing forms with valid data.",
            });
            return;
        }
        this.createPestManagement()
    }

    handleRemoveActivity = (index: number) => {
        let newGapFillingValues = this.state.pestManagnmentForms;
        newGapFillingValues.splice(index, 1);
        this.setState({ pestManagnmentForms: newGapFillingValues });
    };

    validateMinDate = (selectedDate: Date, cropValue?: number[]) => {
        const minimumDate = (cropValue && cropValue.length > 0 && getSowingDate(cropValue, this.state.cropNamesList)) || moment().subtract(361, 'days').toDate();
        return (selectedDate >= minimumDate && selectedDate <= new Date()) ? "" : "Please enter valid date";
    };

    handleFarmChange = (value: string) => {
        const updatedFormValues = this.state.pestManagnmentForms.map((item) => ({
            ...item,
            errors: { ...item.errors, date: this.validateMinDate(item.date, []) },
        }));

        this.setState({
            selectedFarmId: value as string,
            selectedCropIdArr: [],
            selectedFarmIdError: "",
            minimumDate: moment().subtract(360, 'days').toDate(),
            pestManagnmentForms: updatedFormValues,
        }, () => {
            this.fetchCropsData(this.props.accountId);
        });
    };

    handleChangeSelectCrop = (value: string[] | number[] | unknown) => {
        const minimumDate = getSowingDate(value as number[], this.state.cropNamesList) || moment().subtract(360, 'days').toDate();
        this.setState({
            selectedCropIdArr: value as number[],
            selectedCropIdError: "", minimumDate
        })
        const updatedFormValues = [...this.state.pestManagnmentForms];
        this.state.pestManagnmentForms.map((item, index) => {
            updatedFormValues[index] = {
                ...updatedFormValues[index],
                errors: {
                    ...updatedFormValues[index].errors,
                    date: this.validateMinDate(item.date, value as number[]),
                }
            };
        });
        this.setState({ pestManagnmentForms: updatedFormValues });
    }

    handleAddCrop = () => {
        this.props.handleCloseAddActivityModal();
        this.props.handleAddCropModalCallback();
    };
    handleAddFarm = ()  => {
        this.props.handleCloseAddActivityModal();
        this.props.handleOpenFarmModalCallback();
    };
    // Customizable Area End
}