export const imgic_password_invisible = require("../../global_assets/ic_password_invisible.png");
export const imgic_password_visible = require("../../global_assets/ic_password_visible.png");
export const img3a3819e8ff74c051c05abba030d2b5f42f6c6a49 = require("../../global_assets/3a3819e8ff74c051c05abba030d2b5f42f6c6a49.png");
export const img885cd05e16c5353647694c8708ebf06d58be0bc0 = require("../../global_assets/885cd05e16c5353647694c8708ebf06d58be0bc0.png");
export const imgb9c899890db09941a793cdd23d9b52ca4f5d64ab = require("../../global_assets/b9c899890db09941a793cdd23d9b52ca4f5d64ab.png");
export const img026f070ff63ac8acbd820ddf80270a46095e0e61 = require("../../global_assets/026f070ff63ac8acbd820ddf80270a46095e0e61.png");
export const img2fad379cff07ea3d179458a079cf0056610d1a31 = require("../../global_assets/2fad379cff07ea3d179458a079cf0056610d1a31.png");
export const img1c76bf04a3f1c90a040fb311e6e219deb5484c1e = require("../../global_assets/1c76bf04a3f1c90a040fb311e6e219deb5484c1e.png");
export const imge57bc6d94e3ee87d7dd409c0a70810dd67d2a607 = require("../../global_assets/e57bc6d94e3ee87d7dd409c0a70810dd67d2a607.png");
export const img5f04969df21d8414146eebbb8ad215f63861d1f0 = require("../../global_assets/5f04969df21d8414146eebbb8ad215f63861d1f0.png");
export const imgd4e0113576980a218986623f1058d2a8f5065968 = require("../../global_assets/d4e0113576980a218986623f1058d2a8f5065968.png");
export const img649366d5620a7d0023beec001b375ff885721b09 = require("../../global_assets/649366d5620a7d0023beec001b375ff885721b09.png");
export const img1e3ad4e2da86add9dc6df502862484509aa5aaf7 = require("../../global_assets/1e3ad4e2da86add9dc6df502862484509aa5aaf7.png");
export const img3a5e74a5325190178e40b99c0126c263d9b82e03 = require("../../global_assets/3a5e74a5325190178e40b99c0126c263d9b82e03.png");
export const img51e049abde906df6933c05c0900953cebc0ef1ba = require("../../global_assets/51e049abde906df6933c05c0900953cebc0ef1ba.png");
export const img54746e937243a5a066e08cf65126445b0809873c = require("../../global_assets/54746e937243a5a066e08cf65126445b0809873c.png");
export const img46d049e67f1dfab7fe356f9f6722db063fdf4906 = require("../../global_assets/46d049e67f1dfab7fe356f9f6722db063fdf4906.png");
export const imgaaf7b54c5b1eb1c486f9003b63dd6ca60775397e = require("../../global_assets/aaf7b54c5b1eb1c486f9003b63dd6ca60775397e.png");
export const img5e651f100c1c9f3a0b8855cc5625f0121e280c23 = require("../../global_assets/5e651f100c1c9f3a0b8855cc5625f0121e280c23.png");
export const img44729eb4ec83596266b5b1e10606ade5366a07ca = require("../assets/44729eb4ec83596266b5b1e10606ade5366a07ca.png");
export const imgff05e561bbf61cd5ccae99beb1a1d0a83ffe50f2 = require("../assets/ff05e561bbf61cd5ccae99beb1a1d0a83ffe50f2.png");
export const imged1a263fb87e43c432a5871e5509952b41e44cd8 = require("../assets/ed1a263fb87e43c432a5871e5509952b41e44cd8.png");
export const img4f9be6c44828519eba85a15085a464bbdaf15edd = require("../assets/4f9be6c44828519eba85a15085a464bbdaf15edd.png");
export const imgbeef859a768e57dd76ac245c0bc2c99fe4995804 = require("../assets/beef859a768e57dd76ac245c0bc2c99fe4995804.png");
export const img3e6d09e4b7b6d41161e67adc055ba3a6d41b1e33 = require("../assets/3e6d09e4b7b6d41161e67adc055ba3a6d41b1e33.png");
export const imgc20b62ea8952a1deeb3693cb36401d0c4a107caa = require("../assets/c20b62ea8952a1deeb3693cb36401d0c4a107caa.png");
export const img3b7c7398017a0c53b5fb642e0d187b69180890dc = require("../assets/3b7c7398017a0c53b5fb642e0d187b69180890dc.png");
export const img8ba531ea52f7bebab6a4fba9a233c4d31c11d747 = require("../assets/8ba531ea52f7bebab6a4fba9a233c4d31c11d747.png");
export const imgf73fe7fbf0ae12a8fd0d6f2768f04b1e3f3f6412 = require("../assets/f73fe7fbf0ae12a8fd0d6f2768f04b1e3f3f6412.png");
export const img0cd7b94e5101f43edb15405024e285c15701c3a4 = require("../assets/0cd7b94e5101f43edb15405024e285c15701c3a4.png");
export const img1790cfca77a8be3248428fffa68fe90719f16cb5 = require("../assets/1790cfca77a8be3248428fffa68fe90719f16cb5.png");
export const img8d365c890f94127c692504e7cccf9f81afff2ade = require("../assets/8d365c890f94127c692504e7cccf9f81afff2ade.png");
export const img43c0e5f79ec0d46c8e9a8e81c7623a3afb5495cb = require("../assets/43c0e5f79ec0d46c8e9a8e81c7623a3afb5495cb.png");
export const img2067c513bd99a9a03ba8acb39c9e3615ec175f0a = require("../assets/2067c513bd99a9a03ba8acb39c9e3615ec175f0a.png");
export const imga64c47175849bb66f684e6a36f7203d9834fcb7a = require("../assets/a64c47175849bb66f684e6a36f7203d9834fcb7a.png");
export const imga0c72ffc5e7049950d86e962401edc0e00f39179 = require("../assets/a0c72ffc5e7049950d86e962401edc0e00f39179.png");
export const img0a60bd78ee4be7a80998f4124bdbc7faab61a347 = require("../assets/0a60bd78ee4be7a80998f4124bdbc7faab61a347.png");
export const imga2e13fcc3989739e05320690d979c7649a91e9ca = require("../assets/a2e13fcc3989739e05320690d979c7649a91e9ca.png");
export const img9cb7d341396b548cbf72a6ba8f0af58a0ba554ea = require("../assets/9cb7d341396b548cbf72a6ba8f0af58a0ba554ea.png");
export const imge849d80e786d55f5dd81455aee3fc68c96e4719f = require("../assets/e849d80e786d55f5dd81455aee3fc68c96e4719f.png");
export const imga577d5368b292e40500fe4a50700adb5bd1d4d8b = require("../assets/a577d5368b292e40500fe4a50700adb5bd1d4d8b.png");
export const img93d6be96bf6edfe4a1d2bab9d8154401a005992f = require("../assets/93d6be96bf6edfe4a1d2bab9d8154401a005992f.png");
export const imge75fc932bde726a4bca417ec2abc7f21f0db4855 = require("../assets/e75fc932bde726a4bca417ec2abc7f21f0db4855.png");
export const img5f4207b1b489253e7180879a9339cce6e66474d4 = require("../assets/5f4207b1b489253e7180879a9339cce6e66474d4.png");
export const img09b16a0f7547141454240faba790f446b7edb47e = require("../assets/09b16a0f7547141454240faba790f446b7edb47e.png");
export const imgf1458ff0b504f6aa2e54a55fe1c99bef8090b807 = require("../assets/f1458ff0b504f6aa2e54a55fe1c99bef8090b807.png");
export const img90e09c54888a1e069ffc411c90205bf091a29668 = require("../assets/90e09c54888a1e069ffc411c90205bf091a29668.png");
export const imgcfb8a6ebb0146224021e8a3d21ccf098e2b88354 = require("../assets/cfb8a6ebb0146224021e8a3d21ccf098e2b88354.png");
export const img650f923c7aad77ab2ae126dd8fe709043345e425 = require("../assets/650f923c7aad77ab2ae126dd8fe709043345e425.png");
export const img7d2b20b855ffe3f6f739867b7ff6464891f7b666 = require("../assets/7d2b20b855ffe3f6f739867b7ff6464891f7b666.png");
export const img105e5d6152e96911bd9f18b69138e2157ebc6ed7 = require("../assets/105e5d6152e96911bd9f18b69138e2157ebc6ed7.png");
export const img21991160e9d461a584304ef33fc710b48aa20966 = require("../assets/21991160e9d461a584304ef33fc710b48aa20966.png");
export const img74006e7dddd9a1401a3ab969064bfd404a4b2ec3 = require("../assets/74006e7dddd9a1401a3ab969064bfd404a4b2ec3.png");
export const imge954364e2203072a03dfaa244f1870e422221462 = require("../assets/e954364e2203072a03dfaa244f1870e422221462.png");
export const img27538f679e7f66989cf7b8c468d061abc6b1eb64 = require("../assets/27538f679e7f66989cf7b8c468d061abc6b1eb64.png");
export const img9ca898d1e92210b9750c62b5fdd87924535f9bc1 = require("../assets/9ca898d1e92210b9750c62b5fdd87924535f9bc1.png");
export const img91f7914ede509a0717f9ba56d9b93410973f39ab = require("../assets/91f7914ede509a0717f9ba56d9b93410973f39ab.png");
export const imgca16e71ad6f27178ce38f20092748b9b19dbfde7 = require("../assets/ca16e71ad6f27178ce38f20092748b9b19dbfde7.png");
export const img7d153f16fe0ea4fe218180ca66ed88a673fe6e39 = require("../assets/7d153f16fe0ea4fe218180ca66ed88a673fe6e39.png");
export const img899ebdf6fc61e54dec6e40886cc62f2f18180db1 = require("../assets/899ebdf6fc61e54dec6e40886cc62f2f18180db1.png");
export const imgda91c2cf7709fa370aa41ddc07b395b47b28465c = require("../assets/da91c2cf7709fa370aa41ddc07b395b47b28465c.png");

export const imgfarmLogo = require("../assets/newLogo.svg");
export const PersonSVG = require("../assets/z-person.svg");
export const DateSVG = require("../assets/z-date.svg");
export const ChatSVG = require("../assets/z-chat.svg");
export const HelpSVG = require("../assets/z-help.svg");
export const NotificationSVG = require("../assets/z-notification.svg");
export const ScheduleSVG = require("../assets/z-schedule.svg");
export const SurveySVG = require("../assets/z-survey.svg");
export const Home = require("../assets/y-home.svg");
export const Person = require("../assets/y-person.svg");
export const Date = require("../assets/y-calendar.svg");
export const Chat = require("../assets/y-chat.svg");
export const Help = require("../assets/y-help.svg");
export const Bell = require("../assets/y-bell.svg");
export const Event = require("../assets/y-events.svg");
export const Dropdown = require("../assets/y-dropdown.svg");
export const Survey = require("../assets/y-surveys.svg");

export const VHome = require("../assets/v-home.svg");
export const VPerson = require("../assets/v-person.svg");
export const VDate = require("../assets/v-calendar.svg");
export const VChat = require("../assets/v-chat.svg");
export const VHelp = require("../assets/v-help.svg");
export const VBell = require("../assets/v-bell.svg");
export const VEvent = require("../assets/v-events.svg");
export const VSurvey = require("../assets/v-surveys.svg");
export const VDropdown = require("../assets/v-dropdown.svg");

export const uHome = require("../assets/u-home.svg");
export const uPerson = require("../assets/u-person.svg");
export const uDate = require("../assets/u-calendar.svg");
export const uChat = require("../assets/u-chat.svg");
export const uHelp = require("../assets/u-help.svg");
export const uBell = require("../assets/u-bell.svg");
export const uEvent = require("../assets/u-events.svg");
export const uSurvey = require("../assets/u-surveys.svg");
export const uDropdown = require("../assets/u-dropdown.svg");
export const SplashScreenBg = require("../assets/splashbg.png");
