import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Modal,
  Grid,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  Card,
  CardMedia,
  Badge,
  CircularProgress ,
  CardContent
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Clear, KeyboardBackspace, Edit, KeyboardArrowDown, AddAPhoto,Delete,Close, KeyboardArrowLeft, KeyboardArrowRight  } from "@material-ui/icons";
import { styled } from '@material-ui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { CameraStyledBadge } from "../../../components/src/AdminConsoleHelper";
import FarmMapping from "../../../components/src/FarmMapping/FarmMapping.web";
import { addFarmLabel,  cameraAlt,  mapPlusPNG, preSowing } from './assets';
import CustomModal from "../../../components/src/customModal.web";
import {
  ImageTypes,
  initalValueTypeAddFarm,
} from "./CfmultiplefarmsController";

//css Section 
const addFarmModalCss = ` 
.formfieldWrapper .MuiOutlinedInput-notchedOutline {
  border-color: #F0EBE0 !important;
}

.modalAddFarme {
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
  
  .displaytable{
    display: table-footer-group;
  }

  .paperAddFarme {
    border-radius: 12px 12px 12px 12px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    max-width: 712px;
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:95%;
    max-height:95%;
    overflow:hidden;
  }

  
  .mediaAddFarme {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    margin-right: 16px;
    object-fit:cover;
  }
  
  .mediaAddFarmePhoto{
    width: 100%;
    height: 144px;
    border: 0.5px solid #d5d2d2;
    border-radius: 7px;
    margin-right: 12px;
    object-fit: contain;
  }

  .alignCenter {
    display: flex;
    align-items: center;
  }
  
  .onRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }   
  .cstInput{
    padding:12px;
  }  
  .MuiOutlinedInput-input {
    padding: 12px !important;
}  
.cstForm #mui-component-select-number {
    width: 83px;
    padding: 5px 18px;
    box-sizing: border-box;
    height: 28px;
    line-height: 2px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;      
    border-radius: 8px;
    background: #F0EBE0;
    min-width:50px;
}
.areafarmeDropdown .MuiSelect-iconOutlined {
  right:0 !important
}
.paperAddFarme .formfieldWrapper {
    text-align: left;
    height:auto;
    margin-top: 16px;
} 
.paperAddFarme .MuiOutlinedInput-root {
  border: #F0EBE0 !important;
  border-radius: 12px !important;
  background-color: #fcfaf7 !important;
  height: 56px;
  }      

  .uploadFarmImageWrapper, .uploadFarmImageWrapper{
    margin-top: 24px;
    margin-bottom: -8px;
  }

  .paperAddFarme .farmMappingSubmitButton{
    margin: 16px 0px 10px 0px !important;
  }

  .modalSubmitButton{
    box-shadow: none;
  }

  .areafarmeDropdown.MuiInputBase-formControl {
    height: auto;
  }

`
const viewFormModalCss=`  
.viewFarmContainer {
      border-radius: 12px 12px 12px 12px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 2px 8px 32px rgba(0, 0, 0, 0.05999999865889549);
      display: flex;
      max-width: 528px;
      flex-direction: column;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width:95%;
      max-height:95%;
      overflow:hidden;
    }
    .CardSection {
      border-radius: 12px 12px 0px 0px;
      background-color: rgba(
        240.00000089406967,
        235.0000011920929,
        224.000001847744,
        1
      );
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 32px 0 16px;
      position:relative;
    }
    @media (max-width: 991px) {
      .CardSection {
        max-width: 100%;
      }
    }
    .headerSection {
      width: 100%;
      padding-left:32px
    }
    @media (max-width: 991px) {
      .headerSection {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .header {
      display: flex;
      gap: 16px;
    }
    .imgHeader {
      aspect-ratio: 1;
      object-fit: cover;
      object-position: center;
      width: 90px;
      height:90px;
      border-radius:50%;
    }
    .div-4 {
      align-self: center;
      display: flex;
      flex-grow: 1;
      flex-basis: 0%;
      flex-direction: column;
      margin: auto 0;
    }
    .div-5 {
      color: var(--co-farm-v-1-text, #1c2324);
      font: 500 20px/150% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
      text-transform: capitalize;
    }
    .div-6 {
      color: var(--co-farm-v-1-text, #1c2324);
      margin-top: 4px;
      white-space: nowrap;
      font: 400 14px/22px IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
      opacity:64%;  
    }
    .actionBtn {
      display: flex;
      align-items: center;
      margin: 10px 59px 0 0;
    }
    .div-7{
      margin-top:4px
    }
    .w18{
        width: 18px;
    }
    .w24 {
      cursor: pointer;  
      width: 24px;
    }
    .editBtn{
        fill: var(--co-farm-v-1-tertiary-amber, #eb8f06);
        margin-right: 10px;
    }
    .deleteBtn {
        fill: var(--co-farm-v-1-error, #b3261e);
    }
    .div-8 {
      background-color: var(--co-farm-v-1-dark-beige, #e4ddc9);
      display: flex;
      min-height: 1px;
      margin-top: 16px;
      width: 100%;
      flex-direction: column;
    }
    @media (max-width: 991px) {
      .div-8 {
        max-width: 100%;
      }
    }
    .div-9 {
        align-self: center;
        display: flex;
        margin-top: 16px;
        width: 100%;
        max-width: 100%;
        align-items: center;
        justify-content: space-around;
        gap: 20px;
        padding: 0 20px;
    }
    .div-10 {
      align-self: center;
      display: flex;
      flex-grow: 1;
      flex-basis: 0%;
      flex-direction: column;
      margin: auto 0;
      justify-content: center;
        align-items: center;
    }
    .div-11 {
      color: var(--co-farm-v-1-text, #1c2324);
      white-space: nowrap;
      font: 500 18px/156% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .div-11 {
        white-space: initial;
      }
    }
    .div-12 {
      color: #1c2324;
      margin-top: 2px;
      white-space: nowrap;
      font: 400 14px/157% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .div-12 {
        white-space: initial;
      }
    }
    .div-13 {
      background-color: var(--co-farm-v-1-dark-beige, #e4ddc9);
      align-self: stretch;
      display: flex;
      width: 1px;
      height: 52px;
      flex-direction: column;
    }
    .div-14 {
      display: flex;
      flex-grow: 1;
      flex-basis: 0%;
      flex-direction: column;
      align-items:center;
      justify-content:center;
    }
    .div-15 {
      color: var(--co-farm-v-1-text, #1c2324);
      white-space: nowrap;
      margin: -2px 0 0 0;
      font: 500 18px/156% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .div-15 {
        white-space: initial;
      }
    }
    .div-16 {
      color: var(--co-farm-v-1-text, #1c2324);
      white-space: nowrap;
      margin: 2px 0 0 0;
      font: 400 14px/157% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .div-16 {
        white-space: initial;
      }
    }
    .div-17 {
      background-color: var(--co-farm-v-1-dark-beige, #e4ddc9);
      align-self: stretch;
      display: flex;
      width: 1px;
      height: 52px;
      flex-direction: column;
    }
    .div-18 {
      display: flex;
      flex-grow: 1;
      flex-basis: 0%;
      flex-direction: column;
      justify-content: center;
align-items: center;
    }
    .div-19 {
      color: var(--co-farm-v-1-text, #1c2324);
      white-space: nowrap;
      margin: 1px 0 0 0;
      font: 500 18px/156% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .div-19 {
        white-space: initial;
      }
    }
    .div-20 {
      color: var(--co-farm-v-1-text, #1c2324);
      white-space: nowrap;
      margin: 2px 0 0 0;
      font: 400 14px/157% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .div-20 {
        white-space: initial;
      }
    }
    .div-21 {
      display: flex;
      margin-top: 25px;
      width: 100%;
      flex-direction: column;
      padding: 0 32px;
    }
    @media (max-width: 991px) {
      .div-21 {
        max-width: 100%;
        padding: 0 20px;
      }
    }
    .div-22 {
      display: flex;
      width: 100%;
      align-items: start;
      justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 991px) {
      .div-22 {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .div-23 {
      color: var(--co-farm-v-1-text, #1c2324);
      margin-top: 5px;
      font: 600 14px/157% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .div-24 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .div-25 {
      color: var(--co-farm-v-1-text, #1c2324);
      margin-top: 2px;
      font: 400 14px/157% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    .harowingContainer {
      border-radius: 12px;
      border: 1px solid var(--co-farm-v-1-on-surface-beige, #f0ebe0);
      background-color: var(--co-farm-v-1-on-surface-light-beige, #fcfaf7);
      display: flex;
      margin-top: 13px;
      width: 100%;
      justify-content: space-between;
      gap: 20px;
      padding: 8px;
    }
    @media (max-width: 991px) {
      .harowingContainer {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .harowingMainSection {
      display: flex;
      justify-content: space-between;
      gap: 12px;
    }
    .img-7 {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 50px;
      overflow: hidden;
      max-width: 100%;
    }
    .harrowingDetails {
      align-self: center;
      display: flex;
      flex-grow: 1;
      flex-basis: 0%;
      flex-direction: column;
      margin: auto 0;
    }
    .harrowingName {
      color: var(--co-farm-v-1-text, #1c2324);
      white-space: nowrap;
      font: 500 14px/171% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .harrowingName {
        white-space: initial;
      }
    }
    .harrowingDate {
      color: var(--co-farm-v-1-text, #1c2324);
      margin-top: 3px;
      white-space: nowrap;
      font: 400 12px/150% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
      opacity:64%;  
    }
    .harrowingPrice {
      color: var(--co-farm-v-1-text, #1c2324);
      align-self: center;
      margin: auto 0;
      font: 600 16px/150% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
      margin-right: 8px;  
    }
    .croptitle {
      color: var(--co-farm-v-1-text, #1c2324);
      margin-top: 30px;
      font: 600 14px/157% IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media (max-width: 991px) {
      .croptitle {
        max-width: 100%;
      }
    }
    .cropContainer {
      border-radius: 12px;
      border: 1px solid var(--co-farm-v-1-on-surface-beige, #f0ebe0);
      display: flex;
      justify-content: space-between;
      gap: 12px;
      padding: 6px 18px 6px 6px;
    }
    @media (max-width: 991px) {
      .cropContainer {
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
    .cropViewPart {
      border-radius: 8px;
      background-color: var(--co-farm-v-1-white, #fff);
      display: flex;
      width: 68px;
    }
    .cropImg {
      width: 68px;
      border-radius:6px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      min-height:68px;
    }
    .cropDetails {
      align-self: center;
      display: flex;
      flex-grow: 1;
      flex-basis: 0%;
      flex-direction: column;
      margin: auto 0;
    }
    .cropname {
      color: #1c2324;
      text-transform: uppercase;
      font: 500 16px IBM Plex Sans, -apple-system, Roboto, Helvetica,
        sans-serif;
      line-height: 24px;
    }
    .croparea {
      color: #1c2324;
      margin-top: 2px;
      font: 400 14px/157% IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
      opacity:64%;
      line-height:22px;
    }
    @media (max-width: 991px) {
      .croparea {
        white-space: initial;
      }
    }
    .cropOtherDetails {
      align-self: center;
      display: flex;
      flex-grow: 1;
      flex-basis: 0%;
      flex-direction: column;
      margin: auto 0;
      text-align:end;
    }
    .div-39 {
      color: var(--co-farm-v-1-text, #1c2324);
      white-space: nowrap;
      font: italic 400 12px/150% IBM Plex Sans, -apple-system, Roboto,
        Helvetica, sans-serif;
      opacity:64%;  
    }
    @media (max-width: 991px) {
      .div-39 {
        white-space: initial;
      }
    }
    .div-40 {
      color: #eb8f06;
      align-self: end;
      margin-top: 20px;
      white-space: nowrap;
      font: 500 12px/150% IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
    }
    .viewFarm .farmMappingSubmitButton{
      margin-top:32px !important;
    }
`


const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "10%",
    top: "15%",
  },
}));


//validation
const addfarmvalidation = Yup.object().shape({
  name: Yup.string().trim().required("Please enter the Name!").matches(
    /^([a-zA-Z0-9 '])+$/u,
    'Please enter the alphanumeric value!' 
  ).test('len', 'Must be less then 100 characters', val => val?.trim().length <= 100),
  ownership_of_land_id: Yup.number().required("Please select the owership!"),
  farm_area: Yup.number().required("Please select the area!"),
  survey_number: Yup.string().trim().test('len', 'Must be less then 50 characters', val => !val || val?.trim().length <= 50).nullable(),
  soil_texture_id: Yup.number().required("Please select the Soil Texture!"),
  land_type_id: Yup.number().required("Please select the Irrigation!"),
})

import { CropType } from "../../../components/src/interface.web";
import moment from "moment";

// Customizable Area End

import CfmultiplefarmsController, {
  Props,
  configJSON,
} from "./CfmultiplefarmsController";
import { getLabelFromId } from "../../../components/src/helper";

export default class Cfmultiplefarms extends CfmultiplefarmsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ImageFragment = ({ attachment, onChange }: { attachment: ImageTypes, onChange: ( currentTarget: EventTarget & HTMLInputElement) => void }) => {

    return attachment ? (
      <>
        <StyledBadge
          overlap="rectangular"
          badgeContent={
            <Box>
              <input
                type="file"
                style={webStyle.hide}
                id="edit-icon-badge-file"
                className="image-contained-class"
                onChange={(event) => onChange(event.currentTarget)}
                data-testid={"modal_image_handle_select"}
                accept="image/*"
              />
              <label htmlFor="edit-icon-badge-file">
                      <IconButton
                        data-testid={"modal_image_fragment_select"}
                        component="span"
                        aria-label="edit upload picture"
                        disableRipple={true}
                        style={webStyle.badgeButton}
                      >
                        <Edit fontSize="small" htmlColor="#fff" />
                      </IconButton>
                </label>
            </Box>
          }
        >
          <Card variant="outlined" style={webStyle.badgeCardRoot} >
            <CardMedia
              component="img"
              image={typeof attachment ==="string" ? attachment : URL.createObjectURL(attachment)}
              alt="cover-images"
            />
          </Card>
        </StyledBadge>
      </>
    ) : (
      <>
        <Box style={webStyle.formImageContainer}>
          <input
            accept="image/*"
            type="file"
            className="image-empty-container-class"
            style={webStyle.hide}
            id="icon-button-file"
            onChange={(event) => onChange(event.currentTarget)}
            data-testid={"modal_image_handle_select"}
          />
          <Typography className="uploadTxt" style={{textAlign: 'left'}}> Upload your farm image here..
          </Typography>
          <label htmlFor="icon-button-file" style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <IconButton aria-label="upload image" component={"span"} style={{padding:0,marginTop:"18px"}}>
              <AddAPhoto style={webStyle.AddAPhoto} />
            </IconButton>
          </label>
        </Box >
      </>
    );
  };

  farmMappingImage = ({ attachment }: { attachment: string }) => {
    let imgPartial = `${attachment}`
    if (attachment && attachment.startsWith('https://maps.googleapis.com/maps/api/staticmap')) {
      imgPartial = attachment
    }
    return (
      <>
        {attachment ? (
          <>
            <CameraStyledBadge
              overlap="rectangular"
              badgeContent={
                <Box>
                  <label>
                    <Tooltip title="Edit">
                      <IconButton
                        component="span"
                        aria-label="edit"
                        disableRipple={true}
                        style={{ padding: "0.375rem" }}
                        onClick={this.handleEditFarmMapping} 
                      >
                        <Edit fontSize="small" htmlColor="#fff" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </Box>
              } >
              <Box>
                <Card variant="outlined" style={webStyle.badgeCardRoot}>
                  <CardMedia
                    component="img"
                    image={imgPartial}
                    src={imgPartial}
                    alt="cover-images"
                  />
                </Card>
              </Box>
            </CameraStyledBadge>
          </>
        ) : (
          <>
            <Box
              style={{
                background: "#FCFAF7",
                border: "1px solid #F0EBE0",
                borderRadius: "12px",
                padding: "16px",
                boxSizing: "border-box",
                height: "150px",
              }}
            >
              <Typography className="uploadTxt" style={{textAlign: 'left'}}> Plot your farm’s location on map...
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                id="add-crop-mapping" 
                onClick={() => {
                  this.setState({ OpenFarmMapping: true, coordinates: [], tempCoordinates: [] }, () =>
                    this.getCurrentLocation()
                  )
                }}
              >
                  <label htmlFor="add-crop-mapping"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img  src={mapPlusPNG} alt="mapIcon"  style={{ marginTop: "16px" , cursor: "pointer"}} />
                  </label>
              </Box>
            </Box>
          </>
        )}
      </>
    )
  };

  errorMessage = (value: string | undefined, touched: boolean | undefined) => (
    value && touched && <Typography className="text-danger" style={{ fontSize: "0.75rem" }}>
      {value}
    </Typography>
  )

  addFarmer = () => {
    const { isOpenAddfarme,  OpenFarmMapping, measureFarmerUnit,
      owershipList, irrigationList, soilTextureList } = this.state;
     const renderImage=(values:initalValueTypeAddFarm)=>{ 
      if(this.props.isFarmEdit && typeof values.farm_image == "string"){
        return values.farm_image
      }
      return addFarmLabel
     }
      
    return (
      <>
        <style>
          {addFarmModalCss}
        </style>
        <Modal
          onClose={() => this.toggleAddFarmeModal()}
          open={isOpenAddfarme}
          className="modalAddFarme"
          data-test-id="add-modal-close-action"
        >
          <Formik initialValues={{...this.state.addFarmeModalInitalValue}} onSubmit={(value) => this.createFarm(value)} validationSchema={addfarmvalidation} 
          enableReinitialize>
            {({ values, setFieldValue, handleSubmit, errors, setFieldTouched, touched }) => (
              <div className="paperAddFarme">
          <Box style={{height:"100%",overflowY:"auto"}}>
                <CustomBox>   
                <Grid container spacing={1}>
                  <Grid item xs={10} className="alignCenter">
                    <img
                      className="mediaAddFarme"
                      src={renderImage(values)}
                    />
                    <Typography variant="h6" style={{textTransform:"capitalize"}} >{this.props.isFarmEdit ? this.state.addFarmeModalInitalValue.name :"Add farm"} </Typography>
                  </Grid>
                  <Grid item xs={2} className="onRight">
                    <IconButton onClick={this.toggleAddFarmeModal} style={{padding:0}}>
                      <Clear style={{color:"#1C2324",fontSize:"24px"}} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="uploadFarmImageWrapper"
                  >
                    <FormLabel
                      id="farmPhotoFarmerId"
                      className="customLabelField displaytable"
                    >
                      Farm Photo
                    </FormLabel>
                    <div className="mt-8" >
                      {this.ImageFragment({  attachment: values.farm_image, onChange: (currentTarget) => this.uploadFile(currentTarget.files, setFieldValue, "farm_image") })}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="uploadFarmImageWrapper"
                  >
                    <FormLabel
                      id="farmPhotoFarmerId"
                      className="customLabelField displaytable"
                    >
                      Farm Mapping
                    </FormLabel>
                    <div id="farmMappingImageContainer" className="mt-8">
                      {this.farmMappingImage({
                        attachment: typeof(values.farm_mapping_image) === "string" ? values.farm_mapping_image : "",
                      })}
                    </div>
                    {this.errorMessage(errors.farm_mapping_image, touched.farm_mapping_image)}
                  </Grid>
                  <Dialog
                    open={OpenFarmMapping}
                    onClose={this.toggleMapModal}
                    aria-labelledby="customized-dialog-title"
                    PaperProps={{
                      style: { borderRadius: 12, width: "712px" },
                    }}
                    maxWidth={"md"}
                  >
                    <DialogTitle>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <IconButton onClick={this.toggleMapModal}
                          id="backFarmMappingbtn"
                          style={{
                            padding: 0,
                            marginRight: "16px"
                          }}
                        >
                          <KeyboardBackspace />
                        </IconButton>
                        <Typography variant="subtitle1">
                        </Typography>
                      </Box>
                    </DialogTitle>
                    <DialogContent style={{ padding: 0 }}>
                      <FarmMapping
                        onSave={(latlngHash, file, url, savedCoordinates) => this.onSaveAddFarmMapping(latlngHash, file, url, savedCoordinates, setFieldValue, "farm_mapping_image")}
                        CoordinatesArr={this.state.tempCoordinates}
                        userLocation={this.state.userLocation}
                        OpenFarmMapping={this.state.OpenFarmMapping}
                        markerDefault={!this.state.tempCoordinates.length ? this.state.userLocation : this.state.markerDefault}
                        isEditModal={true}
                        closeFarmMapping={this.toggleMapModal}
                      />
                    </DialogContent>
                  </Dialog>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormControl fullWidth>
                    <FormLabel
                      id="farmnameFarmerId"
                      className="customLabelField"
                    >
                      Farm Name
                    </FormLabel>
                      <TextField
                        data-test-id="farm-name-field"
                        placeholder="Enter farm name"
                        type="text"
                        name="farmnameFarmer"
                        variant="outlined"
                        color="secondary"
                        className="mt-8"
                        value={values.name}
                        onChange={(event) => setFieldValue("name", event.target.value)}
                        error={Boolean(this.getError(touched.name,errors.name))}
                        helperText={this.getError(touched.name,errors.name)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormControl fullWidth>
                    <FormLabel
                      id="farmSurveyNumberFarmerId"
                      className="customLabelField"
                    >
                      Farm Survey Number
                    </FormLabel>
                      <TextField
                        placeholder="Enter survey number"
                        type="text"
                        name="farmSurveyNumberFarmer"
                        className="mt-8"
                        variant="outlined"
                        color="secondary"
                        value={values.survey_number}
                        onChange={(event) => setFieldValue("survey_number", event.target.value)}
                        error={Boolean(this.getError(touched.survey_number , errors.survey_number))}
                        helperText={this.getError(touched.survey_number,errors.survey_number)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormControl fullWidth className="cstForm">
                    <FormLabel
                      id="totalFarmAreaFarmerId"
                      className="customLabelField"
                    >
                      Total Farm Area
                    </FormLabel>
                      <TextField
                        fullWidth
                        placeholder="Enter farm area"
                        type="number"
                        name="totalFarmAreaFarmer"
                        variant="outlined"
                        color="secondary"
                        style={{ marginTop: 8  }}
                        value={values.farm_area}  
                        onChange={(event) => setFieldValue("farm_area", event.target.value)}
                        error={Boolean(this.getError(touched.farm_area , errors.farm_area))}
                        helperText={this.getError(touched.farm_area, errors.farm_area )}
                        InputProps={{
                          endAdornment: measureFarmerUnit.length == 1 || !measureFarmerUnit.length ? 
                        <CustomUnitLabel>
                          <Typography noWrap>                          
                          {getLabelFromId(String(values.unit_farm_area_id),measureFarmerUnit)}  
                          </Typography>
                        </CustomUnitLabel> : (
                            <Select
                              className="areafarmeDropdown"
                              inputProps={{
                                name: 'number',
                                id: 'number-select',
                                "aria-label": "Without label"
                              }}
                              IconComponent={KeyboardArrowDown}
                              value={values.unit_farm_area_id}
                              onChange={(event) =>  setFieldValue("unit_farm_area_id", event.target.value)}
                            >
                              {
                                measureFarmerUnit.map((unit, index) => {
                                  return (
                                    <MenuItem key={`measure-farm-unit-${index}`} value={unit.id}><span style={{marginRight:"7px"}}> {unit?.attributes?.unit_name || unit?.attributes?.name}</span></MenuItem>
                                  )
                                })
                              }
                            </Select>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="ownershipFarmerId"
                      className="customLabelField"
                    >
                      Ownership of Farm
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        className="mt-8 farmerFormDDClass"
                        variant="outlined"
                        name="ownershipFarmer"
                        color="secondary"
                        displayEmpty
                        value={values.ownership_of_land_id}
                        onChange={(event) => setFieldValue("ownership_of_land_id", event.target.value)}
                        IconComponent={KeyboardArrowDown}
                      > 
                        <MenuItem value={""} disabled>
                          <span className="opcity-48">Select ownership of farm</span>
                        </MenuItem>
                        {
                          owershipList.map((owership, index) => (
                            <MenuItem value={owership.id} key={`owership-${index}`}>
                              {owership.label_english}
                            </MenuItem>
                          ))
                        }
                      </Select>
                      {this.errorMessage(errors.ownership_of_land_id, touched.ownership_of_land_id)}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="landTypeFarmerId"
                      className="customLabelField"
                    >
                      Farm Irrigation Type
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="landTypeFarmer"
                        color="secondary"
                        data-testid="landTypeFarmerTestid"
                        displayEmpty
                        IconComponent={KeyboardArrowDown}
                        value={values.land_type_id}
                        onChange={(event) =>  setFieldValue("land_type_id", event.target.value)}
                      >
                        <MenuItem value={""} disabled>
                          <span className="opcity-48">Select farm irrigation type</span>
                        </MenuItem>
                        {
                          irrigationList.map((irrigation, index) => (
                            <MenuItem value={irrigation.id} key={index}>
                              {irrigation.label_english}
                            </MenuItem>
                          ))
                        }
                      </Select>
                      {this.errorMessage(errors.land_type_id, touched.land_type_id)}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="soilTextureFarmerId"
                      className="customLabelField"
                    >
                      Soil Texture
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="soilTextureFarmer"
                        color="secondary"
                        IconComponent={KeyboardArrowDown}
                        displayEmpty
                        value={values.soil_texture_id}
                        onChange={(event) =>  setFieldValue("soil_texture_id", event.target.value)}
                      >
                        <MenuItem value={""} disabled>
                          <span className="opcity-48">
                          Select soil texture
                          </span>
                        </MenuItem>
                        {
                          soilTextureList.map((soilTexture, index) => (
                            <MenuItem value={soilTexture.id} key={`soilTexture-${index}`}>
                              {soilTexture.label_english}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                    {this.errorMessage(errors.soil_texture_id, touched.soil_texture_id)}
                  </Grid>
                  <Grid item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="formfieldWrapper">
                    <Box className="farmMappingSubmitButton" >
                      <Button
                        variant="contained"
                        className="modalSubmitButton"
                        fullWidth
                        onClick={() => handleSubmit()}
                        style={{boxShadow:"none"}}
                        data-test-id="form_submit_button"
                      >
                        {!this.state.isLoader &&( this.props.isFarmEdit  ? "Update" :"Save")} { this.state.isLoader && <CircularProgress size={28} style={{ color: "#fff" }} />  }
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CustomBox>
</Box> 
                
              </div>)}
          </Formik>
        </Modal>
      </>
    );
  }

  renderCrop=(cropData:CropType)=>{
    const {crop_name_id,crop_type_id,sowing_date,land_detail_id,unit_of_area_id,variety_ids, total_crop_area} =cropData.attributes
    let varietyName="";
    if(Array.isArray(variety_ids)){
      varietyName = variety_ids.map((item)=>item?.name_english || item?.name).join(", ")
    }
    return  <Box className="cropContainer" key={cropData.id}>
    <Box className="cropViewPart">
        <img
            src={crop_name_id.crop_photo?.url || configJSON.imagePlaceholder}
            className="cropImg"
        />
    </Box>
    <Box className="cropDetails">
        <div className="cropname">{crop_name_id.name}</div>
        <div className="croparea">{total_crop_area} {unit_of_area_id?.unit_name || unit_of_area_id?.name} {varietyName ?  " | " + varietyName : ""}</div>
    </Box>
    <Box className="cropOtherDetails">
        <div className="div-39">Sowing: { moment(sowing_date).format("DD MMM YYYY") }</div>
        <div className="div-40" style={{textTransform:"uppercase",color:crop_type_id?.name.toLowerCase() == "main" ? "#eb8f06" :"#108B88"}}>{crop_type_id?.name}</div>
    </Box>
</Box>
  }

  viewFarmeModal = () => {
    if(!this.state.selectedLandData){
      return
    }
    const {isFarmEdit,selectedFarmId,handleCloseModal}=this.props
    const {name,farm_image,survey_number,farm_area,unit_farm_area_id} =this.state.selectedLandData.attributes
    return (
        <>
            <style>
               {viewFormModalCss}
            </style>
            <Modal open={!isFarmEdit && !!selectedFarmId} onClose={handleCloseModal} >
                <div className="viewFarmContainer">
                  <Box style={{height:"100%",overflowY:"auto"}}>
                  <Box className="CardSection">
                        <Box className="headerSection">
                            <div className="header">
                            {this.state.farmImageUploading ? <Box style={{width:"90px",height:"90px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <CircularProgress size={28} style={{ color: "#DF5200" }} />
                            </Box> : <span style={{position:"relative"}}>
                                <img
                                    src={farm_image?.url || configJSON.imagePlaceholder}
                                    className="imgHeader"
                                />
                                <label style={{position:"absolute",bottom:"2px",right:0 ,cursor:"pointer"}} htmlFor="edit-farm-image-file" >
                                <input
                                  type="file"
                                  style={webStyle.hide}
                                  id="edit-farm-image-file"
                                  className="image-contained-class"
                                  onChange={(event) => this.handleFarmImageChange(event.currentTarget.files)}
                                  data-testid={"edit_farm_image"}
                                  accept="image/*"
                              />
                               <img
                                    src={cameraAlt}
                                    style={{width:"24px",height:"24px"}}
                                    
                                />
                               
                                </label>
                              </span> }
                             
                               
                                <div className="div-4">
                                    <div className="div-5">{name}</div>
                                    <div className="div-6">
                                        Survey number: <span style={{ fontWeight: 500 }}>{survey_number}</span>
                                    </div>
                                    <div className="div-7">
                                        <Edit  className="w18 editBtn" style={{cursor:"pointer"}} onClick={(e)=>this.props.handleEditFarm(e,this.props.selectedFarmId)}/>
                                        <Delete className="w18 deleteBtn" data-test-id="delete-farm-button" style={{cursor:"pointer"}} onClick={this.handleDeleteFarm}/>
                                    </div>
                                </div>
                            </div>
                            <Box style={{position:"absolute",top:"24px",right:"24px"}}>
                            <Close className="w24" onClick={handleCloseModal}/>
                            </Box>
                        </Box>
                        <div className="div-8" />
                        <Box className="div-9">
                            <div className="div-10">
                                <div className="div-11">{farm_area}</div>
                                <div className="div-12">{unit_farm_area_id?.unit_name || unit_farm_area_id?.name}</div>
                            </div>
                            <div className="div-13" />
                            <div className="div-14">
                                <div className="div-15">{this.state.cropsData.length}</div>
                                <div className="div-16">crops</div>
                            </div>
                            <div className="div-17" />
                            <div className="div-18">
                                <div className="div-19">-</div>
                                <div className="div-20">expense</div>
                            </div>
                        </Box>
                    </Box>
                    <div className="div-21">
                        <div className="div-22">
                            <div className="div-23">Pre-Sowing Activity</div>
                            <div className="div-24">
                              <IconButton style={{padding:0}} onClick={()=>this.handleYearChange(-1)} disabled={this.state.sowingYear === 2021}>
                              <KeyboardArrowLeft style={{fontSize:"28px"}} />  
                              </IconButton>
                                <div className="div-25">{this.state.sowingYear} - {(this.state.sowingYear+1).toString().slice(-2)}</div>
                                <IconButton disabled={this.state.sowingYear === new Date().getFullYear()}  style={{padding:0}}  onClick={()=>this.handleYearChange(1)}>
                                <KeyboardArrowRight style={{fontSize:"28px"}}/>
                                </IconButton>
                      
                            </div>
                        </div>
                        <Box className="harowingContainer">
                            <Box className="harowingMainSection">
                                <img
                                    src={preSowing}
                                    className="img-7"
                                />
                                <Box className="harrowingDetails">
                                    <div className="harrowingName">Harrowing</div>
                                    <div className="harrowingDate">04/10/2023</div>
                                </Box>
                            </Box>
                            <div className="harrowingPrice">₹251/-</div>
                        </Box>
                        <Box className="croptitle">Crops</Box>
                        <Box style={{marginTop:"12px",display:"flex",flexDirection:"column",gap:"16px"}}>
                          {!this.state.loadCrops && this.state.cropsData.map((crop:CropType)=>{
                            return this.renderCrop(crop)
                          })}
                          {this.state.loadCrops && <Box style={{display:"flex",justifyContent:"center"}}>
                            <CircularProgress size={32} style={{ color: "#DF5200" }} />
                          </Box>}
                        
                          {!this.state.loadCrops && this.state.cropsData.length == 0 && <Box> <Typography style={{textAlign:"center"}}>No Crops available</Typography></Box>}
                        </Box>     
                    <Box style={{marginBlock:"32px"}}>
                      <Button
                        variant="contained"
                        className="modalSubmitButton"
                        fullWidth
                        style={{boxShadow:"none"}}
                        onClick={this.props.handleAddCropModalCallback}
                      >
                       Add Crop
                      </Button>
                    </Box>
                    </div>
                  </Box>
                   
                </div>
            </Modal>
        </>
    )
}

renderDeleteFarmModal = () => {
  return <Grid item>
    <Modal
      open={this.state.isDeleteFarm}
      onClose={this.hanleCancel}
      aria-labelledby="modal-calendar-alert-validation"
      aria-describedby="modal-calendar-alert"
      data-testid="close_modal_button"
      style={webStyle.secondaryModalBox}
      disableScrollLock={true}
      BackdropProps={{
        style: webStyle.modalBackdropProps,
      }}
    >
      <Card
        variant="outlined"
        style={webStyle.modalCardBoxSuccess}
      >
        <CardContent style={webStyle.secondaryModalCardsContent}>
          <Container
            style={webStyle.secondaryModalContentsContainer}
          >
            <Typography align="center" style={{
              fontFamily: configJSON.fontFamily,
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "36px",
              color: "#1C2324",
            }}>
              Delete Farm
            </Typography>

            <Box style={webStyle.modalimageBox}>
              <Typography align="center" style={{
                fontFamily: configJSON.fontFamily,
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "28px",
                color: "#1C2324",
                textAlign: "center"
              }}>
                Are you sure you want to delete? All the crops related to this farm will be deleted
              </Typography>
            </Box>
            <Box style={webStyle.modalTextSucessContainer}>
              <Button
                fullWidth
                style={webStyle.modalButtonGoBack}
                onClick={this.deleteFarmApi}
                data-testid={"modal_delete_farm_button"}
                disabled={this.state.isDeletingFarm}
              >
                {
                  !this.state.isDeletingFarm ? <strong>DELETE</strong> : <CircularProgress size={28} style={{ color: "#fff" }} />
                }

              </Button>
              <Button
                fullWidth
                style={webStyle.modalButtonCardCancel}
                onClick={this.hanleCancel}
                data-testid="close_modal_button"

              >
                <strong>CANCEL</strong>
              </Button>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Modal>
  </Grid>
}

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.customMessage &&  <CustomModal
              open={!!this.state.customMessage}
              onClose={this.handleCloseCustomModal}
              title={this.state.customMessageTitle}
              description={this.state.customMessage}
        />}

        {this.state.isOpenAddfarme && this.addFarmer()}
        {this.state.selectedLandData && this.viewFarmeModal()}
        {this.state.isDeleteFarm && this.renderDeleteFarmModal()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomUnitLabel = styled(Box)({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  width: "83px",
  height: "28px",
  padding: "0px 12px",
  boxSizing: "border-box",
  lineHeight: "2px",
  textAlign: "center",
  fontSize: "14px",
  borderRadius: "8px",
  background: "#F0EBE0",
})

const CustomBox=styled(Box)({
  padding:"32px",
  '@media (max-width:600px)': {
    padding: "16px",
  },
})
const webStyle = {
  badgeCardRoot: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    minHeight: "150",
    padding: "0",
  },
  hide: {
    display: "none"
  },
  badgeButton: {
    padding: "0.375rem",
  },
  formImageContainer: {
    backgroundColor: 'rgb(252, 250, 247)',
    border: '1px solid rgb(240, 235, 224)',
    borderRadius: '0.75rem',
    width: '100%',
    height: '152px',
    padding: '16px'
  },
  AddAPhoto: {
    width: '40px',
    height: '30px',
    color: 'rgb(180 180 179)',
  },
  secondaryModalBox: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%"
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, .7)",
    backdropFilter: "blur(10px)",
  } as React.CSSProperties,
  modalCardBoxSuccess: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 528,
    width:"95%",
    height: 360,
    fontSize: "16px",
    // overflow: "scroll",
    display: "flex",
    "flexDirection": "column",
    justifyContent: "space-between",
    borderRadius: "12px",
  } as React.CSSProperties,
  secondaryModalCardsContent: {
    // backgroundColor: "red",
    padding: 0,
    paddingBlockStart: "4.5rem",
    height: "100%"
  },

  secondaryModalContentsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    fontSize: "16px",
    alignItems: "center",
    height: "100%",
    width: "100%"
  } as React.CSSProperties,
  modalimageBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingInline: "3rem",
  } as React.CSSProperties,
  modalTextSucessContainer: {
    display: "flex",
    gap: "1rem",
    fontSize: "16px"
  } as React.CSSProperties,
  modalButtonGoBack: {
    width: "142px",
    backgroundColor: "#B3261E",
    color: "#fff",
    borderRadius: "28px",
    "textTransform": "none",
    fontWeight: 500,
    paddingBlock: "0.875rem"
  } as React.CSSProperties,

  modalButtonCardCancel: {
    width: "142px",
    backgroundColor: "#E4DDC9",
    borderRadius: "28px",
    "textTransform": "none",
    fontWeight: 500,
  } as React.CSSProperties,
};
// Customizable Area End

