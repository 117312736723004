import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ActivityDataTypes, ICropDetails, ICropsResponse, StaticActivityType } from "../../../components/src/interface.web";

// Customizable Area Start
import  {
    nutrientManagementSvg,
    weedManagementSvg,
    saleSvg,
    irrigationSvg,
    cropFailureSvg,
    pestManagementSvg,
    harvestSvg,
    presowingImage,
    gapFilling2Svg,
  } from "./assets"
import { debounce, returnSellEndpoint, returnWeedEndpoint, returnPestEndpoint } from "../../../components/src/helper";

 export const leftSideActivities = [
    {
      id: "1",
      name: "Pre-Sowing",
      icon: presowingImage,
      order:1,
      endPoint:"admin/bx_block_farm_dairy/pre_sowings",
      key:"PRE_SOWING"
    },
    {
      id: "2",
      name: "Gap filling",
      icon: gapFilling2Svg,
      order:3,
      key:"GAP_FILLING",
      endPoint:"admin/bx_block_farm_dairy/gap_fillings"
    },
    {
      id: "3",
      name: "Nutrient Management",
      icon: nutrientManagementSvg,
      order:5,
      key:"NUTRIENT_MANAGEMENT",
      endPoint:""
    },
    {
      id: "4",
      name: "Irrigation",
      icon: irrigationSvg,
      order:7,
      key:"IRRIGATION",
      endPoint:""
    },
    {
      id: "5",
      name: "Sell",
      icon: saleSvg,
      key:"SALE",
      order:9,
      endPoint:""
    
    },
  ];
  
  export const rightSideActivities = [
    {
      id: "6",
      name: "Crop failure",
      icon: cropFailureSvg,
      order:2,
      key:"CROP_FAILURE",
      endPoint:""
  
    },
    {
      id: "7",
      name: "Weed Management",
      icon: weedManagementSvg,
      order:4,
      key:"WEED_MANAGEMENT",
      endPoint:""
    },
    {
      id: "8",
      name: "Pest Management",
      icon: pestManagementSvg,
      order:6,
      key:"PEST_MANAGEMENT",
      endPoint:""
    },
  
    {
      id: "9",
      name: "Harvest",
      icon: harvestSvg,
      order:8,
      key:"HARVEST",
      endPoint:""
    },
  ];

interface DeleteAcitivtyResponse{
    data:{
        message:string,
    },
    error?:string
    errors?:string[]

}
interface IHeader {
    "token": string | null;
    "Content-Type"?: string,
    "type"?: string
}
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    TokenValue: string | null;
    farmerData: any[];
    farmerDataLoading: boolean;
    farmDiaryLoading: boolean;
    userFarmDiary: any;
    searchFarmerName: string;    
    errorPopup: string | null;
    popupTitle: string;
    childState: string;
    // NEW STATE
    currentSelectedYear:number;
    // FARMER INTERFACE STARTS
    isFilterOpen: boolean,
    anchorEl: any,
    expandedFL: any;
    savedSortAscendingFL: boolean,
    savedSortDescendingFL: boolean,
    savedIsDisabledFL: any,
    savedStateFL: any,
    savedDistrictFL: any,
    savedTalukaFL: any,
    savedVillageFL: any,
    savedFieldExecutiveFL: any,
    sortAscendingFL: boolean,
    sortDescendingFL: boolean,
    initialStatesFL: any[],
    initalDistrictsFL: any[],
    initialTalukasFL: any[],
    initialVillagesFL: any[],
    initialFieldExectiveFL: any[],
    filteredStatesFL: any[],
    filteredDistrictsFL: any[],
    filteredTalukasFL: any[],
    filteredVillagesFL: any[],
    filteredFieldExecutiveFL: any[],
    addFarmerModal: boolean,
    farmerIsEdit : boolean,
    selectMappopup: boolean,
    isOpenViewFarmeModal: boolean,
    selectedStateFL:
    {
        name: "Select State",
        id: "",
    },
    selectedDistrictFL: {
        name: "Select District",
        id: "",
    },
    selectedTalukaFL: {
        name: "Select Taluka",
        id: "",
    },
    selectedVillageFL: {
        name: "Select Village",
        id: "",
    },
    selectedFieldExecutiveFL: {
        user_name: "Select Field Executive",
        first_name: "Select",
        last_name: "Field Executive",
        id: ""
    },
    stateInputValueFL: "",
    districtInputValueFL: "",
    talukaInputValueFL: "",
    villageInputValueFL: "",
    fieldExecutiveInputValueFL: "",
    isDisabledFL: {
        state: boolean,
        district: boolean,
        taluka: boolean,
        village: boolean,
        fe: boolean,
    },
    filterHasErrorFL: boolean
    hideBadgeFL: boolean
    // FARMER INTERFACE ENDS
    pageCountFarmer:number
    [key: string]: any;
    userFarmsData:UserFarm[],
    selectedFarm:number |string,
    // USER CROPS    
    userCropsDataLoading: boolean,
    userCropsData: ICropDetails[],
    selectedCropsId: string[],
    // ADD CROP MODAL
    showAddCropModal:boolean,
    showEditCropModal:boolean,
    showViewCropModal:boolean,
    selectedCropId:string | number,
    // FARM STATES
    selectedFarmIds:string[],
    isFarmEdit:boolean,
    selectedFarmId:string | number,
    userFarmsLoading:boolean,
    isAddAcvitity:boolean,
    selectedActivityId:string,
    isDeleteActivityModal:boolean,
    isActivitiesLoading:boolean
    selectedActivityKey:string,
    selectedActivityData:any[]
    isEditActivity:boolean,
    selectedActivityFarmId:FarmIdTypes,
    selectedActivityCropId:CropIdTypes,
    isDeletingActivity:boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start

export interface UserFarm {
    id: string
    type: string
    attributes: UserFarmAttributes
  }
  
  export interface UserFarmAttributes {
    name: string
    survey_number: string
    ownership_of_land_id: OwnershipOfLandId
    farm_area: string
    unit_farm_area_id: UnitFarmAreaId
    soil_texture_id: SoilTextureId[]
    land_type_id: LandTypeId[]
    account_id: number
    created_at: string
    updated_at: string
    latitude_longitude: string | null
    fe_update_count: number
    farmer_update_count: number
    search_farm_name: string | null
    farm_image: FarmImage
    farm_mapping_image: FarmMappingImage
    sent_delete_request: boolean
  }
  
  export interface OwnershipOfLandId {
    id: number
    label: string
    label_english: string
    label_hindi: string
    label_gujrati: string
    active: boolean
    created_at: string
    updated_at: string
  }
  
  export interface UnitFarmAreaId {
    id: number
    label: string
    label_english: string
    label_hindi: string
    label_gujrati: string
    active: boolean
    created_at: string
    updated_at: string
  }
  
  export interface SoilTextureId {
    id: number
    label: string
    label_english: string
    label_hindi: string
    label_gujrati: string
    active: boolean
    created_at: string
    updated_at: string
  }
  
  export interface LandTypeId {
    id: number
    label: string
    label_english: string
    label_hindi: string
    label_gujrati: string
    active: boolean
    created_at: string
    updated_at: string
  }
  
  export interface FarmImage {
    id: number
    url: string
    default_image: boolean
  }
  
  export interface FarmMappingImage {
    id: number
    url: string
    default_image: boolean
  }
  type FarmIdTypes=string | undefined
  type CropIdTypes=string | string[] | undefined


// Customizable Area End


export default class AdminConsoleUserFarmDiaryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getFarmerApiCallId: string = ""    
    // FARMER FILTER STARTS
    getFieldExecutiveDataApiFLCallId: string = "";
    getVillageDataApiFLCallId: string = "";
    getTalukaDataApiFLCallId: string = "";
    getDistrictDataApiFLCallId: string = "";
    getStateDataApiFLCallId: string = "";
    getUserFarmsApiCallId:string="";
    getUserCropsApiCallId:string="";
    deleteActivityApiCallId:string ="";
    fetchFarmAndCropActivitiesApiId:string ="";
    // Customizable Area End

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            selectMappopup: false,
            popupTitle: "Error",
            errorPopup: null,
            TokenValue: localStorage.getItem("tokenn"),
            childState: "",
            farmerData: [],
            farmerDataLoading: true,
            farmDiaryLoading: true,
            userFarmDiary: undefined,
            isOpenViewFarmeModal: false,
            searchFarmerName: "",
            addFarmerModal: false , 
            farmerIsEdit : false,            

            // FARMER FILTER STARTS
            anchorEl: null,
            isFilterOpen: false,
            expandedFL: false,

            savedSortAscendingFL: false,
            savedSortDescendingFL: false,
            savedIsDisabledFL: {
                state: false,
                district: false,
                taluka: false,
                village: false,
                fe: false,
            },
            savedStateFL:
            {
                name: "Select State",
                id: "",
            },
            savedDistrictFL: {
                name: "Select District",
                id: "",
            },
            savedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            savedVillageFL: {
                name: "Select Village",
                id: "",
            },
            savedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
            sortAscendingFL: false,
            sortDescendingFL: false,

            initialStatesFL: [],
            initalDistrictsFL: [],
            initialTalukasFL: [],
            initialVillagesFL: [],
            initialFieldExectiveFL: [],

            filteredStatesFL: [],
            filteredDistrictsFL: [],
            filteredTalukasFL: [],
            filteredVillagesFL: [],
            filteredFieldExecutiveFL: [],

            selectedStateFL:
            {
                name: "Select State",
                id: "",
            },
            selectedDistrictFL: {
                name: "Select District",
                id: "",
            },
            selectedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },

            stateInputValueFL: "",
            districtInputValueFL: "",
            talukaInputValueFL: "",
            villageInputValueFL: "",
            fieldExecutiveInputValueFL: "",

            isDisabledFL: {
                state: false,
                district: false,
                taluka: false,
                village: false,
                fe: false,
            },
            filterHasErrorFL: false,
            hideBadgeFL: true,
                 
            pageCountFarmer:1,
            // NEW STATES
            currentSelectedYear:new Date().getFullYear(),
            userFarmsData:[],
            selectedFarm:"",
            // USER CROPS
            userCropsDataLoading:false,
            userCropsData:[],
            selectedCropsId:[],
            // ADD CROPS
            showAddCropModal: false,
            showEditCropModal: false,
            showViewCropModal:false,
            selectedCropId:"",
            // FARM STATES
            selectedFarmIds:[],
            isFarmEdit:false,
            selectedFarmId:"",
            userFarmsLoading:false,
            isAddAcvitity:false,
            selectedActivityId:"",
            isDeleteActivityModal:false,
            isActivitiesLoading:false,
            selectedActivityKey:"",
            selectedActivityData:[],
            isEditActivity:false,
            selectedActivityFarmId:"",
            selectedActivityCropId:"",
            isDeletingActivity:false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    toggleAddFarmeModal = () => this.setState((prevState) => ({  addFarmerModal: !prevState.addFarmerModal }));
    toggleAddFarmeModalMap = () => this.setState((prevState) => ({  selectMappopup: !prevState.selectMappopup }));
    handleOpenFarmModalCallback=()=>this.setState({addFarmerModal:true})
    
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId && responseJson) {
                // Customizable Area Start
                switch (apiRequestCallId) {
                    case this.getFarmerApiCallId:
                        this.getFarmerApiCallIdResp(responseJson);
                        break;
                    
                    case this.getStateDataApiFLCallId:
                        this.getStateDataApiFLCallIdRes(responseJson);
                        break;
                    case this.getDistrictDataApiFLCallId:
                        this.getDistrictDataApiFLCallIdRes(responseJson);
                        break;
                    case this.getTalukaDataApiFLCallId:
                        this.getTalukaDataApiFLCallIdRes(responseJson);
                        break;
                    case this.getVillageDataApiFLCallId:
                        this.getVillageDataApiFLCallIdRes(responseJson);
                        break;
                    case this.getFieldExecutiveDataApiFLCallId:
                        this.getFieldExecutiveDataApiFLCallIdRes(responseJson);
                        break;
                    case this.getUserFarmsApiCallId:
                        this.getUserFarmsApiRes(responseJson);
                        break;    
                    case this.getUserCropsApiCallId:
                        this.handleGetUserCropsApiCallId(responseJson);
                        break;
                    case this.deleteActivityApiCallId:
                        this.handleDeleteActivityResponse(responseJson)
                        break;        
                    case this.fetchFarmAndCropActivitiesApiId:
                        this.handleFetchFarmAndCropActivitiesResponse(responseJson)
                }
                // Customizable Area End
            }
            if (errorReponse) {
                // Customizable Area Start
                console.log("ERROR-RESPONSE", errorReponse)
                // Customizable Area End
            }
            // Customizable Area Start
            // Customizable Area End
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getUserFarmsApiRes=(res:{data: UserFarm[]})=>{
        if(res?.data){
            this.setState({userFarmsData:res.data,userFarmsLoading:false})
        }
    }
    handleGetUserCropsApiCallId = (response: ICropsResponse | undefined) => {
        if (response && response.data) {
            this.setState({
                userCropsData: response.data,
                userCropsDataLoading: false
            })
        } else {
            this.setState({
                userCropsData: [],
                userCropsDataLoading: false
            })
        }
    }


    getFarmerApiCallIdResp = (responseJson: any) => {
        if (responseJson !== null && responseJson.data) {
            const isFilterNotApplied: boolean = this.checkFilterErrorFL()
            if (isFilterNotApplied) {
                this.setState({
                    hideBadgeFL: true
                })
            } else {
                this.setState({
                    hideBadgeFL: false
                })
            }

            this.setState({
                farmerDataLoading: false,
                farmDiaryLoading:false,
                farmerData: [...this.state.farmerData, ...responseJson.data],
            }, () => {
                this.setState({ userFarmDiary: this.state.userFarmDiary ? this.state.userFarmDiary : this.state.farmerData[0] })
                this.handleCloseTableFilterPopupFL()
            })
        } else {
            this.setState({
                farmerDataLoading: false,
                farmerData: [],
                farmDiaryLoading:false
            }, () => {
                this.handleCloseTableFilterPopupFL()
            })
        }
    }
    
    // FARMER FILTER STARTS
    getStateDataApiFLCallIdRes = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                initialStatesFL: responseJson,
                filteredStatesFL: responseJson
            })
        } else {
            this.setState({
                initialStatesFL: [],
                filteredStatesFL: []
            })
        }

    }
    getDistrictDataApiFLCallIdRes = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                initalDistrictsFL: responseJson,
                filteredDistrictsFL: responseJson,
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    district: false,
                },
            })
        } else {
            this.setState({
                initalDistrictsFL: [],
                filteredDistrictsFL: [],
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    district: false,
                },
            })
        }
    }
    getTalukaDataApiFLCallIdRes = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                initialTalukasFL: responseJson,
                filteredTalukasFL: responseJson,
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    taluka: false,
                },
            })
        } else {
            this.setState({
                initialTalukasFL: [],
                filteredTalukasFL: [],
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    taluka: false,
                },
            })
        }
    }
    getVillageDataApiFLCallIdRes = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                initialVillagesFL: responseJson,
                filteredVillagesFL: responseJson,
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    village: false,
                },
            })
        } else {
            this.setState({
                initialVillagesFL: [],
                filteredVillagesFL: [],
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    village: false,
                },
            })
        }
    }
    getFieldExecutiveDataApiFLCallIdRes = (responseJson: any) => {
        if (responseJson?.length) {
            this.setState({
                initialFieldExectiveFL: responseJson,
                filteredFieldExecutiveFL: responseJson,
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    fe: false,
                },
            })
        } else {
            this.setState({
                initialFieldExectiveFL: [],
                filteredFieldExecutiveFL: [],
                isDisabledFL: {
                    ...this.state.isDisabledFL,
                    fe: false,
                },
            })
        }
    }
    // FARMER FILTER ENDS

    // Customizable Area End

    async componentDidMount(): Promise<void> {
        // Customizable Area Start
        this.fetchFarmerData();
        // FARMER FILTER STARTS
        this.getStatesFL();
        this.getDistrictsFL();
        this.getTalukasFL();
        this.getVillagesFL();
        this.getFieldExecutivesFL()
        // FARMER FILTER ENDS
        // Customizable Area End
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<S>,
        snapshot?: SS | undefined
    ): void {
        // Customizable Area Start
        if (prevState.userFarmDiary !== this.state.userFarmDiary) {
            this.resetSelectedFarmCrop()
            this.handleResetActivityState()
            this.fetchCrops()
            this.fetchUserFarms()
        }

        if(this.state.selectedActivityKey && prevState.selectedActivityKey !== this.state.selectedActivityKey){
            this.fetchFarmAndCropActivities()
        }
        
        // Customizable Area End
    }

    // Customizable Area Start
    
    userBadge = (): string => (this.state.userFarmDiary?.attributes?.first_name ? this.state.userFarmDiary?.attributes?.first_name[0].toUpperCase() : "") + (this.state.userFarmDiary?.attributes?.last_name ? this.state.userFarmDiary?.attributes?.last_name.trim()[0].toUpperCase() : "")
   
    userFEname = () => {
        return (this.state.userFarmDiary?.attributes?.assigned_field_executive?.first_name ? this.state.userFarmDiary?.attributes?.assigned_field_executive?.first_name : "")
            + " " + (this.state.userFarmDiary?.attributes?.assigned_field_executive?.last_name ? this.state.userFarmDiary?.attributes?.assigned_field_executive?.last_name : "")
    }
    userName = () => {
        return (this.state.userFarmDiary?.attributes?.first_name ? this.state.userFarmDiary?.attributes?.first_name : "") + " " + (this.state.userFarmDiary?.attributes?.last_name ? this.state.userFarmDiary?.attributes?.last_name : "")
    }
    

    debouncedLog = debounce(() => this.fetchFarmerData(), 500)
    handleChangeSearch = (e: any) => {
        this.setState({ searchFarmerName: e.target.value,farmerData:[],pageCountFarmer:1,farmerDataLoading: true })
        this.debouncedLog(e.target.value)
    }

    
    handleListItemClick = (each: any) => {
        this.setState({ userFarmDiary: each })
    };
    
    //Latest changes

    // FARMER FUNCTIONS STARTS
    handleChangeAtoZFL = () => {
        this.setState({
            sortAscendingFL: !this.state.sortAscendingFL,
            sortDescendingFL: false
        });
    }
    handleChangeZtoAFL = () => {
        this.setState({
            sortDescendingFL: !this.state.sortDescendingFL,
            sortAscendingFL: false
        });
    }
    handleFieldExectiveSearchFL = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ fieldExecutiveInputValueFL: e?.target?.value });
        const filteredArray = query
            ? this.state.initialFieldExectiveFL.filter((value: any) =>
                value.first_name.toLowerCase().includes(query) || value.last_name.toLowerCase().includes(query)
            )
            : this.state.initialFieldExectiveFL;
        this.setState({
            filteredFieldExecutiveFL: filteredArray,
        });

    }
    handleTalukaSearchFL = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ talukaInputValueFL: e?.target?.value });
        const TalukafilteredArray = query
            ? this.state.initialTalukasFL.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initialTalukasFL;
        this.setState({
            filteredTalukasFL: TalukafilteredArray,
        });
    }
    handleVillageSearchFL = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ villageInputValueFL: e?.target?.value });
        const VillagefilteredArray = query
            ? this.state.initialVillagesFL.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initialVillagesFL;
        this.setState({
            filteredVillagesFL: VillagefilteredArray,
        });
    }
    handleDistrictSearchFL = (e: any) => {
        const query = e?.target?.value?.toLowerCase();
        this.setState({ districtInputValueFL: e?.target?.value });
        const DistrictfilteredArray = query
            ? this.state.initalDistrictsFL.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initalDistrictsFL;
        this.setState({
            filteredDistrictsFL: DistrictfilteredArray,
        });
    }
    handleStateSearchFL = (e: any) => {
        const query = e?.target?.value?.toLowerCase() || "";
        this.setState({ stateInputValueFL: e?.target?.value });
        const StatefilteredArray = query
            ? this.state.initialStatesFL.filter((value: any) =>
                value.name.toLowerCase().includes(query)
            )
            : this.state.initialStatesFL;
        this.setState({
            filteredStatesFL: StatefilteredArray,
        });
    }
    handleChangePanelFL = (panel: any) => (event: any, newExpanded: any) => {
        this.setState({
            expandedFL: newExpanded ? panel : false,
            stateInputValueFL: "",
            districtInputValueFL: "",
            talukaInputValueFL: "",
            villageInputValueFL: "",
            fieldExecutiveInputValueFL: "",
            filteredStatesFL: this.state.initialStatesFL,
            filteredDistrictsFL: this.state.initalDistrictsFL,
            filteredTalukasFL: this.state.initialTalukasFL,
            filteredVillagesFL: this.state.initialVillagesFL,
            filteredFieldExecutiveFL: this.state.initialFieldExectiveFL,

        });
    };
    handleVillageFilterClearFL = () => {
        this.setState({
            isFilterOpen: false,
            anchorEl: null,
            sortDescendingFL: false,
            sortAscendingFL: false,
            filteredStatesFL: this.state.initialStatesFL,
            filteredDistrictsFL: this.state.initalDistrictsFL,
            filteredTalukasFL: this.state.initialTalukasFL,
            filteredVillagesFL: this.state.initialVillagesFL,
            filteredFieldExecutiveFL: this.state.initialFieldExectiveFL,

            expandedFL: false,
            savedStateFL:
            {
                name: "Select State",
                id: "",
            },
            savedDistrictFL: {
                name: "Select District",
                id: "",
            },
            savedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            savedVillageFL: {
                name: "Select Village",
                id: "",
            },
            savedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
            savedSortAscendingFL: false,
            savedSortDescendingFL: false,
            savedIsDisabledFL: {
                state: false,
                district: false,
                taluka: false,
                village: false,
                fe: false,
            },
            stateInputValueFL: "",
            districtInputValueFL: "",
            talukaInputValueFL: "",
            villageInputValueFL: "",
            fieldExecutiveInputValueFL: "",
            selectedStateFL:
            {
                name: "Select State",
                id: "",
            },
            selectedDistrictFL: {
                name: "Select District",
                id: "",
            },
            selectedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
            isDisabledFL: {
                state: false,
                district: false,
                taluka: false,
                village: false,
                fe: false,
            },
            filterHasErrorFL: false,
            // RESET USER TABLE STATES
            farmerTableCurrentPage: 0,
            FeTableCurrentPage: 0,
            adminTableCurrentPage: 0,
            searchFilterValue: "",
            hideBadgeFL: true,
            farmerData:[]
        }, () => {

            this.fetchFarmerData();
            this.getFieldExecutivesFL()
            this.getDistrictsFL();
            this.getTalukasFL();
            this.getVillagesFL();
            this.getStatesFL()


        })
    };
    handleCloseTableFilterPopupFL = () => {
        this.setState({
            isFilterOpen: false,
            expandedFL: false,
            selectedStateFL: this.state.savedStateFL,
            selectedDistrictFL: this.state.savedDistrictFL,
            selectedTalukaFL: this.state.savedTalukaFL,
            selectedVillageFL: this.state.savedVillageFL,
            selectedFieldExecutiveFL: this.state.savedFieldExecutiveFL,

            sortAscendingFL: this.state.savedSortAscendingFL,
            sortDescendingFL: this.state.savedSortDescendingFL,
            isDisabledFL: this.state.savedIsDisabledFL,
            stateInputValueFL: "",
            districtInputValueFL: "",
            talukaInputValueFL: "",
            villageInputValueFL: "",
            fieldExecutiveInputValueFL: "",

            filteredStatesFL: this.state.initialStatesFL,
            filteredDistrictsFL: this.state.initalDistrictsFL,
            filteredTalukasFL: this.state.initialTalukasFL,
            filteredVillagesFL: this.state.initialVillagesFL,
            filteredFieldExecutiveFL: this.state.initialFieldExectiveFL,

            filterHasErrorFL: false
        })
    }
    handleSubmitFilterFL = () => {
        const isError = this.checkFilterErrorFL()
        if (!isError) {
            this.setState({
                expandedFL: false,
                savedIsDisabledFL: this.state.isDisabledFL,
                savedSortAscendingFL: this.state.sortAscendingFL,
                savedSortDescendingFL: this.state.sortDescendingFL,
                savedStateFL: this.state.selectedStateFL,
                savedDistrictFL: this.state.selectedDistrictFL,
                savedTalukaFL: this.state.selectedTalukaFL,
                savedVillageFL: this.state.selectedVillageFL,
                savedFieldExecutiveFL: this.state.selectedFieldExecutiveFL,
                farmerData:[],
                pageCountFarmer:1,
                farmerDataLoading: true
            }, () => {
                this.fetchFarmerData();
            });
        } else {
            return false
        }
    }
    handleChangeFieldFL = (key1: any, key2: any, key3: any, key4: any, key5?: any) => {
        const isDisabledInitialValuesFL = {
            state: true,
            district: true,
            taluka: true,
            village: true,
            fe: true,
        }
        this.setState({
            isDisabledFL: {
                ...isDisabledInitialValuesFL,
                ...(key1 && { [key1]: false }),
                ...(key2 && { [key2]: false }),
                ...(key3 && { [key3]: false }),
                ...(key4 && { [key4]: false }),
                ...(key5 && { [key5]: false }),
            }
        })

    }
    handleChangeStateFieldFL = (stateData: any) => {
        this.setState({
            selectedStateFL: stateData,
            expandedFL: false,
        }, () => {
            this.getDistrictsFL()
        });
        this.handleChangeFieldFL("", "state", "district", "", "")
    }
    handleChangeDistrictFieldFL = (districtData: any) => {
        this.setState({
            selectedDistrictFL: districtData,
            expandedFL: false,
        }, () => {
            this.getTalukasFL()
        });
        this.handleChangeFieldFL(this.state.selectedStateFL?.name !== "Select State" ? "state" : "", "district", "taluka", "", "")

    }
    handleChangeTalukaFieldFL = (talukaData: any) => {
        this.setState({
            selectedTalukaFL: talukaData,
            expandedFL: false,
        }, () => {
            this.getVillagesFL()
        });
        this.handleChangeFieldFL(this.state.selectedStateFL?.name !== "Select State" ? "state" : "", this.state.selectedDistrictFL?.name === "Select District" ? "" : "district", "taluka", "village", "")
    }
    handleChangeVillageFieldFL = (villageData: any) => {
        this.setState({
            selectedVillageFL: villageData,
            expandedFL: false,
        }, () => {
            this.getFieldExecutivesFL()
        });
        this.handleChangeFieldFL(this.state.selectedStateFL?.name !== "Select State" ? "state" : "", this.state.selectedDistrictFL?.name === "Select District" ? "" : "district", this.state.selectedTalukaFL?.name === "Select Taluka" ? "" : "taluka", "village", "fe")
    }
    handleChangeFieldExecutiveFieldFL = (fieldExectiveData: any) => {
        this.setState({
            expandedFL: false,
            selectedFieldExecutiveFL: fieldExectiveData
        });
        this.handleChangeFieldFL(this.state.selectedStateFL?.name !== "Select State" ? "state" : "", this.state.selectedDistrictFL?.name === "Select District" ? "" : "district", this.state.selectedTalukaFL?.name === "Select Taluka" ? "" : "taluka", this.state.selectedVillageFL?.name === "Select Village" ? "" : "village", "fe")
    }
    opacityStateCalculatorFL = () => {
        return this.state.selectedStateFL?.name === "Select State" ? 0.48 : 1
    }
    opacityDistrictCalculatorFL = () => {
        return this.state.selectedDistrictFL?.name === "Select District" ? 0.48 : 1
    }
    opacityTalukaCalculatorFL = () => {
        return this.state.selectedTalukaFL?.name === "Select Taluka" ? 0.48 : 1
    }
    opacityVillageCalculatorFL = () => {
        return this.state.selectedVillageFL?.name === "Select Village" ? 0.48 : 1
    }
    opacityFieldExecutiveCalculatorFL = () => {
        return this.state.selectedFieldExecutiveFL?.first_name + " " + this.state.selectedFieldExecutiveFL?.last_name === "Select Field Executive" ? 0.48 : 1
    }
    checkFilterErrorFL = () => {
        if (this.state.sortAscendingFL ||
            this.state.sortDescendingFL ||
            this.state.selectedStateFL.id !== "" ||
            this.state.selectedDistrictFL.id !== "" ||
            this.state.selectedTalukaFL.id !== "" ||
            this.state.selectedVillageFL.id !== "" ||
            this.state.selectedFieldExecutiveFL.id !== ""
        ) {
            this.setState({
                filterHasErrorFL: false
            })
            return false;
        } else {
            this.setState({
                filterHasErrorFL: true
            })
            return true;
        }
    }
    fetchNextFarmers = () =>{
        this.setState(prev => ({ ...prev, pageCountFarmer: prev.pageCountFarmer+1 }),()=>{
            this.fetchFarmerData()
        });    
    }
    isfarmerLoading = (): boolean => {
        return this.state.farmerDataLoading && this.state.pageCountFarmer === 1
    }
    isfarmerData = (): boolean => this.state.farmerData.length > 0 
    // FARMER FUNCTIONS ENDS

    toggleDeletePopup = (deletePopup: string | null) => {
        this.setState({ deletePopup })
    }

    toggleErrorPopup = (errorPopup: string | null, title?: string) => {
        this.setState({ errorPopup, popupTitle: title || "Error" })
    }

    toggleViewFarmeModal= () => this.setState((prevState) => ({ isOpenViewFarmeModal: !prevState.isOpenViewFarmeModal }))

 
    fetchFarmerData = () => {
        this.setState({ farmerDataLoading: true })
        const stateId = this.state.selectedStateFL?.id || ""
        const districtId = this.state.selectedDistrictFL?.id || ""
        const talukaId = this.state.selectedTalukaFL?.id || ""
        const villageId = this.state.selectedVillageFL?.id || ""
        const fieldExecutiveId = this.state.selectedFieldExecutive?.id || ""
        let page:number = this.state.pageCountFarmer
        
        let endpoint = `${configJSON.farmerListUrl}?search=${this.state.searchFarmerName}&state_id=${stateId}&district_id=${districtId}&taluka_id=${talukaId}&village_id=${villageId}&per_page=${20}&page=${page}`;
        if (this.state.sortAscendingFL === true) {
            endpoint = endpoint + `&sort_by_a_to_z=${true}&field_executive_id=${fieldExecutiveId}`
        }
        else if (this.state.sortDescendingFL) {
            endpoint = endpoint + `&sort_by_z_to_a=${true}&field_executive_id=${fieldExecutiveId}`
        }
        else {
            endpoint = endpoint + `&field_executive_id=${fieldExecutiveId}`
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getFarmerApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GET_METHOD_TYPE
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // FARMER FILTER API CALL - STARTS
    getStatesFL() {
        this.setState({
            selectedDistrictFL: {
                name: "Select District",
                id: "",
            },
            selectedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
            isDisabledFL: {
                ...this.state.isDisabledFL,
                district: true,
                village: true,
                taluka: true,
                fe: true
            },
        });
        const headers = {
            token: this.state.TokenValue,
            "Content-Type": configJSON.validationApiContentType,
        };

        const reqApiMSG = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getStateDataApiFLCallId = reqApiMSG.messageId;

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getStateList}`
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(reqApiMSG.id, reqApiMSG);
    }
    getDistrictsFL() {
        let params = this.state.selectedStateFL?.id || ""
        this.setState({

            selectedDistrictFL: {
                name: "Select District",
                id: "",
            },
            isDisabledFL: {
                ...this.state.isDisabledFL,
                district: true,
                village: true,
                taluka: true,
                fe: true
            },
            selectedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
        });
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.TokenValue
        };

        const reqApiMSG = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDistrictDataApiFLCallId = reqApiMSG.messageId;
        reqApiMSG.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getDistrictList}?state_id=${params}`
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(reqApiMSG.id, reqApiMSG);
    }
    getTalukasFL() {
        let params = this.state.selectedDistrictFL?.id || ""
        this.setState({
            isDisabledFL: {
                ...this.state.isDisabledFL,
                taluka: true,
                village: true,
                fe: true
            },
            selectedTalukaFL: {
                name: "Select Taluka",
                id: "",
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
        });
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.TokenValue
        };

        const reqApiMSG = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTalukaDataApiFLCallId = reqApiMSG.messageId;

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getTalukaList}?district_id=${params}`
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(reqApiMSG.id, reqApiMSG);
    }
    getVillagesFL() {
        let params = this.state.selectedTalukaFL?.id || ""
        this.setState({
            isDisabledFL: {
                ...this.state.isDisabledFL,
                village: true,
                fe: true
            },
            selectedVillageFL: {
                name: "Select Village",
                id: "",
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: ""
            },
        })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.TokenValue
        };

        const reqApiMSG = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getVillageDataApiFLCallId = reqApiMSG.messageId;

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getVillageList}?taluka_id=${params}`
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(reqApiMSG.id, reqApiMSG);
    }
    getFieldExecutivesFL() {
        let params = this.state.selectedVillageFL?.id || ""
        this.setState({
            isDisabledFL: {
                ...this.state.isDisabledFL,
                fe: true,
            },
            selectedFieldExecutiveFL: {
                user_name: "Select Field Executive",
                first_name: "Select",
                last_name: "Field Executive",
                id: "",
            }
        })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.TokenValue
        };

        const reqApiMSG = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFieldExecutiveDataApiFLCallId = reqApiMSG.messageId;

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getFieldExecutiveList}?village_id=${params}`
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        reqApiMSG.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(reqApiMSG.id, reqApiMSG);
    }
    // FARMER FILTER ENDS

    // API CALL FOR GETTING THE USER FAMRS 
    fetchCrops = () => {
        if (!this.state.userFarmDiary?.id) {
            return;
        }
        this.setState({ userCropsDataLoading: true, userCropsData: [], selectedCropsId: [] })
        let endpoint = `${configJSON.cropIndexAPIEndPoint}?account_id=${this.state.userFarmDiary?.id}&sowing_year=${this.state.currentSelectedYear}`
        if(this.state.selectedFarmIds.length > 0){
            endpoint = endpoint +`&land_detail_id=${this.state.selectedFarmIds}`
        }
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getUserCropsApiCallId = requestMessage.messageId;

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiMethod);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fetchUserFarms = () => {
        if (!this.state.userFarmDiary?.id) {
            return false;
        }
        this.setState({ userFarmsLoading: true, selectedFarmIds:[], userFarmsData:[] })
        let endpoint = `${configJSON.getUsersFarmsById}/?account_id=${this.state.userFarmDiary?.id}`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
            Accept: configJSON.getTableAPiContentType,
            token: this.state.TokenValue,
        };
        this.getUserFarmsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    handleSelectFarms = (cropId: string) => {

        let existingSelectedFarmsIdArray = [...this.state.selectedFarmIds]

        if (existingSelectedFarmsIdArray.includes(String(cropId))) {
            existingSelectedFarmsIdArray = existingSelectedFarmsIdArray.filter(itemId => itemId !== cropId);
        } else {
            existingSelectedFarmsIdArray.push(String(cropId));
        }
        this.setState({
            selectedFarmIds: existingSelectedFarmsIdArray,
            selectedActivityKey: ""
        }, () => {
            this.fetchCrops()
        })
    }

    handleSelectCrops = (cropId: string) => {

        let existingSelectedCropsIdArray = [...this.state.selectedCropsId]

        if (existingSelectedCropsIdArray.includes(String(cropId))) {
            // If ID is present, remove it from the array
            existingSelectedCropsIdArray = existingSelectedCropsIdArray.filter(itemId => itemId !== cropId);
        } else {
            // If ID is not present, add it to the array
            existingSelectedCropsIdArray.push(String(cropId));
        }
        this.setState({
            selectedCropsId: existingSelectedCropsIdArray,
            selectedActivityKey: ""
        })
    }

    // RESET FUNCTIONS
    resetSelectedFarmCrop = () =>{
        this.setState({
            selectedFarmIds:[],
            selectedCropsId:[]
        })
    }

    // HANDLE CURRENT YEAR
    handleChangeCurrentYear = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            currentSelectedYear: newValue,
            // RESET SOME STATES
            selectedFarmIds:[],
        }, () => { this.fetchCrops() })
        this.handleResetActivityState()
    }

    // HANDLE ADD CROP OPEN
    handleOpenAddCropModal = () => {
        this.setState({
            showAddCropModal: true,
            // reset
            showEditCropModal: false,            
            showViewCropModal: false,
            selectedCropId:"",
        })
    }
    // HANDLE ADD CROP CLOSE
    handleCloseAddCropModal = () => {
        this.setState({
            showAddCropModal: false,
            showEditCropModal: false,            
            showViewCropModal: false,
            selectedCropId:"",
        })
    }

    // HANDLE EDIT CROP OPEN
    handleOpenEditCropModal = (event: React.MouseEvent<SVGSVGElement>, cropId: string | number) => {
        event.stopPropagation()
        this.setState({
            showEditCropModal: true,
            selectedCropId: cropId,
            // reset
            showAddCropModal: false,
            showViewCropModal: false
        })
    }
    // HANDLE EDIT CROP CLOSE
    handleCloseEditCropModal = () => {
        this.setState({
            showEditCropModal: false,
            showAddCropModal: false,
            showViewCropModal: false
        })
    }
    // HANDLE VIEW CROP OPEN
    handleOpenViewCropModal = (cropId: string | number) => {
        this.setState({
            showViewCropModal: true,
            selectedCropId: cropId,
            // reset
            showAddCropModal: false,
            showEditCropModal: false
        })
    }
    // HANDLE VIEW CROP CLOSE
    handleCloseViewCropModal = () => {
        this.setState({
            showViewCropModal: false,
            showAddCropModal: false,
            showEditCropModal: false
        })
    }

    // OPEN ADD ACTIVITY MODAL
    OpenAddActivityModal = () => {
        this.setState({
            isAddAcvitity: true
        })
    }
    handleEditFarm=(e:React.MouseEvent<SVGSVGElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,id:string | number)=>{
        e.stopPropagation()
        this.setState({isFarmEdit:true,selectedFarmId:id})
    }
    
    handleCloseModal=()=>{
        this.setState({isFarmEdit:false,selectedFarmId:"",addFarmerModal:false})
    }

    handleAddCropModalCallback=()=>{
        this.setState({selectedFarmId:"",isFarmEdit:false})
        this.handleOpenAddCropModal()
    }

    handleAddActivityModal=()=>{
        this.setState((prev)=>({isAddAcvitity:!prev.isAddAcvitity}))
    }

    handleResetActivityState=()=>{
        this.setState({selectedActivityId:"",isDeleteActivityModal:false,isEditActivity:false,isAddAcvitity:false,selectedActivityKey:""})
    }

    deleteActivity=(activityId:string, selectedActivityKey?: string)=>{
        this.setState({selectedActivityId:activityId,isDeleteActivityModal:true, selectedActivityKey: selectedActivityKey ?? this.state.selectedActivityKey})
    }

    handleRenderError=(error:string[] | string)=>{
        if(typeof error === "string"){
          this.setState({popupTitle:"Error",errorPopup:error,isLoader:false,isDeletingActivity:false})
          return 
        }
        if(Array.isArray(error) && typeof error[0] === "string"){
          this.setState({popupTitle:"Error",errorPopup:error[0],isLoader:false,isDeletingActivity:false})
          return
        }
        if(Array.isArray(error) &&  typeof error[0] === "object"){
          this.setState({popupTitle:"Error",errorPopup:error[0][Object.keys(error[0])[0]],isLoader:false,isDeletingActivity:false})
        }
      }

    handleDeleteActivityResponse=(res:DeleteAcitivtyResponse)=>{
        let error=res?.error || res?.errors
        if(error){
          this.handleRenderError(error)
          this.handleCloseDeleteActivityModal()
          return
        }
        this.setState({selectedActivityId:"",isDeleteActivityModal:false,popupTitle:"Success",errorPopup:"Activity Deleted Successfully.",isDeletingActivity:false})
        this.fetchFarmAndCropActivities()
    }

    handleCloseDeleteActivityModal =() =>{
        this.setState({
            isDeleteActivityModal: false,
            isDeletingActivity: false,
            selectedActivityId: ""
        })
    }
    // handle get activities associated with farm and crop response
    handleFetchFarmAndCropActivitiesResponse=(responseJson:{data:ActivityDataTypes[]})=>{
        if(responseJson?.data){
            this.setState({selectedActivityData:responseJson.data,isActivitiesLoading:false})
        }else{  
            this.setState({ selectedActivityData: [] ,isActivitiesLoading:false});
        }
    }

    // HANDLE ACTIVITY ACCOUDIAN CLICK
    handleSelectActivity =
   (id: string) => (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
     this.setState({selectedActivityKey:newExpanded?id:"",selectedActivityData:[]})
   };

   // HANDLE EDIT ACTIVITY
   handleEditActivity=(id:string,farmId:FarmIdTypes,cropId:CropIdTypes, selectedActivityKey?: string)=>{
    this.setState({selectedActivityId:id,isEditActivity:true,selectedActivityFarmId:farmId,selectedActivityCropId:cropId, selectedActivityKey: selectedActivityKey ?? this.state.selectedActivityKey})
   }

   
    returnApiData = () => {
        let fetchEndpoint = ""
        let deleteEndpoint = ""
        let header: IHeader = {
            token: this.state.TokenValue
        };
        const userAccountId = this.state.userFarmDiary && this.state.userFarmDiary.id ? this.state.userFarmDiary.id: ""
        
        switch (this.state.selectedActivityKey) {
            case "GAP_FILLING":
                header = { ...header, "Content-Type": configJSON.validationApiContentType }
                deleteEndpoint = `admin/bx_block_farm_dairy/gap_fillings/${this.state.selectedActivityId}`
                fetchEndpoint = `admin/bx_block_farm_dairy/gap_fillings?account_id=${userAccountId}&year=${this.state.currentSelectedYear}&land_detail_ids=${this.state.selectedFarmIds}&crop_ids=${this.state.selectedCropsId}`
                break;
            case "NUTRIENT_MANAGEMENT":
                header = { ...header, "Content-Type": configJSON.validationApiContentType }
                fetchEndpoint = `admin/bx_block_farm_dairy/nutrients/nutrient_history?account_id=${userAccountId}&year=${this.state.currentSelectedYear}&land_detail_ids=${this.state.selectedFarmIds}&crop_ids=${this.state.selectedCropsId}`
                deleteEndpoint = `admin/bx_block_farm_dairy/nutrients/${this.state.selectedActivityId}?account_id=${userAccountId}`
                break;
            case "HARVEST":
                fetchEndpoint = `admin/bx_block_farm_dairy/harvests?account_id=${userAccountId}&year=${this.state.currentSelectedYear}&land_detail_id=${this.state.selectedFarmIds}&crop_id=${this.state.selectedCropsId}`
                deleteEndpoint = `admin/bx_block_farm_dairy/harvests/${this.state.selectedActivityId}?account_id=${userAccountId}`
                break;
            case "CROP_FAILURE": 
                header = { ...header, type: "admin_user" ,  "Content-Type": configJSON.validationApiContentType  }
                deleteEndpoint = `/mobile_and_web/farm_dairy/activity_crop_failures/${this.state.selectedActivityId}?user_id=${userAccountId}`
                fetchEndpoint = `/mobile_and_web/farm_dairy/activity_crop_failures/crop_failure_history?user_id=${userAccountId}&year=${this.state.currentSelectedYear}&land_detail_ids=${this.state.selectedFarmIds}&crop_ids=${this.state.selectedCropsId}`
                break;
            case "WEED_MANAGEMENT":
                header = { ...header, type: "admin_user"  }
                deleteEndpoint = `/mobile_and_web/farm_dairy/activity_weed_managements/${this.state.selectedActivityId}?user_id=${userAccountId}`
                fetchEndpoint = returnWeedEndpoint(userAccountId as string, this.state.currentSelectedYear, this.state.selectedFarmIds,this.state.selectedCropsId)
                break;
            case "PEST_MANAGEMENT":
                header = { ...header, type: "admin_user"  }
                deleteEndpoint = `/mobile_and_web/farm_dairy/activity_pest_managements/${this.state.selectedActivityId}?user_id=${userAccountId}`
                fetchEndpoint = returnPestEndpoint(userAccountId as string, this.state.currentSelectedYear, this.state.selectedFarmIds,this.state.selectedCropsId)
                break;
            case "PRE_SOWING":
                fetchEndpoint = `admin/bx_block_farm_dairy/pre_sowings/pre_sowing_history?year=${this.state.currentSelectedYear}&account_id=${userAccountId}&land_detail_ids=${this.state.selectedFarmIds}&crop_ids=${this.state.selectedCropsId}`
                deleteEndpoint = `admin/bx_block_farm_dairy/pre_sowings/${this.state.selectedActivityId}?account_id=${userAccountId}`
                break;
            case "IRRIGATION":
                header = { ...header, type: "admin_user" }
                deleteEndpoint = `mobile_and_web/farm_dairy/activity_irrigations/${this.state.selectedActivityId}?user_id=${userAccountId}` // NEED TO UPDATE
                fetchEndpoint = `mobile_and_web/farm_dairy/activity_irrigations/irrigation_history?year=${this.state.currentSelectedYear}&user_id=${userAccountId}&land_detail_ids=${this.state.selectedFarmIds}&crop_ids=${this.state.selectedCropsId}`
                break;
            case "SALE":
                header = { ...header, type: "admin_user" }
                fetchEndpoint = returnSellEndpoint(userAccountId as string, this.state.currentSelectedYear, this.state.selectedFarmIds,this.state.selectedCropsId)
                deleteEndpoint = `mobile_and_web/farm_dairy/activity_sells/${this.state.selectedActivityId}?user_id=${userAccountId}` // NEED TO UPDATE
                break;
            

        }
        return { fetchEndpoint, deleteEndpoint, header }
    }
    // Fetch Activities associated to farm and crop
    fetchFarmAndCropActivities=()=>{
        this.setState({isActivitiesLoading:true})
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchFarmAndCropActivitiesApiId = reqMessage.messageId;
        const endpoint = this.returnApiData().fetchEndpoint
        reqMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
        reqMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(this.returnApiData().header)
        );
        reqMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.GET_METHOD_TYPE
        );
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    deleteActivityApi = () => {
        if (!this.state.selectedActivityId) return;
        this.setState({isDeletingActivity:true})
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteActivityApiCallId = reqMessage.messageId;
        const endpoint = this.returnApiData().deleteEndpoint
        reqMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
        reqMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          this.returnApiData().header
        );
        reqMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.DELETE_METHOD_TYPE
        );
        runEngine.sendMessage(reqMessage.id, reqMessage);
      };

    onChildCreateOrUpdate = (newState: string) => {
        if (newState === "Success") {
            this.fetchCrops()
        }
        this.setState({ childState: newState });
    }

    // Customizable Area End
}