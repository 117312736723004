import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
    // Customizable Area Start
  Grid,
  Modal,
  Card,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  styled,
  Tooltip,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  Badge,
  Checkbox,
  CircularProgress,
  AccordionSummary,  
  AccordionDetails,
  Accordion
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Close, Edit,DateRange,KeyboardArrowDown,Add, Delete, KeyboardArrowLeft, KeyboardArrowRight  } from "@material-ui/icons";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { addCropPNG, BackArrowIconSVG, checkedRadioIconSVG, deleteIcon, editIcon, FilterViewCropSVG, mapPlusSVG, rupeeIconSVG, shareIcons, UncheckedRadioIconSVG } from "./assets";
import { NoMenuDataFound, renderErrorMessage } from "../../../components/src/AdminConsoleHelper";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SearchIcon from "@material-ui/icons/Search";
import ActivityFilterPopup from "../../../components/src/ActivityFilterPopup.web";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const inputPropCropArea = {
  min: 0.5,
  step: 1,
};
const inputQuantity = {
  min: 0,
  step: 10,
};
const inputpropBioAgentName = {
  startAdornment: (
    <InputAdornment className="startIconCustom" position="start">
      <img src={rupeeIconSVG} alt="rupees_image" />
    </InputAdornment>
  ),
  inputProps: {
    min: 0,
    max: 100000,
    step: 1, // Allow Integer values
  },
};
const inputpropSeedCost = {
  startAdornment: (
    <InputAdornment className="startIconCustom" position="start">
      <img src={rupeeIconSVG} alt="rupees_image" />
    </InputAdornment>
  ),
  inputProps: {
    min: 0,
    max: 50000,
    step: 1, // Allow Integer values
  },
};
const inputpropLaborCost = {
  startAdornment: (
    <InputAdornment className="startIconCustom" position="start">
      <img src={rupeeIconSVG} alt="rupees_image" />
    </InputAdornment>
  ),
  inputProps: {
    min: 50,
    max: 50000,
    step: 1, // Allow Integer values
  },
};
const inputPropNumberOfPackates = {
  inputProps: {
    min: 1,
    max: 100,
    step: 0.1, // Allow decimal values
  },
};

// Customizable Area End

import CfmultiplecropsController, {
  Props,
  configJSON,
} from "./CfmultiplecropsController";
import FarmMapping from "../../../components/src/FarmMapping/FarmMapping.web";
import { converStringToDate, getLabelFromId, keyDown, returnTruthyString } from "../../../components/src/helper";
import { IDropdownData } from "../../../components/src/interface.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import CustomModal from "../../../components/src/customModal.web";
import CommonActivityDetails from "../../../components/src/ActivityDetails/CommonActivityDetails.web";

export default class Cfmultiplecrops extends CfmultiplecropsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  farmMappingImage = ({ attachment }: { attachment: string | null }) => {
    const imgPartial = attachment ? attachment : undefined
    return (
      <>
        {attachment ? (
          <>
            <CameraStyledBadge
              style={{display:"block"}}
              overlap="rectangular"
              badgeContent={
                <Box>
                  <label htmlFor="edit-crop-mapping">
                    <Tooltip title="Edit">
                      <IconButton
                        component="span"
                        aria-label="edit"
                        disableRipple={true}
                        style={{ padding: "0.375rem" }}
                        onClick={() => { this.setState({ openFarmMapping: true, coordinates: this.state.tempCoordinates })}}
                      >
                        <Edit fontSize="small" htmlColor="#fff" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </Box>
              }
            >
              <Box>
                <Card variant="outlined" style={webStyle.badgeCardRoot}>
                  <CardMedia
                    component="img"
                    image={imgPartial}
                    src={imgPartial}
                    alt="cover-images"
                  />
                </Card>
              </Box>
            </CameraStyledBadge>
          </>
        ) : (
          <>
            <Box
              style={{
                background: "#FCFAF7",
                border: "1px solid #F0EBE0",
                borderRadius: "12px",
                padding: "16px",
                boxSizing: "border-box",
                height: "156px",
              }}
            >
              <Typography className="uploadTxt">
                {" "}
                Plot your farm's location on map
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label
                  htmlFor="add-crop-mapping"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    component={"span"}
                    onClick={() => {
                      this.setState({ openFarmMapping: true, coordinates: [], tempCoordinates: [] }, () => {
                        if (!this.state.farmMappingURL) {
                          this.fetchCurrentLocation()
                        }
                      })
                    }}
                    data-test-id="open-farm-mapping-btn"
                  >
                    <img src={mapPlusSVG} alt="mapIcon" />
                  </IconButton>
                </label>
              </Box>
            </Box>
          </>
        )}
      </>
    );
  };

  renderCropMapping = () => {
    return (
      <Dialog
        open={this.state.openFarmMapping}
        aria-labelledby="farm-mapping-modal"
        PaperProps={{
          style: { borderRadius: 12, width: "712px" },
        }}
        maxWidth={"md"}
        onClose={this.toggleFarmMappingModal}              
      >
        <DialogTitle>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={this.toggleFarmMappingModal}
              style={{ padding: 0, marginRight: "16px" }}
              data-testid="backFarmMappingbtn"
            >
              <img src={BackArrowIconSVG} />
            </IconButton>
            <Typography variant="subtitle1">Crop Mapping</Typography>
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <FarmMapping
            OpenFarmMapping={this.state.openFarmMapping}
            closeFarmMapping={this.toggleFarmMappingModal}
            onSave={this.onSaveFarmMapping}
            CoordinatesArr={this.state.coordinates.length ? this.state.coordinates : this.state.tempCoordinates}
            userLocation={this.state.currentLocation}            
            markerDefault={!this.state.coordinates.length ? this.state.currentLocation : this.state.markerDefault}
            isEditModal={this.props.isEditCropModalOpen}
            data-test-id="farmMappingComponent"
          />
        </DialogContent>
      </Dialog>
    );
  };

  renderCropImageURL = () => {
    let imageURL = addCropPNG
    if (this.props.isAddCropModalOpen && !this.props.isEditCropModalOpen) {
      imageURL = addCropPNG
    } else if (this.props.isEditCropModalOpen && this.state.cropDetailsData) {
      imageURL = this.state.cropDetailsData.attributes.crop_name_id?.crop_photo.url      
    }
    return imageURL
  }
  
  renderAddCropModal=()=>{
    const { initialCropFormValues, CropFormErrors } = this.state
    return <Grid item>
    <Modal
      open={this.triggerCropModal()}
      onClose={this.closeAddEditCropModal}
      data-testid="close_modal_button"
      style={webStyle.secondaryModalBox}
      BackdropProps={{
        style: webStyle.modalBackdropProps,
      }}
      className="addCropModal"
    >
        <Card variant="outlined" style={webStyle.AddCardBoxSuccess}>
          <Box style={webStyle.boxContainer}>
            <CustomAddCropBox>
              <Box style={webStyle.cropHeader}>
                <Box style={webStyle.headerLeft}>
                  <img src={this.renderCropImageURL()} alt="Crop" style={webStyle.headerImage} />
                  <Typography style={webStyle.headerTitle}>{this.renderCropModalTitle()}</Typography>
                </Box>
                <Box>
                  <IconButton style={{ padding: 0 }} onClick={this.closeAddEditCropModal}>
                    <Close />
                  </IconButton>
                </Box>
              </Box>                        
            </CustomAddCropBox>
            {this.state.cropDetailsDataLoading ?
              <Box style={webStyle.cropFormLoaderBox}>
                <CircularProgress size={40} color="secondary" />
              </Box>
              :
              <FormMainWrapper>
              <form
                data-testid='from-submit-testid'
                onSubmit={this.handleSubmitCropForm}
                noValidate
                autoComplete="off"
                className="customFormSpacing"
              >
                <FormGridWrapper container>
                  {/* SELECT FARM - SOWING DATE - CROP NAME - CROP TYPE */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} className="text-field-container">
                      <CustomFormLabel>Select Farm</CustomFormLabel>
                        <FormControl fullWidth>
                          <CustomSelectDD
                            disabled={this.props.isEditCropModalOpen}
                            IconComponent={CustomArrowIcon}
                            style={webStyle.inputFieldStyle}
                            data-testid="select_farm_testid"
                            variant="outlined"
                            color="secondary"
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            name="select_farm"
                            renderValue={(value) => {
                              if (initialCropFormValues.select_farm) {
                                return this.state.farmNamesList.find((item) => item.attributes.id == initialCropFormValues.select_farm)?.attributes?.name
                              } else if (!initialCropFormValues.select_farm) {
                                return (
                                  <span className="opcity-48">Select Farm</span>
                                );
                              }
                            }}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleSelectFarmValue('select_farm', event.target.value) }}
                            error={Boolean(CropFormErrors.select_farm.errorMsg)}
                          >
                            <MenuItem value={""} disabled>
                              <span className="opcity-48">Select Farm</span>
                            </MenuItem>
                            {this.state.farmNamesList.map((item) => {
                              return (
                                <MenuItem
                                  style={{ paddingBlock: "0px" }}
                                  value={item.attributes.id}
                                  key={item.id}
                                >
                                  <RadioGroup
                                    value={initialCropFormValues.select_farm}
                                    style={{
                                      width: "100%",
                                      borderBottom: "1px solid #F0EBE0",
                                    }}
                                  >
                                    <CustomRadioLabel
                                      value={item.attributes.id}
                                      control={<Radio />}
                                      style={{ width: "100%" }}
                                      label={
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography style={webStyle.cropDropDownLable}>
                                              {item.attributes.name}
                                            </Typography>
                                          </Box>
                                          <Typography style={webStyle.cropUnitText}>
                                            {item.attributes.farm_area}{" "}{item.attributes?.unit_farm_area_id?.name}
                                          </Typography>
                                        </Box>
                                      }
                                    />
                                  </RadioGroup>
                                </MenuItem>
                              );
                            })}
                            <CustomButton
                              onClick={this.openAddFarm}
                              startIcon={<Add style={{ width: "18px", height: "18px" }} />}
                            >
                              Add Farm
                            </CustomButton>
                          </CustomSelectDD>
                          {renderErrorMessage(CropFormErrors.select_farm.errorMsg)}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} className="text-field-container addCropDate">
                    <CustomFormLabel>Sowing Date</CustomFormLabel>
                      <FormControl fullWidth>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            required
                            data-testid="sowing_date_testid"
                            className="customDatePicker custom-textbox"
                            clearable                                                        
                            placeholder="DD/MM/YY"
                            format="DD/MM/yyyy"
                            inputVariant="outlined"
                            color="secondary"
                            keyboardIcon={
                              <DateRange style={webStyle.customDateIcon} />
                            }
                            minDate={moment().subtract(360, 'days').toDate()}
                            minDateMessage={'Please select a valid date'}
                            maxDate={moment()}
                            maxDateMessage={'Selected date cannot be a future date'}
                            name="sowing_date"
                            value={initialCropFormValues.sowing_date}
                            onChange={(newDate: Date) => { this.handleSowingDateValue('sowing_date', newDate) }}
                            error={Boolean(CropFormErrors.sowing_date.errorMsg)}
                            helperText={CropFormErrors.sowing_date.errorMsg}
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} className="text-field-container">
                      <CustomFormLabel>Crop Name</CustomFormLabel>
                      <FormControl fullWidth>
                        <CustomSelectDD
                          data-testid="crop_name_testid"
                          variant="outlined"
                          color="secondary"
                          style={webStyle.inputFieldStyle}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          name="crop_name"
                          IconComponent={CustomArrowIcon}
                          renderValue={() => {
                            if (initialCropFormValues.crop_name) {
                              return this.state.cropNamesList.find((item) => String(item.id) == String(initialCropFormValues.crop_name))?.name
                            } else if (!initialCropFormValues.crop_name) {
                              return (
                                <span className="opcity-48">Select Crop Name</span>
                              );
                            }
                          }}
                          onClose={this.resetSearchMenus}
                          error={Boolean(CropFormErrors.crop_name.errorMsg)}
                        >
                          <MenuItem value={""} disabled>
                            <span className="opcity-48">Select Crop Name</span>
                          </MenuItem>

                          <SearchBoxWrapper>
                            <Input
                              fullWidth
                              disableUnderline
                              // autoComplete="off"
                              autoFocus
                              className="custom_search_input"
                              data-testid="search_crop_name_testid"
                              type="text"
                              placeholder="Search by name"
                              onClick={(e) => e.stopPropagation()}
                              onKeyDown={(e) => e.stopPropagation()}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon
                                    style={{ color: "#1C2324", opacity: 0.48, width: 20, height: 20 }}
                                  />
                                </InputAdornment>
                              }
                              name="search_crop_name"
                              value={this.state.searchMenuItem}
                              onChange={(event: React.ChangeEvent<{ value: string }>) => { this.filterCropNamesMenus(event.target.value) }}
                            />
                          </SearchBoxWrapper>
                          <Box>
                            {this.state.filteredCropNamesList.length > 0 ?
                              this.state.filteredCropNamesList.map((each, index) => (
                                <MenuItem
                                  key={each.id}
                                  value={each.id}
                                  data-testid={`crop_name_menu_${index}`}
                                  // MAIN HANDLE FUNCTION 
                                  onClick={() => this.handleCropNameValue("crop_name", each.id)}
                                  selected={String(each.id) == String(initialCropFormValues.crop_name)}>
                                  {each.name}
                                </MenuItem>
                              ))
                              : NoMenuDataFound()
                            }
                          </Box>
                        </CustomSelectDD>
                        {renderErrorMessage(CropFormErrors.crop_name.errorMsg)}
                      </FormControl>
                    </Grid>
                      <Grid item xs={12} lg={6} className="text-field-container">
                        <CustomFormLabel>Crop Type</CustomFormLabel>
                        <FormControl fullWidth>
                          <CustomSelectDD
                            IconComponent={CustomArrowIcon}
                            style={webStyle.inputFieldStyle}
                            data-testid="crop_type_testid"
                            variant="outlined"
                            color="secondary"
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            name="crop_type"
                            value={initialCropFormValues.crop_type}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleCropTypeValue('crop_type', event.target.value) }}
                            error={Boolean(CropFormErrors.crop_type.errorMsg)}
                          >
                            <MenuItem value={""} disabled>
                              <span className="opcity-48">Select Crop Type</span>
                            </MenuItem>
                            {this.state.cropTypesList.length > 0 ?
                              this.state.cropTypesList.map((each) => (
                                <MenuItem
                                  key={each.id}
                                  value={each.id}
                                >
                                  {each.name}
                                </MenuItem>
                              ))
                              : NoMenuDataFound()
                            }
                          </CustomSelectDD>
                          {renderErrorMessage(CropFormErrors.crop_type.errorMsg)}
                        </FormControl>
                      </Grid>
                  </Grid>

                  {/* CROP AREA - CROP MAPPING - VARIETY  */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} className="text-field-container">
                    <CustomFormLabel>Crop Area</CustomFormLabel>
                      <FormControl fullWidth>
                        <TextField
                          className="textfield-with-select"
                          fullWidth
                          onKeyDown={keyDown}
                          data-testid="crop_area_value_testid"
                          placeholder="Enter Crop Area"
                          type="number"
                          variant="outlined"
                          color="secondary"
                          name="crop_area_value"
                          value={initialCropFormValues.crop_area_value}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleCropAreaValue('crop_area_value', event.target.value) }}
                          error={Boolean(CropFormErrors.crop_area_value.errorMsg)}
                          helperText={CropFormErrors.crop_area_value.errorMsg}
                          InputProps={{
                            endAdornment: this.state.unitOfCropAreaList.length === 1 || !this.state.unitOfCropAreaList.length ?
                              <>
                                <CustomUnitLabel>
                                  <Typography noWrap>
                                    {getLabelFromId(initialCropFormValues.crop_area_unit,this.state.unitOfCropAreaList)}
                                  </Typography>
                                </CustomUnitLabel>
                              </> : (
                                <SelectWithInput
                                  className="select-with-input"
                                  IconComponent={KeyboardArrowDown}
                                  data-testid="crop_area_unit_testid"
                                  value={initialCropFormValues.crop_area_unit}
                                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleCropAreaUnitValue('crop_area_unit', event.target.value) }}
                                  error={Boolean(CropFormErrors.crop_area_unit.errorMsg)}
                                >
                                  <MenuItem value={""} disabled>
                                    <span className="opcity-48"><em>None</em></span>
                                  </MenuItem>
                                  {
                                    this.state.unitOfCropAreaList.map((unit, index) => {
                                      return (
                                        <MenuItem
                                          key={unit.id}
                                          value={unit.id}
                                        >
                                          {unit.attributes.unit_name}
                                        </MenuItem>
                                      )
                                    })
                                  }
                                </SelectWithInput>
                              ),
                            inputProps: inputPropCropArea
                          }}
                        />
                      </FormControl>
                      {this.isCropNameCotton() ?
                        <Box marginTop="16px" className="text-field-container">
                          <CustomFormLabel>Variety</CustomFormLabel>
                          <FormControl fullWidth>
                            <Autocomplete
                              multiple          
                              className="crop-variety-autocomplete-custom customAutoComplete"                    
                              id="checkboxes-tags-demo"                                                          
                              options={this.state.cropVarietyList}                              
                              limitTags={2}
                              disableCloseOnSelect
                              getOptionLabel={(option: IDropdownData) => option.name}
                              value={initialCropFormValues.crop_variety}
                              onChange={(e: React.ChangeEvent<{}>, newVal: IDropdownData[]) => this.handleCropVarietyValue(newVal)}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    checked={selected}
                                  />
                                  {option.name}
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField {...params}
                                  variant="outlined"
                                  placeholder={this.returnCropVarietyPlaceholder()}
                                  error={Boolean(CropFormErrors.crop_variety.errorMsg)}
                                  helperText={CropFormErrors.crop_variety.errorMsg}
                                />
                              )}                                                            
                            />                          
                          </FormControl>
                        </Box>
                        : <></>}
                    </Grid>
                    <Grid item xs={12} lg={6} className="text-field-container" style={{ minHeight: "unset%" }}>
                      <FormLabel className="phasesLabelCustom">
                        Crop Mapping
                      </FormLabel>
                      {this.farmMappingImage({attachment: this.state.farmMappingURL})}
                    </Grid>
                  </Grid>

                  {/* NUMBER OF PACKETS OR QTY OF SEEDS - SEED SOURCE */}
                  <Grid container direction="row" alignItems="center" spacing={2} >
                    {this.isCropNameCotton() ?
                      <>
                        <Grid item xs={12} lg={6} className="text-field-container addCropDate">
                        <CustomFormLabel>Number of Packets</CustomFormLabel>
                          <FormControl>
                            <TextField
                              className='input-left-padding custom-textbox'
                              type="number"
                              variant="outlined"
                              color="secondary"
                              placeholder="Enter Number of Packets"
                              data-testid="number_of_packets_testid"
                              InputProps={inputPropNumberOfPackates}
                              name="number_of_packets"
                              value={initialCropFormValues.number_of_packets}
                              onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleNumberOfPacketsValue('number_of_packets', event.target.value) }}
                              error={Boolean(CropFormErrors.number_of_packets.errorMsg)}
                              helperText={CropFormErrors.number_of_packets.errorMsg}
                            />
                          </FormControl>
                        </Grid>
                      </> :
                      <>
                        <Grid item xs={12} lg={6} className="text-field-container">
                          <CustomFormLabel>Quantity of Seed</CustomFormLabel>
                          <FormControl fullWidth>
                            <TextField
                              className="textfield-with-select"
                              data-testid="quantity_of_seed_value_testid"
                              fullWidth
                              onKeyDown={keyDown}
                              placeholder="Enter Quantity"
                              type="number"
                              variant="outlined"
                              color="secondary"
                              name="quantity_of_seed_value"
                              value={initialCropFormValues.quantity_of_seed_value}
                              onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleQuantityOfSeedValue('quantity_of_seed_value', event.target.value) }}
                              error={Boolean(CropFormErrors.quantity_of_seed_value.errorMsg)}
                              helperText={CropFormErrors.quantity_of_seed_value.errorMsg}

                              InputProps={{
                                endAdornment: this.state.unitOfSeedQuantityList.length === 1 || !this.state.unitOfSeedQuantityList.length ?
                                  <>
                                    <CustomUnitLabel>
                                      <Typography noWrap>
                                        {getLabelFromId(initialCropFormValues.quantity_of_seed_unit,this.state.unitOfSeedQuantityList)}
                                      </Typography>
                                    </CustomUnitLabel>
                                  </> : 
                                 (
                                  <SelectWithInput
                                    className="select-with-input"
                                    IconComponent={KeyboardArrowDown}
                                    value={initialCropFormValues.quantity_of_seed_unit}
                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleQuantityOfSeedUnit('quantity_of_seed_unit', event.target.value) }}
                                    error={Boolean(CropFormErrors.quantity_of_seed_unit.errorMsg)}
                                  >
                                    <MenuItem value={""} disabled>
                                      <span className="opcity-48"><em>None</em></span>
                                    </MenuItem>
                                    {
                                      this.state.unitOfSeedQuantityList.map((unit, index) => {
                                        return (
                                          <MenuItem
                                            key={unit.id}
                                            value={unit.id}
                                          >
                                            {unit.attributes.unit_name}
                                          </MenuItem>
                                        )
                                      })
                                    }
                                  </SelectWithInput>
                                ),
                                inputProps: inputQuantity
                              }}
                            />
                          </FormControl>                          
                        </Grid>
                      </>
                    }

                    <Grid item xs={12} lg={6} className="text-field-container">
                    <CustomFormLabel>Seed Source</CustomFormLabel>
                      <FormControl fullWidth>
                        <CustomSelectDD
                          IconComponent={CustomArrowIcon}
                          data-testid="seed_source_testid"
                          variant="outlined"
                          color="secondary"
                          style={webStyle.inputFieldStyle}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          name="seed_source"
                          value={initialCropFormValues.seed_source}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleSeedSourceValue('seed_source', event.target.value) }}
                          error={Boolean(CropFormErrors.seed_source.errorMsg)}
                        >
                          <MenuItem value={""} disabled>
                            <span className="opcity-48">Select Seed Source</span>
                          </MenuItem>
                          {this.state.seedSourcesList.length > 0 ?
                            this.state.seedSourcesList.map((each) => (
                              <MenuItem
                                key={each.id}
                                value={each.id}
                              >
                                {each.name}
                              </MenuItem>
                            ))
                            : NoMenuDataFound()
                          }
                        </CustomSelectDD>
                        {renderErrorMessage(CropFormErrors.seed_source.errorMsg)}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* SEED COST - LABOR COST */}
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item xs={12} lg={6} className="text-field-container">
                    <CustomFormLabel>Seed Cost</CustomFormLabel>
                      <FormControl>
                        <TextField
                          className="inputIconTextFieldCustom custom-textbox"
                          type="number"
                          variant="outlined"
                          color="secondary"
                          placeholder="Total Seed Cost"
                          data-testid="seed_cost_testid"
                          InputProps={inputpropSeedCost}
                          onKeyDown={keyDown}
                          name="seed_cost"
                          value={initialCropFormValues.seed_cost}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleSeedCostValue('seed_cost', event.target.value) }}
                          error={Boolean(CropFormErrors.seed_cost.errorMsg)}
                          helperText={CropFormErrors.seed_cost.errorMsg}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} className="text-field-container">
                    <CustomFormLabel>Labor Cost</CustomFormLabel>
                      <FormControl>
                        <TextField
                          className="inputIconTextFieldCustom custom-textbox"
                          type="number"
                          variant="outlined"
                          color="secondary"
                          placeholder="Total Labor Cost"
                          data-testid="labor_cost_testid"
                          InputProps={inputpropLaborCost}
                          onKeyDown={keyDown}
                          name="labor_cost"
                          value={initialCropFormValues.labor_cost}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleLaborCostValue('labor_cost', event.target.value) }}
                          error={Boolean(CropFormErrors.labor_cost.errorMsg)}
                          helperText={CropFormErrors.labor_cost.errorMsg}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* PERFORMED SEED TREATMENT - NON GMO */}
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12} lg={6}>
                      <Grid container alignItems="center" style={{ flexWrap: "nowrap" }}>
                        <FormLabel className="phasesLabelCustom radio-field-label" style={{ whiteSpace: "nowrap" }}>
                          Performed Seed Treatment?
                        </FormLabel>

                        <FormControl style={{ marginLeft: "22px" }}>
                          <RadioGroup
                            row
                            aria-labelledby="performerSeedTreatment"
                            style={{ flexWrap: "nowrap" }}
                            data-testid="performed_seed_treatment_testid"
                            name="performed_seed_treatment"
                            value={initialCropFormValues.performed_seed_treatment}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handlePerformedSeedTreatmentValue('performed_seed_treatment', event.target.value) }}
                          >
                            <CustomFormControlLabel
                              value={'true'}
                              control={
                                <Radio
                                  icon={<img src={UncheckedRadioIconSVG} />}
                                  checkedIcon={<img src={checkedRadioIconSVG} />}
                                />
                              }
                              label="Yes"
                            />
                            <CustomFormControlLabel
                              value={'false'}
                              control={
                                <Radio
                                  icon={<img src={UncheckedRadioIconSVG} />}
                                  checkedIcon={<img src={checkedRadioIconSVG} />}
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {renderErrorMessage(CropFormErrors.performed_seed_treatment.errorMsg)}
                    </Grid>
                    {this.isCropNameCotton() ?
                      <>
                        <Grid item xs={12} lg={6}>
                          <Grid container alignItems="center">
                            <FormLabel className="phasesLabelCustom radio-field-label" style={{ whiteSpace: "nowrap" }}>
                              Non-GMO Seed?
                            </FormLabel>

                            <FormControl style={{ marginLeft: "32px" }}>
                              <RadioGroup
                                row
                                data-testid="non_gmo_seed_testid"
                                aria-labelledby="nonGmoSeed"
                                name="non_gmo_seed"
                                value={initialCropFormValues.non_gmo_seed}
                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleNonGmoSeedValue('non_gmo_seed', event.target.value) }}
                              >
                                <CustomFormControlLabel
                                  style={{ fontSize: "14px" }}
                                  value={'true'}
                                  control={
                                    <Radio
                                      icon={<img src={UncheckedRadioIconSVG} />}
                                      checkedIcon={<img src={checkedRadioIconSVG} />}
                                    />
                                  }
                                  label="Yes"
                                />
                                <CustomFormControlLabel
                                  style={{ fontSize: "14px" }}
                                  value={'false'}
                                  control={
                                    <Radio
                                      icon={<img src={UncheckedRadioIconSVG} />}
                                      checkedIcon={<img src={checkedRadioIconSVG} />}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          {renderErrorMessage(CropFormErrors.non_gmo_seed.errorMsg)}
                        </Grid>
                      </> : <></>}
                  </Grid>

                  {/* BIO AGENT FIELDS */}
                  {this.isPerformedSeedTreatment() ?
                    <>
                      <Grid container direction="row" alignItems="center" spacing={2} >
                        <Grid item xs={12} lg={6} className="text-field-container">
                          <CustomFormLabel>Bio-agent Name</CustomFormLabel>
                          <FormControl fullWidth>
                            <CustomSelectDD
                              data-testid="bio_agent_name_testid"
                              variant="outlined"
                              color="secondary"
                              style={webStyle.inputFieldStyle}
                              inputProps={{ "aria-label": "Without label" }}
                              displayEmpty
                              name="bio_agent_name"
                              IconComponent={CustomArrowIcon}
                              renderValue={() => {
                                if (initialCropFormValues.bio_agent_name) {
                                  return this.state.BioAgentNamesList.find((item) => String(item.id) == String(initialCropFormValues.bio_agent_name))?.name
                                } else if (!initialCropFormValues.bio_agent_name) {
                                  return (
                                    <span className="opcity-48">Select Bio-agent Name</span>
                                  );
                                }
                              }}
                              onClose={this.resetSearchMenus}
                              error={Boolean(CropFormErrors.bio_agent_name.errorMsg)}
                            >
                              <MenuItem value={""} disabled>
                                <span className="opcity-48">Select Bio-agent Name</span>
                              </MenuItem>

                              <SearchBoxWrapper>
                                <Input
                                  fullWidth
                                  disableUnderline
                                  // autoComplete="off"
                                  autoFocus
                                  className="custom_search_input"
                                  data-testid="search_bio_agent_name_testid"
                                  type="text"
                                  placeholder="Search by name"
                                  onClick={(e) => e.stopPropagation()}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <SearchIcon
                                        style={{ color: "#1C2324", opacity: 0.48, width: 20, height: 20 }}
                                      />
                                    </InputAdornment>
                                  }
                                  name="search_crop_name"
                                  value={this.state.searchMenuItem}
                                  onChange={(event: React.ChangeEvent<{ value: string }>) => { this.filterBioAgentMenus(event.target.value) }}
                                />
                              </SearchBoxWrapper>
                              <Box>
                                {this.state.filteredBioAgentNamesList.length > 0 ?
                                  this.state.filteredBioAgentNamesList.map((each, index) => (
                                    <MenuItem
                                      key={each.id}
                                      value={each.id}
                                      data-testid={`bio_agent_name_menu_${index}`}
                                      // MAIN HANDLE FUNCTION 
                                      onClick={() => this.handleBioAgentNameValue('bio_agent_name', each.id)}
                                      selected={String(each.id) === String(initialCropFormValues.bio_agent_name)}>
                                      {each.name}
                                    </MenuItem>
                                  ))
                                  : NoMenuDataFound()
                                }
                              </Box>
                            </CustomSelectDD>
                            {renderErrorMessage(CropFormErrors.bio_agent_name.errorMsg)}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} className="text-field-container">
                          <CustomFormLabel>Bio-agent Cost</CustomFormLabel>
                          <FormControl>
                            <TextField
                              className="inputIconTextFieldCustom custom-textbox"
                              type="number"
                              variant="outlined"
                              color="secondary"
                              placeholder="Enter Bio-agent Cost"
                              data-testid="bio_agent_cost_testid"
                              InputProps={inputpropBioAgentName}
                              onKeyDown={keyDown}
                              name="bio_agent_cost"
                              value={initialCropFormValues.bio_agent_cost}
                              onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleBioAgentCostValue('bio_agent_cost', event.target.value) }}
                              error={Boolean(CropFormErrors.bio_agent_cost.errorMsg)}
                              helperText={CropFormErrors.bio_agent_cost.errorMsg}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6} className="text-field-container">
                        <CustomFormLabel>Bio-agent Quantity</CustomFormLabel>
                          <FormControl fullWidth>
                            <TextField
                              className="textfield-with-select"
                              fullWidth
                              onKeyDown={keyDown}
                              placeholder="Enter Bio-agent Quantity"
                              data-testid="bio_agent_quntity_testid"
                              type="number"
                              variant="outlined"
                              color="secondary"
                              name="bio_agent_quntity"
                              value={initialCropFormValues.bio_agent_quntity}
                              onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleBioAgentQuntityValue('bio_agent_quntity', event.target.value) }}
                              error={Boolean(CropFormErrors.bio_agent_quntity.errorMsg)}
                              helperText={CropFormErrors.bio_agent_quntity.errorMsg}
                              InputProps={{
                                endAdornment: this.state.unitOfBioAgentList.length === 1 || !this.state.unitOfBioAgentList.length ?
                                  <>
                                    <CustomUnitLabel>
                                      <Typography noWrap>
                                        {getLabelFromId(initialCropFormValues.bio_agent_quntity_unit,this.state.unitOfBioAgentList)}
                                      </Typography>
                                    </CustomUnitLabel>
                                  </> : (
                                    <SelectWithInput
                                      className="select-with-input"
                                      IconComponent={KeyboardArrowDown}
                                      value={initialCropFormValues.bio_agent_quntity_unit}
                                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleBioAgentQuntityUnitValue('bio_agent_quntity_unit', event.target.value) }}
                                      error={Boolean(CropFormErrors.bio_agent_quntity_unit.errorMsg)}
                                    >
                                      <MenuItem value={""} disabled>
                                        <span className="opcity-48"><em>None</em></span>
                                      </MenuItem>
                                      {
                                        this.state.unitOfBioAgentList.map((unit, index) => {
                                          return (
                                            <MenuItem
                                              key={unit.id}
                                              value={unit.id}
                                            >
                                              {unit.attributes.unit_name}
                                            </MenuItem>
                                          )
                                        })
                                      }
                                    </SelectWithInput>
                                ),
                                inputProps: inputQuantity
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                    : <></>}

                  {/* SUBMIT BUTTON */}
                  <Grid container style={{ marginTop: "16px" }}>
                    <Button type="submit"
                      style={webStyle.cropSubmitButton}
                      data-testid="submit_button_testid"
                      disabled={this.state.createCropLoading}
                    >
                      {this.state.createCropLoading ?
                        <CircularProgress size={20} style={{ color: "#fff" }}/> :
                        <Typography style={webStyle.cropSubmitButtonText}> {this.renderCropModalSubmitButtonText()}</Typography>
                      }                      
                    </Button>
                  </Grid>
                </FormGridWrapper>
              </form>
              </FormMainWrapper>
              }
          </Box>
          {this.renderCropMapping()}
        </Card>
    </Modal>
  </Grid>
  }
  returnCropDetailsValues = () => {
    let crop_name = ""
    let crop_type = ""
    let crop_area_value = ""
    let crop_area_unit = ""
    let crop_variety = ""
    let farm_name = ""
    let crop_sowing_date = ""
    let crop_image_url = ""
    let crop_days = ""
    let is_crop_ended = false
    let is_freeze_record = false

    if (this.state.cropDetailsData) {
      const {
        crop_name_id,
        sowing_date,
        crop_type_id,
        land_detail_id,
        total_crop_area,
        unit_of_area_id,
        variety_ids,
        end_crop_status,
        crop_ended,
        freeze_record,
      } = this.state.cropDetailsData.attributes

      crop_name = returnTruthyString(crop_name_id?.name)
      crop_days = returnTruthyString(crop_name_id?.crop_days)
      crop_type = returnTruthyString(crop_type_id?.name)
      farm_name = returnTruthyString(land_detail_id.name)
      crop_sowing_date = returnTruthyString(converStringToDate(sowing_date))
      crop_area_value = returnTruthyString(total_crop_area)
      crop_area_unit = returnTruthyString(unit_of_area_id?.name)
      crop_image_url = returnTruthyString(crop_name_id?.crop_photo?.url)
      is_crop_ended = crop_ended as boolean
      is_freeze_record = freeze_record as boolean

      const namesString = variety_ids.length ? variety_ids.map(variety => variety.name).join(', ') : ""
      crop_variety = returnTruthyString(namesString)
    }
    return { crop_name, crop_days, crop_type, farm_name, crop_sowing_date, crop_image_url, crop_area_value, crop_area_unit, crop_variety, crop_ended:is_crop_ended, freeze_record:is_freeze_record}
  }

  renderViewCropModal = () => {
    const { crop_name, crop_days, crop_type, farm_name, crop_sowing_date, crop_image_url, crop_area_value, crop_area_unit, crop_variety,crop_ended, freeze_record } = this.returnCropDetailsValues()
    return (
      <Grid item>
        <Modal
          open={this.props.isViewCropModalOpen}
          onClose={this.props.handleCloseViewCropModal}
          aria-labelledby="modal-calendar-alert-validation"
          aria-describedby="modal-calendar-alert"
          data-testid="close_modal_button"
          style={styles.secondaryModalBox}
          BackdropProps={{
            style: styles.modalBackdropProps,
          }}
        >
          <Card variant="outlined" style={styles.viewCropBox}>
            <Box style={{height:"100%",overflow:"auto"}}>
            <Box style={styles.modalDummyupboxCotton}>
              <Grid container style={{ padding: "24px 10px 16px 32px" }}>
                <Grid item xs={6} style={{ marginTop: "8px" }}>
                  <Typography
                    style={{
                      color: crop_ended ? "#1C2324" :"#EB8F06",
                      fontFamily: "IBM Plex Sans",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "18px",
                      textTransform: "uppercase",
                      opacity: crop_ended ? 0.64 : 1
                    }}
                  >
                    {crop_type}
                  </Typography>
                  <Typography
                    style={{
                      color: "#1C2324",
                      fontFamily: "IBM Plex Sans",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "30px",
                      marginBottom: "8px",
                    }}
                  >
                    {crop_name}
                  </Typography>
                  <Typography
                    style={{
                      color: "#1C2324",
                      fontFamily: "IBM Plex Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "22px",
                      opacity: 0.64,
                    }}
                    noWrap
                  >
                    {crop_area_value} {crop_area_unit} {crop_variety ? <Tooltip title={crop_variety}><span>| {crop_variety}</span></Tooltip> : ""}
                  </Typography>
                  <Typography
                    style={{
                      color: "#1C2324",
                      fontFamily: "IBM Plex Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "22px",
                      opacity: 0.48,
                      display: "flex",
                      alignItems: "center",
                      marginTop: '4px'
                    }}
                  >
                    <LocationOnIcon htmlColor="#1C2324" style={styles.location} />
                    {farm_name}
                  </Typography>
                  <Typography
                    style={{
                      color: "#1C2324",
                      fontFamily: "IBM Plex Sans",
                      fontSize: "12px",
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "18px",
                      opacity: 0.48,
                      marginTop: "8px",
                    }}
                  >
                    Sowing: {crop_sowing_date}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      gap: "8px",
                      marginRight: "14px"
                    }}
                  >
                      <Tooltip title={freeze_record ? "Crop Freezed" : ""}>
                        <Edit
                          htmlColor="#1C2324"
                          style={{ ...styles.icon, ...{ cursor: !freeze_record ? "pointer" : "default" } }}
                          data-testid={`edit_library_button-${this.props.selectedCropId}`}
                          onClick={(event) => { !freeze_record ? this.handleOpenEditCropModal(event, this.props.selectedCropId as string) : {} }}
                        />
                      </Tooltip>

                      <Tooltip title={freeze_record ? "Crop Freezed" : ""}>
                        <Delete
                          htmlColor="#1C2324"
                          data-testid="delete_library_button"
                          style={{ ...styles.icon, ...{ cursor: !freeze_record ? "pointer" : "default" } }}
                          onClick={() => { !freeze_record ? this.handleOpenCropModal() : {} }}
                        />
                      </Tooltip>
                    { crop_ended ? 
                      <Button style={styles.cropRestartBtn} onClick={this.handleOpenRestartCropModal} data-testid="restart-crop-testid">RESTART CROP</Button> :
                      <Button style={{...styles.cropEndBtn, background: this.state.btnEnabled ?  "#b3261e": "#c96d64" }} onClick={() => this.calculateEnabledDate(crop_sowing_date, crop_days, crop_name, true)} data-testid="end-crop-testid">END CROP</Button>
                    }                                      
                    <Box style={{ marginLeft: "8px" }}>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label="close"
                        data-testid="close_modal_button"
                        onClick={this.handleCloseViewCropModal}
                      >
                        <Close style={{ color: "#000000" }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "12px",
                    }}
                  >
                    <img
                      src={crop_image_url}
                      width="176px"
                      height="109px"
                      style={{opacity: crop_ended ? 0.5 : 1, filter: crop_ended ? "grayscale(1)" : "grayscale(0)"}}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box style={styles.modalDummydownbox}>
              <Box style={{minHeight:"325px"}}>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography
                    style={{
                      color: "#1C2324",
                      fontFamily: "IBM Plex Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "22px",
                    }}
                  >
                    {this.state.filteredActivityData.length} Total Activities
                  </Typography>
                  <Box>
                    <Typography
                      style={{
                        color: "#1C2324",
                        fontFamily: "IBM Plex Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "22px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                        <IconButton style={{ padding: 0 }} onClick={() => this.handleYearChange(-1)} disabled={this.state.sowingYear === 2022}>
                          <KeyboardArrowLeft style={{ fontSize: "28px" }} />
                        </IconButton>
                        <div className="div-25">{this.state.sowingYear} - {(this.state.sowingYear + 1).toString().slice(-2)}</div>
                        <IconButton disabled={this.state.sowingYear === new Date().getFullYear()} style={{ padding: 0 }} onClick={() => this.handleYearChange(1)}>
                          <KeyboardArrowRight style={{ fontSize: "28px" }} />
                        </IconButton>
                      <Box>
                        <IconButton
                          data-testid="farmerListFilterIconBtnTestid"                          
                          style={{ padding: 0, marginLeft: "8px" }}
                          onClick={(event) => {
                            this.setState({ openTableFilterUT: true, anchorEl: event.currentTarget });
                          }}
                        >
                          <Badge
                             variant="dot"
                             color="primary"
                             className="filterApplied"
                             invisible={this.state.hideBadgeUT}
                          >
                            <img src={FilterViewCropSVG} />
                          </Badge>
                        </IconButton>
                      </Box>
                    </Typography>
                  </Box>
                </Box>

                {this.state.loader ? 
                <Box style={webStyle.loaderBox}>
                  <CircularProgress size={40} color="secondary" />
                </Box>
                :
                <CustomActivityContainer>
                  {this.state.filteredActivityData.length > 0 ? this.state.filteredActivityData.map((item, index) => {
                    return (
                      <AccordionActivityBox 
                        data-testid="activity-accordion-select"
                        expanded={this.state.activeAccordionIndex === item.data.id}
                        onChange={this.handleSelectActivity(item.data.id)}
                      >
                        <AccordionSummary
                          id="panel1a-header"
                          aria-controls="panel1a-content"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "66px",
                            padding:"8px"
                          }}
                        >
                          <Box style={{ display: "flex", gap: "12px" }}>
                            <img
                              src={this.handleFilteredActivitySvg(item.data.type)}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                            <Box>
                              <Typography style={styles.activityTitle}>{this.handleFilteredActivityName(item.data.type)?.name}</Typography>
                              <Typography style={styles.activityDate}>{moment(item.data.attributes.date).format("DD/MM/YYYY")}</Typography>
                            </Box>

                          </Box>
                          <Typography style={styles.activityCost}>₹{item.data.attributes.total_expenses ?? "0"}/-</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding:"0"
                          }}
                        >
                          <Box style={{ width: "100%" }}>
                            <CommonActivityDetails 
                              data={[item]}
                              deleteActivity={(id) => this.deleteActivity(id, this.handleFilteredActivityName(item.data.type).key)}
                              handleEditActivity={(id, land_detail_id, crop_id) => this.handleEditActivity(id, land_detail_id, crop_id, this.handleFilteredActivityName(item.data.type).key)}
                              selectedActivity={this.handleFilteredActivityName(item.data.type).key}
                              data-testid="weed-management-activity"
                            />
                          </Box>
                        </AccordionDetails>
                      </AccordionActivityBox>
                    );
                  }) :
                    <Box style={{ paddingBlock: "10px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                      <Typography>No Activities available</Typography>
                    </Box>
                  }
                </CustomActivityContainer>
                }
              </Box>

              <AddActivityBtn onClick={this.openAddActivity}>
                <Typography style={styles.submitBtnTxt}>
                  <Add /> ADD ACTIVITY
                </Typography>
              </AddActivityBtn>
            </Box>
            </Box>
          </Card>
        </Modal>
      </Grid>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {(this.props.isAddCropModalOpen || this.props.isEditCropModalOpen) && this.renderAddCropModal()}
        {this.props.isViewCropModalOpen && this.renderViewCropModal()}

        {/* END CROP MODAL START */}
        <CustomModal
          open={this.state.isEndCropModalOpen}
          title={configJSON.ViewCrop.EndCropModalTitle}
          description={configJSON.ViewCrop.EndCropModalDescription}
          confirmHandler={this.endCrop}
          disableConfirmHandler={this.state.endCropLoading}
          confirmTitle={"END CROP"}
          onClose={this.handleCloseEndCropModal}
          data-testid="end-crop-modal"
        />


         {/* RESTART CROP MODAL START */}
         <CustomModal
          open={this.state.isRestartCropModalOpen}
          title={configJSON.ViewCrop.RestartCropModalOpen}
          description={configJSON.ViewCrop.RestartCropModalDescription}
          confirmHandler={this.restartCrop}
          disableConfirmHandler={this.state.restartCropLoading}
          confirmTitle={"RE-START"}
          onClose={this.handleCloseRestartCropModal}
          data-testid="restart-crop-modal"
        />



        {/* DELETE-CROP-MODAL START */}
        <CustomModal
          open={this.state.isDeleteCropModalOpen}
          title={configJSON.ViewCrop.DeleteCropModalTitle}
          description={configJSON.ViewCrop.DeleteCropModalDescription}
          confirmHandler={this.deleteCrop}
          disableConfirmHandler={this.state.deleteCropLoading}
          confirmTitle={this.state.deleteCropLoading ? "Deleting..." : "Delete"}
          onClose={this.handleCloseDeleteCropModal}
        />

         {/* DISABLE-CROP-MODAL START */}
         <Modal
          onClose={this.closeWarningModal}
          open={this.state.openWarningModal}
          style={webStyle.modalWrapper}
        >
          <div style={webStyle.paper}>
            <IconButton style={{ padding: 0, position:"absolute", top:"15px", right:"15px"}} onClick={this.closeWarningModal}>
              <Close />
            </IconButton>
            <Typography variant='h2' id="server-modal-title" style={webStyle.modalHeading}>Not available yet!</Typography>
            <Typography id="server-modal-description" style={webStyle.modalPara}>You can end <span style={webStyle.modalspan}>{this.state.cropName}</span> crop after <span style={webStyle.modalspan}>{this.state.cropLastDate}.</span></Typography>
          </div>
        </Modal>

        {/* ERROR MODAL START */}
        <ErrorModal
          open={this.state.isModalOpen}
          errorTitle={this.state.modalTitle}
          errorSubTitle={this.state.modalMessage}
          BtnTitle="Okay"
          handleBtnClick={this.handleErrorModalClose}
        />

        <ActivityFilterPopup
          data-testid={'userTableFilterUserDashboard'}
          openTableFilter={this.state.openTableFilterUT}
          anchorEl={this.state.anchorEl}
          sortAscending={this.state.sortAscendingUT}
          sortDescending={this.state.sortDescendingUT}
          handleChangeAtoZ={this.handleChangeAtoZUT}
          handleChangeZtoA={this.handleChangeZtoAUT}
          handleSubmitFilter={this.handleSubmitFilterUT}
          handleCloseFilter={this.handleCloseTableFilterPopupUT}
          handleActivityChange={(event) => this.handleActivityChange(event)}
          selectedActivity={this.state.selectedActivity}
          handleFilterClearUT={this.handleVillageFilterClearUT}
        />

      </>
  
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const AddActivityBtn = styled(Button)({
  borderRadius: "28px",
  background: "#DF5200",
  width: "100%",
  paddingBlock: "10px",
  marginTop: "32px",
  height: "48px",
  "&:hover": {
    background: "#DF5200",
  }
})

const CustomRadioLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
      width: "100%",
  },
});
const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
  },
  "& .MuiIconButton-root": {
    padding: "5px",
  },
});
const SearchBoxWrapper = styled(Box)({
  margin: "8px 16px",
  background: "#FCFAF7",
  borderRadius: "28px",
  border: "2px solid #F0EBE0",
  '& .custom_search_input>.MuiInputAdornment-positionStart': {
      margin: "0 6px 0 12px !important"
  }
})
export const CameraStyledBadge = styled(Badge)(
  {
    "& .MuiBadge-badge": {
      right: "25px",
      top: "25px",
    }
  }
);

const CustomAddCropBox=styled(Box)({
  padding: "32px 32px 0 32px",
  '@media (max-width:600px)': {
    padding: "16px 16px 0 16px",
  },
})
const FormMainWrapper=styled(Box)({
  padding: "24px 32px 32px 32px",
  '@media (max-width:600px)': {
    padding: "16px",
  },
})
const FormGridWrapper=styled(Grid)({
  rowGap:"16px",  
})

const SelectWithInput = styled(Select)({
  '&.select-with-input': {
    height: "28px !important"
  },
  '& .MuiInputBase-formControl': {
    height: "auto"
  },
  '&.select-with-input .MuiSelect-iconOutlined': {
    right: "0 !important"
  },
  '&.select-with-input .MuiSelect-root': {
    width: "83px",
    height: "28px",
    padding: "5px 12px",
    boxSizing: "border-box",
    lineHeight: "2px",
    textAlign: "left",
    fontSize: "14px",
    borderRadius: "8px",
    background: "#F0EBE0",
  }
})

const CustomUnitLabel = styled(Box)({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  width: "83px",
  height: "28px",
  padding: "0px 12px",
  boxSizing: "border-box",
  lineHeight: "2px",
  textAlign: "center",
  fontSize: "14px",
  borderRadius: "8px",
  background: "#F0EBE0",
})

const AccordionActivityBox = styled(Accordion)({
  backgroundColor: "#FCFAF7 !important",
  boxShadow: "none !important",
  border: "1px solid #F0EBE0 !important",
  borderRadius: "12px !important",
  "& .MuiAccordionSummary-root": {
    padding: "8px !important"
  } as React.CSSProperties,
  "& .MuiAccordionSummary-content": {
    margin: "0",
    justifyContent: "space-between",
    alignItems: "center"
  } as React.CSSProperties,
})

const CustomFormLabel = styled(FormLabel)({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#1C2324",
  opacity: 0.64,
  marginBottom: "8px"
})

const CustomSelectDD = styled(Select)({
  "& .MuiSelect-select:focus": {
      backgroundColor: "transparent !important"
  },
  "& .MuiSelect-iconOutlined":{
      right:"10px !important"
  }
})
const CustomArrowIcon = styled(KeyboardArrowDown)({
  fontSize:"26px",    
  color: "#1C2324",
  opacity: 0.40
});
const CustomButton = styled(Button)({
  color: "#006A77",
  margin: "6px 0 0 12px",
  fontSize: "12px",
  fontWeight: 500,
  "& .MuiButton-startIcon": {
      marginRight: "2px ",
  },
});
const webStyle = {
  addCropBox: {
    padding: "32px",
  } as React.CSSProperties,
  cropFormLoaderBox:{
      height:"100%",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
  }as React.CSSProperties,
  loaderBox: {
    height:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    minHeight: "200px"
  } as React.CSSProperties,
  cropHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  } as React.CSSProperties,
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  } as React.CSSProperties,
  headerTitle: {
    color: "#1C2324",
    fontFamily: "IBM Plex Sans",
    fontSize: "20px",
    fontWeight: 500,
  } as React.CSSProperties,
  headerImage: {
    width: "48px",
    height: "48px",
    objectFit: "cover",
  } as React.CSSProperties,
  secondaryModalBox: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%",
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  } as React.CSSProperties,

  AddCardBoxSuccess: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:"95%",
    maxWidth: "712px",
    borderRadius: "12px",
    height: "95%",
  } as React.CSSProperties,
  boxContainer:{
    fontSize: "16px",
    height:"100%",
    overflowY: "auto",
  }as React.CSSProperties,
  modalDummycardboxCotton: {
    borderRadius: "12px",
    border: "1px solid #F0EBE0",
    background: "#FCFAF7",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
  } as React.CSSProperties,
  modalDummyupboxCotton: {
    backgroundColor: "#F0EBE0",
    flexShrink: 0,

    whiteSpace: "nowrap",
  } as React.CSSProperties,
  location: {
    width: "16px",
    height: "16px",
    cursor: "pointer",
    opacity: 0.48,
  } as React.CSSProperties,

  arrow: {
    width: "20px",
    marginLeft: "8px",
    height: "20px",
    cursor: "pointer",
    opacity: 0.56,
  } as React.CSSProperties,

  arrowback: {
    width: "20px",
    marginLeft: "8px",
    height: "20px",
    cursor: "pointer",
  } as React.CSSProperties,

  filter: {
    width: "22px",
    marginLeft: "8px",
    height: "22px",
    cursor: "pointer",
  } as React.CSSProperties,
  modalDummydownbox: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    margin: "24px 32px",
    height: "100%",
  } as React.CSSProperties,
  icon: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    opacity: 0.64,
  } as React.CSSProperties,

  radios: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 0 0",
  } as React.CSSProperties,

  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  } as React.CSSProperties,
  badgeCardRoot: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    minHeight: "150",
    padding: "0",
  } as React.CSSProperties,
  inputFieldStyle:{
    height: "56px"
  } as React.CSSProperties,
  cropSubmitButton: {
    borderRadius: "28px",
    background: "#DF5200",
    width: "100%",
    paddingBlock: "14px",
    height:"56px"
  } as React.CSSProperties,
  cropSubmitButtonText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 8,
  } as React.CSSProperties,
  cropDropDownLable: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#1C2324",
  } as React.CSSProperties,
  cropUnitText: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    fontStyle: "italic",
  } as React.CSSProperties,
  modalWrapper: {
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  paper: {
    textAlign: "center",
    width: "395px",
    padding: "70px 15px",
    backgroundColor: "#fff",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: ' translate(-50%, -50%)',
    boxShadow:' 2px 8px 32px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '12px',
  } as React.CSSProperties,
  modalHeading: {
    color: ' #1C2324',
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize:' 24px',
    paddingBottom:'16px',
  } as React.CSSProperties,
  modalPara: {
    maxWidth: '400px',
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight:' 28px',
    textAlign: 'center',
    color: ' #1C2324',
    margin: 'auto',
    paddingBottom:'24px',
  } as React.CSSProperties,
  modalspan: {
    fontFamily: 'IBM Plex Sans',
    fontWeight: 500,
    fontSize: '18px',
  } as React.CSSProperties,
  };

// VIEW CROPS
const CustomActivityContainer =styled(Box)({
  marginTop: "12px",
  display: "flex",
  gap: "12px",
  flexDirection: "column",
})

const styles = {
  secondaryModalBox: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%",
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  } as React.CSSProperties,

  viewCropBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:"95%",
    maxWidth: "528px",
    maxHeight:'95%',
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    overflow:"hidden"
  } as React.CSSProperties,
  modalDummycardboxCotton: {
    borderRadius: "12px",
    border: "1px solid #F0EBE0",
    background: "#FCFAF7",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
  } as React.CSSProperties,
  modalDummyupboxCotton: {
    backgroundColor: "#F0EBE0",
    flexShrink: 0,

    whiteSpace: "nowrap",
  } as React.CSSProperties,
  location: {
    width: "16px",
    height: "16px",
    cursor: "pointer",
    opacity: 0.48,
    marginRight:'4px'
  } as React.CSSProperties,

  arrow: {
    width: "20px",
    marginLeft: "8px",
    height: "20px",
    cursor: "pointer",
    opacity: 0.56,
  } as React.CSSProperties,

  arrowback: {
    width: "20px",
    marginLeft: "8px",
    height: "20px",
    cursor: "pointer",
  } as React.CSSProperties,

  filter: {
    width: "22px",
    height: "22px",
    cursor: "pointer",
  } as React.CSSProperties,
  modalDummydownbox: {
    backgroundColor: "#FFFFFF",
    margin: "24px 32px",
  } as React.CSSProperties,
  icon: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    opacity: 0.64,
  } as React.CSSProperties,
  checkBoxlabel: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    whiteSpace: "nowrap",
    color: "#1C2324",
    fontFamily: "IBM Plex Sans",
  } as React.CSSProperties,
  cropEndBtn: {
    borderRadius: "24px",
    background: "#B3261E",
    padding: "4px 12px",
    color: "#FFF",
    textAlign: "center",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "uppercase"
  } as React.CSSProperties,
  cropRestartBtn: {
    borderRadius: "24px",
    background: "#006A77",
    padding: "4px 12px",
    color: "#FFF",
    textAlign: "center",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "uppercase"
  } as React.CSSProperties,
  // Activity Title
  activityTitle: {
    color: "#1C2324",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  } as React.CSSProperties,
  activityDate: {
    color: "#1C2324",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    opacity: 0.64,
    marginTop: "2px",
  } as React.CSSProperties,
  activityCost: {
    color: "#1C2324",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    marginRight: "8px",
  } as React.CSSProperties,
tableCell: {
    border: "1px solid #F0EBE0",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
  } as React.CSSProperties,
  iconsbox: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  } as React.CSSProperties,
  actionIcon: {
    width: "20px",
    height: "20px",
  } as React.CSSProperties,
  iconbutton: {
    padding: 0,
  } as React.CSSProperties,
  opacity64: {
    opacity: "64%",
  } as React.CSSProperties,
  tableFooter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 12px",
  } as React.CSSProperties,
  shareInChat: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#006A77",
    lineHeight: "18px",
  } as React.CSSProperties,
  submitBtnTxt: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 8,
  } as React.CSSProperties,
};

// Customizable Area End
