import React from "react";
import {
    // Customizable Area Start
    Box,
    Input,
    Typography,
    InputAdornment,
    IconButton,
    Breadcrumbs,
    List,
    ListItem,
    ListItemText,
    Link,
    Button,
    Grid,
    CircularProgress,
    Badge,
    Tooltip,
    Paper,
    Tab,
    Tabs,
    Modal,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    styled,
    Card,
    CardContent,
    Container
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import './AdminConsoleUserFarmDiary.web.css'
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Add } from "@material-ui/icons";
import {
    imgIconFilter,
    linkSeparatorSVG,
    AddFarmPNG,
    AddCropPNG,
} from "./assets";

import AdminConsoleUserFarmDiaryController, {
  leftSideActivities,rightSideActivities,
    Props, UserFarm
} from "./AdminConsoleUserFarmDiaryController";

import CustomModal from "../../../components/src/customModal.web";
import UserTableFilter from "../../../components/src/UserTableFilter";
import EditIcon from "@material-ui/icons/Edit";
import InfiniteScroll from "react-infinite-scroll-component";
import Cfmultiplecrops from "../../cfmultiplecrops/src/Cfmultiplecrops.web";
import { configJSON } from "./AdminConsoleController";
import Cfmultiplefarms from "../../cfmultiplefarms/src/Cfmultiplefarms.web";
import Cfcropactivities from "../../cfcropactivities/src/Cfcropactivities.web";
import { ICropDetails, StaticActivityType } from "../../../components/src/interface.web";
import GapFillingActivityDetails from "../../../components/src/GapFillingActivityDetails";
import NutrientActivityDetails from "../../../components/src/ActivityDetails/NutrientActivityDetails.web";
import WeedManagementActivityDetails from "../../../components/src/ActivityDetails/WeedManagementActivityDetails";
import IrrigationActivityDetails from "../../../components/src/ActivityDetails/IrrigationActivityDetails.web";
import PestManagementActivityDetails from "../../../components/src/ActivityDetails/PestManagementActivityDetails.web";
import HarvestActivityDetails from "../../../components/src/ActivityDetails/HarvestActivityDetails.web";
import PresowingActivityDetails from "../../../components/src/ActivityDetails/PresowingActivityDetails";
import SellActivityDetails from "../../../components/src/ActivityDetails/SellActivityDetails.web";
import CropFailureActivityDetails from "../../../components/src/ActivityDetails/CropFailureActivityDetails.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#000",
            contrastText: "#fff",
        },
        secondary: {
            main: "#DF5200",
            contrastText: "#fff",
        },
    },
    typography: {
        h4: {
            fontWeight: 600,
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: "36px",
        },
        subtitle2: {
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "28px",
            padding: "16px 0px",
            textTransform: "uppercase",
        },
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    },
});

// Customizable Area End


export default class AdminConsoleUserFarmDiary extends AdminConsoleUserFarmDiaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

   

    returnImageStyle = (farmId:string) => {
        if (this.state.selectedFarmIds.includes(farmId)) {
            return { border: "1.5px solid #DF5200", ...styleWeb.cardImage }}
        return styleWeb.cardImage
    }

    renderUserFarm = (farmData: UserFarm, i: number) => {
        let { name, sent_delete_request, farm_image} = farmData.attributes
        return (
        <Box style={styleWeb.cardContainer}>
            <Box style={styleWeb.cardImageContainer}>
                <img alt="Farm Image" src={farm_image?.url || configJSON.imagePlaceholder} style={this.returnImageStyle(farmData.id)} onClick={() => this.handleSelectFarms(farmData.id)} data-test-id="select-farm-button"/>
              <Tooltip title={sent_delete_request ? "This Land detail is freezed":""}>
                <EditIcon htmlColor="#EB8F06" style={styleWeb.editButtonIcon} data-test-id="farm-edit-button" onClick={(e)=> !sent_delete_request ?this.handleEditFarm(e,farmData.id):{}} />
              </Tooltip>
            </Box>
            <Typography title={name} style={styleWeb.cardTitle}>{name}</Typography>
            <Button data-test-id="farm-view-button" style={styleWeb.cardButton} onClick={()=> {
                this.setState({selectedFarmId:farmData.id})
            }
            }>View</Button>
        </Box>
        )
    }

    renderUserFarms = () => {
        if(this.state.userFarmsLoading){
            return  <Box style={{display:"flex",justifyContent:"center",paddingBlock:"16px"}}>

                    <CircularProgress size={32} style={{ color: "#DF5200" }} />
                </Box>
        }
        if (this.state.userFarmsData.length === 0) {
            return(
                <Box className="farm-body-box-wrapper">
                    <Box style={{ textAlign: "center" }}>
                        <img src={AddFarmPNG} alt="farm" style={{ height: "65px" }} />
                    </Box>
                    <Typography className="farm-body-sub-text">Please add farm by clicking on <span style={{ fontWeight: 500 }}>“add farm”</span> button.</Typography>
                </Box>)
        }

        return(
            <Box style={styleWeb.cardsContainer}>
                <Grid container spacing={1}>
                    {this.state.userFarmsData.map((farm: UserFarm, i: number) => {
                        return <Grid key={i} xs={4} sm={4} md={3} item>{this.renderUserFarm(farm, i)}</Grid>
                    })}
                </Grid>
            </Box>
        )
    }

    renderCropImageStyle = (cropId: string) => {
        if (this.state.selectedCropsId.includes(cropId)) {
            return { ...styleWeb.cropCardImage, border: "1.5px solid #DF5200" }
        } else {
            return { ...styleWeb.cropCardImage, border: "1.5px solid #F0EBE0" }
        }
    }    

  renderCrop = (cropData: ICropDetails, cropIndex: number) => {
    const { flag, crop_ended, freeze_record } = cropData.attributes
    const { name, crop_photo } = cropData.attributes.crop_name_id ?? {};
    return (
      <Box style={{ ...styleWeb.cardContainer, opacity: crop_ended ? 0.5 : 1 }} >
        <Box style={{ ...styleWeb.cropImageContainer, cursor: flag ? "pointer" : "not-allowed" }} data-test-id="select-crop-button" >
          <img
            alt="crop"
            src={crop_photo?.url || configJSON.imagePlaceholder}
            onClick={() => flag ? this.handleSelectCrops(cropData.id) : {}}
            style={{...this.renderCropImageStyle(cropData.id), filter: crop_ended ? "grayscale(1)" : "grayscale(0)"}}
            data-test-id={`crop-image-testid-${cropIndex}`}
          />
          <Tooltip title={freeze_record ? "This Crop detail is freezed":""}>
            <EditIcon
              style={styleWeb.editButtonIcon}
              htmlColor="#EB8F06"
              data-test-id={`crop-edit-button-${cropIndex}`}              
              onClick={(e) => !freeze_record ? this.handleOpenEditCropModal(e, cropData.id) :{}}
            />
          </Tooltip>
        </Box>
        <Tooltip title={name}>
          <Typography style={styleWeb.cardTitle}>{name}</Typography>
        </Tooltip>
        <Button
          style={styleWeb.cardButton}
          data-test-id={`view-crop-button-${cropIndex}`}
          onClick={() => this.handleOpenViewCropModal(cropData.id)}
        >
          View
        </Button>
      </Box>
    )
  }
    renderCropsSection = () => {
        const { userCropsData,userCropsDataLoading } = this.state

        if (userCropsDataLoading) {
            return (
                <Box style={{ display: "flex", justifyContent: "center", paddingBlock: "16px" }}>
                    <CircularProgress size={32} style={{ color: "#DF5200" }} />
                </Box>
            )
        }
        if (userCropsData.length === 0 && !userCropsDataLoading) {
            return (
                <>
                    <Box className="farm-body-box-wrapper">
                        <Box style={{ textAlign: "center" }}>
                            <img src={AddCropPNG} alt="crop" style={{ height: "65px" }} />
                        </Box>
                        <Typography className="farm-body-sub-text">Please add crop by clicking on <span style={{ fontWeight: 500 }}>“add crop”</span> button.</Typography>
                    </Box>
                </>
            )
        }

        return (
            <>
                <Box style={styleWeb.cardsContainer}>
                    <Grid container spacing={1}>
                        {userCropsData.map((crop: ICropDetails, cropIndex: number) => {
                            return <Grid key={cropIndex} xs={4} sm={4} md={3} item>{this.renderCrop(crop, cropIndex)}</Grid>
                        })}
                    </Grid>
                </Box>
            </>
        )
    }


  // RENDER ACTIVITY DETAILS COMPONENT BASE ON THE THE ACTIVITY SELECTION
  renderActivityDetailComponent = (key: string) => {
    switch (key) {
      case "GAP_FILLING":
        return (
        <GapFillingActivityDetails 
            data={this.state.selectedActivityData}
            handleDelete={this.deleteActivity} 
            handleEdit={this.handleEditActivity}
            data-testid="gap-filling-activity"
        />
        )

      case "NUTRIENT_MANAGEMENT":
        return (
          <NutrientActivityDetails
            data={this.state.selectedActivityData}
            handleDelete={this.deleteActivity}
            handleEdit={this.handleEditActivity}
            data-testid="nutrient-activity"
          />
        )
      case "CROP_FAILURE":
        return (
          <CropFailureActivityDetails
            data-testid="crop-failure-activity"
            data={this.state.selectedActivityData}
            handleDelete={this.deleteActivity}
            handleEdit={this.handleEditActivity}
          />
        )
      case "WEED_MANAGEMENT":
        return (
          <WeedManagementActivityDetails
            data-testid="weed-management-activity"
            data={this.state.selectedActivityData}
            handleEdit={this.handleEditActivity}
            handleDelete={this.deleteActivity}
          />
        )
      case "IRRIGATION":
        return (
          <>
            <IrrigationActivityDetails
              data-testid="irrigation-activity"
              data={this.state.selectedActivityData}
              handleDelete={this.deleteActivity}
              handleEdit={this.handleEditActivity}
            />
          </>
        )
      case "PEST_MANAGEMENT":
        return (
          <PestManagementActivityDetails
            data-testid="pest-management-activity"
            data={this.state.selectedActivityData}
            handleDelete={this.deleteActivity}
            handleEdit={this.handleEditActivity}
          />
        )
      case "HARVEST":
        return (
          <HarvestActivityDetails
            data={this.state.selectedActivityData}
            handleDelete={this.deleteActivity}
            handleEdit={this.handleEditActivity}
            data-testid="harvest-activity"
          />
        )
      case "SALE":
        return (
          <SellActivityDetails
            data={this.state.selectedActivityData}
            handleDelete={this.deleteActivity}
            handleEdit={this.handleEditActivity}
            data-testid="sell-activity"
          />
        )
      case "PRE_SOWING":
        return (
          <PresowingActivityDetails
              handleDelete={this.deleteActivity} 
              data={this.state.selectedActivityData}
              data-testid="presowing-activity"
              handleEdit={this.handleEditActivity}
          />
          )
        
      default:
        return(         
          <Box style={{width:"100%"}}>
            <Typography style={{textAlign:"center",marginBottom:"10px"}}>No data found</Typography>
          </Box>        
      )
    }
  }

  renderActivityLoader=()=>{
    return (
      <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        width:"100%"
      }}
      >
      <CircularProgress size={28} style={{ color: "#DF5200" ,marginBottom:"5px"}} />
    </Box>
  )
  }

    renderAllActivity=()=>{
        return <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          gap: "8px",
        }}
      >
        <Grid container spacing={1}>
          <Grid xs={12} sm={6} item>
            {leftSideActivities.map((item: StaticActivityType) => {
              return (
                <Grid item xs={12} style={{ marginBottom: 10 }} key={item.id}>
                  <AccordionBox
                    style={{ borderRadius: "12px" }}
                    data-testid="activity-left-select"
                    expanded={this.state.selectedActivityKey === item.key}
                    onChange={this.handleSelectActivity(item.key)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Box style={{ padding: "0px 12px 0px 0px" }}>
                        <img
                          src={item.icon}
                          style={{ width: "32px", height: "32px" }}
                        />
                      </Box>
                      <Box>
                        <Typography style={styleWeb.faqheading}>
                          {item.name}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        display: "flex",
                        flexDirection: "column",                        
                        maxHeight: "450px",
                        minHeight:"40px",
                        overflow: "auto",
                      }}
                    >
                      {this.state.isActivitiesLoading && this.renderActivityLoader()}
                    {!this.state.isActivitiesLoading && this.state.selectedActivityKey == item.key && this.renderActivityDetailComponent(item.key)}
                    </AccordionDetails>                    
    
                  </AccordionBox>
                </Grid>
              );
            })}
          </Grid>
          <Grid xs={12} sm={6} item>
            {rightSideActivities.map(
              (item: StaticActivityType) => {
                return (
                  <Grid
                  key={item.id}
                    item
                    style={{ marginBottom: 10 }}
                    xs={12}
                  >
                    <AccordionBox
                      data-testid="activity-right-select"
                      style={{ borderRadius: "12px" }}
                      expanded={this.state.selectedActivityKey === item.key}
                      onChange={this.handleSelectActivity(item.key)}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ fontSize: 32 }} />
                        }
                        id="panel1a-header"
                        aria-controls="panel1a-content"
                      >
                        <Box style={{ padding: "0px 12px 0px 0px" }}>
                          <img
                            style={{ width: "32px", height: "32px" }}
                            src={item.icon}
                          />
                        </Box>
                        <Box>
                          <Typography style={styleWeb.faqheading}>{item.name}</Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          maxHeight: "450px",
                          minHeight:"40px",
                          display: "flex",
                          flexDirection: "column",                                                  
                          overflow: "auto",
                        }}
                    >
                      {this.state.isActivitiesLoading && this.renderActivityLoader()}
                    {!this.state.isActivitiesLoading && this.state.selectedActivityKey==item.key && this.renderActivityDetailComponent(item.key)}
                      </AccordionDetails>
                    </AccordionBox>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>
      </Box>
    }


    renderDeleteActivityModal = () => {
      return <Grid item>
        <Modal
          open={this.state.isDeleteActivityModal}
          onClose={this.handleCloseDeleteActivityModal}
          aria-labelledby="modal-calendar-alert-validation"
          aria-describedby="modal-calendar-alert"
          data-testid="close_modal_button"
          style={styleWeb.secondaryModalBox}
          disableScrollLock={true}
          BackdropProps={{
            style: styleWeb.modalBackdropProps,
          }}
        >
          <Card
            variant="outlined"
            style={styleWeb.modalCardBoxSuccess}
          >
            <CardContent style={styleWeb.secondaryModalCardsContent}>
              <Container
                style={styleWeb.secondaryModalContentsContainer}
              >
                <Typography align="center" style={{
                  fontFamily: configJSON.fontFamily,
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "36px",
                  color: "#1C2324",
                }}>
                  Delete Activity
                </Typography>
    
                <Box style={styleWeb.modalimageBox}>
                  <Typography align="center" style={{
                    fontFamily: configJSON.fontFamily,
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "#1C2324",
                    textAlign: "center"
                  }}>
                    Are you sure you want to delete this activity? 
                  </Typography>
                </Box>
                <Box style={styleWeb.modalTextSucessContainer}>
                  <Button
                    fullWidth
                    style={styleWeb.modalButtonGoBack}
                    onClick={this.deleteActivityApi}
                    data-testid={"delete_activity_button"}
                    disabled={this.state.isDeletingFarm}
                  >
                    {!this.state.isDeletingActivity ? <strong>DELETE</strong> : <CircularProgress size={28} style={{ color: "#fff" }} />}
    
                  </Button>
                  <Button
                    fullWidth
                    style={styleWeb.modalButtonCardCancel}
                    onClick={this.handleCloseDeleteActivityModal}
                    data-testid="close_delete_activity_modal_button"
    
                  >
                    <strong>CANCEL</strong>
                  </Button>
                </Box>
              </Container>
            </CardContent>
          </Card>
        </Modal>
      </Grid>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { navigation, id } = this.props;
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
              <NavigationMenu navigation={navigation} id={id}>
                  <ThemeProvider theme={theme}>
                      <Box className='mainContainerFarmDiaryWrapper'>
                          {/* Left Div */}
                          <Box className='userListFarmDiaryMain'>


                              <CustomModal
                                  open={!!this.state.errorPopup}
                                  onClose={() => this.toggleErrorPopup(null)}
                                  title={this.state.popupTitle}
                                  description={this.state.errorPopup}
                              />

                              <Box>
                                  <Typography className='headingTitle'>
                                      Users
                                  </Typography>
                              </Box>
                              <Box className='breadcrumbMain' style={{marginTop:"8px"}}>
                                  <Breadcrumbs
                                      separator={
                                          <img src={linkSeparatorSVG} alt={"separator"} />
                                      }
                                      aria-label="breadcrumb"
                                  >
                                      <Link
                                          className="menuLinkUser"
                                          href="/users"
                                      >
                                          Users
                                      </Link>
                                      <Typography className="menuTextUser">
                                          Farm Diary Entries
                                      </Typography>
                                  </Breadcrumbs>
                              </Box>
                              <Box className='userListWrapper'>
                                  <Box className='userListTitleWrapper'>
                                      <Typography className='userListTitle'>
                                          List of Farmers
                                      </Typography>
                                  </Box>
                                  <Box className='searchInputWrapper'>
                                      <Box style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                          <Box
                                              style={{
                                                  background: "#FCFAF7",
                                                  borderRadius: "28px",
                                                  border: "2px solid #F0EBE0",
                                                  width: "100%",
                                                  display: 'flex',
                                                  alignContent: 'center',
                                                  alignItems: 'center',
                                                  padding: "4px 0px"
                                              }}
                                          >
                                              <Input
                                                  id="searchTableId"
                                                  autoComplete="off"
                                                  disableUnderline
                                                  fullWidth
                                                  data-testid="serchFarmerInputTestid"
                                                  placeholder="Search farmer...."
                                                  type="search"
                                                  name="searchField"
                                                  startAdornment={
                                                      <InputAdornment position="start">
                                                          <SearchIcon
                                                              style={{ color: "#1C2324", opacity: 0.48, marginLeft: "19px" }}
                                                          />
                                                      </InputAdornment>
                                                  }
                                                  value={this.state.searchFarmerName}
                                                  onChange={this.handleChangeSearch}
                                              />
                                          </Box>
                                          <Box>
                                              <IconButton
                                                  className="filter-icon"
                                                  data-testid="farmerListFilterIconBtnTestid"
                                                  disabled={this.state.farmerDataLoading}
                                                  onClick={(event: any) => {
                                                      this.setState({
                                                          isFilterOpen: true,
                                                          anchorEl: event.currentTarget,
                                                      });
                                                  }}
                                              >
                                                  <Badge variant="dot" color="primary" className="filterApplied" invisible={this.state.hideBadgeFL} >
                                                      <img src={imgIconFilter} />
                                                  </Badge>
                                              </IconButton>
                                          </Box>
                                      </Box>
                                  </Box>

                                  <Box className='listWrapper' id="scrollableDiv">
                                      <List component="nav" aria-label="main mailbox folders">
                                          {
                                              this.isfarmerLoading() ? (
                                                  <>
                                                      <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                          <CircularProgress color="secondary" />
                                                      </Box>
                                                  </>
                                              ) : (
                                                  <>
                                                      <InfiniteScroll
                                                          dataLength={this.state.farmerData?.length}
                                                          next={this.fetchNextFarmers}
                                                          scrollableTarget="scrollableDiv"
                                                          hasMore={true}
                                                          loader={<></>}
                                                      >
                                                          {this.isfarmerData() ? (
                                                              this.state.farmerData.map((each: any, index: number) => (
                                                                  <>
                                                                      {
                                                                          <ListItem
                                                                              key={index + 3}
                                                                              data-testid={`userListItem${index}`}
                                                                              className='userlistIteam'
                                                                              button
                                                                              selected={this.state.userFarmDiary?.id === each?.id ? true : false}
                                                                              onClick={() => this.handleListItemClick(each)}
                                                                          >
                                                                              <ListItemText className='userListFont' primary={each?.attributes?.first_name + " " + each?.attributes?.last_name} />
                                                                          </ListItem>
                                                                      }

                                                                  </>
                                                              ))
                                                          ) : (
                                                              <>
                                                                  <Typography className="ps-16">- No Farmers Found</Typography>
                                                              </>
                                                          )}
                                                      </InfiniteScroll>
                                                  </>
                                              )
                                          }
                                      </List>
                                  </Box>
                              </Box>
                          </Box>

                          {/* Right Div */}
                          <Box className="diaryWrapper">
                              {this.state.farmDiaryLoading ?
                                  (<>
                                      <Box className="farmDiaryloader">
                                          <Box className='farmDiaryloaderWrapper'>
                                              <CircularProgress color="secondary" />
                                          </Box>
                                      </Box>
                                  </>) : (
                                      <>
                                          <Box className='userFarmDiaryDetailMain'>
                                              {/* Title */}
                                              <Box className='usernameTitleWrapper'>
                                                  <Box className='avtarTitleWrapper'>
                                                      <Box className='avtarWrapper'>
                                                          <Avatar className='userAvtarCustom'>
                                                              {this.userBadge()}
                                                          </Avatar>
                                                      </Box>
                                                      <Box className='usernameFontWrapper'>
                                                          <Tooltip title={this.userName()}>
                                                              <Typography noWrap className='userNameTitleFont wrapTextWidth'>{this.userName()}</Typography>
                                                          </Tooltip>
                                                          <Tooltip title={this.userFEname()}>
                                                              <Typography noWrap className='wrapTextWidth'><span className='userNameSubTitleFont b-400'>Field Executive: </span><span className='userNameSubTitleFont b-600'>
                                                                  {this.userFEname()}
                                                              </span></Typography>
                                                          </Tooltip>
                                                      </Box>
                                                  </Box>
                                                  <Box className='farmDiaryUpdateBtnWrapper'>
                                                      <Typography className="total-entries">Total Entries: <span>0</span></Typography>
                                                  </Box>
                                              </Box>
                                              <Box>
                                                  {/* Year wise filter */}
                                                  <Paper square>
                                                      <Tabs
                                                          variant="fullWidth"
                                                          data-testid="year-tab-testid"
                                                          value={this.state.currentSelectedYear}
                                                          indicatorColor="primary"
                                                          textColor="primary"
                                                          onChange={this.handleChangeCurrentYear}
                                                          aria-label="disabled tabs example"
                                                          className="custom-year-tab-wrapper"
                                                          style={{minHeight:"34px",height:"34px"}}
                                                          TabIndicatorProps={{ style: { display: 'none' } }}
                                                      >
                                                          <Tab label="2021-22" value={2021} className="custom-year-tab"/>
                                                          <Tab label="2022-23" value={2022} className="custom-year-tab"/>
                                                          <Tab label="2023-24" value={2023} className="custom-year-tab"/>
                                                          <Tab label="2024-25" value={2024} className="custom-year-tab"/>
                                                          <Tab label="2025-26" value={2025} className="custom-year-tab-last" disabled/>
                                                      </Tabs>
                                                  </Paper>
                                                  <Box className="farm-crop-box-wrapper-main">
                                                      <Box className="farm-crop-box-wrapper">
                                                          <Box className="title-btn-wrapper">
                                                              <Box>
                                                                  <Typography className="farm-crop-title-text">Choose Farm</Typography>
                                                              </Box>
                                                              <Box>
                                                              <Button style={{ padding: 0 }} onClick={this.handleOpenFarmModalCallback} id="add_farm_btn">
                                                              <Typography className="add-modal-button">+ Add Farm</Typography>
                                                              </Button>
                                                              <Cfmultiplefarms isOpenAddFarmProp={this.state.addFarmerModal} fetchCropsData={this.fetchCrops} data-test-id={"multiple-farm-component"} handleCloseModal={this.handleCloseModal} navigation={undefined} id={""} accountId={this.state.userFarmDiary?.id} getList={this.fetchUserFarms} isFarmEdit={this.state.isFarmEdit} selectedFarmId={this.state.selectedFarmId} handleEditFarm={this.handleEditFarm} handleAddCropModalCallback={this.handleAddCropModalCallback} />
                                                              </Box>
                                                          </Box>
                                                          {this.renderUserFarms()}

                                                      </Box>
                                                      <Box className="farm-crop-box-wrapper">
                                                          <Box className="title-btn-wrapper">
                                                              <Box>
                                                                  <Typography className="farm-crop-title-text">Choose Crop</Typography>
                                                              </Box>
                                                              <Box>
                                                                  <Button style={{ padding: 0 }} id="add-crop-btn"  onClick={this.handleOpenAddCropModal}>
                                                                      <Typography className="add-modal-button" >+ Add Crop</Typography>
                                                                  </Button>
                                                              </Box>
                                                          </Box>
                                                          {this.renderCropsSection()}
                                                      </Box>
                                                  </Box>
                                                  <Box style={{marginInline:"16px"}}>
                                                      {this.renderAllActivity()}
                                                      <Cfcropactivities  
                                                        navigation={null} 
                                                        id="" 
                                                        data-test-id="cf-crop-activity"
                                                        selectedyear={this.state.currentSelectedYear}
                                                        selectedActivityFarmId={this.state.selectedActivityFarmId}
                                                        selectedActivityCropId={this.state.selectedActivityCropId}
                                                        activitySelected={this.state.selectedActivityKey} 
                                                        selectActivityId={this.state.selectedActivityId} 
                                                        isEditActivity={this.state.isEditActivity} 
                                                        accountId={this.state.userFarmDiary && this.state.userFarmDiary.id} 
                                                        isAddActivity={this.state.isAddAcvitity}
                                                        handleAddActivityModal={this.handleResetActivityState} 
                                                        handleAddCropModalCallback={this.handleAddCropModalCallback}
                                                        handleOpenFarmModalCallback={this.handleOpenFarmModalCallback}
                                                        handleEditFarm={this.handleEditFarm}
                                                        onCreateOrUpdate={(status) => this.onChildCreateOrUpdate(status)}
                                                    />
                                                  </Box>

                                                  <Box style={{height:"350px"}} />
                                                  <Box style={{padding:"0 32px"}}>
                                                  <AddActivityBtn data-test-id="add-activity-button" onClick={this.handleAddActivityModal}>
                                                    <Typography style={styleWeb.submitBtnTxt}>
                                                      <Add /> ADD ACTIVITY
                                                    </Typography>
                                                  </AddActivityBtn>
                                                  </Box>
                                              </Box>
                                          </Box>
                                      </>
                                  )
                              }
                              <UserTableFilter
                                  data-testid={'userTableFilterFarmDiary'}
                                  openTableFilter={this.state.isFilterOpen}
                                  anchorEl={this.state.anchorEl}
                                  HideFEFilter={false}
                                  handleCloseFilter={this.handleCloseTableFilterPopupFL}
                                  handleVillageFilterClear={this.handleVillageFilterClearFL}
                                  handleSubmitFilter={this.handleSubmitFilterFL}
                                  expanded={this.state.expandedFL}
                                  handleChangePanelState={this.handleChangePanelFL("panel1")}
                                  handleChangePanelDistrict={this.handleChangePanelFL("panel2")}
                                  handleChangePanelTaluka={this.handleChangePanelFL("panel3")}
                                  handleChangePanelVillage={this.handleChangePanelFL("panel4")}
                                  handleChangeAtoZ={this.handleChangeAtoZFL}
                                  handleChangeZtoA={this.handleChangeZtoAFL}
                                  isDisabled={this.state.isDisabledFL}
                                  sortAscending={this.state.sortAscendingFL}
                                  sortDescending={this.state.sortDescendingFL}
                                  filteredStates={this.state.filteredStatesFL}
                                  filteredDistricts={this.state.filteredDistrictsFL}
                                  filteredTalukas={this.state.filteredTalukasFL}
                                  filteredVillages={this.state.filteredVillagesFL}
                                  selectedState={this.state.selectedStateFL}
                                  selectedDistrict={this.state.selectedDistrictFL}
                                  selectedTaluka={this.state.selectedTalukaFL}
                                  selectedVillage={this.state.selectedVillageFL}
                                  stateInputValue={this.state.stateInputValueFL}
                                  districtInputValue={this.state.districtInputValueFL}
                                  talukaInputValue={this.state.talukaInputValueFL}
                                  villageInputValue={this.state.villageInputValueFL}
                                  handleChangeStateField={this.handleChangeStateFieldFL}
                                  handleChangeDistrictField={this.handleChangeDistrictFieldFL}
                                  handleChangeTalukaField={this.handleChangeTalukaFieldFL}
                                  handleChangeVillageField={this.handleChangeVillageFieldFL}
                                  handleStateSearch={this.handleStateSearchFL}
                                  handleDistrictSearch={this.handleDistrictSearchFL}
                                  handleTalukaSearch={this.handleTalukaSearchFL}
                                  handleVillageSearch={this.handleVillageSearchFL}
                                  opacityStateCalculator={this.opacityStateCalculatorFL}
                                  opacityDistrictCalculator={this.opacityDistrictCalculatorFL}
                                  opacityTalukaCalculator={this.opacityTalukaCalculatorFL}
                                  opacityVillageCalculator={this.opacityVillageCalculatorFL}
                                  handleChangePanelFE={this.handleChangePanelFL("panel5")}
                                  filteredFieldExecutive={this.state.filteredFieldExecutiveFL}
                                  selectedFieldExecutive={this.state.selectedFieldExecutiveFL}
                                  fieldExecutiveInputValue={this.state.fieldExecutiveInputValueFL}
                                  handleChangeFieldExecutiveField={this.handleChangeFieldExecutiveFieldFL}
                                  handleFieldExectiveSearch={this.handleFieldExectiveSearchFL}
                                  opacityFieldExecutiveCalculator={this.opacityFieldExecutiveCalculatorFL}
                                  filterHasError={this.state.filterHasErrorFL}
                              />
                          </Box>
                      </Box>

                      {/* Crop block start */}

                      <Cfmultiplecrops
                          data-test-id={"multiple-crop-component"}
                          id={id}
                          navigation={navigation}                        
                          userAccountId={this.state.userFarmDiary && this.state.userFarmDiary.id}
                          // CURRENT CROP YEAR
                          currentCropYear={this.state.currentSelectedYear}
                          // ADD
                          isAddCropModalOpen={this.state.showAddCropModal}                        
                          handleCloseAddCropModal={this.handleCloseAddCropModal}
                          successAddCropCallback={this.fetchCrops}
                          // EDIT
                          isEditCropModalOpen={this.state.showEditCropModal}
                          handleOpenEditCropModal={this.handleOpenEditCropModal}                        
                          handleCloseEditCropModal={this.handleCloseEditCropModal}
                          selectedCropId={this.state.selectedCropId}
                          // VIEW
                          isViewCropModalOpen={this.state.showViewCropModal}
                          handleCloseViewCropModal={this.handleCloseViewCropModal}                          
                          OpenAddFarmModalfn={this.handleOpenFarmModalCallback} // OPEN ADD FARM MODAL                          
                          OpenAddActivityModalfn={this.OpenAddActivityModal} // OPEN ADD ACTIVITY MODAL    
                          deleteActivity={this.deleteActivity} 
                          handleEditActivity={this.handleEditActivity}    
                          errorPopup={this.state.errorPopup}              
                      />

                      {/* Crop block end */}

                      {this.state.isDeleteActivityModal && this.renderDeleteActivityModal()}

                  </ThemeProvider>
              </NavigationMenu>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const AccordionBox = styled(Accordion)({
    backgroundColor: "#F5F1E8",
    boxShadow: "none",
    border: "2px solid #F0EBE0",
    borderRadius: "12px",
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0!important",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
    "& .MuiAccordion-root.Mui-expanded:before": {
      opacity: 1,
      height: "2px",
      background: "rgba(0, 0, 0, 0.06)",
    },
    "&.MuiAccordion-root:before": {
      height: "0!important",
    },
    "& .MuiAccordion-root:before": {
      margin: "0 20px",
      height: "2px",
      background: "rgba(0, 0, 0, 0.06)",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0px 16px !important",
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
      "& .MuiPaper-rounded": {
        padding: "0px 24px!important",
      },
      "& .MuiPaper-rounded .MuiAccordionSummary-root": {
        padding: "0px!important",
      },
      "& MuiPaper-rounded .MuiAccordionSummary-root": {
        alignItems: "flex-start",
      },
      "& .MuiAccordionSummary-content": {
        margin: "12px 0px !important",
      },
    },
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
    "& .MuiIconButton-root": {
      padding: "0px !important",
      "& .MuiSvgIcon-root": {
        opacity: "40%",
      },
      "& .MuiIconButton-edgeEnd": {
        margin: 0,
      },
    },
  });

  const AddActivityBtn = styled(Button)({
    borderRadius: "28px",
    background: "#DF5200",
    width: "100%",
    paddingBlock: "10px",
    marginTop: "32px",
    height: "56px",
    "&:hover": {
      background: "#DF5200",
    }
  })

const styleWeb = {
    // Customizable Area Start
    badgeCardRoot: {
        width: "100%",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "12px",
        minHeight: "150",
        padding: "0",
    } as React.CSSProperties,


    // User Farm UI start
    cardsContainer:{
        marginTop: "12px"
    }as React.CSSProperties,

    cardContainer:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }as React.CSSProperties,

    cardImageContainer:{
        position: "relative",
        width: "48px",
        height: "48px",
        cursor: "pointer"
    }as React.CSSProperties,

    cropImageContainer:{
      position: "relative",
      width: "48px",
      height: "48px",
  }as React.CSSProperties,

    cardImage:{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "6px"
    }as React.CSSProperties,

    cardTitle:{
        fontSize: "14px",
        lineHeight: "22px",
        marginTop: "4px",
        textTransform: "capitalize",
        textAlign: "center",
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        userSelect: "none"
    }as React.CSSProperties,

    cardButton:{
        padding: 0,
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: 500,
        color: "#DF5200"
    }as React.CSSProperties,

    editButtonIcon:{
        fontSize:"16px",
        width:"24px",
        height:"24px",
        padding:"4px",
        background:"#FCFAF7",
        borderRadius:"50%",
        position:"absolute",
        right:"-7px",
        bottom:"-7px",
        cursor:"Pointer"
    }as React.CSSProperties,

    faqheading: {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "24px",
        textTransform: "capitalize",
        color: "#1C2324",
        fontFamily: configJSON.fontFamily,
      } as React.CSSProperties,
      opacity64: {
        opacity: "64%",
      } as React.CSSProperties,
      tableCell: {
        border: "1px solid #F0EBE0",
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#1C2324",
        padding: "8px 12px",
      } as React.CSSProperties,
      shareInChat: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#006A77",
        lineHeight: "18px",
      } as React.CSSProperties,
      iconsbox: {
        display: "flex",
        alignItems: "center",
        gap: "4px",
      } as React.CSSProperties,
      actionIcon: {
        width: "20px",
        height: "20px",
      } as React.CSSProperties,
      iconbutton: {
        padding: 0,
      } as React.CSSProperties,
      tableFooter: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px 12px",
      } as React.CSSProperties,

    // User Farm UI end

    cropCardImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "24px",
        userSelect: "none",
    } as React.CSSProperties,



    secondaryModalBox: {
      display: "flex",
      gap: 1,
      fontSize: "16px",
      justifyContent: "center",
      borderBlockStart: "1px solid #ccc",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      alignItems: "center",
      height: "100%"
    } as React.CSSProperties,
    modalBackdropProps: {
      backgroundColor: "rgba(0, 0, 0, .7)",
      backdropFilter: "blur(10px)",
    } as React.CSSProperties,
    modalCardBoxSuccess: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 528,
      width:"95%",
      height: 335,
      fontSize: "16px",
      // overflow: "scroll",
      display: "flex",
      "flexDirection": "column",
      justifyContent: "space-between",
      borderRadius: "12px",
    } as React.CSSProperties,
    secondaryModalCardsContent: {
      // backgroundColor: "red",
      padding: 0,
      paddingBlockStart: "4.5rem",
      height: "100%"
    },
    // delete activity modal
    secondaryModalContentsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      fontSize: "16px",
      alignItems: "center",
      height: "100%",
      width: "100%"
    } as React.CSSProperties,
    modalimageBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingInline: "3rem",
    } as React.CSSProperties,
    modalTextSucessContainer: {
      display: "flex",
      gap: "1rem",
      fontSize: "16px"
    } as React.CSSProperties,
    modalButtonGoBack: {
      width: "142px",
      backgroundColor: "#B3261E",
      color: "#fff",
      borderRadius: "28px",
      "textTransform": "none",
      fontWeight: 500,
      paddingBlock: "0.875rem"
    } as React.CSSProperties,
  
    modalButtonCardCancel: {
      width: "142px",
      backgroundColor: "#E4DDC9",
      borderRadius: "28px",
      "textTransform": "none",
      fontWeight: 500,
    } as React.CSSProperties,

    submitBtnTxt: {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "IBM Plex Sans",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "28px",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      gap: 8,
    } as React.CSSProperties,
    // Customizable Area End
}

// Customizable Area End
