import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ICreatedPreSowing, IDropdownData, ISelectFarm, ISelectFarmDataResponse, IUnitDropdown, IUnitDropdownResponse } from "../../../components/src/interface.web";
import moment from "moment";
import { defaultQuanitityUnit, returnTruthyString } from "../../../components/src/helper";
const isValueFalsy = (value: unknown) => value === "" || value === null || value === undefined


export interface IInitialValuePreSowing {
  id: string | number
  pre_sowing_activity_id: string | number
  date: Date | string | null
  crop_season_id: string | number
  labor_cost: string | number
  machine_cost: string | number
  fuel_consumption_value: string | number
  fuel_consumption_unit: string | number,
  errors: {
    pre_sowing_activity_id: string
    date: string
    crop_season_id: string
    labor_cost: string
    machine_cost: string
    fuel_consumption_value: string
    fuel_consumption_unit: string,
  }
}

export interface IRequestBody {
  farm_dairy: {
    pre_sowing: ICreatePreSowingBody[]
  }
}
export interface ICreatePreSowingBody {
  "id"?: string | number
  "land_detail_id"?: string | number
  "date": string | number
  "pre_sowing_activity_id": string | number
  "labor_cost": string | number
  "cost": string | number
  "crop_season_id": string | number
  "fuel_consumption"?: string | number
  "unit_of_fuel_consumption_id"?: string | number
}

const staticFormValue = {
  id: "",
  pre_sowing_activity_id: "",
  date: new Date(),
  crop_season_id: "",
  labor_cost: "",
  machine_cost: "",
  fuel_consumption_value: "",
  fuel_consumption_unit: "",
  errors: {
    pre_sowing_activity_id: "",
    date: "",
    crop_season_id: "",
    labor_cost: "",
    machine_cost: "",
    fuel_consumption_value: "",
    fuel_consumption_unit: "",
  }
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  userAccountId: string | number;
  isPreSowingModalOpen: boolean;
  handleCloseAddActivityModal: () => void;
  openAddCropModalfn: () => void,
  openAddFarmModalfn: () => void,
  isEditPreSowingModalOpen: boolean; // EDIT
  activityFarmId: string, // FARM ID
  activityCropId: string, // CROP ID 
  selectedyear: number
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken: string | null;
  // DROPDOWNS
  farmNamesList: ISelectFarm[],
  activityNamesList: IDropdownData[],
  cropSeasonNamesList: IDropdownData[],
  fuelUnitList: IUnitDropdown[],
  // FORMS
  selectedFarmId: string | number,
  selectedFarmIdError: string,
  preSowingForms: IInitialValuePreSowing[],
  // CREATE
  createPreSowingLoading: boolean;
  // FETCH
  fetchPreSowingLoading: boolean;
  // EDIT
  editPreSowingLoading: boolean;
  // ERROR MODAL
  customModalOpen: boolean;
  customModalTitle: string;
  customModalMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PreSowingActivityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchFarmNamesDataApiCallId: string = ""; // DROPDOWN
  fetchActivityDataApiCallId: string = ""; // DROPDOWN
  fetchSeasonDataApiCallId: string = ""; // DROPDOWN
  fetchFuelUnitApiCallId: string = ""; // DROPDOWN
  createPresowingApiCallId: string = ""; // CREATE
  fetchPresowingApiCallId: string = ""; // FETCH
  editPresowingApiCallId: string = ""; // EDIT

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authToken: localStorage.getItem("tokenn"),
      // DROPDOWNS
      farmNamesList: [],
      activityNamesList: [],
      cropSeasonNamesList: [],
      fuelUnitList: [],
      // FORMS
      selectedFarmId: "",
      selectedFarmIdError: "",
      preSowingForms: [staticFormValue],
      // CREATE
      createPreSowingLoading: false,
      // FETCH
      fetchPreSowingLoading: false,
      // EDIT
      editPreSowingLoading: false,
      // ERROR MODAL
      customModalOpen: false,
      customModalTitle: "",
      customModalMessage: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      switch (apiRequestCallId) {
        case this.fetchFarmNamesDataApiCallId:
          this.handleFarmNamesDataApiCallId(responseJson);
          break;
        case this.fetchActivityDataApiCallId:
          this.handleActivityDataApiCallId(responseJson);
          break;
        case this.fetchSeasonDataApiCallId:
          this.handleSeasonDataApiCallId(responseJson);
          break;
        case this.fetchFuelUnitApiCallId:
          this.handleFuelUnitApiCallId(responseJson);
          break;
        // CREATE
        case this.createPresowingApiCallId:
          this.handleCreatePresowingApiCallId(responseJson)
          break;
        // FETCH 
        case this.fetchPresowingApiCallId:
          this.handleFetchPresowingApiCallId(responseJson)
          break;
        // EDIT
        case this.editPresowingApiCallId:
          this.handleEditPresowingApiCallId(responseJson)
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start


  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if ((prevProps.isPreSowingModalOpen !== this.props.isPreSowingModalOpen) && this.props.isPreSowingModalOpen && !this.props.isEditPreSowingModalOpen) {
      this.setState({ fetchPreSowingLoading: false })
      this.resetInitialFormValues()
      this.fetchFarmData(this.props.userAccountId);
      this.fetchActivityData()
      this.fetchSeasonData()
      this.fetchFuelUnitData()
    }

    if ((prevProps.isEditPreSowingModalOpen !== this.props.isEditPreSowingModalOpen) && this.props.isEditPreSowingModalOpen) {
      this.fetchFarmData(this.props.userAccountId);
      this.fetchActivityData()
      this.fetchSeasonData()
      this.fetchFuelUnitData()
      this.getActivityDetailsData()
    }
  }

  // HANDLE API CALL
  handleFarmNamesDataApiCallId = (responseJson: ISelectFarmDataResponse) => {
    if (responseJson && responseJson.data && responseJson.data.length) {
      this.setState({ farmNamesList: responseJson.data })
    } else {
      this.setState({ farmNamesList: [] })
    }
  }

  handleActivityDataApiCallId = (responseJson: IDropdownData[]) => {
    if (responseJson && responseJson.length > 0) {
      this.setState({ activityNamesList: responseJson })
    } else {
      this.setState({ activityNamesList: [] })
    }
  }

  handleSeasonDataApiCallId = (responseJson: IDropdownData[]) => {
    if (responseJson && responseJson.length > 0) {
      this.setState({ cropSeasonNamesList: responseJson })
    } else {
      this.setState({ cropSeasonNamesList: [] })
    }
  }

  handleFuelUnitApiCallId = (responseJson: IUnitDropdownResponse | null | undefined) => {
    if (responseJson && responseJson.data.length > 0) {
      this.setState({ fuelUnitList: responseJson.data })
      if (this.props.isPreSowingModalOpen) {
        this.setState({
          preSowingForms: [{
            ...staticFormValue,
            fuel_consumption_unit: defaultQuanitityUnit(responseJson.data, "liter").unitId
          }]
        })

      }
    } else {
      this.setState({ fuelUnitList: [] })
    }
  }

  handleCreatePresowingApiCallId = (response: { errors: string[] } | { data: unknown }[]) => {
    if (response && 'errors' in response && !Array.isArray(response)) {
      this.setState({
        createPreSowingLoading: false,
        customModalOpen: true,
        customModalTitle: "Error",
        customModalMessage: response.errors[0],
      })
    } else if (Array.isArray(response) && response.length > 0) {
      this.setState({
        createPreSowingLoading: false,
        customModalOpen: true,
        customModalTitle: "Success",
        customModalMessage: "Pre-Sowing Activity added Successfully.",
      })
      this.handleCloseActivityModal()
    }
  }

  handleFetchPresowingApiCallId = (response: { data: ICreatedPreSowing[] } | undefined) => {
    if (response && response.data && response.data.length > 0) {
      this.setPreSowingFormsData(response.data)
    } else {
      this.setState({ fetchPreSowingLoading: false })
    }
  }

  handleEditPresowingApiCallId = (response: { errors: string[] } | { data: unknown }[]) => {
    if (response && 'errors' in response && !Array.isArray(response)) {
      this.setState({
        editPreSowingLoading: false,
        customModalOpen: true,
        customModalTitle: "Error",
        customModalMessage: response.errors[0],
      })
    } else if (Array.isArray(response) && response.length > 0) {
      this.setState({
        editPreSowingLoading: false,
        customModalOpen: true,
        customModalTitle: "Success",
        customModalMessage: "Pre-Sowing Activity updated Successfully.",
      })
      this.handleCloseActivityModal()
    }
  }
  // API CALL
  fetchFarmData = (userAccountId: string | number | null) => {
    if (!userAccountId) return
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user"
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFarmNamesDataApiCallId = requestMsg.messageId;

    const endpoint = `${configJSON.fetchFarmListDataAPiEndPoint}?user_id=${this.props.userAccountId}`
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  fetchActivityData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchActivityDataApiCallId = requestMsg.messageId;

    const endpoint = `${configJSON.preSowingActivityendPoint}`
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  fetchSeasonData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchSeasonDataApiCallId = requestMsg.messageId;

    const endpoint = `${configJSON.seasonListendPoint}`
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  fetchFuelUnitData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFuelUnitApiCallId = requestMessage.messageId;
    const endpoint = `${configJSON.unitDropdownAPIEndPoint}?activity=${'pre_sowing'}&dropdown=${'unit_of_fuel_consumption_pre_sowing'}`

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createPreSowingActivity = (requestBody: IRequestBody) => {
    if (!this.props.userAccountId) return
    this.setState({ createPreSowingLoading: true })
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createPresowingApiCallId = requestMessage.messageId;
    const endpoint = `admin/bx_block_farm_dairy/pre_sowings?account_id=${this.props.userAccountId}`

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getActivityDetailsData = () => {
    this.setState({ fetchPreSowingLoading: true })
    this.resetInitialFormValues()
    const farmId = this.props.activityFarmId
    const year = this.props.selectedyear
    const userAccountId = this.props.userAccountId

    if (farmId && this.props.userAccountId) {
      const headers = {
        token: this.state.authToken,
        "Content-Type": configJSON.validationApiContentType,
      };
      const endpoint = `admin/bx_block_farm_dairy/pre_sowings/pre_sowing_history?year=${year}&account_id=${userAccountId}&land_detail_ids=${farmId}`

      const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.fetchPresowingApiCallId = reqMessage.messageId;

      reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
      reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

      runEngine.sendMessage(reqMessage.id, reqMessage);
    } else {
      this.setState({ fetchPreSowingLoading: false })
    }
  }

  editPreSowingActivity = (requestBody: IRequestBody) => {
    if (!this.props.userAccountId) return
    this.setState({ editPreSowingLoading: true })
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editPresowingApiCallId = requestMessage.messageId;
    const endpoint = `admin/bx_block_farm_dairy/pre_sowings?account_id=${this.props.userAccountId}`

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // NEW CHANGES
  OpenPreSowingModal = () => this.props.isPreSowingModalOpen || this.props.isEditPreSowingModalOpen

  handleCloseActivityModal = () => {
    this.resetInitialFormValues()
    this.props.handleCloseAddActivityModal()
  }

  handleCloseCustomModal = () => {
    this.setState({
      customModalOpen: false,
      customModalTitle: "",
      customModalMessage: ""
    })
  }

  openAddFarm = () => {
    this.handleCloseActivityModal()
    this.props.openAddFarmModalfn()
  }

  handleAddAnotherEntry = () => {
    if (!this.isFormComplete()) {
      this.setState({
        customModalOpen: true,
        customModalTitle: "Error",
        customModalMessage: "Please fill the existing forms with valid data."
      })
    } else {
      let existingForms = [...this.state.preSowingForms];
      existingForms.push({
        ...staticFormValue,
        fuel_consumption_unit: defaultQuanitityUnit(this.state.fuelUnitList, "liter").unitId
      });
      this.setState({ preSowingForms: existingForms });
    }
  };

  handleRemoveEntry = (index: number) => {
    let existingForms = this.state.preSowingForms;
    existingForms.splice(index, 1);
    this.setState({ preSowingForms: existingForms });
  };

  // RESET FUNCTIONS
  resetInitialFormValues = () => {
    this.setState({
      selectedFarmId: "",
      selectedFarmIdError: "",
      preSowingForms: [{
        ...staticFormValue,
        fuel_consumption_unit: defaultQuanitityUnit(this.state.fuelUnitList, "liter").unitId
      }],
    })
  }

  handleChangeSelectFarm = (selectedFarmValue: string | number | unknown) => {
    this.setState({
      selectedFarmId: selectedFarmValue as string,
      selectedFarmIdError: this.validateSelectFarmValue(selectedFarmValue as string).errorMessage,
    })
  }
  validateSelectFarmValue = (selectedFarmValue: string | number | null) => {
    let errorMessage = ""
    if (isValueFalsy(selectedFarmValue)) {
      errorMessage = "Please Select Farm Name"
    }
    const isError = Boolean(errorMessage)
    return { errorMessage, isError }
  }

  // VALIDATE PRE-SOWING ACTIVITY 
  validatePreSowingActivityValue = (activityId: string | number | null) => {
    let errorMessage = ""
    if (isValueFalsy(activityId)) {
      errorMessage = "Please Select Pre-sowing Activity"
    }
    const isError = Boolean(errorMessage)
    return { errorMessage, isError }
  }
  updatePreSowingActivityValue = (name: string, value: unknown, formIndex: number) => {
    const Error = this.validatePreSowingActivityValue(value as string).errorMessage
    this.setFormValue(name, value, formIndex, Error)
  }

  // VALIDATE DATE
  validateDateValue = (date: string | Date | null) => {
    let errorMessage = ""
    const today = moment().endOf('day');
    const minDate = moment().subtract(360, 'days').startOf('day');

    if (isValueFalsy(date) || !moment(date).isValid()) {
      errorMessage = "Please enter valid Date"
    }
    if (moment(date).isBefore(minDate)) {
      errorMessage = "Please enter minimum Date"
    }
    if (moment(date).isAfter(today)) {
      errorMessage = "Date can not be more than Today's Date"
    }
    const isError = Boolean(errorMessage)
    return { errorMessage, isError }
  }
  updateDateValue = (name: string, value: unknown, formIndex: number) => {
    const DateValueError = this.validateDateValue(value as string).errorMessage
    this.setFormValue(name, value, formIndex, DateValueError)
  }

  // VALIDATE CROP SEASON
  validateCropSeasonValue = (seasonId: string | number | null) => {
    let errorMessage = ""
    if (isValueFalsy(seasonId)) {
      errorMessage = "Please Select Season"
    }
    const isError = Boolean(errorMessage)
    return { errorMessage, isError }
  }
  updateCropSeasonValue = (name: string, value: unknown, formIndex: number) => {
    const CropSeasonError = this.validateCropSeasonValue(value as string).errorMessage
    this.setFormValue(name, value, formIndex, CropSeasonError)
  }

  // VALIDATE LABOR COST
  validateLaborCostValue = (laborCost: string | number) => {
    let errorMessage = ""
    // Convert the value to an integer
    const floatValue = parseFloat(String(laborCost));

    if (isValueFalsy(laborCost)) {
      errorMessage = "Please enter Labor Cost"
    }
    if (isNaN(floatValue) && !isValueFalsy(laborCost)) {
      errorMessage = "Please enter valid Value"
    }
    if (floatValue < 50) {
      errorMessage = "Minimum allowed value is 50"
    }
    if (floatValue > 50000) {
      errorMessage = "Maximum allowed value is 50000"
    }
    // ADD MIN MAX VALIDATION IF REQUIRED
    const isError = Boolean(errorMessage)
    return { errorMessage, isError }
  }
  updateLaborCostValue = (name: string, value: unknown, formIndex: number) => {
    const LaborCostError = this.validateLaborCostValue(value as string).errorMessage
    this.setFormValue(name, value, formIndex, LaborCostError)
  }

  // VALIDATE MACHINE COST
  validateMachineCostValue = (machineCost: string | number) => {
    let errorMessage = ""
    // Convert the value to an integer
    const floatValue = parseFloat(String(machineCost));

    if (isValueFalsy(machineCost)) {
      errorMessage = "Please enter Machine Cost"
    }
    if (isNaN(floatValue) && !isValueFalsy(machineCost)) {
      errorMessage = "Please enter valid Value"
    }
    if (floatValue < 0) {
      errorMessage = "Minimum allowed value is 0"
    }
    if (floatValue > 3000) {
      errorMessage = "Maximum allowed value is 3000"
    }
    // ADD MIN MAX VALIDATION IF REQUIRED
    const isError = Boolean(errorMessage)
    return { errorMessage, isError }
  }
  updateMachineCostValue = (name: string, value: unknown, formIndex: number) => {
    const MachineCostError = this.validateMachineCostValue(value as string).errorMessage
    this.setFormValue(name, value, formIndex, MachineCostError)
  }

  // VALIDATE FUEL CONSUMPTION
  validateFuelValue = (fuelValue: string | number) => {
    let errorMessage = ""

    const isError = Boolean(errorMessage)
    return { errorMessage, isError }
  }
  updateFuelValue = (name: string, value: unknown, formIndex: number) => {
    const FuelValueError = this.validateFuelValue(value as string).errorMessage
    this.setFormValue(name, value, formIndex, FuelValueError)
  }

  // VALUDATE FUEL UNIT
  validateFuelUnit = (fuelUnit: string | number) => {
    let errorMessage = ""
    // if (isValueFalsy(fuelUnit)) {
    //   errorMessage = "Please select Fuel Consumption Unit"
    // }
    const isError = Boolean(errorMessage)
    return { errorMessage, isError }
  }
  updateFuelUnit = (name: string, value: unknown, formIndex: number) => {
    const FuelUnitError = this.validateFuelUnit(value as string).errorMessage
    this.setFormValue(name, value, formIndex, FuelUnitError)
  }

  handleChange = (name: string, value: unknown, formIndex: number) => {
    switch (name) {
      case "pre_sowing_activity_id":
        this.updatePreSowingActivityValue(name, value, formIndex)
        break;
      case "date":
        this.updateDateValue(name, value, formIndex)
        break;
      case "crop_season_id":
        this.updateCropSeasonValue(name, value, formIndex)
        break;
      case "labor_cost":
        this.updateLaborCostValue(name, value, formIndex)
        break;
      case "machine_cost":
        this.updateMachineCostValue(name, value, formIndex)
        break;
      case "fuel_consumption_value":
        this.updateFuelValue(name, value, formIndex)
        break;
      case "fuel_consumption_unit":
        this.updateFuelUnit(name, value, formIndex)
        break;
    }
  }

  setFormValue = (name: string, value: unknown, formIndex: number, errorMessage: string) => {
    const updatedFormValues = [...this.state.preSowingForms];

    updatedFormValues[formIndex] = {
      ...updatedFormValues[formIndex],
      [name]: value,
      errors: {
        ...updatedFormValues[formIndex].errors,
        [name]: errorMessage
      },
    };
    this.setState({ preSowingForms: updatedFormValues });
  }
  setAllFieldErrors = () => {
    const existingForms = [...this.state.preSowingForms];

    const updatedForms = existingForms.map((form) => {
      return {
        ...form,
        errors: {
          ...form.errors,
          pre_sowing_activity_id: this.validatePreSowingActivityValue(form.pre_sowing_activity_id).errorMessage,
          date: this.validateDateValue(form.date).errorMessage,
          crop_season_id: this.validateCropSeasonValue(form.crop_season_id).errorMessage,
          labor_cost: this.validateLaborCostValue(form.labor_cost).errorMessage,
          machine_cost: this.validateMachineCostValue(form.machine_cost).errorMessage,
          fuel_consumption_value: this.validateFuelValue(form.fuel_consumption_value).errorMessage,
          fuel_consumption_unit: this.validateFuelUnit(form.fuel_consumption_unit).errorMessage
        }
      }
    })
    this.setState({ preSowingForms: updatedForms })
  }
  isFarmValueIsValidated = () => {
    let isValidated = false;
    const farmValue = this.validateSelectFarmValue(this.state.selectedFarmId)

    this.setState({
      selectedFarmIdError: farmValue.errorMessage
    })

    if (!farmValue.isError) {
      isValidated = true
    }
    return isValidated
  }
  isAllFormDataIsValidated = () => {
    this.setAllFieldErrors()

    let allValidationPassed = false

    let validatedFormArrayBoolean: Boolean[] = []

    const allFormsData = [...this.state.preSowingForms];

    allFormsData.forEach((form, index) => {
      let eachFormFieldsValidated = false
      const ActiivityValueError = this.validatePreSowingActivityValue(form.pre_sowing_activity_id).isError
      const DateValueError = this.validateDateValue(form.date).isError
      const CropSeasonValueError = this.validateCropSeasonValue(form.crop_season_id).isError
      const LaborCostValueError = this.validateLaborCostValue(form.labor_cost).isError
      const MachineCostValueError = this.validateMachineCostValue(form.machine_cost).isError
      const FuelValueError = this.validateFuelValue(form.fuel_consumption_value).isError
      const FuelUnitError = this.validateFuelUnit(form.fuel_consumption_unit).isError

      if (!ActiivityValueError && !DateValueError && !CropSeasonValueError && !LaborCostValueError &&
        !MachineCostValueError && !FuelValueError && !FuelUnitError
      ) {
        eachFormFieldsValidated = true
      }
      validatedFormArrayBoolean.push(eachFormFieldsValidated)
    })

    allValidationPassed = validatedFormArrayBoolean.every(value => value === true)

    return allValidationPassed
  }

  reShapeBodyDataArray = () => {
    // CHECK ADD SCENARIO
    let reShapedArray = []
    const formStateData = [...this.state.preSowingForms]

    reShapedArray = formStateData.map((eachFormData) => {
      return {
        // SET ID FOR EDIT CASE
        ...(this.props.isPreSowingModalOpen && eachFormData.id &&
        {
          "id": eachFormData.id ? eachFormData.id : ""
        }),
        // RESTRICT UPDATE FARM WHILE EDIT
        ...(!Boolean(eachFormData.id) &&
        {
          "land_detail_id": this.state.selectedFarmId,
        }),
        "pre_sowing_activity_id": Number(eachFormData.pre_sowing_activity_id),
        "date": moment(eachFormData.date).format("DD/MM/YYYY"),
        "labor_cost": Number(eachFormData.labor_cost),
        "cost": Number(eachFormData.machine_cost),
        "crop_season_id": Number(eachFormData.crop_season_id),
        ...(eachFormData.fuel_consumption_value && eachFormData.fuel_consumption_unit &&
        {
          "fuel_consumption": Number(eachFormData.fuel_consumption_value),
          "unit_of_fuel_consumption_id": Number(eachFormData.fuel_consumption_unit),
        }),
      }
    })
    return reShapedArray;

  }

  isFormComplete = () => {
    let valid = true;
    if (!this.isFarmValueIsValidated()) {
      valid = false;
    }
    if (!this.isAllFormDataIsValidated()) {
      valid = false;
    }
    return valid;
  };

  onSubmitForm = () => {
    if (!this.isFormComplete()) {
      this.setState({
        customModalOpen: true,
        customModalTitle: "Error",
        customModalMessage: "Please fill the existing forms with valid data."
      })
    } else {
      const bodyDataArray = this.reShapeBodyDataArray()
      const requestBody = {
        "farm_dairy": {
          "pre_sowing": bodyDataArray
        }
      }
      if (this.props.isEditPreSowingModalOpen) {
        this.editPreSowingActivity(requestBody)
      } else {
        this.createPreSowingActivity(requestBody)
      }
    }
  }

  setPreSowingFormsData = (activityData: ICreatedPreSowing[]) => {
    let updateInitalValue: IInitialValuePreSowing[] = []
    const filteredData = activityData.filter((item) => item.attributes.freeze_record !== true);
    updateInitalValue = filteredData.map((item) => {

      const { id: itemId, attributes } = item;
      const { pre_sowing_activity_id, date, crop_season_id, labor_cost, cost, fuel_consumption, unit_of_fuel_consumption_id } = attributes;

      const pre_sowing_activity_value = Number(pre_sowing_activity_id && pre_sowing_activity_id[0]?.id)
      const date_value = new Date(String(date))
      const crop_season_id_value = Number(crop_season_id && crop_season_id[0]?.id)

      const labor_cost_value = Number(labor_cost)
      const machine_cost_value = Number(cost)

      const fuel_consumption_value = returnTruthyString(fuel_consumption)
      const fuel_consumption_unit = unit_of_fuel_consumption_id ? returnTruthyString(unit_of_fuel_consumption_id?.id) : defaultQuanitityUnit(this.state.fuelUnitList,"liter").unitId

      return {
        id: returnTruthyString(itemId),
        pre_sowing_activity_id: pre_sowing_activity_value,
        date: date_value,
        crop_season_id: crop_season_id_value,
        labor_cost: labor_cost_value,
        machine_cost: machine_cost_value,
        fuel_consumption_value: fuel_consumption_value,
        fuel_consumption_unit: fuel_consumption_unit,
        errors: {
          pre_sowing_activity_id: "",
          date: "",
          crop_season_id: "",
          labor_cost: "",
          machine_cost: "",
          fuel_consumption_value: "",
          fuel_consumption_unit: "",
        }
      }
    })

    this.setState({
      selectedFarmId: this.props.activityFarmId ? this.props.activityFarmId : "",
      preSowingForms: updateInitalValue,
      fetchPreSowingLoading: false
    })
  }

  // Customizable Area End
}
